import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { makeStyles, Paper, IconButton, Box, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import clsx from "clsx";
import { FilterIcon } from "icons";
import { SearchBar } from "components";
import Filter from "./Filter";
import ManageOwnerAction from "redux/manage-owner.redux";

const HeaderBar = ({ dataFilter, onChangeFilter, isShowFilter, onShowFilter }) => {
  const defaultClasses = useStyles();
  const dispatch = useDispatch();

  const [objectValue, setObjectValue] = useState(dataFilter);

  const onSubmitSearch = name => {
    let formFilter = { filter: name, page: 1 };

    setObjectValue(currentData => ({ ...currentData, ...formFilter }));
    if (objectValue.plan !== OPT_ALL_ITEMS_ID) {
      formFilter.plan = objectValue.plan;
    }
    if (objectValue.paymentStatus !== OPT_ALL_ITEMS_ID) {
      formFilter.paymentStatus = objectValue.paymentStatus;
    }

    if (
      objectValue.from.toDateString() !== TODAY.from.toDateString() &&
      objectValue.to.toDateString() !== TODAY.to.toDateString()
    ) {
      formFilter.from = objectValue.from;
      formFilter.to = objectValue.to;
    }
    onChangeFilter({ ...objectValue, filter: name });

    dispatch(ManageOwnerAction.getListOwner(formFilter));
  };

  const onChangeSelected = (event, name) => {
    let formData = { ...objectValue };
    if (name) formData[name] = event;
    else {
      formData.from = event.from;
      formData.to = event.to;
    }

    onChangeFilter(formData);
    setObjectValue(formData);
  };

  useEffect(() => {
    if (dataFilter) setObjectValue(dataFilter);
  }, [dataFilter]);

  return (
    <Paper className={defaultClasses.root}>
      <Box className={defaultClasses.titleBox}>
        <Box display="flex">
          <Typography className={clsx("medium-md-txt", defaultClasses.titleSearch)}>Tìm kiếm</Typography>
          <SearchBar onSearch={onSubmitSearch} />
        </Box>
        <IconButton onClick={onShowFilter} className={defaultClasses.buttonFilter}>
          {isShowFilter ? (
            <Close className={defaultClasses.iconClose} />
          ) : (
            <FilterIcon className={defaultClasses.iconFilter} />
          )}
        </IconButton>
      </Box>
      {isShowFilter && <Filter data={objectValue} onChangeSelected={onChangeSelected} />}
    </Paper>
  );
};

HeaderBar.propTypes = {
  dataFilter: PropTypes.shape({
    filter: PropTypes.string | PropTypes.number,
    plan: PropTypes.string | PropTypes.number,
    paymentStatus: PropTypes.string | PropTypes.number,
  }),
  isShowFilter: PropTypes.bool,

  onChangeFilter: PropTypes.func,
  onShowFilter: PropTypes.func,
};

HeaderBar.defaultProps = {
  isShowFilter: false,
  onChangeFilter: () => {},
  onShowFilter: () => {},
};

export default memo(HeaderBar);

const OPT_ALL_ITEMS_ID = "all";

const TODAY = {
  from: new Date(),
  to: new Date(),
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    padding: "11px 34px",
    borderRadius: 0,
    boxShadow: "0 1px 6px 0 rgba(0, 0, 0, 0.16)",
  },
  titleBox: {
    display: "flex",
    justifyContent: "space-between",
  },
  titleSearch: {
    margin: "8px 13px 8px 0",
    lineHeight: "22px",
    color: "#3e4045",
  },
  buttonFilter: {
    width: 30,
    height: 30,
    padding: 0,
    borderRadius: 0,
    border: "1px solid " + theme.palette.grey[200],
  },
  iconFilter: {
    width: 20,
    height: 20,
    color: "#4b4d53",
    opacity: 1,
  },
  iconClose: {
    width: 15,
    height: 15,
    color: "#4b4d53",
    opacity: 0.7,
  },
}));
