import { createMuiTheme } from "@material-ui/core";

const defaultTheme = createMuiTheme();

export default {
  root: {
    maxWidth: 1440,
    paddingLeft: 12,
    paddingRight: 12,
    margin: "auto",
    [defaultTheme.breakpoints.up("sm")]: {
      paddingLeft: 16,
      paddingRight: 16,
    },
    [defaultTheme.breakpoints.up("md")]: {
      paddingLeft: 30,
      paddingRight: 30,
    },
  },
};
