import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Dialog, Typography, IconButton } from "@material-ui/core";
import { DesktopMac, TabletMac, Tablet, PhoneIphone, Close, Language } from "@material-ui/icons";
import clsx from "clsx";
import { ApiConstant, LangConstant } from "const";
import { SelectBar } from "components";

const DialogPreview = props => {
  const { isShow, data, tabValue, onChangeLanguage, onClose } = props;
  const defaultClasses = useStyles({ color: data?.color || "" });
  const arrayDevices = arrayDevice(defaultClasses.icon);
  const arrayLanguages = arrayLanguage(defaultClasses.icon);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedLanguageIndex, setSelectedLanguageIndex] = useState(
    arrayLanguages.findIndex(item => item.code === tabValue),
  );
  const [formClasses, setFormClasses] = useState({
    dialogBody: defaultClasses.bodyDesktop,
    bodyLeft: defaultClasses.bodyLeft,
    subtitle: defaultClasses.subtitleDesktop,
    title: defaultClasses.title,
    bodyRight: defaultClasses.bodyRight,
  });

  const onChangeSelectedIndex = index => {
    let objClasses = convertObjectClasses(defaultClasses, arrayDevices[index].name);
    setSelectedIndex(index);
    setFormClasses(objClasses);
  };

  const onChangeTabLanguage = index => {
    setSelectedLanguageIndex(index);
    onChangeLanguage(arrayLanguages[index].code);
  };

  return (
    <Dialog open={isShow} fullScreen classes={{ paper: defaultClasses.dialogPaper }}>
      <Box className={defaultClasses.root}>
        <Box className={defaultClasses.dialogHeader}>
          <Typography className={clsx("medium-md-txt", defaultClasses.textPreview)}>Gboss Preview</Typography>
          <Box display="flex">
            <Box marginRight="8px">
              <SelectBar onChange={onChangeTabLanguage} arrayItem={arrayLanguages} indexItem={selectedLanguageIndex} />
            </Box>
            <SelectBar onChange={onChangeSelectedIndex} arrayItem={arrayDevices} indexItem={selectedIndex} />
            <IconButton className={defaultClasses.buttonClose} onClick={onClose}>
              <Close className={defaultClasses.iconClose} />
            </IconButton>
          </Box>
        </Box>
        <Box className={formClasses.dialogBody}>
          <Box className={formClasses.bodyLeft}>
            <Box className={formClasses.subtitle} dangerouslySetInnerHTML={{ __html: data.highlightTitle[tabValue] }} />
            <Typography className={formClasses.title}>{data.title[tabValue]}</Typography>
            <Typography className={defaultClasses.content}>{data.content[tabValue]}</Typography>
          </Box>
          <Box className={formClasses.bodyRight}>
            {data.slide[0]?.image && (
              <img src={ApiConstant.HOST_ATTACHMENT + data.slide[0].image} className={defaultClasses.img} alt="..." />
            )}
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

DialogPreview.propTypes = {
  isShow: PropTypes.bool,
  tabValue: PropTypes.string,
  data: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.shape({
      vi: PropTypes.string,
      en: PropTypes.string,
    }),
    highlightTitle: PropTypes.shape({
      vi: PropTypes.string,
      en: PropTypes.string,
    }),
    content: PropTypes.shape({
      vi: PropTypes.string,
      en: PropTypes.string,
    }),
    description: PropTypes.shape({
      vi: PropTypes.string,
      en: PropTypes.string,
    }),
    published: PropTypes.number,
    sortOrder: PropTypes.number,
    created: PropTypes.number,
    publishedTime: PropTypes.number,
    slide: PropTypes.array,
    slideType: PropTypes.number,
  }),
  onChangeLanguage: PropTypes.func,
  onClose: PropTypes.func,
};

DialogPreview.defaultProps = {
  isShow: false,
  onChangeLanguage: () => {},
  onClose: () => {},
};

export default memo(DialogPreview);

const NAME_DEVICE = {
  desktop: "Desktop",
  ipadPro: "Ipad Pro",
  ipad: "Ipad",
  mobile: "Mobile",
};

const convertObjectClasses = (defaultClasses, name) => {
  let objClasses = {
    dialogBody: defaultClasses.bodyDesktop,
    bodyLeft: defaultClasses.bodyLeft,
    subtitle: defaultClasses.subtitleDesktop,
    title: defaultClasses.title,
    bodyRight: defaultClasses.bodyRight,
  };

  switch (name) {
    case NAME_DEVICE.ipadPro:
      objClasses.dialogBody = defaultClasses.bodyIpadPro;
      objClasses.subtitle = defaultClasses.subtitle;
      break;
    case NAME_DEVICE.ipad:
      objClasses.dialogBody = defaultClasses.bodyIpad;
      objClasses.bodyLeft = defaultClasses.bodyLeftColumn;
      objClasses.subtitle = defaultClasses.subtitle;
      objClasses.bodyRight = defaultClasses.bodyRightColumn;
      break;
    case NAME_DEVICE.mobile:
      objClasses.dialogBody = defaultClasses.bodyMobile;
      objClasses.bodyLeft = defaultClasses.bodyLeftColumn;
      objClasses.subtitle = defaultClasses.subtitle;
      objClasses.bodyRight = defaultClasses.bodyRightColumn;
      break;
    default:
      break;
  }

  return objClasses;
};

const arrayLanguage = className => {
  let ARR_LANGUAGE = [
    {
      name: LangConstant.OBJ_LANGUAGE.vi.lang,
      icon: <Language className={className} />,
      code: LangConstant.OBJ_LANGUAGE.vi.code,
    },
    {
      name: LangConstant.OBJ_LANGUAGE.en.lang,
      icon: <Language className={className} />,
      code: LangConstant.OBJ_LANGUAGE.en.code,
    },
  ];
  return ARR_LANGUAGE;
};

const arrayDevice = className => {
  let ARR_DEVICE = [
    {
      name: NAME_DEVICE.desktop,
      icon: <DesktopMac className={className} />,
    },
    {
      name: NAME_DEVICE.ipadPro,
      icon: <Tablet className={className} />,
    },
    {
      name: NAME_DEVICE.ipad,
      icon: <TabletMac className={className} />,
    },
    {
      name: NAME_DEVICE.mobile,
      icon: <PhoneIphone className={className} />,
    },
  ];
  return ARR_DEVICE;
};

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    width: "100%",
    borderRadius: 0,
  },
  root: {
    width: "100%",
    height: "100%",
    backgroundColor: "#3e4045",
  },
  dialogHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 46,
    padding: "0 24px",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
  textPreview: {
    height: 22,
    lineHeight: 1.57,
    color: theme.palette.white,
  },
  icon: {
    height: 22,
    width: 22,
    marginRight: 8,
  },
  buttonClose: {
    width: 46,
    height: 46,
    marginLeft: 8,
    borderRadius: 0,
    backgroundColor: theme.palette.black,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  iconClose: {
    width: 46,
    height: 46,
    padding: 7,
    color: theme.palette.white,
  },
  bodyDesktop: {
    display: "flex",
    height: "calc(100vh - 46px)",
    padding: "50px 83px 40px 120px",
    backgroundColor: theme.palette.white,
  },
  bodyIpadPro: {
    display: "flex",
    width: 1024,
    height: "calc(100vh - 46px)",
    padding: "50px 32px 40px",
    margin: "auto",
    backgroundColor: theme.palette.white,
  },
  bodyIpad: {
    width: 768,
    height: "calc(100vh - 46px)",
    overflow: "auto",
    padding: "20px 28px 20px 47px",
    margin: "auto",
    backgroundColor: theme.palette.white,
  },
  bodyMobile: {
    width: 518,
    height: "calc(100vh - 46px)",
    overflow: "auto",
    padding: "4px 89px 20px",
    margin: "auto",
    backgroundColor: theme.palette.white,
  },
  img: {
    maxWidth: "100%",
  },
  bodyLeft: {
    width: "45%",
  },
  bodyLeftColumn: {
    width: "100%",
  },
  subtitleDesktop: {
    marginTop: 103,
    fontSize: 23,
    fontWeight: 800,
    lineHeight: 1.22,
    color: theme.palette.secondary.main,
  },
  subtitle: {
    fontSize: 23,
    fontWeight: 800,
    lineHeight: 1.22,
    color: theme.palette.primary.main,
  },
  title: {
    margin: "8px 0 24px",
    fontSize: 36,
    fontWeight: 800,
    lineHeight: 1.22,
    color: "#2a2c2f",
    wordBreak: "break-word",
  },
  content: {
    fontSize: 15,
    fontWeight: 500,
    lineHeight: 1.73,
    color: "#3e4045",
    wordBreak: "break-word",
  },
  bodyRight: {
    width: "55%",
    maxWidth: "55%",
    paddingTop: 24,
    paddingLeft: 24,
  },
  bodyRightColumn: {
    width: "100%",
    maxWidth: "100%",
    paddingTop: 24,
  },
}));
