// Common
export const HOST_REGISTRATION = "https://api.gboss.io/registration";
export const HOST_RESERVATION = "https://api.gboss.io/reservation";
export const HOST_ATTACHMENT = "https://api.gboss.io";

export const HEADER_DEFAULT = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export const HEADER_DEFAULT_UPLOAD_FILE = {
  ...HEADER_DEFAULT,
  "content-type": "multipart/form-data; boundary=<calculated when request is sent>",
};

export const TIMEOUT = 30000;

//Fixed value

// HTTP Status
export const STT_OK = 200;
export const STT_UNAUTHORIZED = 401;
export const STT_FORBIDDEN = 403;
export const STT_INTERNAL_SERVER = 500;

// Api
export const POST_LOGIN = "/auth/boss/login";

// Api Blogs Category
export const BLOGS_CATEGORY = "/blog-category";
export const BLOGS_CATEGORY_BY_ID = "/blog-category/{0}";

// Api Blogs Item
export const BLOG_ITEM = "/blog-item";
export const BLOG_ITEM_ID = "/blog-item/{0}";

// Api Upload Image
export const UPLOAD_IMAGE = "/attachment/upload";

// Api Shop
export const SHOP = "/shop";
export const SHOP_DETAIL = "/shop/{0}";
export const SHOP_RESERVATION_COUNT = "/reservation/shop/count";
export const SHOP_REGISTRATION = "/registration";
export const SHOP_LOG = "/registration-log";

// Api Setting
export const USER = "/user";
export const CREATE_USER = "/user/admin";
export const USER_ID = "/user/{0}";
export const CHANGE_PASSWORD = "/user/change-password";

// Api HomePage
export const HOME_INTRO = "/home/intro";
export const HOME_INTRO_ID = "/home/intro/{0}";
export const HOME_INTRO_SORT = "/home/intro/sort";
export const HOME_FEATURE = "/home/feature";
export const HOME_FEATURE_ID = "/home/feature/{0}";
export const HOME_EVALUATE = "/home/evaluation";
export const HOME_EVALUATE_ID = "/home/evaluation/{0}";

// Api Help Center
export const HELP_CATEGORY = "/help/category";
export const HELP_CATEGORY_ID = "/help/category/{0}";
export const HELP_CATEGORY_LIST = "/help/category/list";
export const HELP_QUESTION = "/help/question";
export const HELP_QUESTION_ID = "/help/question/{0}";

// Api Manage Owner
export const LIST_OWNER = "/owner";
export const OWNER_STATUS_ID = "/owner/state";
export const PAYMENTS_STATUS_ID = "/package-payment/status";

// Api Owner details
export const LIST_PACKAGE = "/package";
export const OWNER_INFO = "/owner/details/{0}";
export const PAYMENT_HISTORY = "/package-payment/history";
export const PAYMENT_DETAILS = "/package-payment/details/{0}";
export const PAYMENT_DETAIL_UID = "/package-payment/{0}";
export const PACKAGE = "/package";
