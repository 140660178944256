import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { AppConstant, LangConstant } from "const";
import clsx from "clsx";

const StatusItem = props => {
  const { status, statusProps } = props;

  const defaultClasses = useStyles();

  let bgClasses = "";
  switch (status) {
    case AppConstant.STATUS_OPTION.publish:
      bgClasses = defaultClasses.publish;
      break;
    case AppConstant.STATUS_OPTION.draft:
      bgClasses = defaultClasses.draft;
      break;
    case AppConstant.STATUS_OPTION.deleted:
      bgClasses = defaultClasses.deleted;
      break;
    default:
      break;
  }

  return (
    <Box className={clsx(defaultClasses.root, bgClasses)}>
      <Typography className={clsx("medium-sm-txt", defaultClasses.contentStatus)}>
        {statusProps ? statusProps.customizedStatusArray[status].name : LangConstant.ARRAY_STATUS[status].name}
      </Typography>
    </Box>
  );
};

StatusItem.propTypes = {
  status: PropTypes.number.isRequired,
};
StatusItem.defaultProps = {};

export default memo(StatusItem);

const useStyles = makeStyles(() => ({
  root: {
    width: "auto",
    padding: "2px 16px",
    borderRadius: 4,
  },
  contentStatus: {
    textAlign: "center",
    lineHeight: 1.67,
    color: "#3e4045",
  },
  publish: {
    width: 86,
    backgroundColor: "rgba(101, 179, 157, 0.2)",
  },
  draft: {
    width: 86,
    backgroundColor: "rgba(79, 139, 255, 0.2)",
  },
  deleted: {
    width: 72,
    backgroundColor: "rgba(254, 186, 64, 0.2)",
  },
}));
