import React, { memo, useState, useEffect } from "react";
import { makeStyles, Box } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import StringFormat from "string-format";
import ManageLayout from "layouts/ManageLayout";
import { AppConstant, LangConstant } from "const";
import { IntroItemList, ACTION_OPTION, Slide } from "./components";
import IntroductionAction from "redux/introduction.redux";

const Introduction = () => {
  const defaultClasses = useStyles();
  const dispatch = useDispatch();

  const arrayHomeIntro = useSelector(props => props.introductionRedux.data);

  const [numberOfShowing, setNumberOfShowing] = useState(0);
  const [listSlides, setListSlides] = useState([]);
  const [formSlide, setFormSlide] = useState(DEFAULT_SLIDE_FORM);

  const onCreateNew = () => {
    setFormSlide({ ...formSlide, state: ACTION_OPTION.create });
  };

  const onOpenSlideDetail = formSlide => {
    setFormSlide(formSlide);
  };

  const onResetForm = () => {
    setFormSlide(DEFAULT_SLIDE_FORM);
  };

  useEffect(() => {
    dispatch(IntroductionAction.getHomeIntro());
  }, []);

  useEffect(() => {
    let numberOfSlidesShowing = 0;
    arrayHomeIntro.forEach(item => {
      if (item.published === AppConstant.STATUS_OPTION.publish) {
        numberOfSlidesShowing++;
      }
    });
    setNumberOfShowing(numberOfSlidesShowing);
    setListSlides(arrayHomeIntro);
  }, [arrayHomeIntro]);

  let isOpenSlide = [ACTION_OPTION.create, ACTION_OPTION.detail, ACTION_OPTION.edit].includes(formSlide.state);
  return isOpenSlide ? (
    <Slide
      stateSlide={formSlide.state}
      numberOfShowing={numberOfShowing}
      data={formSlide.data}
      onEditSlide={() => setFormSlide({ ...formSlide, state: ACTION_OPTION.edit })}
      onComeBack={onResetForm}
    />
  ) : (
    <>
      <ManageLayout
        title="Quản lý slides"
        subtitle={StringFormat(LangConstant.FM_SHOWING, numberOfShowing)}
        buttonProps={{ onClick: onCreateNew }}
      />
      <Box className={defaultClasses.listIntro}>
        {listSlides.map((item, index) => (
          <Box key={item.id} marginBottom="16px">
            <IntroItemList data={{ ...item, sortOrder: index + 1 }} onOpenSlideDetail={onOpenSlideDetail} />
          </Box>
        ))}
      </Box>
    </>
  );
};

export const DEFAULT_SLIDE_FORM = {
  state: ACTION_OPTION.noAction,
  data: {
    id: null,
    title: {
      vi: "",
      en: "",
    },
    content: {
      vi: "",
      en: "",
    },
    previewImage: {
      vi: "",
      en: "",
    },
    previewImageStatus: AppConstant.STATUS_PREVIEW.display,
    background: {
      color: {
        vi: "",
        en: "",
      },
      image: {
        vi: "",
        en: "",
      },
      video: {
        vi: "",
        en: "",
      },
    },
    backgroundType: AppConstant.BACKGROUND_OPTION.color,
    created: null,
    publishedTime: null,
    published: AppConstant.STATUS_OPTION.draft,
  },
};

export default memo(Introduction);

const useStyles = makeStyles(() => ({
  listIntro: {
    height: "calc(100vh - 150px)",
    padding: "24px 32px 8px",
    overflow: "auto",
  },
}));
