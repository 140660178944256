import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, Box, Typography, Button } from "@material-ui/core";
import { AppConstant, LangConstant } from "const";
import clsx from "clsx";
import { getShopStatusColor } from "utils";
import { Alert, ConfirmDialog } from "components";
import OwnerDetailsAction from "redux/owner-details.redux";
import LineInfo from "../LineInfo";
import AddNewFeatureDialog from "../AddNewFeatureDialog";

const ODPackageFunction = ({ data }) => {
  const classes = useStyles({ statusColor: getShopStatusColor(data?.state) });
  const dispatch = useDispatch();

  const isSuccess = useSelector(({ ownerDetailsRedux }) => ownerDetailsRedux.isSuccessFeatures);

  const [isDisableOwner, setIsDisableOwner] = useState(false);
  const [isAddFunc, setIsAddFunc] = useState(false);
  const [isAddNewFeatureSuccess, setIsAddNewFeatureSuccess] = useState(false);

  const onDisableOwner = () => {
    dispatch(OwnerDetailsAction.disableOwner({ userId: data?.userId, state: AppConstant.SHOP_STATUS.inactive }));
    setIsDisableOwner(false);
  };
  const onCloseDialog = () => {
    setIsAddFunc(false);
  };

  useEffect(() => {
    if (isSuccess) {
      setIsAddNewFeatureSuccess(true);
      dispatch(OwnerDetailsAction.ownerDetailsSuccess({ isSuccessFeatures: false }));
    }
  }, [isSuccess]);

  return (
    <Box className={classes.functionBox}>
      <Typography className="semiBold-md-txt">Chi tiết tính năng</Typography>
      <Box className={classes.textInfoBox}>
        <Box className={classes.lineBox}>
          <Typography className={classes.titleText}>Tính năng:</Typography>
          {data?.functions.length > 0 &&
            data?.functions.map((item, index) => (
              <Box key={index} className={classes.funcBox}>
                {getFunctionName(item, LangConstant.ARRAY_PACKAGE_FUNCTIONS)}
              </Box>
            ))}
        </Box>
        <Box className={classes.lineBox}>
          <Typography className={classes.titleText}>Số Shop:</Typography>
          <Box className={classes.shopBox}>
            <Typography className={clsx(classes.contentText, classes.textNormalColor)}>
              {data?.shopNumber || EMPTY_NUMBER}
            </Typography>
          </Box>
        </Box>
        <LineInfo
          title="Số vị trí:"
          content={data?.placeNumber || EMPTY_NUMBER}
          otherProps={clsx("medium-md-txt", classes.textNormalColor)}
        />
        <LineInfo
          title="Số nhân viên:"
          content={data?.staffNumber || EMPTY_NUMBER}
          otherProps={clsx("medium-md-txt", classes.textNormalColor)}
        />
        <LineInfo
          title="Số sản phẩm:"
          content={data?.itemNumber || EMPTY_NUMBER}
          otherProps={clsx("medium-md-txt", classes.textNormalColor)}
        />
      </Box>

      <Box className={classes.buttonGroup}>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          className={clsx("semiBold-md-txt", classes.btnAction)}
          onClick={() => setIsDisableOwner(true)}
        >
          Vô hiệu shop
        </Button>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          className={clsx("semiBold-md-txt", classes.btnAction)}
          onClick={() => setIsAddFunc(true)}
        >
          Thêm tính năng
        </Button>
        {isDisableOwner && (
          <ConfirmDialog
            isShow={true}
            onClose={() => setIsDisableOwner(false)}
            onSubmit={onDisableOwner}
            title="Vô hiệu tài khoản"
            message="Bạn có chắc chắn muốn vô hiệu hoá các shop của tài khoản này không?"
          />
        )}
      </Box>
      {isAddFunc && <AddNewFeatureDialog open={isAddFunc} data={getPackageData(data)} onClose={onCloseDialog} />}
      {isAddNewFeatureSuccess && (
        <Alert
          isShow={isAddNewFeatureSuccess}
          onClose={() => setIsAddNewFeatureSuccess(false)}
          type="success"
          message="Thêm tính năng thành công"
        />
      )}
    </Box>
  );
};

ODPackageFunction.propTypes = {
  data: PropTypes.object.isRequired,
};

ODPackageFunction.defaultProps = {
  data: {},
};

export default memo(ODPackageFunction);

const EMPTY_NUMBER = 0;

const getFunctionName = (functionIndex, arrObjContent) => {
  if (!functionIndex && !arrObjContent) return;
  let result;

  let functionObj = arrObjContent.find(item => item.value === functionIndex);

  if (functionObj) result = functionObj.name;
  return result;
};

const getPackageData = data => {
  if (data) {
    let existedFeaturesArr = data.functions;
    return {
      packageUid: data.uid,
      startDate: data.startDate,
      expiryDate: data.expiryDate,
      period: data.period,
      plan: data.plan,
      existedFeaturesArr: existedFeaturesArr,
    };
  }
};

const useStyles = makeStyles(theme => ({
  functionBox: {
    width: "100%",
    height: 292,
    padding: "12px 30px 12px 13px",
    backgroundColor: "rgba(212, 213, 216, 0.25)",
    borderRadius: 8,
    "& > *:first-child": {
      marginBottom: 18,
    },
  },
  funcBox: {
    width: 84,
    height: 24,
    fontSize: 12,
    padding: 5,
    marginRight: 8,
    backgroundColor: "rgba(179, 181, 186, 0.3)",
    textAlign: "center",
  },

  lineBox: {
    display: "flex",
    width: "100%",
    minHeight: 30,
    marginBottom: 4,
    paddingRight: 50,
    "& > *": {
      paddingBottom: 10,
    },
  },
  titleText: {
    minWidth: "30%",
    height: 22,
    fontSize: 12,
    marginTop: 4,
    color: "#6c7078",
  },
  contentText: {
    minHeight: 22,
    fontSize: 13,
    marginTop: 4,
  },

  textNormalColor: {
    color: "#3e4045",
  },

  shopBox: {
    display: "flex",
  },

  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 10,
    "& > *:first-child": {
      marginRight: 12,
    },
  },

  btnAction: {
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white,
    },
  },
}));
