import { call, put } from "redux-saga/effects";
import { ApiConstant } from "const";
import { BlogsCategoryService } from "services";
import BlogsCategoryAction from "redux/blogs-category.redux";

export function* getBlogsCategoryList() {
  try {
    let response = yield call(BlogsCategoryService.getBlogsCategoryList);
    let responseData = response.data.data;

    if (response.status === ApiConstant.STT_OK) {
      yield put(BlogsCategoryAction.blogsCategorySuccess(responseData));
    } else {
      yield put(BlogsCategoryAction.blogsCategoryFailure(response.data));
    }
  } catch (error) {
    yield put(BlogsCategoryAction.blogsCategoryFailure(error));
  }
}

export function* deleteBlogsCategory(action) {
  try {
    const { data } = action;
    let response = yield call(BlogsCategoryService.deleteBlogsCategory, data);
    let responseData = response.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(BlogsCategoryAction.blogsCategorySuccess());
      yield getBlogsCategoryList();
    } else {
      yield put(BlogsCategoryAction.blogsCategoryFailure(responseData));
    }
  } catch (error) {
    yield put(BlogsCategoryAction.blogsCategoryFailure(error));
  }
}

export function* createBlogsCategory(action) {
  try {
    const { data } = action;

    let response = yield call(BlogsCategoryService.createBlogsCategory, data);
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data;
      yield put(BlogsCategoryAction.blogsCategorySuccess(responseData));
      yield getBlogsCategoryList();
    } else {
      yield put(BlogsCategoryAction.blogsCategoryFailure(response.data));
    }
  } catch (error) {
    yield put(BlogsCategoryAction.blogsCategoryFailure(error));
  }
}

export function* updateBlogsCategory(action) {
  try {
    const { data } = action;

    let response = yield call(BlogsCategoryService.updateBlogsCategory, data);
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data;
      yield put(BlogsCategoryAction.blogsCategorySuccess(responseData));
      yield getBlogsCategoryList();
    } else {
      yield put(BlogsCategoryAction.blogsCategoryFailure(response.data));
    }
  } catch (error) {
    yield put(BlogsCategoryAction.blogsCategoryFailure(error));
  }
}
