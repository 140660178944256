import React, { memo } from "react";
import PropTypes from "prop-types";
import { Box, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";

const LineInfo = ({ title, content, otherProps }) => {
  const classes = useStyles();

  return (
    <Box className={classes.lineBox}>
      <Typography className={classes.titleText}>{title}</Typography>
      <Typography className={clsx(classes.contentText, otherProps)}>{content}</Typography>
    </Box>
  );
};

LineInfo.propTypes = {
  packageType: PropTypes.number,
};
LineInfo.defaultProps = {};

export default memo(LineInfo);

const useStyles = makeStyles(() => ({
  lineBox: {
    display: "flex",
    width: "100%",
    minHeight: 30,
    marginBottom: 4,
    paddingRight: 50,
    "& > *": {
      paddingBottom: 10,
    },
  },
  titleText: {
    minWidth: "30%",
    height: 22,
    fontSize: 12,
    marginTop: 4,
    color: "#6c7078",
  },
  contentText: {
    minHeight: 22,
    fontSize: 13,
    marginTop: 4,
  },
}));
