import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, Paper, Box, Grid, Typography } from "@material-ui/core";
import StringFormat from "string-format";
import clsx from "clsx";
import { LangConstant } from "const";
import { removeRegularHTMLCodeAndCalculator, removeTags } from "utils";
import { CKEditorCustomize } from "components";
import { HeaderTab, LANG_OPTION, InformationFeature, BackgroundFeature, DialogPreview } from ".";

const TabFeatureInfo = props => {
  const { classes, isReset, onResetSuccess, data, onChangeFormData, isDisplayPreview, isLastFeature, onClosePreview } =
    props;
  const defaultClasses = useStyles();

  const [tabValue, setTabValue] = useState(LANG_OPTION.vi);
  const [formData, setFormData] = useState(data);

  const onChangeTab = value => {
    setTabValue(value);
  };

  const onChange = e => {
    let newFormData = {
      ...formData,
      [e.target.name]: {
        ...formData[e.target.name],
        [tabValue]: e.target.value,
      },
    };
    setFormData(newFormData);
    onChangeFormData(newFormData);
  };

  const onChangeCKEditor = data => {
    let newFormData = {
      ...formData,
      highlightTitle: {
        ...formData.highlightTitle,
        [tabValue]: data,
      },
    };
    setFormData(newFormData);
    onChangeFormData(newFormData);
  };

  const onChangeBackground = (data, name) => {
    let newFormData = {
      ...formData,
      [name]: data,
    };
    setFormData(newFormData);
    onChangeFormData(newFormData);
  };

  useEffect(() => {
    if (isReset && !data?.id) {
      setFormData(data);
      onResetSuccess();
    }
  }, [isReset]);

  return (
    <Box className={clsx(defaultClasses.root, classes?.root)}>
      <Paper className={defaultClasses.paper}>
        <HeaderTab tabValue={tabValue} onChangeTab={onChangeTab} />
        <Grid container spacing={3} className={defaultClasses.contentFeature}>
          <InformationFeature
            data={{
              title: formData.title[tabValue],
              content: formData.content[tabValue],
            }}
            onChange={onChange}
            langValue={tabValue}
          >
            <Box paddingBottom="36px">
              <Typography className={clsx("regular-md-txt", defaultClasses.title)}>Tiêu đề phụ</Typography>
              <CKEditorCustomize
                config={CONFIG_CKEDITOR}
                data={formData.highlightTitle[tabValue]}
                onChange={onChangeCKEditor}
              />
              <Box className={defaultClasses.borderCountWord}>
                <Typography className={defaultClasses.contentCountWord}>
                  {StringFormat(
                    LangConstant.FM_LIMIT_WORD,
                    formData.highlightTitle[tabValue]
                      ? removeRegularHTMLCodeAndCalculator(removeTags(formData.highlightTitle[tabValue]))
                      : 0,
                    LIMIT_WORD,
                  )}
                </Typography>
              </Box>
            </Box>
          </InformationFeature>
          <BackgroundFeature
            isLastFeature={isLastFeature}
            formURL={{
              facebook: formData?.slide[0] && formData.slide[0].image,
              website: formData?.slide[1] && formData.slide[1].image,
              google: formData?.slide[2] && formData.slide[2].image,
            }}
            onChange={onChangeBackground}
            langValue={tabValue.toUpperCase()}
          />
        </Grid>
        {isDisplayPreview && (
          <DialogPreview
            isShow={isDisplayPreview}
            data={formData}
            tabValue={tabValue}
            onChangeLanguage={onChangeTab}
            onClose={onClosePreview}
          />
        )}
      </Paper>
    </Box>
  );
};

const CONFIG_CKEDITOR = {
  toolbarGroups: [{ name: "colors" }],
  extraPlugins: "colorbutton",
  contentsCss: [
    "body {font-size: 28px; line-height: 24px; word-wrap: break-word; font-family: Inter,Roboto,Arial,sans-serif;}",
  ],
  colorButton_colors:
    "000,800000,8B4513,2F4F4F,008080,000080,4B0082,696969,B22222,A52A2A,DAA520,006400,40E0D0,0000CD,800080,808080,F00,FF8C00,FFD700,008000,0FF,00F,EE82EE,A9A9A9,FFA07A,FFA500,FFFF00,00FF00,AFEEEE,ADD8E6,DDA0DD,D3D3D3,FFF0F5,FAEBD7,FFFFE0,F0FFF0,F0FFFF,F0F8FF,E6E6FA,FFF",
  colorButton_foreStyle: {
    element: "span",
    styles: { color: "#(color)" },
    overrides: [{ element: "font", attributes: { color: null } }],
  },
};

const LIMIT_WORD = 15;

TabFeatureInfo.propTypes = {
  classes: PropTypes.object,
  isDisplayPreview: PropTypes.bool,
  isLastFeature: PropTypes.bool,
  isReset: PropTypes.bool,
  data: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.shape({
      vi: PropTypes.string,
      en: PropTypes.string,
    }),
    highlightTitle: PropTypes.shape({
      vi: PropTypes.string,
      en: PropTypes.string,
    }),
    content: PropTypes.shape({
      vi: PropTypes.string,
      en: PropTypes.string,
    }),
    description: PropTypes.shape({
      vi: PropTypes.string,
      en: PropTypes.string,
    }),
    published: PropTypes.number,
    sortOrder: PropTypes.number,
    created: PropTypes.number,
    publishedTime: PropTypes.number,
    slide: PropTypes.array,
    slideType: PropTypes.number,
  }),
  onResetSuccess: PropTypes.func,
  onChangeFormData: PropTypes.func,
  onClosePreview: PropTypes.func,
};

TabFeatureInfo.defaultProps = {
  isDisplayPreview: false,
  isLastFeature: false,
  onChangeFormData: () => {},
  onClosePreview: () => {},
  onResetSuccess: () => {},
};

export default memo(TabFeatureInfo);

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    padding: 16,
  },
  paper: {
    padding: "24px 18px 34px",
    boxShadow: "0 1px 6px 0 rgba(0, 0, 0, 0.16)",
  },
  contentFeature: {
    paddingTop: 36,
  },
  title: {
    marginBottom: 12,
    color: "#3e4045",
    lineHeight: "22px",
  },
  numberWord: {
    marginTop: 12,
    color: "#3e4045",
    lineHeight: "22px",
  },
  borderCountWord: {
    height: 30,
    padding: "5px 18px",
    backgroundColor: "rgba(212, 213, 216, 0.5)",
  },
  contentCountWord: {
    fontSize: 12,
    fontWeight: 400,
    height: 20,
    lineHeight: 1.67,
    color: "#3e4045",
  },
}));
