import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Button } from "@material-ui/core";
import clsx from "clsx";
import { LangConstant } from "const";

const HeaderTab = props => {
  const { classes, tabValue, onChangeTab } = props;
  const defaultClasses = useStyles();

  let isLangVi = tabValue === LANG_OPTION.vi;
  return (
    <Box className={clsx(defaultClasses.root, classes?.root)}>
      <Button
        onClick={() => {
          if (!isLangVi) {
            onChangeTab(LANG_OPTION.vi);
          }
        }}
        className={clsx("regular-md-txt", isLangVi ? defaultClasses.buttonFocus : defaultClasses.button)}
      >
        Tiếng Việt
      </Button>
      <Button
        onClick={() => {
          if (isLangVi) {
            onChangeTab(LANG_OPTION.en);
          }
        }}
        className={clsx("regular-md-txt", isLangVi ? defaultClasses.button : defaultClasses.buttonFocus)}
      >
        Tiếng Anh
      </Button>
    </Box>
  );
};

export const LANG_OPTION = {
  vi: LangConstant.OBJ_LANGUAGE.vi.code,
  en: LangConstant.OBJ_LANGUAGE.en.code,
};

HeaderTab.propTypes = {
  classes: PropTypes.object,
  tabValue: PropTypes.string,
  onChangeTab: PropTypes.func,
};

HeaderTab.defaultProps = {
  classes: {},
  tabValue: LANG_OPTION.vi,
  onChangeTab: () => {},
};
export default memo(HeaderTab);

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    height: 40,
    flexGrow: 1,
    backgroundColor: "rgba(101, 179, 157, 0.1)",
    borderBottom: "1px solid #d4d5d8",
  },
  buttonFocus: {
    width: 117,
    color: theme.palette.white,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  button: {
    width: 117,
    color: theme.palette.grey[500],
  },
}));
