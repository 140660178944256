import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Button, Typography } from "@material-ui/core";
import clsx from "clsx";

const ManageLayout = props => {
  const { classes, title, children, subtitle, buttonProps } = props;

  const defaultClasses = useStyles();

  return (
    <Box className={clsx(defaultClasses.root, classes?.root)}>
      <Box className={defaultClasses.headerRoot}>
        <Typography variant="subtitle2" className={defaultClasses.title}>
          {title}
        </Typography>
        {buttonProps && (
          <Box display="flex">
            {subtitle && (
              <Typography className={clsx("medium-md-txt", defaultClasses.displayNumber)}>{subtitle}</Typography>
            )}
            <Button size="small" color="primary" variant="contained" {...buttonProps}>
              {buttonProps.content || "Tạo mới"}
            </Button>
          </Box>
        )}
      </Box>
      {children}
    </Box>
  );
};

ManageLayout.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string,
  children: PropTypes.node,
  subtitle: PropTypes.string,
};

ManageLayout.defaultProps = {
  classes: {},
};
export default memo(ManageLayout);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  headerRoot: {
    display: "flex",
    justifyContent: "space-between",
    width: "inherit",
    height: 48,
    padding: "8px 32px",
    borderTop: "1px solid rgb(212, 213, 216, 0.5)",
    borderBottom: "1px solid rgb(212, 213, 216, 0.5)",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(101, 179, 157, 0.1)",
  },
  title: {
    height: 24,
    margin: "3px 0",
    lineHeight: 1.5,
  },
  displayNumber: {
    height: 22,
    margin: "4px 16px 4px 0px",
    lineHeight: 1.57,
    color: theme.palette.grey[500],
  },
}));
