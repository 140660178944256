import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  makeStyles,
  IconButton,
  TextField,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { AppConstant, LangConstant } from "const";
import QuestionCategoryAction from "redux/question-category.redux";

const CategoryDialog = ({ isShow, onClose, data, title }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState(data);

  const onChangeCategoryTitle = e => {
    setFormData({
      ...formData,
      title: {
        ...formData.title,
        [e.target.name]: e.target.value,
      },
    });
  };

  const onChangeCategoryDescription = e => {
    setFormData({
      ...formData,
      [e.target.name]: {
        vi: e.target.value,
      },
    });
  };

  const onSubmit = newState => {
    if (newState !== formData.published) {
      let newData = { ...formData, published: newState };
      if (formData.id) {
        dispatch(QuestionCategoryAction.updateHelpCategory(newData));
      } else {
        dispatch(QuestionCategoryAction.createHelpCategory(newData));
      }
    } else {
      if (formData.id) {
        dispatch(QuestionCategoryAction.updateHelpCategory(formData));
      } else {
        dispatch(QuestionCategoryAction.createHelpCategory(formData));
      }
    }
  };

  return (
    <Dialog open={isShow} classes={{ root: classes.dialogRoot, paper: classes.dialogPaper }} onBackdropClick={onClose}>
      <DialogTitle className={classes.dialogTitle}>
        <Typography className={`semiBold-lg-txt ${classes.titleText}`}>{title}</Typography>
        <IconButton onClick={onClose} className={classes.buttonIconClose} size="small">
          <Close className={classes.closeIcon} />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <Typography classes={{ body1: `semiBold-sm-txt ${classes.textContent}` }}>Tên danh mục</Typography>
        <TextField
          variant="outlined"
          name="vi"
          value={formData?.title?.vi}
          placeholder="Nhập tên danh mục (VI)"
          InputProps={{
            classes: { root: `medium-md-txt ${classes.categoryOutlineRoot}`, input: classes.categoryOutlineInput },
          }}
          onChange={onChangeCategoryTitle}
        />
        <TextField
          variant="outlined"
          name="en"
          value={formData?.title?.en}
          placeholder="Nhập tên danh mục (EN)"
          InputProps={{
            classes: { root: `medium-md-txt ${classes.categoryOutlineRoot}`, input: classes.categoryOutlineInput },
          }}
          onChange={onChangeCategoryTitle}
        />
        <Typography classes={{ body1: `semiBold-sm-txt ${classes.textContent}` }}>Mô tả danh mục</Typography>
        <TextField
          multiline
          variant="outlined"
          value={formData?.description?.vi}
          placeholder="Nhập mô tả danh mục (VI)"
          name="description"
          InputProps={{
            classes: {
              root: `medium-md-txt ${classes.categoryDescriptionOutlineRoot}`,
              input: classes.categoryOutlineInput,
              multiline: classes.categoryDescriptionInput,
            },
          }}
          onChange={onChangeCategoryDescription}
        />
        <Typography classes={{ body1: `semiBold-sm-txt ${classes.textContent}` }}>Trạng thái</Typography>
        <TextField
          disabled
          variant="outlined"
          value={LangConstant.ARRAY_STATUS[formData?.published]?.name}
          InputProps={{
            readOnly: true,
            classes: {
              root: `medium-md-txt ${classes.categoryOutlineRoot}`,
              input: classes.categoryOutlineInput,
              disabled: classes.categoryReadOnly,
            },
          }}
        />
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Button
          onClick={() => onSubmit(AppConstant.STATUS_OPTION.draft)}
          disabled={!Boolean(formData?.title?.vi)}
          className={classes.button}
          size="small"
        >
          Lưu
        </Button>
        <Button
          onClick={() => onSubmit(AppConstant.STATUS_OPTION.publish)}
          disabled={!Boolean(formData?.title?.vi)}
          variant="contained"
          color="primary"
          size="small"
        >
          Xuất bản
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CategoryDialog.propTypes = {
  isShow: PropTypes.bool,
  data: PropTypes.shape({
    id: PropTypes.number,
    thumbnail: PropTypes.shape({
      vi: PropTypes.string,
      en: PropTypes.string,
    }),
    title: PropTypes.shape({
      vi: PropTypes.string,
      en: PropTypes.string,
    }),
    description: PropTypes.shape({
      vi: PropTypes.string,
      en: PropTypes.string,
    }),
    published: PropTypes.number,
  }),
  title: PropTypes.string,
  onClose: PropTypes.func,
};

CategoryDialog.defaultProps = {
  onClose: () => {},
};

export default memo(CategoryDialog);

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    "& > div:first-child": {
      backgroundColor: "rgba(0, 0, 0, 0.25)",
    },
  },
  dialogPaper: {
    width: 450,
    borderRadius: 5,
    margin: 0,
  },
  dialogTitle: {
    padding: "8px 10px 8px 24px",
    backgroundColor: theme.palette.secondary.dark,
    "&>*": {
      display: "flex",
      alignItems: "center",
      color: theme.palette.white,
    },
  },
  titleText: {
    color: theme.palette.white,
  },
  dialogContent: {
    color: "#3e4045",
  },
  buttonIconClose: {
    marginLeft: "auto",
  },
  closeIcon: {
    width: 20,
    height: 20,
    color: theme.palette.white,
  },
  textContent: {
    padding: "8px 0px",
    color: "inherit",
    fontSize: 13,
    fontWeight: 500,
  },
  categoryOutlineRoot: {
    width: 402,
    height: 40,
    marginBottom: 8,
  },
  categoryDescriptionOutlineRoot: {
    width: 402,
    minHeight: 60,
    marginBottom: 8,
  },
  categoryOutlineInput: {
    textOverflow: "ellipsis",
    padding: 0,
    margin: "0px 16px",
    borderRadius: 3,
  },
  categoryDescriptionInput: {
    alignItems: "flex-start",
    padding: "8px 0px",
  },
  categoryReadOnly: {
    color: "#3e4045",
  },
  dialogAction: {
    padding: " 26px 27px 16px 0px",
  },
  button: {
    color: "#3e4045",
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: "transparent",
    },
  },
}));
