import { ApiConstant } from "const";
import { createApiRegistration } from "api";
import StringFormat from "string-format";

export const postHomeFeature = data => createApiRegistration().post(ApiConstant.HOME_FEATURE, data);

export const putHomeFeature = data =>
  createApiRegistration().put(StringFormat(ApiConstant.HOME_FEATURE_ID, data.id), data);

export const getHomeFeature = data => createApiRegistration().get(ApiConstant.HOME_FEATURE, data);

export const getHomeFeatureById = data =>
  createApiRegistration().get(StringFormat(ApiConstant.HOME_FEATURE_ID, data.id));

export const deleteHomeFeature = data =>
  createApiRegistration().delete(StringFormat(ApiConstant.HOME_FEATURE_ID, data.id));
