import React, { memo, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Typography, Popover, Button } from "@material-ui/core";
import { SelectedItem, RangeDatePicker } from "components";
import clsx from "clsx";
import StringFormat from "string-format";
import { AppConstant, LangConstant } from "const";
import { format as DateFormat, isSameDay } from "date-fns";

const Filter = ({ data, onChangeSelected }) => {
  const classes = useStyles();
  const datePickerDialogRef = useRef();

  const [isSelectTimeRange, setIsSelectTimeRange] = useState(false);
  const [datePickerAnchor, setDatePickerAnchor] = useState();
  const [dataSelectedDate, setDataSelectedDate] = useState();
  const [selectedDate, setSelectedDate] = useState();

  const onTimeRangeSelected = dateRange => {
    setIsSelectTimeRange(false);
    if (dateRange.from && dateRange.to) {
      onChangeSelected(dateRange);
      setDataSelectedDate(dateRange);
    }
  };

  useEffect(() => {
    if (data && data.from && data.to) setDataSelectedDate({ from: data.from, to: data.to });
  }, [data]);

  useEffect(() => {
    if (dataSelectedDate && dataSelectedDate.from && dataSelectedDate.to) {
      if (isSameDay(dataSelectedDate.from, dataSelectedDate.to)) {
        setSelectedDate(fetchDateToText(dataSelectedDate.from));
      } else {
        setSelectedDate(
          DateFormat(dataSelectedDate.from, AppConstant.FM_DD_MM_YYYY) +
            " - " +
            DateFormat(dataSelectedDate.to, AppConstant.FM_DD_MM_YYYY),
        );
      }
    }
  }, [dataSelectedDate]);

  return (
    <Box className={classes.headerRoot}>
      <Box className={clsx(classes.borderSelectBar, classes.marginRight)}>
        <Typography className={clsx("bold-sm-txt", classes.titleFilter)}>Gói dịch vụ</Typography>
        <SelectedItem
          value={data.plan}
          onChangeInput={event => onChangeSelected(event, FILTER_SELECTED.plan)}
          data={Object.values(LangConstant.OBJ_PACKAGES)}
        />
      </Box>
      <Box className={clsx(classes.borderSelectBar, classes.marginMiddle)}>
        <Typography className={clsx("bold-sm-txt", classes.titleFilter)}>Trạng thái thanh toán</Typography>
        <SelectedItem
          value={data.paymentStatus}
          onChangeInput={event => onChangeSelected(event, FILTER_SELECTED.paymentStatus)}
          data={ARR_PACKAGE_STATUS_FOR_FILTER}
        />
      </Box>
      <Box className={clsx(classes.borderSelectBar, classes.marginLeft)}>
        <Typography className={clsx("bold-sm-txt", classes.titleFilter)}>Thời gian hết hạn</Typography>
        <Button
          variant="outlined"
          size="small"
          classes={{ root: classes.timeRangeButton, label: `medium-md-txt` }}
          ref={datePickerDialogRef}
          onClick={() => {
            setDatePickerAnchor(datePickerDialogRef.current);
            setIsSelectTimeRange(true);
          }}
        >
          {selectedDate}
        </Button>
      </Box>
      <Popover
        open={isSelectTimeRange}
        anchorEl={datePickerAnchor}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        classes={{
          root: classes.datePickerContainer,
          paper: classes.datePickerPaper,
        }}
        onClose={() => setIsSelectTimeRange(false)}
      >
        <RangeDatePicker onChange={onTimeRangeSelected} dateRange={{ from: data.from, to: data.to }} />
      </Popover>
    </Box>
  );
};

Filter.propTypes = {
  data: PropTypes.shape({
    plan: PropTypes.string | PropTypes.number,
    paymentStatus: PropTypes.string | PropTypes.number,
    from: PropTypes.objectOf(Date),
    to: PropTypes.objectOf(Date),
  }).isRequired,

  onChangeSelected: PropTypes.func,
};

Filter.defaultProps = {
  data: {},
  onChangeSelected: () => {},
};

export default memo(Filter);

const FILTER_SELECTED = {
  plan: "plan",
  paymentStatus: "paymentStatus",
};
const ARR_PACKAGE_STATUS_FOR_FILTER = [
  { value: AppConstant.PAYMENTS_STATUS.unpaid, name: "Chưa thanh toán" },
  { value: AppConstant.PAYMENTS_STATUS.paid, name: "Đã thanh toán" },
];

const fetchDateToText = date => {
  let dayIndex = new Date(date).getDay();
  let dateNum = new Date(date).getDate();
  let monthNum = new Date(date).getMonth() + 1; //getMonth() is zero-based
  let yearNum = new Date(date).getFullYear();

  let formattedDateText = StringFormat(
    LangConstant.FM_DATE_INPUT,
    LangConstant.ARR_DAY_OF_WEEK[dayIndex > 0 ? dayIndex - 1 : 6],
    `${dateNum < 10 ? "0" : ""}${dateNum}`,
    `${monthNum < 10 ? "0" : ""}${monthNum}`,
    yearNum,
  );
  return formattedDateText;
};

const useStyles = makeStyles(theme => ({
  headerRoot: {
    display: "flex",
    flexGrow: 1,
    margin: "11px 0 5px",
  },
  borderSelectBar: {
    padding: "16px 10px",
    flex: 1,
    borderRadius: 2,
    backgroundColor: "#fafafb",
  },
  titleFilter: {
    marginBottom: 8,
    lineHeight: 1.67,
    color: "#3e4045",
  },
  marginRight: {
    marginRight: 16,
  },
  marginMiddle: {
    margin: "0 8px",
  },
  marginLeft: {
    marginLeft: 16,
  },

  timeRangeButton: {
    display: "flex",
    minWidth: "100%",
    minHeight: 40,
    padding: "9px 14px",
    border: "1px solid #c0c0c1",
    borderRadius: 4,
    textTransform: "none",
    justifyContent: "left",
    cursor: "pointer",
    letterSpacing: "unset",
    "&:hover": {
      border: `1px solid ${theme.palette.grey[600]}`,
      backgroundColor: "#fafafb",
    },
  },
  datePickerContainer: {
    display: "flex",
    textTransform: "none",
  },
  datePickerPaper: {
    display: "block",
    borderRadius: 4,
    marginTop: 5,
  },
}));
