import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Typography, OutlinedInput } from "@material-ui/core";
import { useSelector } from "react-redux";
import { SelectedItem } from "components";
import { LangConstant } from "const";
import { convertTimestampToDate, getTimestamp } from "utils";
import clsx from "clsx";

const HeaderSelector = props => {
  const { data, onChangeSelected } = props;
  const defaultClasses = useStyles();

  const arrayCategoryData = useSelector(props => props.helpCategoryRedux.data);

  const [arrayCategory, setArrayCategory] = useState([]);

  useEffect(() => {
    setArrayCategory(arrayCategoryData.map(item => ({ value: item.id, name: item.title?.vi })));
  }, [arrayCategoryData]);

  return (
    <Box className={defaultClasses.root}>
      <Box className={clsx(defaultClasses.borderSelectBar, defaultClasses.marginRight)}>
        <Typography className={clsx("bold-sm-txt", defaultClasses.titleOutlined)}>Danh mục</Typography>
        <SelectedItem isShow={false} value={data.category} onChangeInput={onChangeSelected} data={arrayCategory} />
      </Box>
      <Box className={clsx(defaultClasses.borderSelectBar, defaultClasses.marginMiddle)}>
        <Typography className={clsx("bold-sm-txt", defaultClasses.titleOutlined)}>Trạng thái</Typography>
        <OutlinedInput
          value={LangConstant.ARRAY_STATUS[data.state].name}
          disabled
          classes={{
            root: clsx("medium-md-txt", defaultClasses.outlinedRoot),
            disabled: defaultClasses.outlinedDisabled,
          }}
        />
      </Box>
      <Box className={clsx(defaultClasses.borderSelectBar, defaultClasses.marginLeft)}>
        <Typography className={clsx("bold-sm-txt", defaultClasses.titleOutlined)}>Thời gian tạo</Typography>
        <OutlinedInput
          value={convertTimestampToDate(getTimestamp(data?.createdTime || Date.now()))}
          disabled
          classes={{
            root: clsx("medium-md-txt", defaultClasses.outlinedRoot),
            disabled: defaultClasses.outlinedDisabled,
          }}
        />
      </Box>
    </Box>
  );
};

HeaderSelector.propTypes = {
  data: PropTypes.shape({
    category: PropTypes.any,
    state: PropTypes.number,
    createdTime: PropTypes.number,
  }),
  onChangeSelected: PropTypes.func,
};

HeaderSelector.defaultProps = {
  onChangeSelected: () => {},
};

export default memo(HeaderSelector);

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexGrow: 1,
    margin: "16px 0 5px",
  },
  borderSelectBar: {
    padding: "16px 10px",
    flex: 1,
    borderRadius: 2,
    backgroundColor: "#fafafb",
  },
  titleOutlined: {
    marginBottom: 8,
    lineHeight: 1.67,
    color: "#3e4045",
  },
  outlinedRoot: {
    height: 40,
    maxHeight: 40,
    width: "100%",
  },
  outlinedDisabled: {
    color: "#3b404c",
  },
  marginRight: {
    marginRight: 16,
  },
  marginMiddle: {
    margin: "0 8px",
  },
  marginLeft: {
    marginLeft: 16,
  },
}));
