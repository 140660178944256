import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, TablePagination, Typography } from "@material-ui/core";
import { Pagination, PaginationItem } from "@material-ui/lab";
import stringFormat from "string-format";

const PaginationFooter = props => {
  const { data, onChangePage } = props;
  const defaultClasses = useStyles();

  return (
    <Box className={defaultClasses.paginationParent}>
      <TablePagination
        classes={{
          root: defaultClasses.tablePagination,
          selectRoot: defaultClasses.selectRoot,
          toolbar: defaultClasses.toolbar,
          caption: defaultClasses.caption,
          actions: defaultClasses.actions,
        }}
        component="div"
        onChangePage={() => null}
        count={data.total}
        page={data.page - 1}
        rowsPerPage={data.rowsPerPage}
        labelRowsPerPage={``}
        labelDisplayedRows={({ from, to, count }) => (
          <Typography
            variant="body2"
            classes={{ root: defaultClasses.paginationTypo, body2: "regular-sm-txt" }}
            component="span"
          >
            {stringFormat(FM_DESCRIPTION_PAGINATION, from, to, count)}
          </Typography>
        )}
      />
      <Pagination
        className={defaultClasses.tablePagination}
        count={parseInt(data.totalPage)}
        onChange={onChangePage}
        page={data.page}
        size="small"
        renderItem={item => (
          <PaginationItem
            classes={{
              page: "regular-sm-txt",
              selected: defaultClasses.selected,
            }}
            {...item}
          />
        )}
      />
    </Box>
  );
};

const FM_DESCRIPTION_PAGINATION = "Hiển thị kết quả từ {0} - {1} trên tổng {2}";

PaginationFooter.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    total: PropTypes.number,
    totalPage: PropTypes.number,
  }),
  onChangePage: PropTypes.func,
};

PaginationFooter.defaultProps = {
  onChangePage: () => {},
};

export default memo(PaginationFooter);

const useStyles = makeStyles(theme => ({
  paginationParent: {
    display: "flex",
    maxWidth: "100%",
  },
  tablePagination: {
    marginTop: 15,
    display: "flex",
    color: theme.palette.grey[600],
  },
  paginationTypo: {
    marginLeft: 10,
  },
  selected: {
    color: theme.palette.text.link,
    backgroundColor: "unset !important",
  },
  selectRoot: {
    display: "none",
    paddingLeft: 0,
  },
  toolbar: {
    paddingLeft: 0,
  },
  caption: {
    paddingBottom: 4,
  },
  actions: {
    display: "none",
  },
}));
