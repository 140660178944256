import { ApiConstant } from "const";
import { createApiRegistration } from "api";
import StringFormat from "string-format";

export const postCategory = data => createApiRegistration().post(ApiConstant.HELP_CATEGORY, data);

export const putCategory = data =>
  createApiRegistration().put(StringFormat(ApiConstant.HELP_CATEGORY_ID, data.id), data);

export const getCategories = data => createApiRegistration().get(ApiConstant.HELP_CATEGORY_LIST, data);

export const getCategoryById = data => createApiRegistration().get(StringFormat(ApiConstant.HELP_CATEGORY_ID, data.id));

export const deleteCategory = data =>
  createApiRegistration().delete(StringFormat(ApiConstant.HELP_CATEGORY_ID, data.id));
