import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Button, Typography, OutlinedInput, Tooltip, IconButton } from "@material-ui/core";
import clsx from "clsx";
import { PreviewIcon } from "icons";
import { AppConstant, LangConstant } from "const";
import { convertTimestampToDate, getTimestamp } from "utils";

const HomePageLayout = props => {
  const { classes, data, title, header, subtitle, children, onClickPreview, buttonDisplayProps, buttonSaveDraftProps } =
    props;
  const defaultClasses = useStyles();

  return (
    <Box className={clsx(defaultClasses.root, classes?.root)}>
      <Box className={defaultClasses.header}>
        {title}
        {children}
        <Typography className={clsx("medium-md-txt", defaultClasses.displayNumber)}>{subtitle}</Typography>
        <Button size="small" color="primary" variant="contained" {...buttonDisplayProps}>
          Hiển thị
        </Button>
      </Box>
      <Box className={defaultClasses.info}>
        <Box className={defaultClasses.information}>
          <Typography className={clsx("bold-md-txt", defaultClasses.textInformation)}>{header}</Typography>
          <Box display="flex">
            <Box className={defaultClasses.boxPreview}>
              <Tooltip title="Xem trước" classes={{ tooltip: clsx("medium-sm-txt", defaultClasses.tooltip) }}>
                <IconButton
                  onClick={onClickPreview}
                  component="div"
                  classes={{ root: defaultClasses.buttonIcon, disabled: defaultClasses.disabledButton }}
                >
                  <PreviewIcon className={defaultClasses.iconAction} />
                </IconButton>
              </Tooltip>
            </Box>
            <Button
              size="small"
              color="primary"
              variant="outlined"
              className={defaultClasses.buttonSaveDraft}
              {...buttonSaveDraftProps}
            >
              {buttonSaveDraftProps.content}
            </Button>
          </Box>
        </Box>
        <Box className={defaultClasses.outlinedInfo}>
          <Box className={defaultClasses.statusOutline}>
            <Typography className={clsx("bold-sm-txt", defaultClasses.titleOutlined)}>Trạng thái</Typography>
            <OutlinedInput
              value={LangConstant.OBJ_STATUS_DISPLAY[data.published].name}
              disabled
              classes={{
                root: clsx("medium-md-txt", defaultClasses.outlinedRoot),
                disabled: defaultClasses.outlinedDisabled,
              }}
            />
          </Box>
          <Box className={defaultClasses.createdTimeOutline}>
            <Typography className={clsx("bold-sm-txt", defaultClasses.titleOutlined)}>Thời gian tạo</Typography>
            <OutlinedInput
              value={convertTimestampToDate(data.created || getTimestamp(Date.now()))}
              disabled
              classes={{
                root: clsx("medium-md-txt", defaultClasses.outlinedRoot),
                disabled: defaultClasses.outlinedDisabled,
              }}
            />
          </Box>
          {data?.publishedTime && data?.published === AppConstant.STATUS_OPTION.publish && (
            <Box className={defaultClasses.publishedTimeOutline}>
              <Typography className={clsx("bold-sm-txt", defaultClasses.titleOutlined)}>Thời gian đăng</Typography>
              <OutlinedInput
                value={convertTimestampToDate(data.publishedTime)}
                disabled
                classes={{
                  root: clsx("medium-md-txt", defaultClasses.outlinedRoot),
                  disabled: defaultClasses.outlinedDisabled,
                }}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

HomePageLayout.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.shape({
    created: PropTypes.number,
    publishedTime: PropTypes.number,
    published: PropTypes.number,
  }),
  title: PropTypes.any,
  header: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node,
  onClickPreview: PropTypes.func,
  buttonDisplayProps: PropTypes.object,
  buttonSaveDraftProps: PropTypes.object,
};

HomePageLayout.defaultProps = {};

export default memo(HomePageLayout);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexGrow: 1,
    height: 48,
    padding: "8px 32px",
    borderTop: "1px solid rgb(212, 213, 216, 0.5)",
    borderBottom: "1px solid rgb(212, 213, 216, 0.5)",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(101, 179, 157, 0.1)",
  },
  displayNumber: {
    height: 22,
    margin: "4px 16px 4px 0px",
    lineHeight: 1.57,
    color: theme.palette.grey[500],
  },
  tooltip: {
    height: 20,
    marginLeft: 50,
    marginTop: 2,
    padding: "0 5px",
    backgroundColor: "#7f838c",
    lineHeight: 1.67,
    textAlign: "center",
    borderRadius: 0,
  },
  buttonIcon: {
    width: 30,
    height: 30,
    padding: 0,
    borderRadius: 0,
    border: "1px solid #d4d5d8",
    "&:hover": {
      border: "1px solid " + theme.palette.primary.main,
    },
  },
  iconAction: {
    width: 30,
    height: 30,
    padding: 5,
    color: theme.palette.grey[500],
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  info: {
    width: "inherit",
    padding: "19px 32px 16px",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.08)",
    backgroundColor: theme.palette.white,
  },
  information: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 32,
  },
  textInformation: {
    marginTop: 3,
    lineHeight: "24px",
  },
  boxPreview: {
    height: 30,
    marginRight: 8,
  },
  buttonSaveDraft: {
    fontWeight: 600,
    "&:hover": {
      color: theme.palette.white,
      backgroundColor: theme.palette.primary.main,
    },
  },
  outlinedInfo: {
    width: "100%",
    minWidth: 600,
    display: "flex",
  },
  statusOutline: {
    flexGrow: 1,
    padding: "16px 10px",
    marginRight: 16,
    backgroundColor: "#fafafb",
  },
  createdTimeOutline: {
    flexGrow: 1,
    margin: "0 8px",
    padding: "16px 10px",
    backgroundColor: "#fafafb",
  },
  publishedTimeOutline: {
    flexGrow: 1,
    padding: "16px 10px",
    marginLeft: 16,
    backgroundColor: "#fafafb",
  },
  titleOutlined: {
    marginBottom: 8,
    lineHeight: "20px",
    color: "#3e4045",
  },
  outlinedRoot: {
    height: 40,
    maxHeight: 40,
    width: "100%",
  },
  outlinedDisabled: {
    color: "#3b404c",
  },
}));
