/**
 * Saga index: connects action type and saga
 */

import { takeLatest, all } from "redux-saga/effects";

/* ------------- Types ------------- */
import { AuthTypes } from "../redux/auth.redux";
import { BlogsCategoryTypes } from "redux/blogs-category.redux";
import { BlogItemTypes } from "redux/blog-item.redux";
import { UploadTypes } from "redux/upload.redux";
import { ShopTypes } from "redux/shop.redux";
import { AccountTypes } from "redux/account.redux";
import { IntroductionTypes } from "redux/introduction.redux";
import { FeatureTypes } from "redux/feature.redux";
import { EvaluateTypes } from "redux/evaluate.redux";
import { HelpCategoryTypes } from "redux/question-category.redux";
import { HelpQuestionTypes } from "redux/question.redux";
import { ManageOwnerTypes } from "redux/manage-owner.redux";
import { OwnerDetailsTypes } from "redux/owner-details.redux";

/* ------------- Sagas ------------- */
import { requestLogin } from "./auth.saga";
import { raiseError } from "./error.saga";
import {
  deleteBlogsCategory,
  getBlogsCategoryList,
  createBlogsCategory,
  updateBlogsCategory,
} from "./blogs-category.saga";
import { getListBlogItem, updateBlogItem, createBlogItem } from "./blog-item.saga";
import { uploadImage } from "./upload.saga";
import {
  getListShop,
  getCountReservation,
  getShopRegistration,
  putShopRegistration,
  getShopLog,
  putShopDetail,
  postShop,
} from "./shop.saga";
import { getListAccount, createAccount, updateAccount, getAccountDetails, changePassword } from "./account.saga";
import {
  getListHomeIntro,
  createHomeIntro,
  updateHomeIntro,
  deleteHomeIntro,
  sortHomeIntro,
} from "./introduction.saga";
import { getListHomeFeature, createHomeFeature, updateHomeFeature, deleteHomeFeature } from "./feature.saga";
import { getListHomeEvaluate, createHomeEvaluate, updateHomeEvaluate, deleteHomeEvaluate } from "./evaluate.saga";
import {
  getListQuestionCategory,
  createQuestionCategory,
  updateQuestionCategory,
  deleteQuestionCategory,
} from "./question-category.saga";
import { getListQuestion, createQuestion, updateQuestion, deleteQuestion } from "./question.saga";
import { getListOwner, updateOwnerStatus, updatePaymentStatus } from "./manage-owner.saga";
import {
  getListPackage,
  getOwnerInfo,
  getPaymentDetail,
  getPaymentHistory,
  updatePaymentDetail,
  updatePackageFeatures,
  changePackage,
  disableOwner,
  updatePaymentAsPaid,
} from "./owner-details.saga";

/* ------------- Connect Types To Sagas ------------- */
export default function* root() {
  yield all([
    //authentication
    takeLatest(AuthTypes.REQUEST_LOGIN, requestLogin),
    takeLatest(BlogsCategoryTypes.GET_BLOGS_CATEGORY_LIST, getBlogsCategoryList),
    takeLatest(BlogsCategoryTypes.DELETE_BLOGS_CATEGORY, deleteBlogsCategory),
    takeLatest(BlogsCategoryTypes.CREATE_BLOGS_CATEGORY, createBlogsCategory),
    takeLatest(BlogsCategoryTypes.UPDATE_BLOGS_CATEGORY, updateBlogsCategory),

    // Blog Item
    takeLatest(BlogItemTypes.GET_LIST_BLOG_ITEM, getListBlogItem),
    takeLatest(BlogItemTypes.UPDATE_BLOG_ITEM, updateBlogItem),
    takeLatest(BlogItemTypes.CREATE_BLOG_ITEM, createBlogItem),

    // Upload
    takeLatest(UploadTypes.UPLOAD_IMAGE, uploadImage),

    // Shop
    takeLatest(ShopTypes.GET_LIST_SHOP, getListShop),
    takeLatest(ShopTypes.GET_COUNT_RESERVATION, getCountReservation),
    takeLatest(ShopTypes.GET_SHOP_REGISTRATION, getShopRegistration),
    takeLatest(ShopTypes.PUT_SHOP_REGISTRATION, putShopRegistration),
    takeLatest(ShopTypes.GET_SHOP_LOG, getShopLog),
    takeLatest(ShopTypes.PUT_SHOP_DETAIL, putShopDetail),
    takeLatest(ShopTypes.POST_SHOP, postShop),

    // Account
    takeLatest(AccountTypes.GET_LIST_ACCOUNT, getListAccount),
    takeLatest(AccountTypes.CREATE_ACCOUNT, createAccount),
    takeLatest(AccountTypes.UPDATE_ACCOUNT, updateAccount),
    takeLatest(AccountTypes.GET_ACCOUNT_DETAILS, getAccountDetails),
    takeLatest(AccountTypes.CHANGE_PASSWORD, changePassword),

    // HomePage Introduction
    takeLatest(IntroductionTypes.GET_HOME_INTRO, getListHomeIntro),
    takeLatest(IntroductionTypes.CREATE_HOME_INTRO, createHomeIntro),
    takeLatest(IntroductionTypes.UPDATE_HOME_INTRO, updateHomeIntro),
    takeLatest(IntroductionTypes.DELETE_HOME_INTRO, deleteHomeIntro),
    takeLatest(IntroductionTypes.SORT_HOME_INTRO, sortHomeIntro),

    // HomePage Feature
    takeLatest(FeatureTypes.GET_HOME_FEATURE, getListHomeFeature),
    takeLatest(FeatureTypes.CREATE_HOME_FEATURE, createHomeFeature),
    takeLatest(FeatureTypes.UPDATE_HOME_FEATURE, updateHomeFeature),
    takeLatest(FeatureTypes.DELETE_HOME_FEATURE, deleteHomeFeature),

    // HomePage Evaluate
    takeLatest(EvaluateTypes.GET_HOME_EVALUATE, getListHomeEvaluate),
    takeLatest(EvaluateTypes.CREATE_HOME_EVALUATE, createHomeEvaluate),
    takeLatest(EvaluateTypes.UPDATE_HOME_EVALUATE, updateHomeEvaluate),
    takeLatest(EvaluateTypes.DELETE_HOME_EVALUATE, deleteHomeEvaluate),

    // Help Center: Category
    takeLatest(HelpCategoryTypes.GET_HELP_CATEGORY, getListQuestionCategory),
    takeLatest(HelpCategoryTypes.CREATE_HELP_CATEGORY, createQuestionCategory),
    takeLatest(HelpCategoryTypes.UPDATE_HELP_CATEGORY, updateQuestionCategory),
    takeLatest(HelpCategoryTypes.DELETE_HELP_CATEGORY, deleteQuestionCategory),

    // Help Center: Question
    takeLatest(HelpQuestionTypes.GET_HELP_QUESTION, getListQuestion),
    takeLatest(HelpQuestionTypes.CREATE_HELP_QUESTION, createQuestion),
    takeLatest(HelpQuestionTypes.UPDATE_HELP_QUESTION, updateQuestion),
    takeLatest(HelpQuestionTypes.DELETE_HELP_QUESTION, deleteQuestion),

    // Manage Owner
    takeLatest(ManageOwnerTypes.GET_LIST_OWNER, getListOwner),
    takeLatest(ManageOwnerTypes.UPDATE_OWNER_STATUS, updateOwnerStatus),
    takeLatest(ManageOwnerTypes.UPDATE_PAYMENT_STATUS, updatePaymentStatus),

    // Owner details
    takeLatest(OwnerDetailsTypes.GET_LIST_PACKAGE, getListPackage),
    takeLatest(OwnerDetailsTypes.GET_OWNER_INFO, getOwnerInfo),
    takeLatest(OwnerDetailsTypes.GET_PAYMENT_HISTORY, getPaymentHistory),
    takeLatest(OwnerDetailsTypes.GET_PAYMENT_DETAILS, getPaymentDetail),
    takeLatest(OwnerDetailsTypes.UPDATE_PAYMENT_DETAIL, updatePaymentDetail),
    takeLatest(OwnerDetailsTypes.UPDATE_PACKAGE_FEATURE, updatePackageFeatures),
    takeLatest(OwnerDetailsTypes.CHANGE_PACKAGE, changePackage),
    takeLatest(OwnerDetailsTypes.DISABLE_OWNER, disableOwner),
    takeLatest(OwnerDetailsTypes.UPDATE_PAYMENT_AS_PAID, updatePaymentAsPaid),

    takeLatest(
      [
        // Authenticate
        AuthTypes.LOGIN_FAILURE,

        // Blogs Category
        BlogsCategoryTypes.BLOGS_CATEGORY_FAILURE,

        // Blog Item
        BlogItemTypes.BLOG_ITEM_FAILURE,

        // Shop
        ShopTypes.SHOP_FAILURE,

        // Account
        AccountTypes.ACCOUNT_FAILURE,

        // HomePage Introduction
        IntroductionTypes.HOME_INTRO_FAILURE,

        // HomePage Feature
        FeatureTypes.HOME_FEATURE_FAILURE,

        // HomePage Evaluate
        EvaluateTypes.HOME_EVALUATE_FAILURE,

        // Help Center: Category
        HelpCategoryTypes.HELP_CATEGORY_FAILURE,

        // Help Center: Question
        HelpQuestionTypes.HELP_QUESTION_FAILURE,

        // Manage Owner
        ManageOwnerTypes.MANAGE_OWNER_FAILURE,

        // Owner details
        OwnerDetailsTypes.OWNER_DETAILS_FAILURE,
      ],
      raiseError,
    ),
  ]);
}
