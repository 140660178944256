import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import { Provider } from "react-redux";
import theme from "./theme/material";

import "./language";
import "./theme/styles";
import store from "./redux";
import { PathConstant } from "const";
import Login from "pages/Login";
import MainLayout from "layouts/MainLayout";
import NotFound from "pages/NotFound";

export default class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Switch>
              <Route exact path={PathConstant.LOGIN} component={Login} />
              <Route exact path={PathConstant.NOT_FOUND} component={NotFound} />
              <Route path={PathConstant.ROOT} component={MainLayout} />
            </Switch>
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
    );
  }
}
