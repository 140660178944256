/**
 * Export an instance of api client
 */

import { ApiConstant, AppConstant } from "const";
import apisauce from "apisauce";
import Cookies from "js-cookie";

export const API_CONFIG_REGISTRATION = {
  baseURL: ApiConstant.HOST_REGISTRATION,
  headers: ApiConstant.HEADER_DEFAULT,
  timeout: ApiConstant.TIMEOUT,
};

export const API_CONFIG_RESERVATION = {
  baseURL: ApiConstant.HOST_RESERVATION,
  headers: ApiConstant.HEADER_DEFAULT,
  timeout: ApiConstant.TIMEOUT,
};

export const API_CONFIG_ATTACHMENT_UPLOAD = {
  baseURL: ApiConstant.HOST_ATTACHMENT,
  headers: ApiConstant.HEADER_DEFAULT_UPLOAD_FILE,
  timeout: ApiConstant.TIMEOUT,
};
export const createApiByConfig = initConfig => apisauce.create(initConfig);

export const createApiWithToken = (initConfig = API_CONFIG_REGISTRATION, token) => {
  let appToken = token || Cookies.get(AppConstant.KEY_TOKEN);
  if (appToken) {
    initConfig.headers.Authorization = `Bearer ${appToken}`;
  }
  return apisauce.create(initConfig);
};

export const createApiRegistration = () => createApiWithToken(API_CONFIG_REGISTRATION);
export const createApiReservation = () => createApiWithToken(API_CONFIG_RESERVATION);
export const createApiAttachmentUpload = () => createApiWithToken(API_CONFIG_ATTACHMENT_UPLOAD);
