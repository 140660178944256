import React, { memo, useEffect, useState } from "react";
import { Box, makeStyles } from "@material-ui/core";
import ManageLayout from "layouts/ManageLayout";
import { HeaderFilter, BodyListShop } from "./components";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import ShopAction from "redux/shop.redux";
import { ShopDetailLayout, ShopInformationForm } from "./components/ShopInformation";

const ManageShops = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [dataShop, isCreateSuccess] = useSelector(
    ({ shopRedux }) => [shopRedux.data, shopRedux.isCreateSuccess],
    shallowEqual,
  );

  const [total, setTotal] = useState(0);
  const [isDetail, setIsDetail] = useState(false);
  const [isShowCreate, setIsShowCreate] = useState(false);
  const [isClickCancel, setIsClickCancel] = useState(false);

  const onCloseCreate = () => {
    setIsClickCancel(false);
    setIsShowCreate(false);
    dispatch(ShopAction.getListShop());
  };

  const onCreateShop = data => {
    dispatch(
      ShopAction.postShop({
        ...data,
        social: {
          website: data.website || "",
          facebook: data.facebook || "",
          google: data.google || "",
        },
      }),
    );
  };

  useEffect(() => {
    dispatch(ShopAction.getListShop());
    if (isCreateSuccess) {
      dispatch(ShopAction.shopSuccess({ isCreateSuccess: false }));
      setIsClickCancel(false);
      setIsShowCreate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateSuccess]);

  useEffect(() => {
    if (dataShop?.total) {
      setTotal(dataShop?.total);
    }
  }, [dataShop]);

  return (
    <Box height="calc(100% - 40px)">
      {!(isDetail || isShowCreate) && (
        <ManageLayout title="Quản lý Shops" buttonProps={{ onClick: () => setIsShowCreate(true) }}>
          <HeaderFilter totalShop={total} />
        </ManageLayout>
      )}
      {isShowCreate ? (
        <Box className={classes.root}>
          <ShopDetailLayout onBack={() => setIsClickCancel(true)} title="Thông tin shop">
            <Box className={classes.shopDetail}>
              <ShopInformationForm
                renderCondition={{
                  isEditing: true,
                  isCreating: true,
                  isClickCancel: isClickCancel,
                  data: {},
                }}
                actionContent={{
                  confirmContent: "Tạo mới",
                  onCancel: onCloseCreate,
                  onConfirm: onCreateShop,
                }}
              />
            </Box>
          </ShopDetailLayout>
        </Box>
      ) : (
        <BodyListShop setIsOpenShopDetail={setIsDetail} isOpenShopDetail={isDetail} />
      )}
    </Box>
  );
};

export default memo(ManageShops);

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  shopDetail: {
    width: "100%",
    display: "flex",
    padding: "24px 34px",
  },
  backButton: {
    padding: 4,
    width: 28,
    height: 28,
    "&>span>svg": {},
  },
}));
