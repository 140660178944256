export const ROOT = "/";
export const BLOGS = "/blogs";
export const BLOGS_ADD = "/blogs/add";
export const BLOGS_CATEGORIES = "/blog-categories";
export const BACKUP_VERSION = "/backup-version";
export const SHOP_LIST = "/shop-list";
export const SHOP_REGISTRATION = "/shop-registration";
export const SHOP_LOGS = "/shop-logs";
export const ACCOUNT = "/account";
export const PROFILE = "/profile";

// HOME PAGE
export const INTRODUCTION = "/introduction";
export const FEATURE = "/feature";
export const EVALUATE = "/evaluate";

// HELP CENTER
export const CREATE_QUESTION = "/create-question";
export const MANAGE_QUESTION = "/manage-questions";
export const MANAGE_CATEGORIES = "/manage-categories";

export const LOGIN = "/login";
export const NOT_FOUND = "/not-found";

// MANAGE OWNERS
export const OWNERS = "/owners";
export const OWNER_DETAIL = "/owners/:ownerId";
export const FM_OWNER_DETAIL = "/owners/{0}";
