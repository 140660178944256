import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { AppConstant } from "const";
import clsx from "clsx";

const StatusAccount = props => {
  const { status } = props;

  const defaultClasses = useStyles();

  let bgClasses = "";
  if (status === AppConstant.STATUS_ACCOUNT.inactive) {
    bgClasses = defaultClasses.inactive;
  } else {
    bgClasses = defaultClasses.active;
  }

  return (
    <Box className={clsx(defaultClasses.root, bgClasses)}>
      <Typography className={clsx("medium-sm-txt", defaultClasses.contentStatus)}>
        {status === AppConstant.STATUS_ACCOUNT.active ? "Active" : "Inactive"}
      </Typography>
    </Box>
  );
};

StatusAccount.propTypes = {
  status: PropTypes.number.isRequired,
};
StatusAccount.defaultProps = {};

export default memo(StatusAccount);

const useStyles = makeStyles(() => ({
  root: {
    width: "auto",
    padding: "2px 16px",
    borderRadius: 4,
  },
  contentStatus: {
    textAlign: "center",
    lineHeight: 1.67,
    color: "#3e4045",
  },
  active: {
    width: 70,
    backgroundColor: "rgba(101, 179, 157, 0.2)",
  },
  inactive: {
    width: 80,
    backgroundColor: "rgba(254, 186, 64, 0.2)",
  },
}));
