import { ApiConstant } from "const";
import { createApiRegistration, createApiReservation } from "api";
import StringFormat from "string-format";

export const getListShop = data => createApiRegistration().get(ApiConstant.SHOP, data);

export const getShopReservationCount = data => createApiReservation().get(ApiConstant.SHOP_RESERVATION_COUNT + data);

export const getShopRegistration = data => createApiRegistration().get(ApiConstant.SHOP_REGISTRATION, data);

export const putShopRegistration = data => createApiRegistration().put(ApiConstant.SHOP_REGISTRATION, data);

export const getShopLog = data => createApiRegistration().get(ApiConstant.SHOP_LOG, data);

export const putShopDetail = data => createApiRegistration().put(StringFormat(ApiConstant.SHOP_DETAIL, data.id), data);

export const postShop = data => createApiRegistration().post(ApiConstant.SHOP, data);
