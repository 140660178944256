import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Grid, Typography, Button, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import stringFormat from "string-format";
import clsx from "clsx";
import { UploadImage, Alert } from "components";
import { LangConstant, ApiConstant } from "const";
import UploadAction from "redux/upload.redux";
import DialogAddLinkImage from "./DialogAddLinkImage";
import { validateFileSize } from "utils";

const UploadCover = props => {
  const { langValue, onChange, isReset, url } = props;
  const defaultClasses = useStyles();
  const dispatch = useDispatch();

  const imageURI = useSelector(state => state.uploadRedux.dataImage);
  const [urlBackground, setUrlBackground] = useState("");
  const [urlImage, setUrlImage] = useState("");
  const [stateUploadImage, setStateUploadImage] = useState(STATE_OPTION.noUpload);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isOpenOutSize, setIsOpenOutSize] = useState(false);

  const onChangeBackground = dataForm => {
    setStateUploadImage(STATE_OPTION.uploadBackground);
    if (dataForm?.file) {
      dispatch(UploadAction.uploadImage(dataForm));
    } else if (dataForm?.isRemove) {
      setUrlBackground("");
    } else {
      setUrlBackground("");
      setIsOpenOutSize(true);
    }
  };

  const onUploadImage = event => {
    let img = event.target.files;
    if (img[0]?.size && validateFileSize(img[0].size)) {
      setStateUploadImage(STATE_OPTION.uploadImage);
      dispatch(UploadAction.uploadImage({ file: img, type: TYPE_IMAGE }));
    } else {
      setIsOpenOutSize(true);
    }
    event.target.value = "";
  };

  const onCloseDialogImageURL = () => {
    setIsOpenDialog(false);
    setUrlImage("");
  };

  useEffect(() => {
    if (imageURI) {
      if (stateUploadImage === STATE_OPTION.uploadBackground) {
        setUrlBackground(imageURI.uri[0]);
      } else if (stateUploadImage === STATE_OPTION.uploadImage) {
        setUrlImage(ApiConstant.HOST_ATTACHMENT + imageURI.uri[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageURI]);

  useEffect(() => {
    if (stateUploadImage === STATE_OPTION.uploadBackground) {
      setStateUploadImage(STATE_OPTION.noUpload);
      onChange(urlBackground);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlBackground]);

  useEffect(() => {
    if (urlImage && stateUploadImage === STATE_OPTION.uploadImage) {
      setIsOpenDialog(true);
      setStateUploadImage(STATE_OPTION.noUpload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlImage]);

  useEffect(() => {
    if (isReset) {
      setUrlBackground("");
    }
  }, [isReset]);

  useEffect(() => {
    if (url) {
      setUrlBackground(url);
    }
  }, [url]);

  return (
    <Grid item xs={3} className={defaultClasses.root}>
      <Box className={defaultClasses.content}>
        <UploadImage
          id="upload-blog"
          isShowWarning={false}
          onChange={onChangeBackground}
          defaultUrl={urlBackground && ApiConstant.HOST_ATTACHMENT + urlBackground}
        />
        <Typography className={clsx("regular-sm-txt", defaultClasses.descriptionBackground)}>
          {stringFormat(LangConstant.FM_UPLOAD_IMAGE, langValue)}
        </Typography>
        <form className={defaultClasses.formUploadButton}>
          <Button color="primary" variant="outlined" className={defaultClasses.uploadButton}>
            <label className={defaultClasses.uploadLabel}>
              Tải lên
              <input type="file" accept=".png, .jpg, .jpeg" className={defaultClasses.file} onChange={onUploadImage} />
            </label>
          </Button>
        </form>
      </Box>
      {isOpenDialog && urlImage && (
        <DialogAddLinkImage isShow={isOpenDialog} onClose={onCloseDialogImageURL} imageUrl={urlImage} />
      )}
      {isOpenOutSize && (
        <Alert
          isShow={true}
          onClose={() => setIsOpenOutSize(false)}
          message="Ảnh có dung lượng vượt quá 2MB"
          type="warning"
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          action={
            <IconButton onClick={() => setIsOpenOutSize(false)} className={defaultClasses.button}>
              <Close className={defaultClasses.iconClose} />
            </IconButton>
          }
          classes={{
            root: defaultClasses.rootAlert,
            action: defaultClasses.action,
            icon: defaultClasses.icon,
            message: clsx("regular-sm-txt", defaultClasses.message),
          }}
        />
      )}
    </Grid>
  );
};

const TYPE_IMAGE = "type";

const STATE_OPTION = {
  noUpload: 0,
  uploadBackground: 1,
  uploadImage: 2,
};

UploadCover.propTypes = {
  langValue: PropTypes.string,
  isReset: PropTypes.bool,
  onChange: PropTypes.func,
  url: PropTypes.string,
};

UploadCover.defaultProps = {
  classes: {},
  onChange: () => {},
};
export default memo(UploadCover);

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 34,
  },
  content: {
    maxHeight: 600,
    position: "sticky",
    top: 60,
  },
  descriptionBackground: {
    margin: "8px 0 24px",
    lineHeight: 1.69,
    color: theme.palette.grey[500],
    textAlign: "center",
  },
  formUploadButton: {
    textAlign: "center",
  },
  uploadButton: {
    minHeight: 30,
    height: 30,
    width: 76,
    padding: "3px 14px",
    fontSize: 14,
    fontWeight: 600,
    "&:hover": {
      color: theme.palette.white,
      backgroundColor: theme.palette.primary.main,
    },
  },
  uploadLabel: {
    minHeight: 30,
    height: 30,
    minWidth: 76,
    lineHeight: 1.71,
    marginBottom: 0,
    padding: "3px 14px",
    cursor: "pointer",
  },
  file: {
    display: "none",
  },
  rootAlert: {
    width: 400,
    height: 30,
    padding: "5px 8px",
    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.16)",
    backgroundColor: "rgba(254, 186, 64, 0.5)",
    color: "#3e4045",
  },
  button: {
    padding: 0,
  },
  iconClose: {
    width: 20,
    height: 20,
  },
  icon: {
    display: "none",
  },
  action: {
    marginRight: 0,
    paddingLeft: 8,
    display: "unset",
  },
  message: {
    width: "100%",
    lineHeight: 1.67,
    padding: 0,
    textAlign: "center",
  },
}));
