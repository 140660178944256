import React from "react";
import PropTypes from "prop-types";
import { TableCell, TableRow, Typography } from "@material-ui/core";
import StringFormat from "string-format";
import { LangConstant, AppConstant } from "const";
import { convertTimestampToDateRange, formatToFormCurrency } from "utils";

const PaymentDialogRow = props => {
  const { data } = props;

  return (
    <TableRow hover>
      <TableCell>
        <Typography>
          {data?.plan
            ? `Gói ${LangConstant.OBJ_PACKAGES[data.plan].name}`
            : LangConstant.PACKAGE_TYPES_NAME[data?.type]}
        </Typography>
      </TableCell>
      <TableCell align="center">{data?.orderNumber}</TableCell>
      <TableCell>
        {StringFormat(FM_USING_TIME, {
          type: PACKAGE_USING_TYPE_NAME[data?.usingTimeType || AppConstant.USING_TIME_TYPE.custom],
          timeRange: convertTimestampToDateRange(data?.startDate, data?.expiryDate),
        })}
      </TableCell>
      <TableCell align="center">{data?.period}</TableCell>
      <TableCell>{formatToFormCurrency(data?.price) || 0}</TableCell>
      <TableCell>{formatToFormCurrency(data?.total) || 0}</TableCell>
    </TableRow>
  );
};

PaymentDialogRow.propTypes = {
  data: PropTypes.object,
};

export default PaymentDialogRow;

export const PACKAGE_USING_TYPE_NAME = {
  [AppConstant.USING_TIME_TYPE.custom]: "Tùy chọn",
  [AppConstant.USING_TIME_TYPE.byPackage]: "Theo gói",
};

const FM_USING_TIME = "{type}    {timeRange}";
