import { ApiConstant } from "const";
import { createApiRegistration } from "api";
import StringFormat from "string-format";

export const getBlogsCategoryList = () => createApiRegistration().get(ApiConstant.BLOGS_CATEGORY);

export const deleteBlogsCategory = data =>
  createApiRegistration().delete(StringFormat(ApiConstant.BLOGS_CATEGORY_BY_ID, data));

export const createBlogsCategory = data => createApiRegistration().post(ApiConstant.BLOGS_CATEGORY, data);

export const updateBlogsCategory = data =>
  createApiRegistration().put(StringFormat(ApiConstant.BLOGS_CATEGORY_BY_ID, data.id), data);
