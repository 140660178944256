import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, Paper, Box, Grid, Typography, OutlinedInput } from "@material-ui/core";
import StringFormat from "string-format";
import clsx from "clsx";
import { HeaderTab, LANG_OPTION, UploadCover } from "pages/PostBlog/components";
import { CKEditorCustomize } from "components";
import { removeTags, removeRegularHTMLCodeAndCalculator } from "utils";
import { LangConstant } from "const";

const InfoQuestion = props => {
  const { classes, isReset, onResetSuccess, data, onChangeData } = props;
  const defaultClasses = useStyles();

  const [tabValue, setTabValue] = useState(LANG_OPTION.vi);
  const [formData, setFormData] = useState(data);

  const onChangeTab = value => {
    setTabValue(value);
  };

  const onChange = e => {
    let newFormData = {
      ...formData,
      [e.target.name]: {
        ...formData[e.target.name],
        [tabValue]: e.target.value,
      },
    };
    setFormData(newFormData);
    onChangeData(newFormData);
  };

  const onChangeCKEditor = data => {
    let newFormData = {
      ...formData,
      content: {
        ...formData.content,
        [tabValue]: data,
      },
    };
    setFormData(newFormData);
    onChangeData(newFormData);
  };

  const onChangeCoverBlog = url => {
    let newFormData = {
      ...formData,
      thumbnail: {
        en: url,
        vi: url,
      },
    };
    setFormData(newFormData);
    onChangeData(newFormData);
  };

  useEffect(() => {
    if (isReset) {
      setFormData(data);
      onResetSuccess();
    }
  }, [isReset]);

  return (
    <Box className={clsx(defaultClasses.root, classes?.root)}>
      <Paper className={defaultClasses.paper}>
        <HeaderTab tabValue={tabValue} onChangeTab={onChangeTab} />
        <Grid container spacing={3} className={defaultClasses.contentBlog}>
          <Grid item xs={9}>
            <Box paddingBottom="36px">
              <Typography className={clsx("regular-md-txt", defaultClasses.title)}>Câu hỏi</Typography>
              <OutlinedInput
                title="Người đại diện"
                multiline
                name="title"
                onChange={onChange}
                value={data?.title[tabValue]}
                placeholder={StringFormat(LangConstant.FM_INPUT_QUESTION, tabValue.toUpperCase())}
                classes={{
                  root: clsx("semiBold-lg-txt", defaultClasses.rootInput),
                  input: defaultClasses.input,
                  multiline: defaultClasses.inputMultipleLine,
                }}
              />
              <Box className={defaultClasses.borderCountWord}>
                <Typography className={clsx("regular-sm-txt", defaultClasses.contentCountWord)}>
                  {StringFormat(LangConstant.FM_LIMIT_WORD, data.title[tabValue].length, LIMIT)}
                </Typography>
              </Box>
            </Box>
            <CKEditorCustomize data={formData.content[tabValue]} onChange={onChangeCKEditor} />
            <Typography className={clsx("regular-md-txt", defaultClasses.numberWord)}>
              {StringFormat(
                LangConstant.FM_NUMBER_WORD,
                formData.content[tabValue]
                  ? removeRegularHTMLCodeAndCalculator(removeTags(formData.content[tabValue]))
                  : 0,
              )}
            </Typography>
          </Grid>
          <UploadCover url={data.thumbnail[tabValue]} onChange={onChangeCoverBlog} langValue={tabValue.toUpperCase()} />
        </Grid>
      </Paper>
    </Box>
  );
};

const LIMIT = 80;

InfoQuestion.propTypes = {
  classes: PropTypes.object,
  isReset: PropTypes.bool,
  onResetSuccess: PropTypes.func,
  data: PropTypes.object,
  onChangeData: PropTypes.func,
};

InfoQuestion.defaultProps = {
  classes: {},
  onResetSuccess: () => {},
  onChangeData: () => {},
};
export default memo(InfoQuestion);

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    padding: 16,
  },
  paper: {
    padding: "24px 18px 34px",
    boxShadow: "0 1px 6px 0 rgba(0, 0, 0, 0.16)",
  },
  contentBlog: {
    paddingTop: 36,
  },
  numberWord: {
    marginTop: 12,
    color: "#3e4045",
    lineHeight: "22px",
  },
  rootInput: {
    width: "100%",
    minHeight: 48,
  },
  input: {
    minHeight: 54,
    padding: "12px 16px",
  },
  inputMultipleLine: {
    padding: 0,
  },
  title: {
    marginBottom: 12,
    color: "#3e4045",
    lineHeight: "22px",
  },
  borderCountWord: {
    height: 30,
    padding: "5px 18px",
    backgroundColor: "rgba(212, 213, 216, 0.5)",
  },
  contentCountWord: {
    height: 20,
    lineHeight: 1.67,
    color: "#3e4045",
  },
}));
