import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getListBlogItem: ["data"],
  updateBlogItem: ["data"],
  createBlogItem: ["data"],

  blogItemSuccess: ["data"],
  blogItemFailure: ["error"],
  blogItemReset: [],
});

export const BlogItemTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  data: {
    data: [],
    page: 1,
    size: 10,
    asc: 1,
    filter: "",
  },

  isSuccess: false,
  isCreateSuccess: false,
  isUpdateSuccess: false,
  isFetching: false,
  error: null,
};

/* ------------- Reducers ------------- */
export const request = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
});

export const success = (state = INITIAL_STATE, action) => {
  let data = action.data || {};
  return { ...state, isFetching: false, isSuccess: true, ...data };
};

export const failure = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: false,
});

export const reset = () => INITIAL_STATE;

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.GET_LIST_BLOG_ITEM]: request,
  [Types.UPDATE_BLOG_ITEM]: request,
  [Types.CREATE_BLOG_ITEM]: request,

  [Types.BLOG_ITEM_SUCCESS]: success,
  [Types.BLOG_ITEM_FAILURE]: failure,
  [Types.BLOG_ITEM_RESET]: reset,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
