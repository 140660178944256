import React, { memo, useState, useEffect } from "react";
import { makeStyles, Box, Button, IconButton, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import BlogsLayout from "layouts/BlogsLayout";
import { Alert } from "components";
import { TabBlogs } from "pages/PostBlog/components";
import { useDispatch, useSelector } from "react-redux";
import BlogItemAction from "redux/blog-item.redux";
import { AppConstant, LangConstant } from "const";
import clsx from "clsx";

const EditBlog = props => {
  const { data, onComeBack } = props;
  const defaultClasses = useStyles();
  const dispatch = useDispatch();

  const isUpdateSuccess = useSelector(state => state.blogItemRedux.isUpdateSuccess);
  const listCategory = useSelector(state => state.blogsCategoryRedux.data);
  let listCategoryId = listCategory.map(dataMap => dataMap.id);

  const [formDataInput, setFormDataInput] = useState(DEFAULT_FORM_DATA);
  const [categoryValue, setCategoryValue] = useState(OPT_ALL_ITEMS_ID);
  const [flagHomeValue, setFlagHomeValue] = useState(AppConstant.STATUS_DISPLAY_HOME_PAGE.hidden);
  const [isDisplayAlert, setIsDisplayAlert] = useState(false);
  const [codeStatus, setCodeStatus] = useState(AppConstant.STATUS_OPTION.draft);

  const onChangeCategory = e => {
    let valueCategory = e.target.value;
    let arrayCategory = [];
    if (valueCategory !== OPT_ALL_ITEMS_ID) {
      arrayCategory.push(valueCategory);
    } else {
      arrayCategory = listCategoryId;
    }
    setFormDataInput({ ...formDataInput, blogCategoryIdList: arrayCategory });
    setCategoryValue(valueCategory);
  };

  const onCheckDisplayInHomePage = e => {
    let checkData = e.target.checked
      ? AppConstant.STATUS_DISPLAY_HOME_PAGE.display
      : AppConstant.STATUS_DISPLAY_HOME_PAGE.hidden;
    setFormDataInput(currentData => ({ ...currentData, flagShowToHomePage: checkData }));
    setFlagHomeValue(checkData);
  };

  const onUpdateBlogs = status => {
    let arrayCategory = [];
    if (categoryValue !== OPT_ALL_ITEMS_ID) {
      arrayCategory.push(categoryValue);
    } else {
      arrayCategory = listCategoryId;
    }
    let newFormData = { ...formDataInput, blogCategoryIdList: arrayCategory, published: status };
    dispatch(BlogItemAction.updateBlogItem(newFormData));
  };

  const onChangeData = data => {
    setFormDataInput({ ...formDataInput, ...data });
  };

  const onCloseAlert = () => {
    setIsDisplayAlert(false);
  };

  useEffect(() => {
    if (isUpdateSuccess) {
      dispatch(BlogItemAction.blogItemReset());
      setIsDisplayAlert(true);
      dispatch(BlogItemAction.getListBlogItem());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (data) {
      setFormDataInput({ ...DEFAULT_FORM_DATA, ...data });
      let categoryValue = OPT_ALL_ITEMS_ID;
      if (data.blogCategoryIdList.length === 1) {
        categoryValue = data.blogCategoryIdList[0];
      }
      setFlagHomeValue(data.flagShowToHomePage);
      setCategoryValue(categoryValue);
      setCodeStatus(data.published);
    }
  }, [data]);

  return (
    <Box>
      <BlogsLayout
        title={
          <Button onClick={onComeBack} className={defaultClasses.buttonManage}>
            Quản lý bài đăng
          </Button>
        }
        isDisplaySaveDraft
        valueHomePage={flagHomeValue}
        statusValue={LangConstant.ARRAY_STATUS[codeStatus].name}
        onCreateBlogs={onUpdateBlogs}
        onCheckDisplay={onCheckDisplayInHomePage}
        propsCategory={{
          value: categoryValue,
          onChangeInput: onChangeCategory,
        }}
      >
        <Box className={defaultClasses.middleLayout}>
          <Typography variant="subtitle2" className={defaultClasses.title}>
            Chỉnh sửa bài đăng
          </Typography>
        </Box>
      </BlogsLayout>
      <TabBlogs onChangeData={onChangeData} data={formDataInput} />
      {isDisplayAlert && (
        <Alert
          isShow={true}
          onClose={onCloseAlert}
          message="Bài viết đã được đăng"
          type="success"
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          action={
            <IconButton onClick={onCloseAlert} className={defaultClasses.button}>
              <Close className={defaultClasses.iconClose} />
            </IconButton>
          }
          classes={{
            root: defaultClasses.rootAlert,
            action: defaultClasses.action,
            icon: defaultClasses.icon,
            message: clsx("regular-sm-txt", defaultClasses.message),
          }}
        />
      )}
    </Box>
  );
};

const OPT_ALL_ITEMS_ID = "all";

const DEFAULT_FORM_DATA = {
  title: {
    vi: "",
    en: "",
  },
  content: {
    vi: "",
    en: "",
  },
  published: AppConstant.STATUS_OPTION.publish,
  thumbnail: "",
  blogCategoryIdList: [],
  description: {
    vi: "",
    en: "",
  },
  hashtags: [],
};

export default memo(EditBlog);

const useStyles = makeStyles(theme => ({
  middleLayout: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
  },
  buttonManage: {
    height: 24,
    marginTop: 3,
    padding: 0,
    color: theme.palette.grey[500],
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: 16,
    "&:hover": {
      color: theme.palette.grey[700],
      backgroundColor: "unset",
    },
  },
  title: {
    height: 24,
    margin: "3px 0 3px 24px",
    lineHeight: 1.5,
  },
  buttonCreateNew: {
    marginRight: 8,
    fontWeight: 600,
    "&:hover": {
      color: theme.palette.white,
      backgroundColor: theme.palette.primary.main,
    },
  },
  rootAlert: {
    width: 400,
    height: 30,
    padding: "5px 8px",
    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.16)",
    backgroundColor: "rgba(101, 179, 157, 0.5)",
    color: "#3e4045",
  },
  button: {
    padding: 0,
  },
  iconClose: {
    width: 20,
    height: 20,
  },
  icon: {
    display: "none",
  },
  action: {
    marginRight: 0,
    paddingLeft: 8,
    display: "unset",
  },
  message: {
    width: "100%",
    lineHeight: 1.67,
    padding: 0,
    textAlign: "center",
  },
}));
