import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  OutlinedInput,
  TableCell,
  TableRow,
  Typography,
  makeStyles,
  IconButton,
  Popover,
} from "@material-ui/core";
import { Close, DateRange } from "@material-ui/icons";
import { convertTimestampToDateRange, formatToFormCurrency } from "utils";
import { AppConstant, LangConstant } from "const";
import { PACKAGE_USING_TYPE_NAME } from "./PaymentDialogRow";
import { ConfirmDialog, RangeDatePicker } from "components";

const PaymentDialogEditingRow = props => {
  const { data, onChange, onDelete } = props;
  let cloneData = data ? { ...data } : {};
  const classes = useStyles();
  const datePickerDialogRef = useRef();

  const [isConfirmDelete, setIsConfirmDelete] = useState(false);
  const [isSelectTimeRange, setIsSelectTimeRange] = useState(false);
  const [datePickerAnchor, setDatePickerAnchor] = useState();

  const onChangeData = event => {
    let key = event.target.name;
    let value = event.target.value;
    cloneData[key] = value;

    if (key === INPUT_NM_PERIOD && value > 0) {
      cloneData.period = value;
      cloneData.total = value * cloneData.price;
    }

    if (key === INPUT_NM_PRICE) {
      cloneData.price = value.replaceAll(".", "");
      cloneData.total = cloneData.period * value.replaceAll(".", "");
    }

    if (onChange) onChange(cloneData);
  };

  const onDeleteFeature = () => {
    setIsConfirmDelete(false);
    onDelete();
  };

  const onTimeRangeSelected = dateRange => {
    if (dateRange.from && dateRange.to) {
      // Convert date to timestamp before set data
      let startTime = Math.floor(dateRange.from.getTime() / 1000);
      let endTime = Math.floor(dateRange.to.getTime() / 1000);

      onChange({
        startDate: startTime,
        expiryDate: endTime,
      });
    }
    setIsSelectTimeRange(false);
  };

  let isPackageFunctions =
    Boolean(cloneData.plan) ||
    cloneData.type === AppConstant.PACKAGE_DETAIL_TYPE.booking ||
    cloneData.type === AppConstant.PACKAGE_DETAIL_TYPE.order;

  return (
    <>
      <TableRow hover>
        <TableCell>
          <Typography>
            {cloneData.plan
              ? `Gói ${LangConstant.OBJ_PACKAGES[cloneData.plan]?.name}`
              : LangConstant.PACKAGE_TYPES_NAME[cloneData.type]}
          </Typography>
        </TableCell>
        <TableCell align="center">
          {isPackageFunctions ? (
            <Box className={classes.cellReadOnly}>{cloneData.orderNumber}</Box>
          ) : (
            <OutlinedInput name="orderNumber" value={cloneData.orderNumber} onChange={onChangeData} />
          )}
        </TableCell>
        <TableCell>
          {cloneData.plan ? (
            <Box className={classes.timePickerBox}>
              <Box>{PACKAGE_USING_TYPE_NAME[cloneData.usingTimeType || AppConstant.USING_TIME_TYPE.custom]}</Box>

              <Box>
                {convertTimestampToDateRange(cloneData.startDate, cloneData.expiryDate)}
                <IconButton
                  size="small"
                  ref={datePickerDialogRef}
                  onClick={() => {
                    setDatePickerAnchor(datePickerDialogRef.current);
                    setIsSelectTimeRange(true);
                  }}
                >
                  <DateRange />
                </IconButton>
              </Box>
            </Box>
          ) : (
            <Box className={classes.usingTimeRoot}>
              <Box>{PACKAGE_USING_TYPE_NAME[cloneData.usingTimeType || AppConstant.USING_TIME_TYPE.custom]}</Box>
              <Box>
                {convertTimestampToDateRange(cloneData.startDate, cloneData.expiryDate)}
                <DateRange />
              </Box>
            </Box>
          )}
        </TableCell>
        <TableCell align="center">
          <OutlinedInput type="number" name="period" value={cloneData.period} onChange={onChangeData} />
        </TableCell>
        <TableCell>
          <OutlinedInput name="price" value={formatToFormCurrency(cloneData.price)} onChange={onChangeData} />
        </TableCell>
        <TableCell>
          <OutlinedInput
            value={cloneData.total > 0 ? formatToFormCurrency(cloneData.total) : 0}
            onChange={onChangeData}
          />
        </TableCell>
        <TableCell padding="none">
          <IconButton size="small" onClick={() => setIsConfirmDelete(true)}>
            <Close fontSize="small" />
          </IconButton>
        </TableCell>
      </TableRow>
      {isConfirmDelete && (
        <ConfirmDialog
          isShow={true}
          onClose={() => setIsConfirmDelete(false)}
          onSubmit={onDeleteFeature}
          title="Bạn có chắc muốn xóa tính năng này không?"
          message="Tính năng này sẽ được xóa khỏi gói dịch vụ của bạn."
        />
      )}
      <Popover
        open={isSelectTimeRange}
        anchorEl={datePickerAnchor}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        classes={{
          root: classes.datePickerContainer,
          paper: classes.datePickerPaper,
        }}
        onClose={() => setIsSelectTimeRange(false)}
      >
        <RangeDatePicker
          onChange={onTimeRangeSelected}
          dateRange={{
            from: cloneData.startDate ? new Date(cloneData.startDate * 1000) : new Date(),
            to: cloneData.expiryDate ? new Date(cloneData.expiryDate * 1000) : new Date(),
          }}
        />
      </Popover>
    </>
  );
};

PaymentDialogEditingRow.propTypes = {
  data: PropTypes.object,

  onChange: PropTypes.func,
};

export default PaymentDialogEditingRow;

const INPUT_NM_PERIOD = "period";
const INPUT_NM_PRICE = "price";

const useStyles = makeStyles(theme => ({
  timePickerBox: {
    display: "flex",
    "& > div:first-child": {
      background: "rgba(212, 213, 216, 0.4)",
    },
    "& > div:last-child": {
      color: "#4f8bff",
    },
    "& > div": {
      padding: theme.spacing(2, 1),
      borderRadius: 3,

      "& + div": {
        display: "flex",
        alignItems: "center",
        flexGrow: 1,
        marginLeft: 4,

        "& svg": {
          marginLeft: 4,
          fontSize: 16,
          color: "#979aa1",
        },
      },
    },
  },
  usingTimeRoot: {
    display: "flex",
    "& > div": {
      padding: theme.spacing(2, 1),
      background: "rgba(212, 213, 216, 0.4)",
      borderRadius: 3,

      "& + div": {
        display: "flex",
        alignItems: "center",
        flexGrow: 1,
        marginLeft: 4,

        "& svg": {
          marginLeft: 4,
          fontSize: 16,
          color: "#979aa1",
        },
      },
    },
  },
  cellReadOnly: {
    padding: theme.spacing(2, 1),
    background: "rgba(212, 213, 216, 0.4)",
    borderRadius: 3,
  },

  datePickerContainer: {
    display: "flex",
    textTransform: "none",
  },
  datePickerPaper: {
    display: "block",
    borderRadius: 4,
    marginTop: 5,
  },
}));
