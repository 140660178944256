import React, { memo } from "react";
import PropTypes from "prop-types";
import { Link as MuiLink } from "@material-ui/core";
import { Link } from "react-router-dom";

const AppLink = props => {
  const { to, href, children, ...otherProps } = props;
  let nextRouter = to || href || "#";

  return (
    <Link to={nextRouter} component={MuiLink} {...otherProps}>
      {children}
    </Link>
  );
};

AppLink.propTypes = { to: PropTypes.any, as: PropTypes.string, className: PropTypes.string };
AppLink.defaultProps = {};

export default memo(AppLink);
