import { call, put, select } from "redux-saga/effects";
import { toSnake, toCamel } from "utils";
import { ApiConstant, AppConstant, LangConstant } from "const";
import { OwnerDetailsService } from "services";
import OwnerDetailsAction from "redux/owner-details.redux";

export function* getListPackage(action) {
  try {
    const { data } = action;

    let response = yield call(OwnerDetailsService.getListPackage, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      let responseData = toCamel(response.data.data);
      let listPackage = restructureData(responseData, data.userId);

      yield put(
        OwnerDetailsAction.ownerDetailsSuccess({
          listPackage: listPackage,
          isSuccess: true,
        }),
      );
    } else {
      let responseData = response.data?.error;
      yield put(OwnerDetailsAction.ownerDetailsFailure(responseData));
    }
  } catch (error) {
    yield put(OwnerDetailsAction.ownerDetailsFailure(error));
  }
}

export function* getOwnerInfo(action) {
  try {
    const { data } = action;

    let response = yield call(OwnerDetailsService.getOwnerInfo, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      let responseData = toCamel(response.data.data);
      yield put(
        OwnerDetailsAction.ownerDetailsSuccess({
          ownerDetails: { ...responseData, ownerId: data.id },
          isSuccess: true,
        }),
      );
    } else {
      let responseData = response.data?.error;
      yield put(OwnerDetailsAction.ownerDetailsFailure(responseData));
    }
  } catch (error) {
    yield put(OwnerDetailsAction.ownerDetailsFailure(error));
  }
}

export function* getPaymentHistory(action) {
  try {
    const { data } = action;
    let response = yield call(OwnerDetailsService.getPaymentHistory, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      let responseData = toCamel(response.data.data);
      let paymentHistory = responseData.map(dataMap => restructurePaymentDetail(dataMap));

      yield put(
        OwnerDetailsAction.ownerDetailsSuccess({
          isHistorySuccess: true,
          paymentHistory: paymentHistory,
          paymentHistoryInput: data,
        }),
      );
    } else {
      let responseData = response.data?.error;
      yield put(OwnerDetailsAction.ownerDetailsFailure(responseData));
    }
  } catch (error) {
    yield put(OwnerDetailsAction.ownerDetailsFailure(error));
  }
}

export function* getPaymentDetail(action) {
  try {
    const { data } = action;
    let response = yield call(OwnerDetailsService.getPaymentDetail, data);
    if (response.status === ApiConstant.STT_OK) {
      let toCamelData = toCamel(response.data.data);
      let responseData = restructurePaymentDetail(toCamelData);

      yield put(
        OwnerDetailsAction.ownerDetailsSuccess({
          paymentDetail: responseData,
        }),
      );
    } else {
      let responseData = response.data?.error;
      yield put(OwnerDetailsAction.ownerDetailsFailure(responseData));
    }
  } catch (error) {
    yield put(OwnerDetailsAction.ownerDetailsFailure(error));
  }
}

export function* updatePaymentDetail(action) {
  try {
    const { data } = action;

    const {
      ownerDetailsRedux: { paymentHistoryInput },
    } = yield select();

    let response = yield call(OwnerDetailsService.updatePaymentDetail, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      let responseData = toCamel(response.data.data);

      yield put(
        OwnerDetailsAction.ownerDetailsSuccess({
          isUpdateSuccess: true,
          paymentHistory: responseData,
        }),
      );
      if (Object.keys(paymentHistoryInput).length > 0)
        yield put(OwnerDetailsAction.getPaymentHistory(paymentHistoryInput));
    } else {
      let responseData = response.data?.error;
      yield put(OwnerDetailsAction.ownerDetailsFailure(responseData));
    }
  } catch (error) {
    yield put(OwnerDetailsAction.ownerDetailsFailure(error));
  }
}

export function* updatePackageFeatures(action) {
  try {
    const {
      ownerDetailsRedux: { paymentHistoryInput },
    } = yield select();
    const { data } = action;

    let updatedPackageFeatures = data.packageDetails.map(item => ({
      ...item,
      price: Number(item.price),
      period: Number(item.period),
      orderNumber: Number(item.orderNumber),
    }));

    let response = yield call(
      OwnerDetailsService.updatePackageFeatures,
      toSnake({ ...data, packageDetails: updatedPackageFeatures }),
    );
    if (response.status === ApiConstant.STT_OK) {
      yield put(
        OwnerDetailsAction.ownerDetailsSuccess({
          isSuccessFeatures: true,
        }),
      );
      // After add new features get payment history and list packages to immediately see new updates
      yield put(OwnerDetailsAction.getPaymentHistory(paymentHistoryInput));
      yield put(OwnerDetailsAction.getListPackage({ userId: paymentHistoryInput.userId }));
    } else {
      let responseData = response.data?.error;
      yield put(OwnerDetailsAction.ownerDetailsFailure(responseData));
    }
  } catch (error) {
    yield put(OwnerDetailsAction.ownerDetailsFailure(error));
  }
}

export function* changePackage(action) {
  try {
    const { data } = action;
    let updatedPackageDetail = data.packageDetails.map(item => ({
      ...item,
      price: Number(item.price),
      period: Number(item.period),
    }));

    let response = yield call(
      OwnerDetailsService.changePackage,
      toSnake({ ...data, packageDetails: updatedPackageDetail }),
    );

    if (response.status === ApiConstant.STT_OK) {
      let responseData = toCamel(response.data.data);

      yield put(
        OwnerDetailsAction.ownerDetailsSuccess({
          isSuccess: true,
          paymentHistory: responseData,
        }),
      );
      yield put(OwnerDetailsAction.getListPackage({ userId: data.userId }));
    } else {
      let responseData = response.data?.error;
      yield put(OwnerDetailsAction.ownerDetailsFailure(responseData));
    }
  } catch (error) {
    yield put(OwnerDetailsAction.ownerDetailsFailure(error));
  }
}

export function* disableOwner(action) {
  try {
    const {
      ownerDetailsRedux: { listPackage },
    } = yield select();

    const { data } = action;

    let updateIndex = listPackage.findIndex(key => key.userId === data.userId);
    listPackage[updateIndex].state = data.state;

    let response = yield call(OwnerDetailsService.disableOwner, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      yield put(OwnerDetailsService.ownerDetailsSuccess({ listPackage: listPackage, isSuccess: true }));
    } else {
      let responseData = response.data?.error;
      yield put(OwnerDetailsService.ownerDetailsFailure(responseData));
    }
  } catch (error) {
    yield put(OwnerDetailsService.ownerDetailsFailure(error));
  }
}

export function* updatePaymentAsPaid(action) {
  try {
    const {
      ownerDetailsRedux: { paymentHistoryInput },
    } = yield select();
    const { data } = action;

    let response = yield call(OwnerDetailsService.updatePaymentAsPaid, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      yield put(OwnerDetailsAction.getPaymentHistory(paymentHistoryInput));
      yield put(OwnerDetailsAction.getListPackage({ userId: paymentHistoryInput.userId }));
    } else {
      let responseData = response.data?.error;
      yield put(OwnerDetailsAction.ownerDetailsFailure(responseData));
    }
  } catch (error) {
    yield put(OwnerDetailsAction.ownerDetailsFailure(error));
  }
}

const getPackageProperties = listPackage => {
  let functions = [];
  let resources = [];
  if (listPackage) {
    listPackage.forEach(dataMap => {
      switch (dataMap.type) {
        case AppConstant.PACKAGE_DETAIL_TYPE.order:
          !functions.includes(AppConstant.PACKAGE_DETAIL_TYPE.order) &&
            functions.push(AppConstant.PACKAGE_DETAIL_TYPE.order);
          break;
        case AppConstant.PACKAGE_DETAIL_TYPE.booking:
          !functions.includes(AppConstant.PACKAGE_DETAIL_TYPE.booking) &&
            functions.push(AppConstant.PACKAGE_DETAIL_TYPE.booking);
          break;
        case AppConstant.PACKAGE_DETAIL_TYPE.item:
          !resources.includes(AppConstant.PACKAGE_DETAIL_TYPE.item) &&
            resources.push(AppConstant.PACKAGE_DETAIL_TYPE.item);
          break;
        case AppConstant.PACKAGE_DETAIL_TYPE.staff:
          !resources.includes(AppConstant.PACKAGE_DETAIL_TYPE.staff) &&
            resources.push(AppConstant.PACKAGE_DETAIL_TYPE.staff);
          break;
        case AppConstant.PACKAGE_DETAIL_TYPE.place:
          !resources.includes(AppConstant.PACKAGE_DETAIL_TYPE.place) &&
            resources.push(AppConstant.PACKAGE_DETAIL_TYPE.place);
          break;
        default:
          break;
      }
    });
  }
  return { functions: functions, resources: resources };
};

const getFunctionsAmount = packageObj => {
  let result = {
    packageNumber: 0,
    shopNumber: 0,
    orderNumber: 0,
    bookingNumber: 0,
    itemNumber: 0,
    staffNumber: 0,
    placeNumber: 0,
  };

  if (packageObj && Array.isArray(packageObj.packageDetails)) {
    // Count number by package function type (package function: item, staff, ...)
    packageObj.packageDetails.forEach(item => {
      switch (item.type) {
        case AppConstant.PACKAGE_DETAIL_TYPE.package:
          result.packageNumber += item.orderNumber || 0;
          break;
        case AppConstant.PACKAGE_DETAIL_TYPE.shop:
          result.shopNumber += item.orderNumber || 0;
          break;
        case AppConstant.PACKAGE_DETAIL_TYPE.order:
          result.orderNumber += item.orderNumber || 0;
          break;
        case AppConstant.PACKAGE_DETAIL_TYPE.booking:
          result.bookingNumber += item.orderNumber || 0;
          break;
        case AppConstant.PACKAGE_DETAIL_TYPE.item:
          result.itemNumber += item.orderNumber || 0;
          break;
        case AppConstant.PACKAGE_DETAIL_TYPE.staff:
          result.staffNumber += item.orderNumber || 0;
          break;
        case AppConstant.PACKAGE_DETAIL_TYPE.place:
          result.placeNumber += item.orderNumber || 0;
          break;

        default:
          break;
      }
    });
  }

  return result;
};

const restructureData = (array, ownerId) => {
  if (!array || array.length === 0) return [];
  let result = array.map(dataMap => {
    let orderNumberGroup = getFunctionsAmount(dataMap);
    return {
      ...dataMap,
      ...orderNumberGroup,
      userId: ownerId,
      functions: getPackageProperties(dataMap.packageDetails).functions,
      resources: getPackageProperties(dataMap.packageDetails).resources,
    };
  });
  return result;
};

const restructurePaymentDetail = data => {
  let result = {};
  if (data) {
    let paymentDetail = data.packageDetails?.map(dataMap => {
      if (dataMap.type === AppConstant.PACKAGE_DETAIL_TYPE.package) return { ...dataMap, plan: data.plan };
      else
        return {
          ...dataMap,
          maxPeriod: getPeriod(data.startDate, data.expiryDate),
        };
    });

    let strPaymentStatus = LangConstant.OBJ_PACKAGES_STATUS[data?.status]?.name || "";
    result = {
      ...data,
      packageDetails: paymentDetail || [],
      strCode: "#" + data.code,
      strPaymentStatus: strPaymentStatus,
    };
  }
  return result;
};

const getPeriod = (startTime, endTime) => {
  let result = 0;

  if (startTime && endTime) {
    let startDate = new Date(startTime * 1000);
    let endDate = new Date(endTime * 1000);
    result = Math.max(
      (endDate.getFullYear() - startDate.getFullYear()) * 12 + endDate.getMonth() - startDate.getMonth(),
      0,
    );
  }
  return result;
};
