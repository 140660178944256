import { AppConstant } from "const";

// Common language
export const OBJ_LANGUAGE = {
  vi: {
    lang: "Tiếng Việt",
    code: "vi",
  },
  en: {
    lang: "English",
    code: "en",
  },
};

// List language code
export const DEFAULT_LANG = "vi";

// Namespace Key
export const NS_COMMON = "common";
export const NS_LOGIN = "login";

// Text Key
export const TXT_LOGIN = "TXT_LOGIN";

// Format
// export const FORMAT_??? = "???";

// Message
// export const MSG_?? = "???";

// Error
// export const ERR_EMAIL_PHONE = "ERR_EMAIL_PHONE";

export const ARRAY_STATUS = [
  {
    value: AppConstant.STATUS_OPTION.draft,
    name: "Bản nháp",
  },
  {
    value: AppConstant.STATUS_OPTION.publish,
    name: "Xuất bản",
  },
  {
    value: AppConstant.STATUS_OPTION.deleted,
    name: "Đã xóa",
  },
];

export const ARRAY_STATUS_TWO_OPTION = [
  {
    value: AppConstant.STATUS_OPTION.draft,
    name: "Bản nháp",
  },
  {
    value: AppConstant.STATUS_OPTION.publish,
    name: "Xuất bản",
  },
];

export const OBJ_STATUS_DISPLAY = {
  [AppConstant.STATUS_OPTION.draft]: {
    value: AppConstant.STATUS_OPTION.draft,
    name: "Đang ẩn",
  },
  [AppConstant.STATUS_OPTION.publish]: {
    value: AppConstant.STATUS_OPTION.publish,
    name: "Hiển thị",
  },
  [AppConstant.STATUS_OPTION.deleted]: {
    value: AppConstant.STATUS_OPTION.deleted,
    name: "Đã xóa",
  },
};

export const OBJ_DISPLAY_HOME_PAGE = {
  display: {
    value: AppConstant.STATUS_DISPLAY_HOME_PAGE.display,
    name: "Hiển thị",
  },
  hidden: {
    value: AppConstant.STATUS_DISPLAY_HOME_PAGE.hidden,
    name: "Không hiển thị",
  },
};

export const FM_INPUT_TITLE = "Nhập tên bài viết ({0})";
export const FM_INPUT_DESCRIPTION = "Nhập mô tả bài viết ({0})";
export const FM_UPLOAD_IMAGE = "Ảnh cover của bài viết ({0})";
export const FM_MESSAGE_DIALOG_BLOGS = `Bài viết "{0}" chưa được lưu, bạn có muốn lưu bài viết này không?`;
export const FM_TOTAL_SHOP = "(Tổng: {0} shop)";
export const FM_CONTENT_DISABLE = "Bạn có chắc muốn vô hiệu hóa tài khoản {0} trong hệ thống không?";
export const FM_CONTENT_ENABLE = "Bạn có chắc muốn kích hoạt tài khoản {0} trong hệ thống không?";
export const FM_CONTENT_RESET = "Bạn có chắc muốn reset mật khẩu tài khoản {0} trong hệ thống không?";
export const NOTIFY_UNSAVED = "Thông tin tài khoản chưa được lưu, bạn có muốn lưu thay đổi này ko?";
export const FM_NUMBER_WORD = "Số ký tự: {0}";
export const FM_LIMIT_WORD = "Số ký tự: {0}/{1}";
export const FM_TITLE = "Nhập tiêu đề slide ({0})";
export const FM_TITLE_FEATURE = "Nhập tiêu đề chính ({0})";
export const FM_SUBTITLE_FEATURE = "Nhập tiêu đề phụ ({0})";
export const FM_CONTENT_FEATURE = "Nhập nội dung ({0})";
export const FM_INPUT_DEPUTY_EVALUATE = "Nhập người đại diện ({0})";
export const FM_INPUT_TITLE_EVALUATE = "Nhập chức danh ({0})";
export const FM_LINK_TO_BLOG = "Link tới bài viết ({0})";
export const FM_ILLUSTRATION = "Ảnh minh họa ({0})";
export const FM_ILLUSTRATION_SOCIAL = "Ảnh minh họa {0} ({1})"; // 0: name social network, 1: language tab
export const FM_SHOWING = "Đang hiển thị {0}";
export const FM_BACKGROUND_COLOR = "Màu background ({0})";
export const FM_IMAGE_VIDEO = "Ảnh/video background ({0})";
export const FM_IMAGE_DEPUTY = "Ảnh người đại diện ({0})";
export const FM_IMAGE_BRANCH = "Ảnh thương hiệu ({0})";
export const FM_INPUT_QUESTION = "Nhập câu hỏi ({0})";
export const FM_MESSAGE_SLIDE_SAVE = `Slide "{0}" chưa được lưu bạn có muốn lưu slide này không?`;
export const FM_MESSAGE_FEATURE_SAVE = `Tính năng "{0}" chưa được lưu bạn có muốn lưu tính năng này không?`;
export const FM_MESSAGE_EVALUATE_SAVE = `Đánh giá "{0}" chưa được lưu bạn có muốn lưu đánh giá này không?`;
export const FM_DATE_INPUT = `{0}, {1}/{2}/{3}`;

export const ARR_DAY_OF_WEEK = ["Thứ 2", "Thứ 3", "Thứ 4", "Thứ 5", "Thứ 6", "Thứ 7", "CN"];

export const ARRAY_GENDER = [
  {
    value: 1,
    name: AppConstant.GENDER[1],
  },
  {
    value: 2,
    name: AppConstant.GENDER[2],
  },
  {
    value: 3,
    name: AppConstant.GENDER[3],
  },
];

export const PACKAGE_TYPE = {
  1: "Tiêu chuẩn",
  2: "Nâng cao",
  3: "Doanh nghiệp",
  4: "Miễn phí",
};
// Owners and Packages
export const FM_FUNCTION = `Tính năng: {0}`;
export const FM_RESOURCE = `Tài nguyên: {0}`;

export const OBJ_PACKAGES = {
  [AppConstant.PACKAGE_TYPE.free]: {
    value: AppConstant.PACKAGE_TYPE.free,
    name: "Free",
  },
  [AppConstant.PACKAGE_TYPE.standard]: {
    value: AppConstant.PACKAGE_TYPE.standard,
    name: "Standard",
  },
  [AppConstant.PACKAGE_TYPE.premium]: {
    value: AppConstant.PACKAGE_TYPE.premium,
    name: "Premium",
  },
  [AppConstant.PACKAGE_TYPE.enterprise]: {
    value: AppConstant.PACKAGE_TYPE.enterprise,
    name: "Enterprise",
  },
};

export const ARRAY_PLAN_PACKAGES = [
  {
    value: AppConstant.PACKAGE_TYPE.free,
    name: "Gói miễn phí",
  },
  {
    value: AppConstant.PACKAGE_TYPE.standard,
    name: "Gói Standard",
  },
  {
    value: AppConstant.PACKAGE_TYPE.premium,
    name: "Gói Premium",
  },
  {
    value: AppConstant.PACKAGE_TYPE.enterprise,
    name: "Gói Enterprise",
  },
];

export const OBJ_PACKAGES_STATUS = {
  [AppConstant.PAYMENTS_STATUS.unpaid]: {
    value: AppConstant.PAYMENTS_STATUS.unpaid,
    name: "Chưa thanh toán",
  },
  [AppConstant.PAYMENTS_STATUS.paid]: {
    value: AppConstant.PAYMENTS_STATUS.paid,
    name: "Đã thanh toán",
  },
  [AppConstant.PAYMENTS_STATUS.free]: {
    value: AppConstant.PAYMENTS_STATUS.free,
    name: "Miễn phí",
  },
};

export const SHOP_STATUS = {
  [AppConstant.SHOP_STATUS.active]: "Active",
  [AppConstant.SHOP_STATUS.inactive]: "Inactive",
};

export const ARRAY_PACKAGE_FUNCTIONS = [
  {
    value: AppConstant.PACKAGE_DETAIL_TYPE.booking,
    name: "Đặt lịch",
  },
  {
    value: AppConstant.PACKAGE_DETAIL_TYPE.order,
    name: "Đặt hàng",
  },
];

export const ARRAY_PACKAGE_RESOURCES = [
  {
    value: AppConstant.PACKAGE_DETAIL_TYPE.item,
    name: "Sản phẩm",
  },
  {
    value: AppConstant.PACKAGE_DETAIL_TYPE.staff,
    name: "Nhân viên",
  },
  {
    value: AppConstant.PACKAGE_DETAIL_TYPE.place,
    name: "Vị trí",
  },
];

export const ARRAY_PACKAGE_DETAILS = [
  {
    value: AppConstant.PACKAGE_DETAIL_TYPE.booking,
    name: "Đặt chỗ",
  },
  {
    value: AppConstant.PACKAGE_DETAIL_TYPE.order,
    name: "Đặt hàng",
  },
  {
    value: AppConstant.PACKAGE_DETAIL_TYPE.shop,
    name: "Cửa hàng",
  },
  {
    value: AppConstant.PACKAGE_DETAIL_TYPE.item,
    name: "Sản phẩm",
  },
  {
    value: AppConstant.PACKAGE_DETAIL_TYPE.staff,
    name: "Nhân viên",
  },
  {
    value: AppConstant.PACKAGE_DETAIL_TYPE.place,
    name: "Vị trí",
  },
];
export const PACKAGE_TYPES_NAME = {
  [AppConstant.PACKAGE_DETAIL_TYPE.shop]: "Shop",
  [AppConstant.PACKAGE_DETAIL_TYPE.order]: "Đặt hàng",
  [AppConstant.PACKAGE_DETAIL_TYPE.booking]: "Đặt chỗ",
  [AppConstant.PACKAGE_DETAIL_TYPE.item]: "Sản phẩm",
  [AppConstant.PACKAGE_DETAIL_TYPE.staff]: "Nhân viên",
  [AppConstant.PACKAGE_DETAIL_TYPE.place]: "Vị trí",
};
