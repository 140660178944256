import { ApiConstant } from "const";
import { call, put, select } from "redux-saga/effects";
import EvaluateAction from "redux/evaluate.redux";
import { EvaluateService } from "services";
import { toCamel, toSnake } from "utils";

export function* getListHomeEvaluate() {
  try {
    let response = yield call(EvaluateService.getHomeEvaluate);
    if (response.status === ApiConstant.STT_OK) {
      let responseData = toCamel(response.data.data);
      yield put(EvaluateAction.homeEvaluateSuccess({ data: responseData }));
    } else {
      let responseData = response.data?.error;
      yield put(EvaluateAction.homeEvaluateFailure(responseData));
    }
  } catch (error) {
    yield put(EvaluateAction.homeEvaluateFailure(error));
  }
}

export function* createHomeEvaluate(action) {
  try {
    const {
      evaluateRedux: { data: reduxData },
    } = yield select();
    const { data } = action;
    let formData = { ...data };
    if (!formData?.id) {
      delete formData["id"];
    }

    let response = yield call(EvaluateService.postHomeEvaluate, toSnake(formData));
    if (response.status === ApiConstant.STT_OK) {
      let newData = [...reduxData, toCamel(response.data.data)];
      yield put(EvaluateAction.homeEvaluateSuccess({ data: newData, isSuccess: true }));
    } else {
      let responseData = response.data?.error;
      yield put(EvaluateAction.homeEvaluateFailure(responseData));
    }
  } catch (error) {
    yield put(EvaluateAction.homeEvaluateFailure(error));
  }
}

export function* updateHomeEvaluate(action) {
  try {
    const {
      evaluateRedux: { data: reduxData },
    } = yield select();
    const { data } = action;

    let response = yield call(EvaluateService.putHomeEvaluate, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      let selectedIndex = reduxData.findIndex(key => key.id === data.id);

      let updateArray = [...reduxData];
      updateArray[selectedIndex] = Object.assign(updateArray[selectedIndex], toCamel(response.data.data));

      yield put(EvaluateAction.homeEvaluateSuccess({ data: updateArray, isSuccess: true }));
    } else {
      let responseData = response.data?.error;
      yield put(EvaluateAction.homeEvaluateFailure(responseData));
    }
  } catch (error) {
    yield put(EvaluateAction.homeEvaluateFailure(error));
  }
}

export function* deleteHomeEvaluate(action) {
  try {
    const {
      evaluateRedux: { data: reduxData },
    } = yield select();
    const { data } = action;

    let response = yield call(EvaluateService.deleteHomeEvaluate, data);
    if (response.status === ApiConstant.STT_OK) {
      let newData = reduxData.filter(item => item.id !== data.id);
      yield put(EvaluateAction.homeEvaluateSuccess({ data: newData }));
    } else {
      let responseData = response.data?.error;
      yield put(EvaluateAction.homeEvaluateFailure(responseData));
    }
  } catch (error) {
    yield put(EvaluateAction.homeEvaluateFailure(error));
  }
}
