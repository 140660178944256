import React, { memo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import { AppConstant, PathConstant } from "const";
import { BlogsInList, HeaderBlogsBar, EditBlog } from "./components";
import { PaginationFooter } from "components";
import ManageLayout from "layouts/ManageLayout";
import BlogItemAction from "redux/blog-item.redux";
import { useHistory } from "react-router-dom";
import { isArrayNotEqual } from "utils";

const ManageBlogs = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { data: listBlogs, ...pagination } = useSelector(state => state.blogItemRedux.data);

  const [arrayBlogItem, setArrayBlogItem] = useState([]);
  const [isShowFilter, setIsShowFilter] = useState(false);
  const [pageData, setPageData] = useState(DEFAULT_PAGE);
  const [formEdit, setFormEdit] = useState(DEFAULT_FORM_EDIT);

  const onChangePage = (_, newPage) => {
    dispatch(BlogItemAction.getListBlogItem({ page: newPage }));
  };

  const onCreateNew = () => {
    history.push(PathConstant.BLOGS_ADD);
  };

  const onEditBlogs = dataBlog => {
    setFormEdit({ isEdit: true, data: dataBlog });
  };

  const onComeBack = () => {
    setFormEdit({ isEdit: false, data: {} });
  };

  useEffect(() => {
    dispatch(BlogItemAction.getListBlogItem());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (listBlogs && isArrayNotEqual(listBlogs, arrayBlogItem)) {
      // Restructure data
      let formatArrayBlog = formatArrayBlogs(listBlogs, pagination.page);

      setArrayBlogItem(formatArrayBlog);
      setPageData({
        page: pagination.page,
        rowsPerPage: 10,
        total: pagination.total || 0,
        totalPage: pagination.totalPage,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listBlogs]);

  return (
    <>
      {formEdit.isEdit ? (
        <EditBlog data={formEdit.data} onComeBack={onComeBack} />
      ) : (
        <>
          <ManageLayout title="Quản lý bài đăng" buttonProps={{ onClick: onCreateNew }} />
          <HeaderBlogsBar isShowFilter={isShowFilter} onShowFilter={() => setIsShowFilter(!isShowFilter)} />
          <Box padding="24px 32px 8px" height="calc(100vh - 230px)" overflow="auto">
            {arrayBlogItem.map((data, index) => (
              <Box key={index} marginBottom="16px">
                <BlogsInList
                  data={data}
                  onEditItem={() => onEditBlogs(data)}
                  disableOption={{
                    isDisableEdit: false,
                    isDisableDelete: data.published !== AppConstant.STATUS_OPTION.publish,
                  }}
                />
              </Box>
            ))}
            <PaginationFooter data={pageData} onChangePage={onChangePage} />
          </Box>
        </>
      )}
    </>
  );
};

const DEFAULT_PAGE = {
  total: 0,
  page: 1,
  rowsPerPage: 10,
  totalPage: 0,
};

const DEFAULT_FORM_EDIT = {
  isEdit: false,
  data: {},
};

export default memo(ManageBlogs);

const formatArrayBlogs = (listBlogs, page) => {
  let formatArrayBlogs = listBlogs.map((blog, index) => ({
    number: (page - 1) * 10 + index + 1,
    id: blog.id,
    thumbnail: blog?.thumbnail || "",
    title: blog?.title || {},
    content: blog?.content || {},
    description: blog?.description || {},
    createdTime: blog?.created || 0,
    publishTime: blog?.publishTime || 0,
    published: blog?.published,
    blogCategoryIdList: blog.blogCategoryList.map(data => data.id),
    flagShowToHomePage: blog?.flagShowToHomePage || 0,
  }));
  return formatArrayBlogs;
};
