import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, FormControl, Select, MenuItem } from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import clsx from "clsx";

const SelectedItem = props => {
  const { classes, value, data, onChangeInput, isShow, ...otherProps } = props;

  const defaultClasses = useStyles();

  const [valueSelected, setValueSelected] = useState(OPT_ALL_ITEMS_ID);

  useEffect(() => {
    if (value !== valueSelected) setValueSelected(value || data[0]?.value);
  }, [value]);

  return (
    <FormControl variant="outlined" className={clsx(defaultClasses.root, classes?.root)} {...otherProps}>
      <Select
        value={valueSelected}
        onChange={event => {
          let value = event.target.value;
          onChangeInput(value);
          setValueSelected(value);
        }}
        IconComponent={KeyboardArrowDown}
        classes={{ select: defaultClasses.selectedSelect }}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          MenuListProps: { disablePadding: true },
          getContentAnchorEl: null,
          classes: { paper: defaultClasses.dropdownStyle },
        }}
      >
        {isShow && (
          <MenuItem
            value={OPT_ALL_ITEMS_ID}
            className={defaultClasses.menuOption}
            ListItemClasses={{ selected: defaultClasses.itemSelected }}
          >
            Tất cả
          </MenuItem>
        )}
        {data.map((dataMap, index) => (
          <MenuItem
            key={index}
            value={dataMap.value ? dataMap.value : dataMap}
            className={defaultClasses.menuOption}
            ListItemClasses={{ selected: defaultClasses.itemSelected }}
          >
            {dataMap.name ? dataMap.name : dataMap}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const OPT_ALL_ITEMS_ID = "all";

SelectedItem.propTypes = {
  classes: PropTypes.object,
  value: PropTypes.any,
  data: PropTypes.array,
  isShow: PropTypes.bool,
  onChangeInput: PropTypes.func,
};

SelectedItem.defaultProps = {
  onChangeInput: () => {},
  isShow: true,
};

export default memo(SelectedItem);

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: "100%",
    "& .MuiOutlinedInput-root": {
      height: 40,
      maxHeight: 40,
    },
    "& .MuiOutlinedInput-input": {
      padding: "9px 14px",
    },
  },
  selectedSelect: {
    fontSize: 14,
    color: theme.palette.grey[600],
  },
  menuOption: {
    fontSize: 14,
    "&:hover": {
      backgroundColor: theme.palette.grey[600],
      color: theme.palette.white,
    },
  },
  itemSelected: {
    "&:hover": {
      backgroundColor: `${theme.palette.grey[600]} !important`,
    },
  },
  dropdownStyle: {
    marginTop: 5,
  },
}));
