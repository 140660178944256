import React, { memo, useState, useEffect } from "react";
import {
  AppBar,
  Avatar,
  Box,
  CardMedia,
  ClickAwayListener,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { LogoImage } from "theme/images";
import { handlingLogout, hasLogin } from "utils";
import { PathConstant, AppConstant } from "const";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import AccountAction from "redux/account.redux";
import Cookies from "js-cookie";

const Header = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const userProfile = useSelector(state => state.accountRedux.accountDetails);

  const [isShow, setIsShow] = useState(false);

  const onLogout = () => {
    setIsShow(false);
    handlingLogout();
    let logoutInterval = setInterval(() => {
      if (!hasLogin()) {
        clearInterval(logoutInterval);
        history.replace(PathConstant.LOGIN);
      }
    }, 200);
  };

  useEffect(() => {
    if (!userProfile) {
      let userId = Cookies.get(AppConstant.KEY_USER_ID);
      if (userId) dispatch(AccountAction.getAccountDetails({ id: userId }));
    }
  }, [userProfile, dispatch]);

  return (
    <AppBar color="inherit" position="sticky">
      <Box className={classes.root}>
        <CardMedia component="img" src={LogoImage} className={classes.logo} />
        <IconButton onClick={() => setIsShow(true)}>
          <Avatar alt={userProfile?.name || ""} src={userProfile?.avatar || userProfile?.name || ""} />
        </IconButton>
        {isShow && (
          <ClickAwayListener onClickAway={() => setIsShow(false)}>
            <Paper className={classes.profileAction}>
              <List component="div">
                <ListItem button className={classes.actionItem}>
                  <ListItemText
                    primary={
                      <Typography variant="body2" color="inherit">
                        Đăng xuất
                      </Typography>
                    }
                    onClick={onLogout}
                  />
                </ListItem>
              </List>
            </Paper>
          </ClickAwayListener>
        )}
      </Box>
    </AppBar>
  );
};

export default memo(Header);

const useStyles = makeStyles({
  root: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: 60,
    paddingLeft: 30,
    paddingRight: 30,
  },
  logo: {
    width: 82,
    maxHeight: "100%",
  },
  profile: {},
  profileAction: {
    position: "absolute",
    top: 60,
    right: 42,
  },
  actionItem: {
    minWidth: 190,
    paddingTop: 3,
    paddingBottom: 3,
  },
});
