import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Redirect, Switch } from "react-router-dom";
import { PathConstant, AppConstant } from "const";
import Cookies from "js-cookie";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import { AuthenticationRoute, BglobalJSC, ErrorHandling } from "components";
import ManageBlogs from "pages/ManageBlogs";
import ManageCategory from "pages/ManageCategory";
import PostBlog from "pages/PostBlog";
import BackupBlogs from "pages/BackupBlogs";
import ManageShops from "pages/ManageShops";
import ManageRegistration from "pages/ManageRegistration";
import ManageShopsLog from "pages/ManageShopsLog";
import ManageAccount from "pages/ManageAccount";
import ManageProfile from "pages/ManageProfile";
import Introduction from "pages/Introduction";
import Feature from "pages/Feature";
import Evaluate from "pages/Evaluate";
import ManageCategories from "pages/HelpCenter/ManageCategories";
import ManageQuestions from "pages/HelpCenter/ManageQuestions";
import CreateQuestion from "pages/HelpCenter/CreateQuestion";
import ManageOwner from "pages/ManageOwner";
import OwnerDetail from "pages/OwnerDetail";

const MainLayout = () => {
  const defaultClasses = useStyles();
  let isRoot = Cookies.get(AppConstant.KEY_USER_ID) === AppConstant.ROOT_ID;

  return (
    <>
      <CssBaseline />
      <Header />
      <Box className={defaultClasses.root}>
        <Sidebar />
        <main className={defaultClasses.main}>
          <Switch>
            {/* HOME PAGE */}
            <AuthenticationRoute exact path={PathConstant.INTRODUCTION} component={Introduction} />
            <AuthenticationRoute exact path={PathConstant.FEATURE} component={Feature} />
            <AuthenticationRoute exact path={PathConstant.EVALUATE} component={Evaluate} />

            {/* HELP CENTER */}
            <AuthenticationRoute exact path={PathConstant.CREATE_QUESTION} component={CreateQuestion} />
            <AuthenticationRoute exact path={PathConstant.MANAGE_QUESTION} component={ManageQuestions} />
            <AuthenticationRoute exact path={PathConstant.MANAGE_CATEGORIES} component={ManageCategories} />

            {/* BLOGS */}
            <AuthenticationRoute exact path={PathConstant.BLOGS_ADD} component={PostBlog} />
            <AuthenticationRoute exact path={PathConstant.BLOGS} component={ManageBlogs} />
            <AuthenticationRoute exact path={PathConstant.BLOGS_CATEGORIES} component={ManageCategory} />
            <AuthenticationRoute exact path={PathConstant.SHOP_LIST} component={ManageShops} />
            <AuthenticationRoute exact path={PathConstant.SHOP_REGISTRATION} component={ManageRegistration} />
            <AuthenticationRoute exact path={PathConstant.SHOP_LOGS} component={ManageShopsLog} />
            <AuthenticationRoute exact path={PathConstant.PROFILE} component={ManageProfile} />
            {isRoot && <AuthenticationRoute exact path={PathConstant.ACCOUNT} component={ManageAccount} />}
            <AuthenticationRoute exact path={PathConstant.BACKUP_VERSION} component={BackupBlogs} />
            <AuthenticationRoute exact path={PathConstant.OWNERS} component={ManageOwner} />
            <AuthenticationRoute exact path={PathConstant.OWNER_DETAIL} component={OwnerDetail} />
            <Redirect exact from={PathConstant.ROOT} to={PathConstant.INTRODUCTION} />
            <Redirect to={PathConstant.NOT_FOUND} />
          </Switch>
          <BglobalJSC />
        </main>
      </Box>
      <ErrorHandling />
    </>
  );
};

MainLayout.propTypes = {
  classes: PropTypes.object,
};

MainLayout.defaultProps = {
  classes: {},
};

export default memo(MainLayout);

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "stretch",
  },
  main: {
    flexGrow: 1,
    minHeight: "calc(100vh - 60px)",
    backgroundColor: "#fafafb",
  },
});
