import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, OutlinedInput, IconButton, Button } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import clsx from "clsx";

const SearchBar = props => {
  const { classes, onSearch } = props;

  const defaultClasses = useStyles();

  const [valueInput, setValueInput] = useState("");

  const onTyping = e => {
    setValueInput(e.target.value);
  };

  const onClearTyping = () => {
    setValueInput("");
  };

  const onSubmitSearch = () => {
    onSearch(valueInput);
  };

  return (
    <Box className={clsx(defaultClasses.root, classes?.root)}>
      <OutlinedInput
        className={clsx("medium-md-txt", defaultClasses.outlineInput)}
        endAdornment={
          valueInput?.length > 0 && (
            <IconButton
              classes={{ root: defaultClasses.buttonCloseRoot, edgeEnd: defaultClasses.buttonCloseEdge }}
              onClick={onClearTyping}
              edge="end"
            >
              <Close className={defaultClasses.iconClose} />
            </IconButton>
          )
        }
        value={valueInput}
        onChange={onTyping}
        labelWidth={0}
      />
      <Button size="medium" variant="contained" className={defaultClasses.buttonSearch} onClick={onSubmitSearch}>
        Tìm kiếm
      </Button>
    </Box>
  );
};

SearchBar.propTypes = {
  classes: PropTypes.object,
  onSearch: PropTypes.func,
};

SearchBar.defaultProps = {
  onSearch: () => {},
};

export default memo(SearchBar);

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    height: 40,
  },
  outlineInput: {
    width: 352,
    minWidth: 200,
  },
  buttonCloseRoot: {
    width: 20,
    height: 20,
    padding: 0,
    backgroundColor: "#d4d5d8",
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: "#d4d5d8",
    },
  },
  buttonCloseEdge: {
    marginRight: -3,
  },
  iconClose: {
    width: 14,
    height: 14,
    color: "#4b4d53",
  },
  buttonSearch: {
    marginLeft: 8,
    borderRadius: 4,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.white,
    border: "1px solid " + theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.white,
      backgroundColor: theme.palette.primary.main,
    },
  },
}));
