import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getHelpCategory: [],
  createHelpCategory: ["data"],
  updateHelpCategory: ["data"],
  deleteHelpCategory: ["data"],

  helpCategorySuccess: ["data"],
  helpCategoryFailure: ["error"],
  resetHelpCategorySuccess: [],
});

export const HelpCategoryTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  data: [],

  isSuccess: false,
  isFetching: false,
  error: null,
};

/* ------------- Reducers ------------- */
export const request = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
});

export const success = (state = INITIAL_STATE, action) => {
  let data = action.data || {};
  return { ...state, isFetching: false, isSuccess: true, ...data };
};

export const failure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  error: action.error,
});

export const reset = (state = INITIAL_STATE) => ({ ...state, isSuccess: false });

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.GET_HELP_CATEGORY]: request,
  [Types.CREATE_HELP_CATEGORY]: request,
  [Types.UPDATE_HELP_CATEGORY]: request,
  [Types.DELETE_HELP_CATEGORY]: request,

  [Types.HELP_CATEGORY_SUCCESS]: success,
  [Types.HELP_CATEGORY_FAILURE]: failure,
  [Types.RESET_HELP_CATEGORY_SUCCESS]: reset,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
