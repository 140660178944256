import React, { memo } from "react";
import { SvgIcon } from "@material-ui/core";

const DisabledOwnerIcon = ({ className }) => {
  return (
    <SvgIcon className={className} viewBox=" 0 0 16 16">
      <path
        d="M10.12 7.293a2.3 2.3 0 0 0 .213-.96A2.336 2.336 0 0 0 8 4a2.3 2.3 0 0 0-.96.213l3.08 3.08z"
      />
      <path
        d="M8 1.333A6.67 6.67 0 0 0 1.333 8 6.67 6.67 0 0 0 8 14.667 6.67 6.67 0 0 0 14.667 8 6.669 6.669 0 0 0 8 1.333zM2.667 8c0-1.233.42-2.367 1.127-3.267L5.7 6.64c.14 1.04.954 1.86 1.994 1.993L9.16 10.1a6.631 6.631 0 0 0-5.253 1.313A5.304 5.304 0 0 1 2.667 8zM8 13.333a5.294 5.294 0 0 1-3.1-1c.873-.626 1.94-1 3.1-1 1.16 0 2.227.374 3.1 1-.873.627-1.94 1-3.1 1zm4.207-2.066L4.733 3.793A5.268 5.268 0 0 1 8 2.667 5.332 5.332 0 0 1 13.334 8c0 1.233-.42 2.36-1.127 3.267z"
      />
    </SvgIcon>
  );
};

export default memo(DisabledOwnerIcon);
