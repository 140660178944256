import React, { memo } from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import clsx from "clsx";

const HeaderRow = () => {
  const defaultClasses = useStyles();
  let className = clsx("medium-md-txt", defaultClasses.title);

  return (
    <Box className={defaultClasses.root}>
      <Box flexBasis="6%" paddingRight="12px">
        <Typography className={clsx(className, defaultClasses.titleCenter)}>STT</Typography>
      </Box>
      <Box flexBasis="22%" paddingRight="12px">
        <Typography className={className}>Tên tài khoản</Typography>
      </Box>
      <Box flexBasis="22%" paddingRight="12px">
        <Typography className={className}>Email</Typography>
      </Box>
      <Box flexBasis="16%" paddingRight="12px">
        <Typography className={className}>Số điện thoại</Typography>
      </Box>
      <Box flexBasis="10%" paddingRight="12px">
        <Typography className={className}>Giới tính</Typography>
      </Box>
      <Box flexBasis="24%" paddingRight="12px">
        <Typography className={className}>Trạng thái</Typography>
      </Box>
    </Box>
  );
};

export default memo(HeaderRow);

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    width: "inherit",
    height: 46,
    backgroundColor: "rgba(101, 179, 157, 0.1)",
    padding: "12px 16px",
  },
  title: {
    height: 22,
    color: "#3e4045",
    lineHeight: 1.57,
  },
  titleCenter: {
    textAlign: "center",
  },
}));
