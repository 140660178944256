import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Button, Typography, IconButton } from "@material-ui/core";
import clsx from "clsx";
import { ArrowBack } from "@material-ui/icons";

const ShopDetailLayout = props => {
  const { classes, title, children, buttonProps, onBack } = props;
  const defaultClasses = useStyles();

  return (
    <Box className={clsx(defaultClasses.root, classes.root)}>
      <Box className={defaultClasses.headerRoot}>
        <IconButton className={defaultClasses.backButton} onClick={onBack}>
          <ArrowBack />
        </IconButton>
        <Typography variant="subtitle2" className={defaultClasses.title}>
          {title}
        </Typography>
        {buttonProps?.content && (
          <Button
            className={defaultClasses.actionButton}
            size="small"
            color="primary"
            variant="contained"
            onClick={buttonProps?.onClick}
          >
            {buttonProps.content}
          </Button>
        )}
      </Box>
      {children}
    </Box>
  );
};

ShopDetailLayout.propTypes = {
  classes: PropTypes.object,
  buttonProps: PropTypes.object,

  title: PropTypes.string,

  onBack: PropTypes.func.isRequired,
};

ShopDetailLayout.defaultProps = {
  classes: {},
};
export default memo(ShopDetailLayout);

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  headerRoot: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "inherit",
    height: 48,
    borderTop: "1px solid rgb(212, 213, 216, 0.5)",
    borderBottom: "1px solid rgb(212, 213, 216, 0.5)",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(101, 179, 157, 0.1)",
    position: "relative",
  },
  title: {
    position: "absolute",
    height: 24,
    margin: "3px 0",
    lineHeight: 1.5,
  },
  backButton: {
    position: "absolute",
    left: 32,
  },
  actionButton: {
    position: "absolute",
    right: 32,
  },
}));
