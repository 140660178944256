import React from "react";
import { CardMedia, makeStyles } from "@material-ui/core";

const BackupBlogs = () => {
  const classes = useStyles();
  return <CardMedia component="iframe" className={classes.root} src={IFRAME_LINK} />;
};

export default BackupBlogs;

const IFRAME_LINK = "https://root-v01.gboss.io/";
const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: "calc(100% - 40px)",
    border: 0,
  },
}));
