import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Menu, MenuItem, IconButton } from "@material-ui/core";
import { ArrowDropUp, ArrowDropDown, Check } from "@material-ui/icons";
import clsx from "clsx";

const SelectBar = props => {
  const { onChange, classes, indexItem, arrayItem } = props;

  const defaultClasses = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(indexItem);

  const onClickListItem = event => {
    setAnchorEl(event.currentTarget);
  };

  const onMenuItemClick = (_, index) => {
    if (index !== selectedIndex) {
      setSelectedIndex(index);
      setAnchorEl(null);
      onChange(index);
    }
  };

  useEffect(() => {
    if (indexItem !== selectedIndex) setSelectedIndex(indexItem);
  }, [indexItem]);

  const onClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box className={clsx(defaultClasses.boxRoot, classes?.root)}>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={onClickListItem}
        classes={{
          root: clsx(defaultClasses.btnRoot, classes?.rootButton),
          label: clsx("medium-md-txt", classes?.label, defaultClasses.btnLabel),
        }}
      >
        {arrayItem[selectedIndex].icon}
        {arrayItem[selectedIndex].name}
        {anchorEl ? <ArrowDropUp className={defaultClasses.icon} /> : <ArrowDropDown className={defaultClasses.icon} />}
      </IconButton>
      <Menu
        id="lock-menu"
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onClose}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        classes={{ list: defaultClasses.menuList, paper: clsx(defaultClasses.menuPaper, classes?.paper) }}
      >
        {arrayItem.map((option, index) => (
          <MenuItem
            onClick={event => onMenuItemClick(event, index)}
            className={clsx("medium-md-txt", defaultClasses.menuItemRoot, classes?.item)}
            key={index}
          >
            {option.icon}
            {option.name}
            {selectedIndex === index && <Check className={clsx(defaultClasses.icon, defaultClasses.iconCheck)} />}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

SelectBar.propTypes = {
  onChange: PropTypes.func,
  classes: PropTypes.object,
  indexItem: PropTypes.number,
  arrayItem: PropTypes.array,
};

SelectBar.defaultProps = {
  onChange: () => {},
};

export default memo(SelectBar);

const useStyles = makeStyles(theme => ({
  boxRoot: {
    height: "100%",
    width: 210,
    backgroundColor: theme.palette.black,
  },
  btnRoot: {
    width: "100%",
    height: "100%",
    color: theme.palette.white,
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 0,
    paddingLeft: 4,
    paddingRight: 45,
  },
  btnLabel: {
    justifyContent: "unset",
    marginLeft: 8,
  },
  menuPaper: {
    marginTop: "2px",
    borderRadius: 0,
    boxShadow: `0 1px 6px 0 rgba(0, 0, 0, 0.1)`,
    maxHeight: " calc(100% - 30px)",
    overflow: "auto",
  },
  menuList: {
    paddingTop: `0px`,
    paddingBottom: `0px`,
    paddingRight: `0px !important`,
  },
  menuItemRoot: {
    display: "flex",
    height: 36,
    width: 210,
    alignItems: "center",
    padding: "8px 12px",
    color: theme.palette.white,
    backgroundColor: theme.palette.black,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  icon: {
    right: 5,
    [theme.breakpoints.down("sm")]: {
      right: 8,
    },
    position: "absolute",
    color: theme.palette.white,
  },
  iconCheck: {
    fontSize: 20,
  },
}));
