import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getListPackage: ["data"],
  getOwnerInfo: ["data"],
  getPaymentHistory: ["data"],
  getPaymentDetails: ["data"],
  updatePaymentDetail: ["data"],
  updatePackageFeature: ["data"],
  changePackage: ["data"],
  disableOwner: ["data"],
  updatePaymentAsPaid: ["data"],

  ownerDetailsSuccess: ["data"],
  ownerDetailsFailure: ["error"],
  ownerDetailsSet: ["data"],
  resetOwnerDetails: [],
});

export const OwnerDetailsTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  listPackage: [],
  ownerDetails: {},
  paymentHistory: [], // Ex: [{{summary paymentDetail object}}]
  paymentHistoryInput: {},
  paymentDetail: {},

  isUpdateSuccess: false,
  isHistorySuccess: false,
  isSuccess: false,
  isSuccessFeatures: false,
  isFetching: false,
  error: null,
};

/* ------------- Reducers ------------- */
export const request = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
});

export const success = (state = INITIAL_STATE, action) => {
  let data = action.data || {};
  return { ...state, isFetching: false, ...data };
};

export const setAttribute = (state = INITIAL_STATE, action) => {
  let data = action.data || {};
  return { ...state, isFetching: false, ...data };
};

export const failure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  error: action.error,
});

export const reset = () => INITIAL_STATE;

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.GET_LIST_PACKAGE]: request,
  [Types.GET_OWNER_INFO]: request,
  [Types.GET_PAYMENT_HISTORY]: request,
  [Types.GET_PAYMENT_DETAILS]: request,
  [Types.UPDATE_PAYMENT_DETAIL]: request,
  [Types.UPDATE_PACKAGE_FEATURE]: request,
  [Types.CHANGE_PACKAGE]: request,
  [Types.DISABLE_OWNER]: request,
  [Types.UPDATE_PAYMENT_AS_PAID]: request,

  [Types.OWNER_DETAILS_SUCCESS]: success,
  [Types.OWNER_DETAILS_FAILURE]: failure,
  [Types.OWNER_DETAILS_SET]: setAttribute,
  [Types.RESET_OWNER_DETAILS]: reset,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
