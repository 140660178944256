import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { makeStyles, Paper, Box, IconButton, Tooltip, Typography } from "@material-ui/core";
import clsx from "clsx";
import { convertTimestampToDateRange, getShopStatusColor } from "utils";
import { AppConstant, LangConstant } from "const";
import { ConfirmDialog, PackageItem, PaymentStatus } from "components";
import { DisableOwnerIcon, MarkAsPaidIcon } from "icons";
import ManageOwnerAction from "redux/manage-owner.redux";

const OwnerList = ({ data }) => {
  const classes = useStyles({ statusColor: getShopStatusColor(data?.state) });
  const dispatch = useDispatch();

  let hasPaid = [AppConstant.PAYMENTS_STATUS.paid, AppConstant.PAYMENTS_STATUS.free].includes(data?.paymentStatus);
  let isInactiveOwner = data?.state === AppConstant.SHOP_STATUS.inactive;

  const [isPaid, setIsPaid] = useState(false);
  const [isDisableOwner, setIsDisableOwner] = useState(false);

  const onOpenDisableOwner = event => {
    event.preventDefault();
    setIsDisableOwner(true);
  };

  const onOpenPayment = event => {
    event.preventDefault();
    setIsPaid(true);
  };

  const onMarkAsPaid = () => {
    dispatch(
      ManageOwnerAction.updatePaymentStatus({ uid: data?.paymentUid, status: AppConstant.PAYMENTS_STATUS.paid }),
      setIsPaid(false),
    );
  };

  const onDisableOwner = () => {
    dispatch(ManageOwnerAction.updateOwnerStatus({ userId: data?.userId, state: AppConstant.SHOP_STATUS.inactive }));
    setIsDisableOwner(false);
  };

  return (
    <Box display="flex">
      <Box className={classes.ownerNum}>
        <Typography className={clsx("medium-md-txt", classes.boldTextColor, classes.number)}>
          {data?.ownerPosition}
        </Typography>
      </Box>
      <Paper className={classes.paperRoot}>
        <Box className={classes.mainContent}>
          <Box flexBasis="15%" className={classes.ownerNameBox}>
            <Typography className={clsx("semiBold-lg-txt ellipsis", classes.boldTextColor)}>
              {data?.ownerName}
            </Typography>
            <Typography className={clsx("medium-md-txt ellipsis", classes.normalTextColor)}>{data?.email}</Typography>
            <Typography className={clsx("medium-md-txt ellipsis", classes.normalTextColor)}>
              {data?.phoneNumber}
            </Typography>
          </Box>
          <Box flexBasis="15%" className={classes.shopNumBox}>
            <Typography className={clsx("semiBold-lg-txt ellipsis", classes.boldTextColor)}>
              {data?.shopAmount} shop
            </Typography>
            <Typography className={clsx("medium-md-txt ellipsis", classes.shopStatus)}>
              {LangConstant.SHOP_STATUS[data?.state]}
            </Typography>
          </Box>
          <Box flexBasis="30%">
            {data &&
              data.shops?.map((shop, key) => (
                <Typography
                  key={key}
                  className={clsx("medium-md-txt ellipsis", classes.normalTextColor, classes.shopName)}
                >
                  {key < 3 && shop.name}
                </Typography>
              ))}
          </Box>
          <Box flexBasis="20%">
            <PackageItem packageType={data?.plan || AppConstant.PACKAGE_TYPE.free} />
            <Typography className={clsx("medium-md-txt ellipsis", classes.normalTextColor)}>
              {convertTimestampToDateRange(data?.from, data?.to)}
            </Typography>
          </Box>
          <Box flexBasis="10%">
            <PaymentStatus paymentStatus={data?.paymentStatus} />
          </Box>
          <Box flexBasis="10%" className={classes.buttonGroup}>
            <Tooltip title=" Đánh dấu đã thanh toán" classes={{ tooltip: clsx("medium-sm-txt", classes.tooltip) }}>
              <IconButton
                component="div"
                onClick={onOpenPayment}
                disabled={hasPaid}
                classes={{ root: classes.iconButton, disabled: classes.disabledButton }}
              >
                <MarkAsPaidIcon className={classes.iconAction} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Vô hiệu tài khoản" classes={{ tooltip: clsx("medium-sm-txt", classes.tooltip) }}>
              <IconButton
                component="div"
                onClick={onOpenDisableOwner}
                disabled={isInactiveOwner}
                classes={{ root: classes.iconButton, disabled: classes.disabledButton }}
              >
                <DisableOwnerIcon className={classes.iconAction} />
              </IconButton>
            </Tooltip>
            {isPaid && (
              <ConfirmDialog
                isShow={true}
                onClose={() => setIsPaid(false)}
                onSubmit={onMarkAsPaid}
                title="Đánh dấu đã thanh toán"
                message="Bạn có chắc chắn muốn đánh dấu tài khoản này đã thanh toán hay không?"
              />
            )}
            {isDisableOwner && (
              <ConfirmDialog
                isShow={true}
                onClose={() => setIsDisableOwner(false)}
                onSubmit={onDisableOwner}
                title="Vô hiệu tài khoản"
                message="Bạn có chắc chắn muốn vô hiệu hoá các shop của tài khoản này không?"
              />
            )}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

OwnerList.propTypes = {
  data: PropTypes.object,
};

OwnerList.defaultProps = {
  data: {},
};

export default memo(OwnerList);

const useStyles = makeStyles(theme => ({
  boldTextColor: {
    color: "#3e4045",
  },
  normalTextColor: {
    color: "#6c7078",
  },

  ownerNum: {
    width: 24,
    height: 24,
    marginRight: 17,
    backgroundColor: theme.palette.white,
  },
  number: {
    textAlign: "center",
    lineHeight: 1.57,
  },

  paperRoot: {
    width: "100%",
    height: 110,
    maxHeight: 110,
    display: "flex",
    borderRadius: 4,
    boxShadow: "0 1px 6px 0 rgba(0, 0, 0, 0.16)",
    "&:hover": {
      backgroundColor: "rgba(79, 139, 255, 0.1)",
    },
  },
  mainContent: {
    display: "flex",
    margin: theme.spacing(2, 1),
    flexGrow: 1,
    "& > div:not(:last-child)": {
      margin: "0 10px",
    },
  },

  ownerNameBox: {
    "& > *:not(:last-child)": {
      paddingBottom: 4,
    },
  },

  shopNumBox: {
    textAlign: "center",
  },
  shopStatus: {
    color: props => props.statusColor,
  },

  shopName: {
    lineHeight: 1.57,
    textAlign: "left",
  },

  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: 8,
  },
  tooltip: {
    height: 20,
    marginLeft: 50,
    marginTop: 2,
    padding: "0 5px",
    backgroundColor: "#7f838c",
    borderRadius: 0,
    lineHeight: 1.67,
    textAlign: "center",
  },
  iconButton: {
    width: 24,
    height: 24,
    padding: 0,
    borderRadius: 0,
    border: "1px solid " + theme.palette.grey[200],
    "&:hover": {
      border: "1px solid" + theme.palette.primary.main,
    },
  },
  iconAction: {
    width: 16,
    height: 16,
    color: theme.palette.grey[500],
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  disabledButton: {
    opacity: 0.6,
  },
}));
