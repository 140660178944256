import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  uploadImage: ["data"],

  uploadSuccess: ["data"],
  uploadFailure: ["error"],
  uploadReset: [],
});

export const UploadTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  dataImage: null,
  isSuccess: false,
  isFetching: false,
  error: null,
};

/* ------------- Reducers ------------- */
export const request = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
});

export const success = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  isSuccess: true,
  ...action.data,
});

export const failure = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: false,
});

export const reset = () => INITIAL_STATE;

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.UPLOAD_IMAGE]: request,

  [Types.UPLOAD_SUCCESS]: success,
  [Types.UPLOAD_FAILURE]: failure,
  [Types.UPLOAD_RESET]: reset,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
