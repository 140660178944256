import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Button, IconButton, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import { AppConstant } from "const";
import { Alert } from "components";
import HeaderBar from "pages/HelpCenter/CreateQuestion/HeaderBar";
import InfoQuestion from "pages/HelpCenter/CreateQuestion/InfoQuestion";
import QuestionAction from "redux/question.redux";

const EditQuestion = props => {
  const { data, onClose } = props;
  const defaultClasses = useStyles();
  const dispatch = useDispatch();

  const arrayCategoryData = useSelector(props => props.helpCategoryRedux.data);
  const isSuccessUpdate = useSelector(props => props.helpQuestionRedux.isSuccess);

  const [formData, setFormData] = useState(data);
  const [alertForm, setAlertForm] = useState(DEFAULT_FORM_ALERT);
  const [statusMessage, setStatusMessage] = useState();

  const onCheckDisplay = e => {
    if (e.target.checked) {
      setFormData({ ...formData, flagShowToHomePage: AppConstant.STATUS_DISPLAY_HOME_PAGE.display });
    } else {
      setFormData({ ...formData, flagShowToHomePage: AppConstant.STATUS_DISPLAY_HOME_PAGE.hidden });
    }
  };

  const onChangeCategory = value => {
    let valueCategory = value;
    let arrayCategory = [];
    if (valueCategory !== OPT_ALL_ITEMS_ID) {
      arrayCategory.push(valueCategory);
    } else {
      arrayCategoryData.forEach(item => arrayCategory.push(item.id));
    }
    setFormData({ ...formData, helpCategoryIdList: arrayCategory });
  };

  const onChangeFormData = newData => {
    setFormData(newData);
  };

  const onSubmit = status => {
    let newFormData = { ...formData };
    if (formData.helpCategoryIdList.length === 0) {
      newFormData.helpCategoryIdList = arrayCategoryData.map(item => item.id);
    }
    newFormData.published = status;
    setStatusMessage(status);
    dispatch(QuestionAction.updateHelpQuestion(newFormData));
  };

  const onCloseAlert = () => {
    setAlertForm(DEFAULT_FORM_ALERT);
  };

  useEffect(() => {
    if (isSuccessUpdate) {
      dispatch(QuestionAction.resetHelpQuestionSuccess());
      if (statusMessage === AppConstant.STATUS_OPTION.publish) {
        setAlertForm(OBJ_ALERT_MESSAGE.display);
      } else if (statusMessage === AppConstant.STATUS_OPTION.draft) {
        setAlertForm(OBJ_ALERT_MESSAGE.hidden);
      }
    }
  }, [isSuccessUpdate]);

  return (
    <>
      <Box className={defaultClasses.root}>
        <Box className={defaultClasses.headerRoot}>
          <Box className={defaultClasses.middleLayout}>
            <Button onClick={onClose} className={defaultClasses.buttonManage}>
              Quản lý câu hỏi
            </Button>
            <Typography variant="subtitle2" className={defaultClasses.title}>
              Chỉnh sửa câu hỏi
            </Typography>
          </Box>
          <Button
            onClick={() => onSubmit(AppConstant.STATUS_OPTION.publish)}
            size="small"
            color="primary"
            variant="contained"
          >
            Đăng câu hỏi
          </Button>
        </Box>
        <HeaderBar
          data={{
            category: formData.helpCategoryIdList.length === 0 ? OPT_ALL_ITEMS_ID : formData.helpCategoryIdList[0],
            display: formData.flagShowToHomePage,
            state: formData.published,
          }}
          onSelectCategory={onChangeCategory}
          onCheckDisplay={onCheckDisplay}
          onSaveDraft={() => onSubmit(AppConstant.STATUS_OPTION.draft)}
        />
      </Box>
      <InfoQuestion data={formData} onChangeData={onChangeFormData} />
      {alertForm.isShowing && (
        <Alert
          isShow={true}
          onClose={onCloseAlert}
          message={alertForm.message}
          type={alertForm.type}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          action={
            <IconButton onClick={onCloseAlert} className={defaultClasses.button}>
              <Close className={defaultClasses.iconClose} />
            </IconButton>
          }
          classes={{
            root: clsx(
              defaultClasses.rootAlert,
              alertForm.type === OBJ_ALERT_MESSAGE.hidden.type && defaultClasses.colorWarning,
            ),
            action: defaultClasses.action,
            icon: defaultClasses.icon,
            message: clsx("regular-sm-txt", defaultClasses.message),
          }}
        />
      )}
    </>
  );
};

const OPT_ALL_ITEMS_ID = "all";

const DEFAULT_FORM_ALERT = {
  isShowing: false,
  message: "",
  type: "",
};

const OBJ_ALERT_MESSAGE = {
  display: {
    isShowing: true,
    message: "Tính năng đã được hiển thị/cập nhật",
    type: "success",
  },
  hidden: {
    isShowing: true,
    message: "Tính năng chưa được hiển thị/cập nhật",
    type: "warning",
  },
};

EditQuestion.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    thumbnail: PropTypes.shape({
      vi: PropTypes.string,
      en: PropTypes.string,
    }),
    helpCategoryIdList: PropTypes.array,
    title: PropTypes.shape({
      vi: PropTypes.string,
      en: PropTypes.string,
    }),
    content: PropTypes.shape({
      vi: PropTypes.string,
      en: PropTypes.string,
    }),
    description: PropTypes.shape({
      vi: PropTypes.string,
      en: PropTypes.string,
    }),
    created: PropTypes.number,
    published: PropTypes.number,
    flagShowToHomePage: PropTypes.number,
  }),
  onClose: PropTypes.func,
};

EditQuestion.defaultProps = {};

export default memo(EditQuestion);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  headerRoot: {
    display: "flex",
    justifyContent: "space-between",
    width: "inherit",
    height: 48,
    padding: "8px 32px",
    borderTop: "1px solid rgb(212, 213, 216, 0.5)",
    borderBottom: "1px solid rgb(212, 213, 216, 0.5)",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(101, 179, 157, 0.1)",
  },
  title: {
    height: 24,
    margin: "3px 0",
    lineHeight: 1.5,
  },
  middleLayout: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
  },
  buttonManage: {
    height: 24,
    marginRight: 24,
    padding: 0,
    color: theme.palette.grey[500],
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: 16,
    "&:hover": {
      color: theme.palette.grey[700],
      backgroundColor: "unset",
    },
  },
  displayNumber: {
    height: 22,
    margin: "4px 16px 4px 0px",
    lineHeight: 1.57,
    color: theme.palette.grey[500],
  },
  rootAlert: {
    width: 400,
    height: 30,
    padding: "5px 8px",
    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.16)",
    backgroundColor: "rgba(101, 179, 157, 0.5)",
    color: "#3e4045",
  },
  colorWarning: {
    backgroundColor: "rgba(254, 186, 64, 0.5)",
  },
  button: {
    padding: 0,
  },
  iconClose: {
    width: 20,
    height: 20,
  },
  icon: {
    display: "none",
  },
  action: {
    marginRight: 0,
    paddingLeft: 8,
    display: "unset",
  },
  message: {
    width: "100%",
    lineHeight: 1.67,
    padding: 0,
    textAlign: "center",
  },
}));
