import React, { memo, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles, Paper, Box, IconButton, Tooltip, Typography } from "@material-ui/core";
import { EditOutlined, Replay } from "@material-ui/icons";
import clsx from "clsx";
import StringFormat from "string-format";
import { StatusItem } from "components";
import { convertTimestampToDate } from "utils";
import ConfirmDialog from "components/dialog/ConfirmDialog";
import BlogsCategoryAction from "redux/blogs-category.redux";
import { CreateAndModifyCategoryDialog } from "./index";

const CategoryInList = props => {
  const { data, position, disableOption } = props;
  const defaultClasses = useStyles();
  const dispatch = useDispatch();

  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);

  const onEditItem = () => {
    setIsOpenEditDialog(true);
  };

  const onDeleteItem = () => {
    setIsOpenDeleteDialog(true);
  };

  const onCloseDeleteDialog = () => {
    setIsOpenDeleteDialog(false);
  };

  const onSubmitDeleteDialog = () => {
    dispatch(BlogsCategoryAction.deleteBlogsCategory(data.id));
    setIsOpenDeleteDialog(false);
  };

  return (
    <Box display="flex">
      <Box width="24px" height="24px" marginRight="17px">
        <Typography className={clsx("medium-md-txt", defaultClasses.number)}>{position}</Typography>
      </Box>
      <Paper className={defaultClasses.paperRoot}>
        <Box display="flex" width="100%">
          <Box flexBasis="25%" marginRight="20px">
            <Typography className={clsx("semiBold-lg-txt ellipsis", defaultClasses.nameViCategoryTypography)}>
              {data.title.vi}
            </Typography>
            <Typography className={clsx("medium-md-txt ellipsis", defaultClasses.greyTextTypography)}>
              {data.title.en}
            </Typography>
          </Box>
          <Box flexBasis="40%" marginRight="20px">
            <Typography
              className={clsx(
                "medium-md-txt ellipsis",
                defaultClasses.contentCategory,
                defaultClasses.hiddenTextTwoLine,
              )}
            >
              {data.description.vi}
            </Typography>
          </Box>
          <Box flexBasis="15%" marginRight="20px">
            <Typography
              className={clsx(
                "medium-md-txt ellipsis",
                defaultClasses.greyTextTypography,
                defaultClasses.hiddenTextTwoLine,
              )}
            >
              Ngày tạo: {convertTimestampToDate(data.created)}
            </Typography>
          </Box>
          <Box flexBasis="10%" marginRight="20px">
            <StatusItem status={data.published} />
          </Box>
          <Box flexBasis="10%" marginRight="20px">
            <Typography className={clsx("medium-md-txt ellipsis", defaultClasses.greyTextTypography)}>
              {data.amount} bài viết
            </Typography>
          </Box>
        </Box>
        <Box display="flex" minWidth="48px">
          <Tooltip title="Sửa" classes={{ tooltip: clsx("medium-sm-txt", defaultClasses.tooltip) }}>
            <IconButton
              onClick={onEditItem}
              disabled={disableOption.isDisableEdit}
              classes={{ root: defaultClasses.buttonIconEdit, disabled: defaultClasses.disabledButton }}
            >
              <EditOutlined className={defaultClasses.iconEdit} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Thu hồi" classes={{ tooltip: clsx("medium-sm-txt", defaultClasses.tooltip) }}>
            <IconButton
              onClick={onDeleteItem}
              disabled={disableOption.isDisableDelete}
              classes={{ root: defaultClasses.buttonIconDelete, disabled: defaultClasses.disabledButton }}
            >
              <Replay className={defaultClasses.iconDelete} />
            </IconButton>
          </Tooltip>
        </Box>

        <ConfirmDialog
          isShow={isOpenDeleteDialog}
          title={StringFormat(FM_TITLE, data.title.vi)}
          message={StringFormat(FM_DESCRIPTION, data.description.vi)}
          onClose={onCloseDeleteDialog}
          onSubmit={onSubmitDeleteDialog}
        />

        <CreateAndModifyCategoryDialog
          isShow={isOpenEditDialog}
          title="Chỉnh sửa danh mục"
          data={data}
          onClose={() => setIsOpenEditDialog(false)}
        />
      </Paper>
    </Box>
  );
};

CategoryInList.propTypes = {
  data: PropTypes.shape({
    created: PropTypes.number,
    published: PropTypes.number,
    amount: PropTypes.number,
  }),
  onEditItem: PropTypes.func,
  onDeleteItem: PropTypes.func,
  disableOption: PropTypes.shape({
    isDisableEdit: PropTypes.bool,
    isDisableDelete: PropTypes.bool,
  }),
};

CategoryInList.defaultProps = {
  onEditItem: () => {},
  onDeleteItem: () => {},
  disableOption: {
    isDisableEdit: false,
    isDisableDelete: false,
  },
  amount: 0,
};

const FM_TITLE = `Thu hồi danh mục "{0}" ?`;
const FM_DESCRIPTION = `Bạn có chắc muốn thu hồi danh mục "{0}" trên website không?`;

export default memo(CategoryInList);

const useStyles = makeStyles(theme => ({
  paperRoot: {
    width: "100%",
    height: 78,
    maxHeight: 78,
    display: "flex",
    padding: "16px 19.5px",
    borderRadius: 4,
    boxShadow: "0 1px 6px 0 rgba(0, 0, 0, 0.16)",
  },
  number: {
    textAlign: "center",
    color: "#3e4045",
    lineHeight: 1.57,
  },
  nameViCategoryTypography: {
    color: "#3e4045",
    lineHeight: 1.5,
  },
  greyTextTypography: {
    lineHeight: 1.57,
    color: theme.palette.grey[500],
  },
  contentCategory: {
    color: "#3e4045",
    lineHeight: 1.57,
  },
  hiddenTextTwoLine: {
    WebkitLineClamp: 2,
  },
  tooltip: {
    height: 20,
    marginLeft: 50,
    marginTop: 2,
    padding: "0 5px",
    backgroundColor: "#7f838c",
    lineHeight: 1.67,
    textAlign: "center",
    borderRadius: 0,
  },
  buttonIconEdit: {
    width: 24,
    height: 24,
    padding: 0,
    borderRadius: 0,
    border: "1px solid " + theme.palette.grey[200],
    "&:hover": {
      border: "1px solid #feba40",
    },
  },
  iconEdit: {
    width: 19,
    height: 19,
    color: theme.palette.grey[500],
    "&:hover": {
      color: "#feba40",
    },
  },
  buttonIconDelete: {
    width: 24,
    height: 24,
    padding: 0,
    borderRadius: 0,
    border: "1px solid " + theme.palette.grey[200],
    "&:hover": {
      border: "1px solid " + theme.palette.primary.main,
    },
  },
  iconDelete: {
    width: 19,
    height: 19,
    color: theme.palette.grey[500],
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  disabledButton: {
    opacity: 0.4,
  },
}));
