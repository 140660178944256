import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles, Checkbox } from "@material-ui/core";

const CheckBoxItem = props => {
  const { checked, onChecked, ...otherProps } = props;
  const defaultClasses = useStyles();

  return (
    <Checkbox
      color="primary"
      checked={checked}
      onChange={onChecked}
      className={defaultClasses.checkBoxStatus}
      {...otherProps}
    />
  );
};

CheckBoxItem.propTypes = {
  checked: PropTypes.bool,
  onChecked: PropTypes.func,
};
CheckBoxItem.defaultProps = {
  checked: false,
  onChecked: () => {},
};

export default memo(CheckBoxItem);

const useStyles = makeStyles(theme => ({
  root: {
    padding: "0 0 0",
    width: 16,
    height: 16,
    marginLeft: 10,
  },
}));
