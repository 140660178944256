import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Typography } from "@material-ui/core";
import ODPackageFunction from "./ODPackageFunction";
import ODPackageService from "./ODPackageService";

const PackageDetail = ({ packageDetail }) => {
  const classes = useStyles();

  return (
    <Box className={classes.packageDetailBox}>
      <Typography className={classes.textHeader}>Thông tin gói dịch vụ</Typography>
      <ODPackageService data={packageDetail} />
      <ODPackageFunction data={packageDetail} />
    </Box>
  );
};

PackageDetail.propTypes = {
  packageDetail: PropTypes.object.isRequired,
};

PackageDetail.defaultProps = {
  packageDetail: {},
};

export default memo(PackageDetail);

const useStyles = makeStyles(() => ({
  packageDetailBox: {
    "& > *:first-child": {
      marginBottom: 33,
    },
    "& > *:last-child": {
      marginTop: 12,
      marginBottom: 36,
    },
  },
  textHeader: {
    fontSize: 20,
    fontWeight: 600,
    color: "#3e4045",
  },
}));
