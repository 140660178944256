import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { SelectedItem } from "components";
import { LangConstant } from "const";

const HeaderFilter = props => {
  const { data, onChangeSelected } = props;
  const defaultClasses = useStyles();

  const arrayCategoryData = useSelector(props => props.helpCategoryRedux.data);

  const [arrayCategory, setArrayCategory] = useState([]);

  useEffect(() => {
    setArrayCategory(arrayCategoryData.map(item => ({ value: item.id, name: item.title?.vi })));
  }, [arrayCategoryData]);

  return (
    <Box className={defaultClasses.root}>
      <Box className={clsx(defaultClasses.borderSelectBar, defaultClasses.marginRight)}>
        <Typography className={clsx("bold-sm-txt", defaultClasses.titleFilter)}>Danh mục</Typography>
        <SelectedItem
          value={data.helpCategoryId}
          onChangeInput={e => onChangeSelected(e, NAME_SELECTED_ITEM.helpCategoryId)}
          data={arrayCategory}
        />
      </Box>
      <Box className={clsx(defaultClasses.borderSelectBar, defaultClasses.marginMiddle)}>
        <Typography className={clsx("bold-sm-txt", defaultClasses.titleFilter)}>Trạng thái</Typography>
        <SelectedItem
          value={data.published}
          onChangeInput={e => onChangeSelected(e, NAME_SELECTED_ITEM.published)}
          data={LangConstant.ARRAY_STATUS_TWO_OPTION}
        />
      </Box>
      <Box className={clsx(defaultClasses.borderSelectBar, defaultClasses.marginLeft)}>
        <Typography className={clsx("bold-sm-txt", defaultClasses.titleFilter)}>Hiển thị trên Homepage</Typography>
        <SelectedItem
          value={data.flagShowToHomePage}
          onChangeInput={e => onChangeSelected(e, NAME_SELECTED_ITEM.flagShowToHomePage)}
          data={Object.values(LangConstant.OBJ_DISPLAY_HOME_PAGE)}
        />
      </Box>
    </Box>
  );
};

const NAME_SELECTED_ITEM = {
  helpCategoryId: "helpCategoryId",
  published: "published",
  flagShowToHomePage: "flagShowToHomePage",
};

HeaderFilter.propTypes = {
  data: PropTypes.shape({
    helpCategoryId: PropTypes.any,
    published: PropTypes.any,
    display: PropTypes.any,
  }),
  onChangeSelected: PropTypes.func,
};

HeaderFilter.defaultProps = {
  onChangeSelected: () => {},
};

export default memo(HeaderFilter);

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexGrow: 1,
    margin: "11px 0 5px",
  },
  borderSelectBar: {
    padding: "16px 10px",
    flex: 1,
    borderRadius: 2,
    backgroundColor: "#fafafb",
  },
  titleFilter: {
    marginBottom: 8,
    lineHeight: 1.67,
    color: "#3e4045",
  },
  marginRight: {
    marginRight: 16,
  },
  marginMiddle: {
    margin: "0 8px",
  },
  marginLeft: {
    marginLeft: 16,
  },
}));
