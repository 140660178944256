import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getHomeFeature: [],
  createHomeFeature: ["data"],
  updateHomeFeature: ["data"],
  deleteHomeFeature: ["data"],

  homeFeatureSuccess: ["data"],
  homeFeatureFailure: ["error"],
  resetFeatureSuccess: [],
});

export const FeatureTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  data: [],

  isSuccess: false,
  isFetching: false,
  error: null,
};

/* ------------- Reducers ------------- */
export const request = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
  isSuccess: false,
});

export const success = (state = INITIAL_STATE, action) => {
  let data = action.data || {};
  return { ...state, isFetching: false, ...data };
};

export const failure = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: false,
});

export const reset = (state = INITIAL_STATE) => ({ ...state, isSuccess: false });

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.GET_HOME_FEATURE]: request,
  [Types.CREATE_HOME_FEATURE]: request,
  [Types.UPDATE_HOME_FEATURE]: request,
  [Types.DELETE_HOME_FEATURE]: request,

  [Types.HOME_FEATURE_SUCCESS]: success,
  [Types.HOME_FEATURE_FAILURE]: failure,
  [Types.RESET_FEATURE_SUCCESS]: reset,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
