import React, { useState, useEffect } from "react";
import { makeStyles, Box } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import ManageLayout from "layouts/ManageLayout";
import { CategoryItemList, ACTION_OPTION, CategoryDialog } from "./components";
import { AppConstant } from "const";
import QuestionCategoryAction from "redux/question-category.redux";

const ManageCategories = () => {
  const defaultClasses = useStyles();
  const dispatch = useDispatch();

  const arrayCategoryData = useSelector(props => props.helpCategoryRedux.data);
  const isSuccess = useSelector(props => props.helpCategoryRedux.isSuccess);

  const [categoryData, setCategoryData] = useState([]);
  const [formData, setFormData] = useState(DEFAULT_FORM);

  const onOpenCategory = formData => {
    setFormData(formData);
  };

  const onClose = () => {
    setFormData(DEFAULT_FORM);
  };

  useEffect(() => {
    dispatch(QuestionCategoryAction.getHelpCategory());
  }, []);

  useEffect(() => {
    setCategoryData(arrayCategoryData);
  }, [arrayCategoryData]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(QuestionCategoryAction.resetHelpCategorySuccess());
      onClose();
    }
  }, [isSuccess]);

  return (
    <>
      <ManageLayout
        title="Quản lý danh mục"
        buttonProps={{ onClick: () => onOpenCategory({ ...DEFAULT_FORM, state: ACTION_OPTION.add }) }}
      />
      <Box className={defaultClasses.root}>
        {categoryData.map((data, index) => (
          <Box key={index} marginBottom="16px">
            <CategoryItemList
              data={data}
              onEditItem={onOpenCategory}
              disabledRemove={data.published === AppConstant.STATUS_OPTION.draft}
            />
          </Box>
        ))}
      </Box>
      {[ACTION_OPTION.add, ACTION_OPTION.edit].includes(formData.state) && (
        <CategoryDialog isShow={true} onClose={onClose} title={FM_TITLE[formData.state]} data={formData.data} />
      )}
    </>
  );
};

const DEFAULT_FORM = {
  state: ACTION_OPTION.noAction,
  data: {
    id: null,
    thumbnail: {
      vi: "",
      en: "",
    },
    title: {
      vi: "",
      en: "",
    },
    description: {
      vi: "",
      en: "",
    },
    created: null,
    published: AppConstant.STATUS_OPTION.draft,
  },
};

const FM_TITLE = {
  [ACTION_OPTION.add]: "Thêm mới danh mục",
  [ACTION_OPTION.edit]: "Chỉnh sửa danh mục",
};

export default ManageCategories;

const useStyles = makeStyles(() => ({
  root: {
    height: "calc(100vh - 170px)",
    overflow: "auto",
    padding: "24px 32px 8px",
  },
}));
