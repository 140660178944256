import React, { memo } from "react";
import { makeStyles, List, Box } from "@material-ui/core";
import { ListAlt, BackupOutlined, Settings, StoreOutlined, HomeOutlined, StarOutline } from "@material-ui/icons";
import { PathConstant } from "const";
import { HelpCenterIcon } from "icons";
import SidebarWithChildren from "./SidebarWithChildren";
import SidebarItem from "./SidebarItem";

const Sidebar = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <List className={classes.list}>
        {SIDEBAR_DATA.map((item, index) =>
          Boolean(item.children) ? (
            <SidebarWithChildren data={item} key={index} />
          ) : (
            <SidebarItem item={item} key={index} />
          ),
        )}
      </List>
    </Box>
  );
};

Sidebar.propTypes = {};

export default memo(Sidebar);

const DRAWER_WIDTH_OPEN = 210;

const SIDEBAR_DATA = [
  {
    text: "Trang chủ",
    icon: <HomeOutlined color="inherit" />,
    children: [
      {
        text: "Giới thiệu",
        path: PathConstant.INTRODUCTION,
      },
      {
        text: "Tính năng",
        path: PathConstant.FEATURE,
      },
      {
        text: "Đánh giá sản phẩm",
        path: PathConstant.EVALUATE,
      },
    ],
  },
  {
    text: "Blogs",
    icon: <ListAlt color="inherit" />,
    children: [
      {
        text: "Đăng bài",
        path: PathConstant.BLOGS_ADD,
      },
      {
        text: "Quản lý bài đăng",
        path: PathConstant.BLOGS,
      },
      {
        text: "Quản lý danh mục",
        path: PathConstant.BLOGS_CATEGORIES,
      },
    ],
  },
  {
    text: "Trung tâm trợ giúp",
    icon: <HelpCenterIcon />,
    children: [
      {
        text: "Tạo câu hỏi",
        path: PathConstant.CREATE_QUESTION,
      },
      {
        text: "Quản lý câu hỏi",
        path: PathConstant.MANAGE_QUESTION,
      },
      {
        text: "Quản lý danh mục",
        path: PathConstant.MANAGE_CATEGORIES,
      },
    ],
  },
  {
    text: "Shops",
    icon: <StoreOutlined color="inherit" />,
    children: [
      {
        text: "Quản lý shops",
        path: PathConstant.SHOP_LIST,
      },
      {
        text: "Đăng ký",
        path: PathConstant.SHOP_REGISTRATION,
      },
      {
        text: "Shops Log",
        path: PathConstant.SHOP_LOGS,
      },
    ],
  },
  {
    text: "Gói dịch vụ",
    icon: <StarOutline color="inherit" />,
    path: PathConstant.OWNERS,
  },
  {
    text: "Cài đặt",
    icon: <Settings color="inherit" />,
    children: [
      {
        text: "Profile",
        path: PathConstant.PROFILE,
      },
      {
        text: "Quản lý tài khoản",
        path: PathConstant.ACCOUNT,
      },
    ],
  },
  {
    text: "Backup Version",
    icon: <BackupOutlined color="inherit" />,
    path: PathConstant.BACKUP_VERSION,
  },
];

const useStyles = makeStyles({
  root: {
    position: "sticky",
    width: DRAWER_WIDTH_OPEN,
    height: "calc(100vh - 60px)",
    minWidth: DRAWER_WIDTH_OPEN,
    top: 60,
    overflowY: "auto",
    background: "#3e4045",
  },
  list: {
    padding: 0,
  },
});
