import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Grid, Typography, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import StringFormat from "string-format";
import clsx from "clsx";
import { UploadImage, Alert } from "components";
import { LangConstant, ApiConstant } from "const";
import UploadAction from "redux/upload.redux";

const BackgroundFeature = props => {
  const { langValue, formURL, onChange, isLastFeature } = props;
  const defaultClasses = useStyles({ isLastFeature: isLastFeature });
  const dispatch = useDispatch();

  const imageURI = useSelector(state => state.uploadRedux.dataImage);

  const [formImage, setFormImage] = useState(FORM_DATA_DEFAULT);
  const [selectedImageUpload, setSelectedImageUpload] = useState(OPTION_UPLOAD.facebook);
  const [isOpenOutSize, setIsOpenOutSize] = useState(false);

  const onUploadFacebook = dataForm => {
    if (dataForm?.file) {
      setSelectedImageUpload(OPTION_UPLOAD.facebook);
      dispatch(UploadAction.uploadImage(dataForm));
    } else {
      if (dataForm?.isRemove) {
        let formData = [...formImage];
        formData[OPTION_UPLOAD.facebook].image = "";
        onChange(formData, NM_IMAGE_SLIDE);
      } else {
        setIsOpenOutSize(true);
      }
    }
  };

  const onUploadWebsite = dataForm => {
    if (dataForm?.file) {
      setSelectedImageUpload(OPTION_UPLOAD.website);
      dispatch(UploadAction.uploadImage(dataForm));
    } else if (dataForm?.isRemove) {
      let formData = [...formImage];
      formData[OPTION_UPLOAD.website].image = "";
      onChange(formData, NM_IMAGE_SLIDE);
    } else {
      setIsOpenOutSize(true);
    }
  };

  const onUploadGoogle = dataForm => {
    if (dataForm?.file) {
      setSelectedImageUpload(OPTION_UPLOAD.google);
      dispatch(UploadAction.uploadImage(dataForm));
    } else if (dataForm?.isRemove) {
      let formData = [...formImage];
      formData[OPTION_UPLOAD.google].image = "";
      onChange(formData, NM_IMAGE_SLIDE);
    } else {
      setIsOpenOutSize(true);
    }
  };

  useEffect(() => {
    if (imageURI && imageURI?.uri[0]) {
      let formData = [...FORM_DATA_DEFAULT];
      switch (selectedImageUpload) {
        case OPTION_UPLOAD.website:
          formData[OPTION_UPLOAD.website].image = imageURI.uri[0];
          break;
        case OPTION_UPLOAD.google:
          formData[OPTION_UPLOAD.google].image = imageURI.uri[0];
          break;
        default:
          formData[OPTION_UPLOAD.facebook].image = imageURI.uri[0];
      }
      onChange(formData, NM_IMAGE_SLIDE);
    }
  }, [imageURI]);

  useEffect(() => {
    let newFormImageUpdate = [...formImage];
    newFormImageUpdate[OPTION_UPLOAD.facebook].image = formURL.facebook;
    newFormImageUpdate[OPTION_UPLOAD.website].image = formURL.website;
    newFormImageUpdate[OPTION_UPLOAD.google].image = formURL.google;
    setFormImage(newFormImageUpdate);
  }, [formURL.facebook, formURL.google, formURL.website]);

  return (
    <Grid item xs={3} className={defaultClasses.root}>
      <Box className={defaultClasses.content}>
        <UploadImage
          id="facebook-id"
          isShowWarning={false}
          onChange={onUploadFacebook}
          defaultUrl={
            formImage[OPTION_UPLOAD.facebook].image &&
            StringFormat(FM_ATTACHMENT_IMAGE, formImage[OPTION_UPLOAD.facebook].image)
          }
        />
        <Box className={defaultClasses.boxIllustration}>
          <Typography className={defaultClasses.contentIllustration}>
            {isLastFeature
              ? StringFormat(LangConstant.FM_ILLUSTRATION_SOCIAL, "Facebook", langValue)
              : StringFormat(LangConstant.FM_ILLUSTRATION, langValue)}
          </Typography>
        </Box>
        {isLastFeature && (
          <>
            <UploadImage
              id="website-id"
              isShowWarning={false}
              onChange={onUploadWebsite}
              defaultUrl={
                formImage[OPTION_UPLOAD.website].image &&
                StringFormat(FM_ATTACHMENT_IMAGE, formImage[OPTION_UPLOAD.website].image)
              }
            />
            <Box className={defaultClasses.boxIllustration}>
              <Typography className={defaultClasses.contentIllustration}>
                {isLastFeature
                  ? StringFormat(LangConstant.FM_ILLUSTRATION_SOCIAL, "Website", langValue)
                  : StringFormat(LangConstant.FM_ILLUSTRATION, langValue)}
              </Typography>
            </Box>
            <UploadImage
              id="google-id"
              isShowWarning={false}
              onChange={onUploadGoogle}
              defaultUrl={
                formImage[OPTION_UPLOAD.google].image &&
                StringFormat(FM_ATTACHMENT_IMAGE, formImage[OPTION_UPLOAD.google].image)
              }
            />
            <Box className={defaultClasses.boxIllustration}>
              <Typography className={defaultClasses.contentIllustration}>
                {isLastFeature
                  ? StringFormat(LangConstant.FM_ILLUSTRATION_SOCIAL, "Google map", langValue)
                  : StringFormat(LangConstant.FM_ILLUSTRATION, langValue)}
              </Typography>
            </Box>
          </>
        )}
      </Box>
      {isOpenOutSize && (
        <Alert
          isShow={true}
          onClose={() => setIsOpenOutSize(false)}
          message="Ảnh có dung lượng vượt quá 2MB"
          type="warning"
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          action={
            <IconButton onClick={() => setIsOpenOutSize(false)} className={defaultClasses.button}>
              <Close className={defaultClasses.iconClose} />
            </IconButton>
          }
          classes={{
            root: defaultClasses.rootAlert,
            action: defaultClasses.action,
            icon: defaultClasses.icon,
            message: clsx("regular-sm-txt", defaultClasses.message),
          }}
        />
      )}
    </Grid>
  );
};

const NM_IMAGE_SLIDE = "slide";

const OPTION_UPLOAD = {
  facebook: 0,
  website: 1,
  google: 2,
};

const FORM_DATA_DEFAULT = [
  {
    color: "",
    image: "",
    name: {
      vi: "",
      en: "",
    },
  },
  {
    color: "",
    image: "",
    name: {
      vi: "",
      en: "",
    },
  },
  {
    color: "",
    image: "",
    name: {
      vi: "",
      en: "",
    },
  },
];

const FM_ATTACHMENT_IMAGE = `${ApiConstant.HOST_ATTACHMENT}{0}`;

BackgroundFeature.propTypes = {
  langValue: PropTypes.string,
  formURL: PropTypes.shape({
    facebook: PropTypes.string,
    website: PropTypes.string,
    google: PropTypes.string,
  }),
  onChange: PropTypes.func,
  isLastFeature: PropTypes.bool,
};

BackgroundFeature.defaultProps = {
  isLastFeature: false,
  onChange: () => {},
};

export default memo(BackgroundFeature);

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 34,
  },
  imgPreviewThumbnail: {
    maxWidth: "100%",
    maxHeight: "100%",
    minHeight: 200,
  },
  content: {
    maxHeight: "100%",
    position: props => (props.isLastFeature ? "block" : "sticky"),
    top: 60,
  },
  boxIllustration: {
    display: "flex",
    height: 20,
    margin: "8px 0 24px",
  },
  contentIllustration: {
    height: 20,
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.grey[500],
    lineHeight: 1.67,
  },
  rootAlert: {
    width: 400,
    height: 30,
    padding: "5px 8px",
    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.16)",
    backgroundColor: "rgba(254, 186, 64, 0.5)",
    color: "#3e4045",
  },
  button: {
    padding: 0,
  },
  iconClose: {
    width: 20,
    height: 20,
  },
  icon: {
    display: "none",
  },
  action: {
    marginRight: 0,
    paddingLeft: 8,
    display: "unset",
  },
  message: {
    width: "100%",
    lineHeight: 1.67,
    padding: 0,
    textAlign: "center",
  },
}));
