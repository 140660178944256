import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, Box, Typography, Button } from "@material-ui/core";
import { formatCurrency, getDateFromTimeStamp, getPaymentStatusColor, isNotEqual } from "utils";
import { AppConstant } from "const";
import clsx from "clsx";
import { Alert, ConfirmDialog } from "components";
import LineInfo from "../LineInfo";
import PaymentDialog from "../PaymentDialog";
import OwnerDetailsAction from "redux/owner-details.redux";

const ODPaymentItem = ({ data }) => {
  const dispatch = useDispatch();

  const isUpdateSuccess = useSelector(({ ownerDetailsRedux }) => ownerDetailsRedux.isUpdateSuccess);

  const [paymentDetail, setPaymentDetail] = useState({});
  const [isShowConfirmPayment, setIsShowConfirmPayment] = useState(false);
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);

  const classes = useStyles({ statusColor: getPaymentStatusColor(paymentDetail.status) });

  const onMarkAsPaid = () => {
    dispatch(
      OwnerDetailsAction.updatePaymentAsPaid({
        uid: paymentDetail.uid,
        status: AppConstant.PAYMENTS_STATUS.paid,
      }),
      setIsShowConfirmPayment(false),
    );
  };

  const onControlDetailDialog = () => {
    setIsOpenDetail(!isOpenDetail);
  };

  useEffect(() => {
    if (data && isNotEqual(data, paymentDetail)) {
      setPaymentDetail(data);
    }
  }, [data]);

  useEffect(() => {
    if (isUpdateSuccess) {
      setIsUpdated(true);
      dispatch(OwnerDetailsAction.ownerDetailsSuccess({ isUpdateSuccess: false }));
    }
  }, [isUpdateSuccess]);

  let isUnpaid = paymentDetail.status === AppConstant.PAYMENTS_STATUS.unpaid;

  return (
    <Box className={classes.paymentItemBox}>
      <Box className={classes.outerCircle}>
        <Box className={classes.timelineCircle} />
      </Box>
      <Box className={classes.paymentItemDetail}>
        <Box className={classes.identifyBox}>
          <Typography className="semiBold-md-txt">Mã thanh toán &nbsp;</Typography>
          <Typography className={clsx("semiBold-md-txt", classes.codeText)}>{paymentDetail.strCode}</Typography>
          <Box className={classes.created}>{getDateFromTimeStamp(paymentDetail.created)}</Box>
        </Box>
        <LineInfo
          title="Số tiền:"
          content={formatCurrency(paymentDetail.total)}
          otherProps={clsx("medium-md-txt", classes.textNormalColor)}
        />
        <LineInfo
          title="Trạng thái:"
          content={paymentDetail.strPaymentStatus}
          otherProps={clsx("medium-md-txt", classes.paymentStatus)}
        />
        <LineInfo
          title="Ngày thanh toán:"
          content={getDateFromTimeStamp(paymentDetail.paymentDate)}
          otherProps={clsx("medium-md-txt", classes.textNormalColor)}
        />

        <Box className={classes.actionBox}>
          <Box className={classes.actionLine} />
          {isUnpaid && (
            <Button
              variant="outlined"
              color="primary"
              size="small"
              className={clsx("semiBold-md-txt", classes.markAsPaidBtn)}
              onClick={() => setIsShowConfirmPayment(true)}
            >
              Đánh dấu đã thanh toán
            </Button>
          )}
          <Button
            color="primary"
            onClick={onControlDetailDialog}
            variant="text"
            size="small"
            className={clsx("medium-md-txt", classes.detailText)}
          >
            Chi tiết
          </Button>
          {isShowConfirmPayment && (
            <ConfirmDialog
              isShow={isShowConfirmPayment}
              onClose={() => setIsShowConfirmPayment(false)}
              onSubmit={onMarkAsPaid}
              title="Đánh dấu đã thanh toán"
              message="Bạn có chắc chắn muốn đánh dấu tài khoản này đã thanh toán hay không?"
            />
          )}
        </Box>
      </Box>
      {isOpenDetail && <PaymentDialog open={isOpenDetail} data={paymentDetail} onClose={onControlDetailDialog} />}
      {isUpdated && (
        <Alert
          isShow={isUpdated}
          onClose={() => setIsUpdated(false)}
          type="success"
          message="Cập nhật chi tiết thanh toán thành công"
        />
      )}
    </Box>
  );
};

ODPaymentItem.propTypes = {
  data: PropTypes.object,
};

ODPaymentItem.defaultProps = {
  data: {},
};

export default memo(ODPaymentItem);

const useStyles = makeStyles(theme => ({
  paymentItemBox: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    padding: "0 0 18px 15px",
    marginLeft: 15,
    flexGrow: 1,
    borderLeft: "1px dashed #b3b5ba",
  },

  outerCircle: {
    position: "absolute",
    top: 23,
    left: -6,
    borderTop: "12px solid #fafafb",
    borderBottom: "12px solid #fafafb",
    cursor: "pointer",
  },
  timelineCircle: {
    width: 10,
    height: 10,
    backgroundColor: theme.palette.primary.main,
    borderRadius: "50%",
  },

  paymentItemDetail: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: 216,
    padding: "12px 16px",
    borderRadius: 8,
    backgroundColor: "#fff",
  },

  identifyBox: {
    display: "flex",
    "& > div": {
      marginLeft: "auto",
    },
  },
  created: {
    display: "flex",
    width: 100,
    height: 30,
    padding: "4px 12px",
    borderRadius: 4,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(79, 139, 255, 0.2)",
  },
  textBox: {
    marginTop: 21,
  },

  codeText: {
    color: theme.palette.text.link,
    lineHeight: 1.57,
  },

  textNormalColor: {
    color: "#3e4045",
  },

  paymentStatus: {
    color: props => props.statusColor,
  },

  actionBox: {
    display: "flex",
    marginTop: 10,
    alignItems: "center",
    "& > *:last-child": {
      marginLeft: "auto",
    },
  },
  actionLine: {
    width: "87%",
    borderTop: "1px dashed #d4d5d8",
  },
  markAsPaidBtn: {
    position: "absolute",
    right: 86,
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white,
    },
  },
  detailText: {
    minHeight: 30,
    padding: 4,
  },
}));
