import React, { memo, useState, useEffect } from "react";
import { makeStyles, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { Prompt } from "react-router-dom";
import clsx from "clsx";
import { Alert } from "components";
import ManageLayout from "layouts/ManageLayout";
import { AppConstant } from "const";
import HeaderBar from "./HeaderBar";
import InfoQuestion from "./InfoQuestion";
import QuestionCategoryAction from "redux/question-category.redux";
import QuestionAction from "redux/question.redux";
import { handleBeforeUnload } from "utils";

const CreateQuestion = () => {
  const defaultClasses = useStyles();
  const dispatch = useDispatch();

  const isSuccessCreate = useSelector(props => props.helpQuestionRedux.isSuccess);

  const [formData, setFormData] = useState(DEFAULT_FORM);
  const [alertForm, setAlertForm] = useState(DEFAULT_FORM_ALERT);
  const [statusMessage, setStatusMessage] = useState();
  const [isUpdateData, setIsUpdateData] = useState(false);
  const [isReset, setIsReset] = useState(false);

  const onCheckDisplay = e => {
    if (e.target.checked) {
      setFormData({ ...formData, flagShowToHomePage: AppConstant.STATUS_DISPLAY_HOME_PAGE.display });
    } else {
      setFormData({ ...formData, flagShowToHomePage: AppConstant.STATUS_DISPLAY_HOME_PAGE.hidden });
    }
  };

  const onChangeCategory = value => {
    setFormData({ ...formData, helpCategoryIdList: [value] });
  };

  const onChangeFormData = newData => {
    setFormData({
      ...newData,
      flagShowToHomePage: formData.flagShowToHomePage,
      helpCategoryIdList: formData.helpCategoryIdList,
    });
    if (newData.title.vi || newData.title.en) {
      setIsUpdateData(true);
    }
  };

  const onSubmit = status => {
    if (
      !alertForm.isShowing &&
      (formData.title.vi || formData.title.en) &&
      (formData.content.vi || formData.content.en) &&
      formData.helpCategoryIdList.length === 1
    ) {
      let newFormData = { ...formData };
      newFormData.published = status;
      setStatusMessage(status);
      dispatch(QuestionAction.createHelpQuestion(newFormData));
    }
  };

  const onCloseAlert = () => {
    setAlertForm(DEFAULT_FORM_ALERT);
  };

  useEffect(() => {
    dispatch(QuestionCategoryAction.getHelpCategory());
  }, []);

  useEffect(() => {
    if (isSuccessCreate) {
      dispatch(QuestionAction.resetHelpQuestionSuccess());
      if (statusMessage === AppConstant.STATUS_OPTION.publish) {
        setAlertForm(OBJ_ALERT_MESSAGE.display);
      } else if (statusMessage === AppConstant.STATUS_OPTION.draft) {
        setAlertForm(OBJ_ALERT_MESSAGE.hidden);
      }
      setFormData(DEFAULT_FORM);
      setIsUpdateData(false);
      setIsReset(true);
    }
  }, [isSuccessCreate]);

  useEffect(() => {
    if (checkInputDataBeforeChangePage(formData) || isUpdateData) {
      window.addEventListener("beforeunload", handleBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  }, [formData]);

  return (
    <>
      <Prompt
        when={checkInputDataBeforeChangePage(formData) || isUpdateData}
        message={() => `Các thay đổi bạn đã thực hiện có thể không được lưu.`}
      />
      <ManageLayout
        title="Tạo câu hỏi"
        buttonProps={{ content: "Đăng câu hỏi", onClick: () => onSubmit(AppConstant.STATUS_OPTION.publish) }}
      />
      <HeaderBar
        data={{
          category: formData.helpCategoryIdList.length === 1 ? formData.helpCategoryIdList[0] : null,
          display: formData.flagShowToHomePage,
          state: formData.published,
        }}
        onSelectCategory={onChangeCategory}
        onCheckDisplay={onCheckDisplay}
        onSaveDraft={() => onSubmit(AppConstant.STATUS_OPTION.draft)}
      />
      <InfoQuestion
        isReset={isReset}
        onResetSuccess={() => setIsReset(false)}
        data={formData}
        onChangeData={onChangeFormData}
      />
      {alertForm.isShowing && (
        <Alert
          isShow={true}
          onClose={onCloseAlert}
          message={alertForm.message}
          type={alertForm.type}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          action={
            <IconButton onClick={onCloseAlert} className={defaultClasses.button}>
              <Close className={defaultClasses.iconClose} />
            </IconButton>
          }
          classes={{
            root: clsx(
              defaultClasses.rootAlert,
              alertForm.type === OBJ_ALERT_MESSAGE.hidden.type && defaultClasses.colorWarning,
            ),
            action: defaultClasses.action,
            icon: defaultClasses.icon,
            message: clsx("regular-sm-txt", defaultClasses.message),
          }}
        />
      )}
    </>
  );
};

const DEFAULT_FORM = {
  thumbnail: {
    vi: "",
    en: "",
  },
  helpCategoryIdList: [],
  title: {
    vi: "",
    en: "",
  },
  content: {
    vi: "",
    en: "",
  },
  description: {
    vi: "",
    en: "",
  },
  created: null,
  published: AppConstant.STATUS_OPTION.draft,
  flagShowToHomePage: AppConstant.STATUS_DISPLAY_HOME_PAGE.display,
};

const DEFAULT_FORM_ALERT = {
  isShowing: false,
  message: "",
  type: "",
};

const OBJ_ALERT_MESSAGE = {
  display: {
    isShowing: true,
    message: "Câu hỏi đã được hiển thị/cập nhật",
    type: "success",
  },
  hidden: {
    isShowing: true,
    message: "Câu hỏi chưa được hiển thị/cập nhật",
    type: "warning",
  },
};

export default memo(CreateQuestion);

const checkInputDataBeforeChangePage = data => {
  if (
    data.title.vi ||
    data.title.en ||
    data.content.vi ||
    data.content.en ||
    data.description.vi ||
    data.description.en ||
    data.thumbnail.vi ||
    data.thumbnail.en
  ) {
    return true;
  }
  return false;
};

const useStyles = makeStyles(() => ({
  rootAlert: {
    width: 400,
    height: 30,
    padding: "5px 8px",
    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.16)",
    backgroundColor: "rgba(101, 179, 157, 0.5)",
    color: "#3e4045",
  },
  colorWarning: {
    backgroundColor: "rgba(254, 186, 64, 0.5)",
  },
  button: {
    padding: 0,
  },
  iconClose: {
    width: 20,
    height: 20,
  },
  icon: {
    display: "none",
  },
  action: {
    marginRight: 0,
    paddingLeft: 8,
    display: "unset",
  },
  message: {
    width: "100%",
    lineHeight: 1.67,
    padding: 0,
    textAlign: "center",
  },
}));
