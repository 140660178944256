import React, { memo, useState, useEffect } from "react";
import { makeStyles, Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import StringFormat from "string-format";
import ManageLayout from "layouts/ManageLayout";
import { AppConstant, LangConstant } from "const";
import { FeatureItemList, ACTION_OPTION, FeatureDetail } from "./components";
import FeatureAction from "redux/feature.redux";

const Feature = () => {
  const defaultClasses = useStyles();
  const dispatch = useDispatch();

  const arrayFeature = useSelector(props => props.featureRedux.data);

  const [numberOfShowing, setNumberOfShowing] = useState(0);
  const [listFeatures, setListFeatures] = useState([]);
  const [formFeature, setFormFeature] = useState(DEFAULT_FEATURE_FORM);

  const onCreateNew = () => {
    let isLastFeature = false;
    if (arrayFeature.length === 4) {
      isLastFeature = true;
    }
    setFormFeature({ ...formFeature, state: ACTION_OPTION.create, isLastFeature: isLastFeature });
  };

  const onOpenFeatureDetail = formFeature => {
    let isLastFeature = false;
    if (arrayFeature.length === 5 && arrayFeature[4].id === formFeature.data.id) {
      isLastFeature = true;
    }
    setFormFeature({ ...formFeature, isLastFeature: isLastFeature });
  };

  const onResetForm = () => {
    setFormFeature(DEFAULT_FEATURE_FORM);
  };

  useEffect(() => {
    dispatch(FeatureAction.getHomeFeature());
  }, []);

  useEffect(() => {
    let numberOfFeaturesShowing = 0;
    arrayFeature.forEach(item => {
      if (item.published === AppConstant.STATUS_OPTION.publish) {
        numberOfFeaturesShowing++;
      }
    });
    setNumberOfShowing(numberOfFeaturesShowing);
    setListFeatures(arrayFeature);
  }, [arrayFeature]);

  let isOpenFeature = [ACTION_OPTION.create, ACTION_OPTION.detail, ACTION_OPTION.edit].includes(formFeature.state);
  return isOpenFeature ? (
    <FeatureDetail
      isLastFeature={formFeature.isLastFeature}
      stateFeature={formFeature.state}
      numberOfShowing={numberOfShowing}
      data={formFeature.data}
      onEditFeature={() => setFormFeature({ ...formFeature, state: ACTION_OPTION.edit })}
      onComeBack={onResetForm}
    />
  ) : (
    <>
      <ManageLayout
        title="Quản lý tính năng"
        subtitle={StringFormat(LangConstant.FM_SHOWING, numberOfShowing)}
        buttonProps={{
          disabled: listFeatures.length >= 5,
          onClick: onCreateNew,
          classes: { contained: defaultClasses.containedButton },
        }}
      />
      <Box className={defaultClasses.listIntro}>
        {listFeatures.map((item, index) => (
          <Box key={item.id} marginBottom="16px">
            <FeatureItemList data={{ ...item, sortOrder: index + 1 }} onOpenFeatureDetail={onOpenFeatureDetail} />
          </Box>
        ))}
      </Box>
    </>
  );
};

export const DEFAULT_FEATURE_FORM = {
  state: ACTION_OPTION.noAction,
  data: {
    id: null,
    title: {
      vi: "",
      en: "",
    },
    highlightTitle: {
      vi: "",
      en: "",
    },
    content: {
      vi: "",
      en: "",
    },
    description: {
      vi: "",
      en: "",
    },
    published: AppConstant.STATUS_OPTION.draft,
    sortOrder: null,
    created: null,
    publishedTime: null,
    slide: [
      {
        color: "",
        image: "",
        name: {
          vi: "",
          en: "",
        },
      },
      {
        color: "",
        image: "",
        name: {
          vi: "",
          en: "",
        },
      },
      {
        color: "",
        image: "",
        name: {
          vi: "",
          en: "",
        },
      },
    ],
    slideType: 1,
  },
};

export default memo(Feature);

const useStyles = makeStyles(theme => ({
  listIntro: {
    height: "calc(100vh - 150px)",
    padding: "24px 32px 8px",
    overflow: "auto",
  },
  containedButton: {
    "&.Mui-disabled": {
      color: theme.palette.white,
      opacity: 0.6,
      backgroundColor: theme.palette.primary.main,
    },
  },
}));
