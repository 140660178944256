const textSizeSmall = { fontSize: 14, fontWeight: 500 };
const textSizeMedium = { fontSize: 16, fontWeight: 600 };
const smallStyle = {
  paddingLeft: 16,
  paddingRight: 16,
  paddingTop: 3,
  paddingBottom: 3,
  minHeight: 30,
  borderRadius: 2,
};
const mediumStyle = {
  paddingLeft: 36,
  paddingRight: 36,
  paddingTop: 6,
  paddingBottom: 6,
  minHeight: 40,
  borderRadius: 2,
  ...textSizeMedium,
};
const largeStyle = {
  paddingLeft: 36,
  paddingRight: 36,
  paddingTop: 9,
  paddingBottom: 9,
  minHeight: 46,
  borderRadius: 2,
};

export default {
  root: {
    textTransform: "normal",
    borderRadius: 2,
  },
  contained: {
    boxShadow: "none",
    borderRadius: 20,
    ...mediumStyle,
  },
  outlined: {
    borderRadius: 20,
    ...mediumStyle,
  },
  textSizeSmall: textSizeSmall,
  textSizeLarge: textSizeMedium,
  sizeSmall: { ...smallStyle, ...textSizeSmall },
  sizeLarge: { ...largeStyle, ...textSizeMedium },
};
