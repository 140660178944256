import { ApiConstant } from "const";
import { createApiRegistration } from "api";
import StringFormat from "string-format";
import { joinQueryStringArray } from "utils";

export const postHomeIntro = data => createApiRegistration().post(ApiConstant.HOME_INTRO, data);

export const putHomeIntro = data => createApiRegistration().put(StringFormat(ApiConstant.HOME_INTRO_ID, data.id), data);

export const getHomeIntro = data => createApiRegistration().get(ApiConstant.HOME_INTRO, data);

export const getHomeIntroById = data => createApiRegistration().get(StringFormat(ApiConstant.HOME_INTRO_ID, data.id));

export const deleteHomeIntro = data => createApiRegistration().delete(StringFormat(ApiConstant.HOME_INTRO_ID, data.id));

export const sortHomeIntro = data =>
  createApiRegistration().put(joinQueryStringArray(ApiConstant.HOME_INTRO_SORT, data, "intro_id_list"));
