import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Grid, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import StringFormat from "string-format";
import { UploadImage } from "components";
import { LangConstant, ApiConstant } from "const";
import UploadAction from "redux/upload.redux";

const BackgroundEvaluate = props => {
  const { langValue, formURL, onChange } = props;
  const defaultClasses = useStyles();
  const dispatch = useDispatch();

  const imageURI = useSelector(state => state.uploadRedux.dataImage);

  const [formImage, setFormImage] = useState(DEFAULT_FORM);
  const [typeImage, setTypeImage] = useState();

  const onChangeImage = dataForm => {
    if (dataForm?.file) {
      setTypeImage(NM_IMAGE);
      dispatch(UploadAction.uploadImage(dataForm));
    } else {
      let newFormImage = {
        ...formImage.image,
        [langValue]: "",
      };
      setFormImage(currentData => ({
        ...currentData,
        image: newFormImage,
      }));
      onChange(newFormImage, NM_IMAGE);
    }
  };

  const onChangeLogo = dataForm => {
    if (dataForm?.file) {
      setTypeImage(NM_LOGO);
      dispatch(UploadAction.uploadImage(dataForm));
    } else {
      let newFormImage = {
        ...formImage.image,
        [langValue]: "",
      };
      setFormImage(currentData => ({
        ...currentData,
        logo: newFormImage,
      }));
      onChange(newFormImage, NM_LOGO);
    }
  };

  useEffect(() => {
    if (imageURI) {
      if (typeImage === NM_IMAGE) {
        let newFormImage = {
          ...formImage.image,
          [langValue]: imageURI.uri[0],
        };
        setFormImage(currentData => ({
          ...currentData,
          image: newFormImage,
        }));
        onChange(newFormImage, NM_IMAGE);
      } else if (typeImage === NM_LOGO) {
        let newFormLogo = {
          ...formImage.logo,
          [langValue]: imageURI.uri[0],
        };
        setFormImage(currentData => ({
          ...currentData,
          logo: newFormLogo,
        }));
        onChange(newFormLogo, NM_LOGO);
      }
    }
  }, [imageURI]);

  useEffect(() => {
    setFormImage(formURL);
  }, [formURL]);

  return (
    <Grid item xs={3} className={defaultClasses.root}>
      <Box className={defaultClasses.content}>
        <UploadImage
          id="image"
          onChange={onChangeImage}
          defaultUrl={formImage.image[langValue] ? StringFormat(FM_ATTACHMENT_IMAGE, formImage.image[langValue]) : ""}
        />
        <Box className={defaultClasses.boxIllustration}>
          <Typography className={defaultClasses.contentIllustration}>
            {StringFormat(LangConstant.FM_IMAGE_DEPUTY, langValue.toUpperCase())}
          </Typography>
        </Box>
        <UploadImage
          id="logo"
          onChange={onChangeLogo}
          defaultUrl={formImage.logo[langValue] ? StringFormat(FM_ATTACHMENT_IMAGE, formImage.logo[langValue]) : ""}
        />
        <Box className={defaultClasses.boxIllustration}>
          <Typography className={defaultClasses.contentIllustration}>
            {StringFormat(LangConstant.FM_IMAGE_BRANCH, langValue.toUpperCase())}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

const FM_ATTACHMENT_IMAGE = `${ApiConstant.HOST_ATTACHMENT}{0}`;

const DEFAULT_FORM = {
  image: {
    vi: "",
    en: "",
  },
  logo: {
    vi: "",
    en: "",
  },
};

const NM_IMAGE = "image";
const NM_LOGO = "logo";

BackgroundEvaluate.propTypes = {
  langValue: PropTypes.string,
  formURL: PropTypes.shape({
    image: PropTypes.shape({
      vi: PropTypes.string,
      en: PropTypes.string,
    }),
    logo: PropTypes.shape({
      vi: PropTypes.string,
      en: PropTypes.string,
    }),
  }),
  onChange: PropTypes.func,
};

BackgroundEvaluate.defaultProps = {
  onChange: () => {},
};

export default memo(BackgroundEvaluate);

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 34,
  },
  imgPreviewThumbnail: {
    maxWidth: "100%",
    maxHeight: "100%",
    minHeight: 200,
  },
  content: {
    maxHeight: "100%",
  },
  boxIllustration: {
    display: "flex",
    height: 20,
    margin: "8px 0 24px",
  },
  contentIllustration: {
    height: 20,
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.grey[500],
    lineHeight: 1.67,
  },
}));
