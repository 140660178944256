import React, { memo, useState, useEffect } from "react";
import { makeStyles, Box } from "@material-ui/core";
import { PaginationFooter } from "components";
import ManageLayout from "layouts/ManageLayout";
import { HeaderRow, BodyRow } from "pages/ManageRegistration/components";
import { useDispatch, useSelector } from "react-redux";
import ShopAction from "redux/shop.redux";
import { isArrayNotEqual } from "utils";

function ManageShopsLog() {
  const defaultClasses = useStyles();
  const dispatch = useDispatch();

  const { data, ...pagination } = useSelector(state => state.shopRedux.shopLog);

  const [arrayShopLog, setArrayShopLog] = useState([]);
  const [pageData, setPageData] = useState(DEFAULT_PAGE);

  const onChangePage = (_, newPage) => {
    dispatch(ShopAction.getShopLog({ page: newPage }));
  };

  useEffect(() => {
    dispatch(ShopAction.getShopLog());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data && isArrayNotEqual(data, arrayShopLog)) {
      let formatData = formatArrayShop(data, pagination.page);
      setArrayShopLog(formatData);
      setPageData({ ...pageData, ...pagination });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Box>
      <ManageLayout title="Shops Log" />
      <Box className={defaultClasses.rootTable}>
        <HeaderRow />
        {arrayShopLog.map((shopRegistration, index) => (
          <BodyRow key={index} data={shopRegistration} />
        ))}
        <PaginationFooter data={pageData} onChangePage={onChangePage} />
      </Box>
    </Box>
  );
}

const DEFAULT_PAGE = {
  total: 0,
  page: 1,
  rowsPerPage: 10,
  totalPage: 0,
};

const formatArrayShop = (arrayRegistration, page = 1) => {
  let formatArrayRegistration = arrayRegistration.map((blog, index) => ({
    ...blog,
    number: (page - 1) * 10 + index + 1,
  }));
  return formatArrayRegistration;
};

export default memo(ManageShopsLog);

const useStyles = makeStyles(() => ({
  rootTable: {
    width: "100%",
    height: "calc(100vh - 190px)",
    padding: "24px 32px 24px 24px",
    overflow: "auto",
  },
}));
