import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { FiberManualRecord } from "@material-ui/icons";
import StringFormat from "string-format";
import { convertTimestampToDateRange } from "utils";
import { AppConstant, LangConstant } from "const";
import { PackageItem } from "components";

const PackageTimeline = ({ data, onSelected }) => {
  const classes = useStyles();

  const [selectedUid, setSelectedUid] = useState();
  const [displayData, setDisplayData] = useState([]);
  const [isSeeAll, setIsSeeAll] = useState(false);

  const onCollapse = () => {
    setIsSeeAll(false);
    // Set back to default data
    setDisplayData(data.slice(0, DEFAULT_SIZE_DATA));
    setSelectedUid(data[0].uid);
  };
  const onSeeMore = () => {
    let dataSize = displayData.length + SEE_MORE_SIZE_DATA;

    setDisplayData(data.slice(0, dataSize));
    if (dataSize >= data.length) setIsSeeAll(true);
  };

  const onSelectedPackage = uid => {
    setSelectedUid(uid);
    onSelected(uid);
  };

  useEffect(() => {
    // At first render 4 latest packages
    setDisplayData(data.slice(0, DEFAULT_SIZE_DATA));
    // Get data of newest package
    setSelectedUid(data[0].uid);
  }, [data]);

  return (
    <Box className={classes.packageTimelineBox}>
      <Typography className={classes.timelineText}>Các kỳ dịch vụ</Typography>
      {displayData.map((dataMap, index) => (
        <PackageItemInfo
          key={index}
          onSelectPackage={() => onSelectedPackage(dataMap.uid)}
          selectedUid={selectedUid}
          packageDetail={dataMap}
        />
      ))}
      {isSeeAll ? (
        <Typography
          color="primary"
          className={data.length > DEFAULT_SIZE_DATA ? "medium-lg-txt" : classes.hiddenText}
          onClick={onCollapse}
        >
          Thu gọn
        </Typography>
      ) : (
        <Typography
          color="primary"
          className={data.length > DEFAULT_SIZE_DATA ? "medium-lg-txt" : classes.hiddenText}
          onClick={onSeeMore}
        >
          Xem thêm
        </Typography>
      )}
    </Box>
  );
};

PackageTimeline.propTypes = {
  data: PropTypes.array.isRequired,
  onSelected: PropTypes.func,
};

PackageTimeline.defaultProps = {
  data: [],
  onSelected: () => {},
};

export default memo(PackageTimeline);

const PackageItemInfo = ({ selectedUid, onSelectPackage, packageDetail }) => {
  const classes = useStyles();
  // TODO: packageDetail notion: create two arrs which contain function and resources information in saga

  return (
    <Box className={classes.packageItemBox} onClick={() => onSelectPackage()}>
      <Box className={classes.outerCircle}>
        <Box className={classes.timelineCircle}>
          {selectedUid === packageDetail.uid && <FiberManualRecord className={classes.circleCenter} />}
        </Box>
      </Box>
      <Box className={selectedUid === packageDetail.uid ? classes.packageItemSelected : classes.packageItem}>
        <Box className={classes.textBox}>
          <Typography className="semiBold-md-txt">
            {convertTimestampToDateRange(packageDetail?.startDate, packageDetail?.expiryDate)}
          </Typography>
          <Typography className="medium-md-txt">
            {StringFormat(
              LangConstant.FM_FUNCTION,
              getArrContent(packageDetail?.functions, LangConstant.ARRAY_PACKAGE_FUNCTIONS),
            )}
          </Typography>
          <Typography className="medium-md-txt">
            {StringFormat(
              LangConstant.FM_RESOURCE,
              getArrContent(packageDetail?.resources, LangConstant.ARRAY_PACKAGE_RESOURCES),
            )}
          </Typography>
        </Box>
        <PackageItem packageType={packageDetail?.plan || AppConstant.PACKAGE_TYPE.free} />
      </Box>
    </Box>
  );
};

PackageItemInfo.propTypes = {
  selectedUid: PropTypes.string,
  onSelectPackage: PropTypes.func,
  packageDetail: PropTypes.object,
};

PackageItemInfo.defaultProps = {};

const DEFAULT_SIZE_DATA = 4;
const SEE_MORE_SIZE_DATA = 3;

const getArrContent = (arrIndex, arrObjContent) => {
  if (!arrIndex || arrIndex.length === 0) return "";

  let result = [];
  arrObjContent.map(obj => {
    if (arrIndex.includes(obj.value)) result.push(obj.name);
  });
  return result.join(", ");
};

const useStyles = makeStyles(theme => ({
  packageTimelineBox: {
    width: "100%",
    marginBottom: 36,
    "& > *:last-child": {
      textAlign: "center",
      cursor: "pointer",
    },
  },
  timelineText: {
    paddingBottom: 24,
    fontSize: 28,
    fontWeight: 600,
    color: "#3e4045",
  },
  hiddenText: {
    display: "none",
  },

  packageItemBox: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    padding: "0 28px 18px 30px",
    marginLeft: 15,
    flexGrow: 1,
    borderLeft: "1px dashed #b3b5ba",
  },

  outerCircle: {
    position: "absolute",
    top: 23,
    left: -9,
    borderTop: "12px solid #fafafb",
    borderBottom: "12px solid #fafafb",
    cursor: "pointer",
  },
  timelineCircle: {
    width: 18,
    height: 18,
    backgroundColor: "#fafafb",
    border: "1.5px solid" + theme.palette.primary.main,
    borderRadius: "50%",
  },
  circleCenter: {
    color: theme.palette.primary.main,
    fontSize: "unset",
  },

  packageItemSelected: {
    display: "flex",
    width: "100%",
    height: 120,
    borderRadius: 8,
    padding: "12px 16px",
    justifyContent: "space-between",
    backgroundColor: "rgba(212, 213, 216, 0.25)",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.08)",
  },
  packageItem: {
    display: "flex",
    width: "100%",
    height: 120,
    borderRadius: 8,
    padding: "12px 16px",
    justifyContent: "space-between",
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "rgba(79, 139, 255, 0.1)",
    },
  },
  textBox: {
    "& > *": {
      lineHeight: 2.14,
    },
    "& > *:not(:first-child)": {
      color: "#6c7078",
    },
    "& > *:first-child": {
      color: "#3e4045",
    },
  },
}));
