import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { SelectedItem } from "components";
import { useDispatch, useSelector } from "react-redux";
import BlogsCategoryAction from "redux/blogs-category.redux";
import { LangConstant } from "const";
import clsx from "clsx";

const HeaderFilter = props => {
  const { valueCategory, valueStatus, onChangeCategory, onChangeStatus } = props;
  const defaultClasses = useStyles();
  const dispatch = useDispatch();

  const listCategory = useSelector(state => state.blogsCategoryRedux.data);

  const [arrayCategory, setArrayCategory] = useState([]);

  useEffect(() => {
    dispatch(BlogsCategoryAction.getBlogsCategoryList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let dataFormat = listCategory.map(dataMap => ({
      value: dataMap.id,
      name: dataMap.title?.vi,
    }));
    setArrayCategory(dataFormat);
  }, [listCategory]);

  return (
    <Box className={defaultClasses.root}>
      <Box width="50%" marginRight="44px">
        <Typography className={clsx("bold-sm-txt", defaultClasses.titleFilter)}>Danh mục</Typography>
        <SelectedItem value={valueCategory} onChangeInput={onChangeCategory} data={arrayCategory} />
      </Box>
      <Box width="50%">
        <Typography className={clsx("bold-sm-txt", defaultClasses.titleFilter)}>Trạng thái</Typography>
        <SelectedItem value={valueStatus} onChangeInput={onChangeStatus} data={LangConstant.ARRAY_STATUS} />
      </Box>
    </Box>
  );
};

HeaderFilter.propTypes = {
  valueCategory: PropTypes.any,
  valueStatus: PropTypes.any,
  onChangeCategory: PropTypes.func,
  onChangeStatus: PropTypes.func,
};

HeaderFilter.defaultProps = {
  valueCategory: 0,
  valueStatus: 0,
  onChangeCategory: () => {},
  onChangeStatus: () => {},
};

export default memo(HeaderFilter);

const useStyles = makeStyles(() => ({
  root: {
    width: "70%",
    minWidth: 600,
    display: "flex",
  },
  titleFilter: {
    marginTop: 16,
    marginBottom: 8,
    lineHeight: "20px",
    color: "#3e4045",
  },
}));
