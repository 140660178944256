const white = "#FFFFFF";
const black = "#000000";

export default {
  black,
  white,
  primary: {
    contrastText: white,
    dark: "#D93444",
    main: "#EF5845",
    light: "#FF8F80",
  },
  secondary: {
    contrastText: white,
    dark: "#65B39D",
    main: "#65B39D",
    light: "#65B39D",
  },
  grey: {
    100: "#f2f2f2",
    200: "#ebeced",
    300: "#cecfd2",
    400: "#9d9fa5",
    500: "#6c7078",
    600: "#3b404c",
    700: "#343434",
    800: "#08080a",
  },
  text: {
    primary: "#3B404C",
    secondary: "#9D9FA5",
    link: "#447AFF",
  },
  background: {
    default: white,
    paper: white,
  },
  divider: "#CECFD2",
};
