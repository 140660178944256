import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, makeStyles } from "@material-ui/core";
import { AppDialog } from "components";
import { AppConstant, LangConstant } from "const";
import AddNewFeatureDialogHeader from "./AddNewFeatureDialogHeader";
import AddNewFeatureDialogBody from "./AddNewFeatureDialogBody";
import { createCloneObj } from "utils";

const AddNewFeatureDialog = props => {
  const classes = useStyles();
  const { data, onClose, ...otherProps } = props;

  const [packageDetail, setPackageDetail] = useState(createCloneObj(DEFAULT_PACKAGE_DETAIL));

  const onAddNewFeature = selectedFeatureName => {
    let cloneData = createCloneObj(packageDetail);
    let newFeatureObj = {};

    if (selectedFeatureName) {
      newFeatureObj = getNewFeatureDetailData(selectedFeatureName, cloneData);
    }

    cloneData.packageDetails.push(newFeatureObj);
    cloneData.existedFeaturesArr.push(newFeatureObj.type);
    setPackageDetail(cloneData);
  };

  const onHandle = data => {
    let cloneData = createCloneObj(packageDetail);

    if (Array.isArray(data)) {
      let packageDetailIndex = data.findIndex(item => item.type === AppConstant.PACKAGE_DETAIL_TYPE.package);

      if (packageDetailIndex > -1) {
        cloneData.total = data[packageDetailIndex].total;
      }
      // Update total price of package
      let sum = 0;
      data.forEach(item => (sum += item.total));
      cloneData.totalPrice = sum;

      setPackageDetail({ ...cloneData, packageDetails: data });
    } else onClose();
  };

  useEffect(() => {
    let isValidData = data && data.packageUid;
    if (isValidData) {
      setPackageDetail({ ...DEFAULT_PACKAGE_DETAIL, ...data });
    }
  }, [data]);

  return (
    <AppDialog title="Thêm tính năng" onClose={onClose} {...otherProps}>
      <Box className={classes.root}>
        <AddNewFeatureDialogHeader data={packageDetail} onAddNewFeature={onAddNewFeature} />
        <AddNewFeatureDialogBody data={packageDetail} onCallback={onHandle} />
      </Box>
    </AppDialog>
  );
};

AddNewFeatureDialog.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.object,

  onClose: PropTypes.func.isRequired,
};

export default AddNewFeatureDialog;

const DEFAULT_PACKAGE_DETAIL = {
  packageUid: 0,
  plan: 0,
  orderNumber: 0,
  period: 0,
  startDate: 0,
  expiryDate: 0,
  price: 0,
  total: 0,
  totalPrice: 0,
  packageDetails: [],
};

const getNewFeatureDetailData = (featureName, data) => {
  let result = {};
  if (featureName && data.packageUid) {
    result = {
      expiryDate: data.expiryDate,
      orderNumber: 1,
      period: data.period,
      maxPeriod: data.period,
      price: 0,
      startDate: data.startDate,
      total: 0,
      type: Number(
        Object.keys(LangConstant.PACKAGE_TYPES_NAME).find(key => LangConstant.PACKAGE_TYPES_NAME[key] === featureName),
      ),
    };
  }
  return result;
};

const useStyles = makeStyles({
  root: {
    width: 1000,
    maxWidth: "100%",
    padding: "16px 24px",
  },
});
