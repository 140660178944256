import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, Paper, Box, Grid, Typography } from "@material-ui/core";
import HeaderTab, { LANG_OPTION } from "./HeaderTab";
import InformationBlogs from "./InformationBlogs";
import UploadCover from "./UploadCover";
import { CKEditorCustomize } from "components";
import { removeTags, removeRegularHTMLCodeAndCalculator } from "utils";
import { LangConstant } from "const";
import StringFormat from "string-format";
import clsx from "clsx";

const TabBlogs = props => {
  const { classes, data, onChangeData } = props;
  const defaultClasses = useStyles();

  const [tabValue, setTabValue] = useState(LANG_OPTION.vi);
  const [formData, setFormData] = useState(DEFAULT_FORM_DATA);

  const onChangeTab = value => {
    setTabValue(value);
  };

  const onChange = e => {
    let newFormData = {
      ...formData,
      [e.target.name]: {
        ...formData[e.target.name],
        [tabValue]: e.target.value,
      },
    };
    setFormData(newFormData);
    onChangeData(newFormData);
  };

  const onChangeCKEditor = data => {
    let newFormData = {
      ...formData,
      content: {
        ...formData.content,
        [tabValue]: data,
      },
    };
    setFormData(newFormData);
    onChangeData(newFormData);
  };

  const onChangeCoverBlog = url => {
    let newFormData = {
      ...formData,
      thumbnail: url,
    };
    setFormData(newFormData);
    onChangeData(newFormData);
  };

  useEffect(() => {
    if (data?.isReset) {
      setFormData(DEFAULT_FORM_DATA);
    } else if (data?.title && !formData.title.vi) {
      setFormData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Box className={clsx(defaultClasses.root, classes?.root)}>
      <Paper className={defaultClasses.paper}>
        <HeaderTab tabValue={tabValue} onChangeTab={onChangeTab} />
        <Grid container spacing={3} className={defaultClasses.contentBlog}>
          <InformationBlogs
            data={{
              title: formData.title[tabValue],
              description: formData.description[tabValue],
            }}
            onChange={onChange}
            langValue={tabValue}
          >
            <CKEditorCustomize data={formData.content[tabValue]} onChange={onChangeCKEditor} />
            <Typography className={clsx("regular-md-txt", defaultClasses.numberWord)}>
              {StringFormat(
                LangConstant.FM_NUMBER_WORD,
                formData.content[tabValue]
                  ? removeRegularHTMLCodeAndCalculator(removeTags(formData.content[tabValue]))
                  : 0,
              )}
            </Typography>
          </InformationBlogs>
          <UploadCover url={data.thumbnail} onChange={onChangeCoverBlog} langValue={tabValue.toUpperCase()} />
        </Grid>
      </Paper>
    </Box>
  );
};

const DEFAULT_FORM_DATA = {
  title: {
    vi: "",
    en: "",
  },
  description: {
    vi: "",
    en: "",
  },
  content: {
    vi: "",
    en: "",
  },
};

TabBlogs.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  onChangeData: PropTypes.func,
};

TabBlogs.defaultProps = {
  classes: {},
  onChangeData: () => {},
};
export default memo(TabBlogs);

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    padding: 16,
  },
  paper: {
    padding: "24px 18px 34px",
    boxShadow: "0 1px 6px 0 rgba(0, 0, 0, 0.16)",
  },
  contentBlog: {
    paddingTop: 36,
  },
  numberWord: {
    marginTop: 12,
    color: "#3e4045",
    lineHeight: "22px",
  },
}));
