import React, { memo, useEffect, useState } from "react";
import { makeStyles, Box } from "@material-ui/core";
import ManageLayout from "layouts/ManageLayout";
import { HeaderRow, BodyRow, DialogAccount } from "./components";
import ConfirmDialog from "components/dialog/ConfirmDialog";
import { AppConstant, LangConstant } from "const";
import { useDispatch, useSelector } from "react-redux";
import AccountAction from "redux/account.redux";
import { isArrayNotEqual } from "utils";
import StringFormat from "string-format";

const ManageAccount = () => {
  const defaultClasses = useStyles();
  const dispatch = useDispatch();

  const listAccount = useSelector(state => state.accountRedux.data);
  const isCreateSuccess = useSelector(state => state.accountRedux.isCreateSuccess);
  const isUpdateSuccess = useSelector(state => state.accountRedux.isUpdateSuccess);

  const [arrayAccount, setArrayAccount] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(DEFAULT_DIALOG_DATA);
  const [isChangeDataEdit, setIsChangeDataEdit] = useState(false);

  const onCreateAccount = () => {
    setDialogOpen({
      data: {},
      option: OPTION_ONCLICK.isCreate,
    });
  };

  const onClick = (data, option) => {
    let newFormDialog = { data: data };
    switch (option) {
      case OPTION_ONCLICK.isEdit:
        newFormDialog.option = OPTION_ONCLICK.isEdit;
        break;
      case OPTION_ONCLICK.isResetPassword:
        newFormDialog.option = OPTION_ONCLICK.isResetPassword;
        break;
      case OPTION_ONCLICK.isDisabled:
        newFormDialog.option = OPTION_ONCLICK.isDisabled;
        break;
      default:
        newFormDialog.option = OPTION_ONCLICK.isClose;
    }
    setDialogOpen(newFormDialog);
  };

  const onSubmit = data => {
    let formatCurrentData = { ...dialogOpen.data };
    switch (dialogOpen.option) {
      case OPTION_ONCLICK.isCreate:
        dispatch(AccountAction.createAccount(data));
        break;
      case OPTION_ONCLICK.isEdit:
        dispatch(AccountAction.updateAccount(data));
        break;
      case OPTION_ONCLICK.isResetPassword:
        formatCurrentData.password = formatCurrentData.email;
        dispatch(AccountAction.updateAccount(formatCurrentData));
        break;
      case OPTION_ONCLICK.isDisabled:
        formatCurrentData.state =
          formatCurrentData.state === AppConstant.STATUS_ACCOUNT.active
            ? AppConstant.STATUS_ACCOUNT.inactive
            : AppConstant.STATUS_ACCOUNT.active;
        dispatch(AccountAction.updateAccount(formatCurrentData));
        break;
      case OPTION_ONCLICK.isCloseChange:
        dispatch(AccountAction.updateAccount(dialogOpen.data));
        break;
      default:
        dispatch(AccountAction.updateAccount(dialogOpen.data));
    }
  };

  const onClose = data => {
    if (OPTION_ONCLICK.isEdit && isChangeDataEdit && data) {
      setDialogOpen({
        option: OPTION_ONCLICK.isCloseChange,
        data: data,
      });
    } else {
      setDialogOpen(DEFAULT_DIALOG_DATA);
      if (isChangeDataEdit) {
        setIsChangeDataEdit(false);
      }
    }
  };

  useEffect(() => {
    dispatch(AccountAction.getListAccount());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (listAccount && isArrayNotEqual(listAccount, arrayAccount)) {
      setArrayAccount(formatArrayAccount(listAccount));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listAccount]);

  useEffect(() => {
    if (isCreateSuccess || isUpdateSuccess) {
      dispatch(AccountAction.resetAccount());
      setDialogOpen(DEFAULT_DIALOG_DATA);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateSuccess, isUpdateSuccess]);

  return (
    <Box>
      <ManageLayout title="Phân quyền" buttonProps={{ onClick: onCreateAccount }} />
      <Box className={defaultClasses.rootTable}>
        <HeaderRow />
        {arrayAccount.map((account, index) => (
          <BodyRow key={index} data={account} onClick={onClick} />
        ))}
      </Box>
      <ConfirmDialog
        isShow={
          dialogOpen.option === OPTION_ONCLICK.isDisabled ||
          dialogOpen.option === OPTION_ONCLICK.isResetPassword ||
          dialogOpen.option === OPTION_ONCLICK.isCloseChange
        }
        title={formatTitle(dialogOpen.option, dialogOpen.data.state)}
        message={formatMessage(dialogOpen.option, dialogOpen.data)}
        onSubmit={onSubmit}
        onClose={onClose}
      />
      <DialogAccount
        isShow={dialogOpen.option === OPTION_ONCLICK.isEdit || dialogOpen.option === OPTION_ONCLICK.isCreate}
        onClose={onClose}
        data={dialogOpen.data}
        onSubmit={onSubmit}
        onChange={() => setIsChangeDataEdit(true)}
      />
    </Box>
  );
};

export default memo(ManageAccount);

const OPTION_ONCLICK = {
  isClose: 0,
  isEdit: 1,
  isResetPassword: 2,
  isDisabled: 3,
  isCreate: 4,
  isCloseChange: 5,
};

const DEFAULT_DIALOG_DATA = {
  data: {},
  option: OPTION_ONCLICK.isClose,
};

const formatArrayAccount = arrayAccount => {
  let dataArrayAccount = arrayAccount.map((blog, index) => ({
    ...blog,
    number: index + 1,
  }));
  return dataArrayAccount;
};

const formatTitle = (option, state) => {
  let title = "";
  switch (option) {
    case OPTION_ONCLICK.isDisabled:
      if (state === AppConstant.STATUS_ACCOUNT.inactive) {
        title = "Kích hoạt tài khoản";
      } else {
        title = "Vô hiệu hóa tài khoản";
      }
      break;
    case OPTION_ONCLICK.isResetPassword:
      title = "Reset mật khẩu tài khoản";
      break;
    default:
      title = "Lưu thay đổi";
  }
  return title;
};
const formatMessage = (option, data) => {
  let message = "";
  switch (option) {
    case OPTION_ONCLICK.isDisabled:
      if (data.state === AppConstant.STATUS_ACCOUNT.inactive) {
        message = StringFormat(LangConstant.FM_CONTENT_ENABLE, data.email);
      } else {
        message = StringFormat(LangConstant.FM_CONTENT_DISABLE, data.email);
      }
      break;
    case OPTION_ONCLICK.isResetPassword:
      message = StringFormat(LangConstant.FM_CONTENT_RESET, data.email);
      break;
    default:
      message = LangConstant.NOTIFY_UNSAVED;
  }
  return message;
};

const useStyles = makeStyles(() => ({
  rootTable: {
    width: "100%",
    height: "calc(100vh - 190px)",
    padding: "24px 32px 24px 24px",
    overflow: "auto",
  },
}));
