import { ApiConstant } from "const";
import { call, put, select } from "redux-saga/effects";
import ShopAction from "redux/shop.redux";
import { ShopService } from "services";
import { AppConstant } from "const";
import { toCamel, formatPage, toSnake } from "utils";

export function* getListShop(action) {
  try {
    const {
      shopRedux: {
        data: { ...paginationShop },
      },
    } = yield select();
    const { data } = action;

    let formDataPage = formatPage(paginationShop);

    let response = yield call(ShopService.getListShop, { ...formDataPage, ...data });
    if (response.status === ApiConstant.STT_OK) {
      let responseData = toCamel(response.data);
      yield put(ShopAction.shopSuccess({ data: responseData }));
    } else {
      let responseData = response.data.error;
      yield put(ShopAction.shopFailure({ error: responseData }));
    }
  } catch (error) {
    yield put(ShopAction.shopFailure(error));
  }
}

export function* getCountReservation(action) {
  try {
    const { data } = action;

    let response = yield call(ShopService.getShopReservationCount, data);
    if (response.status === ApiConstant.STT_OK) {
      let responseData = toCamel(response.data.data);
      yield put(ShopAction.shopSuccess({ countReservation: responseData }));
    } else {
      let responseData = response.data.error;
      yield put(ShopAction.shopFailure({ error: responseData }));
    }
  } catch (error) {
    yield put(ShopAction.shopFailure(error));
  }
}

export function* getShopRegistration(action) {
  try {
    const {
      shopRedux: {
        shopRegistration: { ...paginationShop },
      },
    } = yield select();
    const { data } = action;

    let formDataPage = formatPage(paginationShop);

    let response = yield call(ShopService.getShopRegistration, { ...formDataPage, ...data });
    let responseData = toCamel(response.data);
    if (response.status === ApiConstant.STT_OK) {
      yield put(ShopAction.shopSuccess({ shopRegistration: responseData }));
    } else {
      yield put(ShopAction.shopFailure({ error: responseData.error }));
    }
  } catch (error) {
    yield put(ShopAction.shopFailure(error));
  }
}

export function* putShopRegistration(action) {
  try {
    const {
      shopRedux: {
        shopRegistration: { data: dataCurrent, ...pagination },
      },
    } = yield select();
    const { data } = action;

    let response = yield call(ShopService.putShopRegistration, data);
    if (response.status === ApiConstant.STT_OK) {
      let newData = dataCurrent.map(item => ({
        ...item,
        state: data.includes(item.id) ? AppConstant.STATE_REGISTRATION.contacted : item.state,
      }));
      yield put(
        ShopAction.shopSuccess({
          shopRegistration: {
            data: newData,
            ...pagination,
          },
        }),
      );
    } else {
      yield put(ShopAction.shopFailure({ error: response.data }));
    }
  } catch (error) {
    yield put(ShopAction.shopFailure(error));
  }
}

export function* getShopLog(action) {
  try {
    const {
      shopRedux: {
        shopLog: { ...paginationShop },
      },
    } = yield select();
    const { data } = action;

    let formDataPage = formatPage(paginationShop);

    let response = yield call(ShopService.getShopLog, { ...formDataPage, ...data });
    let responseData = toCamel(response.data);
    if (response.status === ApiConstant.STT_OK) {
      yield put(ShopAction.shopSuccess({ shopLog: responseData }));
    } else {
      yield put(ShopAction.shopFailure({ error: responseData.error }));
    }
  } catch (error) {
    yield put(ShopAction.shopFailure(error));
  }
}

export function* putShopDetail(action) {
  try {
    let { data } = action;
    let response = yield call(ShopService.putShopDetail, toSnake(data));
    let responseData = toCamel(response.data);
    if (response.status === ApiConstant.STT_OK) {
      yield put(ShopAction.shopSuccess({ isUpdateSuccess: true }));
    } else {
      yield put(ShopAction.shopFailure({ error: responseData.error }));
    }
  } catch (error) {
    yield put(ShopAction.shopFailure(error));
  }
}

export function* postShop(action) {
  try {
    let { data } = action;
    let response = yield call(ShopService.postShop, toSnake(data));
    let responseData = toCamel(response.data);
    if (response.status === ApiConstant.STT_OK) {
      yield put(ShopAction.shopSuccess({ isCreateSuccess: true }));
    } else {
      yield put(ShopAction.shopFailure({ error: responseData.error }));
    }
  } catch (error) {
    yield put(ShopAction.shopFailure(error));
  }
}
