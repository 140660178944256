import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, Paper, Box, Grid } from "@material-ui/core";
import clsx from "clsx";
import { HeaderTab, LANG_OPTION, InformationSlide, BackgroundSlide, DialogPreview } from "../components";

const TabSlideInfo = props => {
  const { classes, isReset, data, onChangeFormData, onResetSuccess, isDisplayPreview, onClosePreview } = props;
  const defaultClasses = useStyles();

  const [tabValue, setTabValue] = useState(LANG_OPTION.vi);
  const [formData, setFormData] = useState(data);

  const onChangeTab = value => {
    setTabValue(value);
  };

  const onChange = e => {
    let newFormData = {
      ...formData,
      [e.target.name]: {
        ...formData[e.target.name],
        [tabValue]: e.target.value,
      },
    };
    setFormData(newFormData);
    onChangeFormData(newFormData);
  };

  const onChangeBackground = (data, name) => {
    let newFormData = {
      ...formData,
      [name]: data,
    };
    setFormData(newFormData);
    onChangeFormData(newFormData);
  };

  useEffect(() => {
    if (isReset && !data?.id) {
      setFormData(data);
      onResetSuccess();
    }
  }, [isReset]);

  return (
    <Box className={clsx(defaultClasses.root, classes?.root)}>
      <Paper className={defaultClasses.paper}>
        <HeaderTab tabValue={tabValue} onChangeTab={onChangeTab} />
        <Grid container spacing={3} className={defaultClasses.contentSlide}>
          <InformationSlide
            data={{
              title: formData.title[tabValue],
              content: formData.content[tabValue],
            }}
            onChange={onChange}
            langValue={tabValue}
          />
          <BackgroundSlide data={formData} onChange={onChangeBackground} langValue={tabValue} />
        </Grid>
        {isDisplayPreview && (
          <DialogPreview
            isShow={isDisplayPreview}
            data={formData}
            tabValue={tabValue}
            onChangeLanguage={onChangeTab}
            onClose={onClosePreview}
          />
        )}
      </Paper>
    </Box>
  );
};

TabSlideInfo.propTypes = {
  classes: PropTypes.object,
  isDisplayPreview: PropTypes.bool,
  isReset: PropTypes.bool,
  data: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.shape({
      vi: PropTypes.string,
      en: PropTypes.string,
    }),
    content: PropTypes.shape({
      vi: PropTypes.string,
      en: PropTypes.string,
    }),
    previewImage: PropTypes.shape({
      vi: PropTypes.string,
      en: PropTypes.string,
    }),
    previewImageStatus: PropTypes.number,
    background: PropTypes.shape({
      color: PropTypes.shape({
        vi: PropTypes.string,
        en: PropTypes.string,
      }),
      image: PropTypes.shape({
        vi: PropTypes.string,
        en: PropTypes.string,
      }),
      video: PropTypes.shape({
        vi: PropTypes.string,
        en: PropTypes.string,
      }),
    }),
    backgroundType: PropTypes.number,
    created: PropTypes.number,
    publishedTime: PropTypes.number,
    sortOrder: PropTypes.number,
    published: PropTypes.number,
  }),
  onResetSuccess: PropTypes.func,
  onChangeFormData: PropTypes.func,
  onClosePreview: PropTypes.func,
};

TabSlideInfo.defaultProps = {
  onChangeFormData: () => {},
  onResetSuccess: () => {},
  onClosePreview: () => {},
};

export default memo(TabSlideInfo);

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    padding: 16,
  },
  paper: {
    padding: "24px 18px 34px",
    boxShadow: "0 1px 6px 0 rgba(0, 0, 0, 0.16)",
  },
  contentSlide: {
    paddingTop: 36,
  },
  numberWord: {
    marginTop: 12,
    color: "#3e4045",
    lineHeight: "22px",
  },
}));
