import React, { memo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles, Box, Typography, OutlinedInput } from "@material-ui/core";
import { SelectedItem } from "components";
import BlogsCategoryAction from "redux/blogs-category.redux";
import clsx from "clsx";

const FilterLayout = props => {
  const { statusValue, ...propsCategory } = props;
  const defaultClasses = useStyles();
  const dispatch = useDispatch();

  const listCategory = useSelector(state => state.blogsCategoryRedux.data);

  const [arrayCategory, setArrayCategory] = useState([]);

  useEffect(() => {
    dispatch(BlogsCategoryAction.getBlogsCategoryList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let dataFormat = listCategory.map(dataMap => ({
      value: dataMap?.id,
      name: dataMap?.title?.vi,
    }));
    setArrayCategory(dataFormat);
  }, [listCategory]);

  return (
    <Box className={defaultClasses.root}>
      <Box className={defaultClasses.categoryFilter}>
        <Typography className={clsx("bold-sm-txt", defaultClasses.titleFilter)}>Danh mục</Typography>
        <SelectedItem {...propsCategory} data={arrayCategory} />
      </Box>
      <Box className={defaultClasses.statusContent}>
        <Typography className={clsx("bold-sm-txt", defaultClasses.titleFilter)}>Trạng thái</Typography>
        <OutlinedInput
          value={statusValue}
          disabled
          classes={{
            root: clsx("medium-md-txt", defaultClasses.outlinedStatus),
            disabled: defaultClasses.outlinedDisabled,
          }}
        />
      </Box>
    </Box>
  );
};

FilterLayout.propTypes = {
  statusValue: PropTypes.string,
};

FilterLayout.defaultProps = {};

export default memo(FilterLayout);

const useStyles = makeStyles(theme => ({
  root: {
    width: "70%",
    minWidth: 600,
    display: "flex",
  },
  categoryFilter: {
    width: "50%",
    padding: "16px 10px",
    marginRight: 24,
    backgroundColor: "#fafafb",
  },
  statusContent: {
    width: "50%",
    padding: "16px 10px",
    backgroundColor: "#fafafb",
  },
  titleFilter: {
    marginBottom: 8,
    lineHeight: "20px",
    color: "#3e4045",
  },
  outlinedStatus: {
    height: 40,
    maxHeight: 40,
    width: "100%",
  },
  outlinedDisabled: {
    color: "#3b404c",
  },
}));
