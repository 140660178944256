import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getListAccount: [],
  createAccount: ["data"],
  updateAccount: ["data"],
  getAccountDetails: ["data"],
  changePassword: ["data"],

  accountSuccess: ["data"],
  accountFailure: ["error"],
  resetAccount: [],
});

export const AccountTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  data: [],
  accountDetails: null,
  isSuccess: false,
  isCreateSuccess: false,
  isUpdateSuccess: false,
  isChangePasswordSuccess: false,
  isFetching: false,
  error: null,
};

/* ------------- Reducers ------------- */
export const request = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
});

export const success = (state = INITIAL_STATE, action) => {
  let data = action.data || {};
  return {
    ...state,
    isFetching: false,
    isSuccess: true,
    ...data,
  };
};

export const failure = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: false,
});

export const reset = (state = INITIAL_STATE) => ({
  ...state,
  isSuccess: false,
  isCreateSuccess: false,
  isUpdateSuccess: false,
});

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.GET_LIST_ACCOUNT]: request,
  [Types.CREATE_ACCOUNT]: request,
  [Types.UPDATE_ACCOUNT]: request,
  [Types.GET_ACCOUNT_DETAILS]: request,
  [Types.CHANGE_PASSWORD]: request,

  [Types.ACCOUNT_SUCCESS]: success,
  [Types.ACCOUNT_FAILURE]: failure,
  [Types.RESET_ACCOUNT]: reset,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
