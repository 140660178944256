import { ApiConstant } from "const";
import { call, put, select } from "redux-saga/effects";
import FeatureAction from "redux/feature.redux";
import { FeatureService } from "services";
import { toCamel, toSnake } from "utils";

export function* getListHomeFeature() {
  try {
    let response = yield call(FeatureService.getHomeFeature);
    if (response.status === ApiConstant.STT_OK) {
      let responseData = toCamel(response.data.data);
      yield put(FeatureAction.homeFeatureSuccess({ data: responseData }));
    } else {
      let responseData = response.data?.error;
      yield put(FeatureAction.homeFeatureFailure(responseData));
    }
  } catch (error) {
    yield put(FeatureAction.homeFeatureFailure(error));
  }
}

export function* createHomeFeature(action) {
  try {
    const {
      featureRedux: { data: reduxData },
    } = yield select();
    const { data } = action;
    let formData = { ...data };
    if (!formData?.id) {
      delete formData["id"];
    }
    let response = yield call(FeatureService.postHomeFeature, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      let newData = [...reduxData, toCamel(response.data.data)];
      yield put(FeatureAction.homeFeatureSuccess({ data: newData, isSuccess: true }));
    } else {
      let responseData = response.data?.error;
      yield put(FeatureAction.homeFeatureFailure(responseData));
    }
  } catch (error) {
    yield put(FeatureAction.homeFeatureFailure(error));
  }
}

export function* updateHomeFeature(action) {
  try {
    const {
      featureRedux: { data: reduxData },
    } = yield select();
    const { data } = action;

    let response = yield call(FeatureService.putHomeFeature, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      let selectedIndex = reduxData.findIndex(key => key.id === data.id);

      let updateArray = [...reduxData];
      updateArray[selectedIndex] = Object.assign(updateArray[selectedIndex], toCamel(response.data.data));

      yield put(FeatureAction.homeFeatureSuccess({ data: updateArray, isSuccess: true }));
    } else {
      let responseData = response.data?.error;
      yield put(FeatureAction.homeFeatureFailure(responseData));
    }
  } catch (error) {
    yield put(FeatureAction.homeFeatureFailure(error));
  }
}

export function* deleteHomeFeature(action) {
  try {
    const { data } = action;

    let response = yield call(FeatureService.deleteHomeFeature, data);
    if (response.status === ApiConstant.STT_OK) {
      // TODO: Logic
      yield put(FeatureAction.homeFeatureSuccess());
    } else {
      let responseData = response.data?.error;
      yield put(FeatureAction.homeFeatureFailure(responseData));
    }
  } catch (error) {
    yield put(FeatureAction.homeFeatureFailure(error));
  }
}
