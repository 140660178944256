import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, makeStyles } from "@material-ui/core";
import { AppDialog } from "components";
import { AppConstant } from "const";
import ChangePackageDialogBody from "./ChangePackageDialogBody";
import ChangePackageDialogHeader from "./ChangePackageDialogHeader";
import { createCloneObj } from "utils";

const ChangePackageDialog = props => {
  const classes = useStyles();
  const { data, onClose, ...otherProps } = props;

  const [packageDetail, setPackageDetail] = useState(createCloneObj(DEFAULT_PACKAGE_DETAIL));

  const onAddNewFeature = selectedItem => {
    let cloneData = createCloneObj(packageDetail);
    let newFeatureObj = {};

    if (selectedItem) {
      newFeatureObj = getNewFeatureDetailData(selectedItem, cloneData);
    }

    cloneData.packageDetails.push(newFeatureObj);
    setPackageDetail(cloneData);
  };

  const onHandle = data => {
    let cloneData = createCloneObj(packageDetail);

    if (Array.isArray(data)) {
      let packageDetailIndex = data.findIndex(item => item.type === AppConstant.PACKAGE_DETAIL_TYPE.package);

      if (packageDetailIndex > -1) {
        cloneData.startDate = data[packageDetailIndex].startDate;
        cloneData.expiryDate = data[packageDetailIndex].expiryDate;
        cloneData.total = data[packageDetailIndex].total;
        cloneData.plan = data[packageDetailIndex].plan;
        cloneData.period = Number(data[packageDetailIndex].period);
      }

      // Update total price of package
      let sum = 0;
      data.forEach(item => (sum += item.total));
      cloneData.totalPrice = sum;
      setPackageDetail({ ...cloneData, packageDetails: data });
    } else onClose();
  };

  useEffect(() => {
    let isValidData = data && data.userId;
    if (isValidData) {
      setPackageDetail({ ...DEFAULT_PACKAGE_DETAIL, ...data });
    }
  }, [data]);

  return (
    <AppDialog title="Thay đổi gói dịch vụ" onClose={onClose} {...otherProps}>
      <Box className={classes.root}>
        <ChangePackageDialogHeader data={packageDetail} onChange={onAddNewFeature} />
        <ChangePackageDialogBody data={packageDetail} onCallback={onHandle} />
      </Box>
    </AppDialog>
  );
};

ChangePackageDialog.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.object,

  onClose: PropTypes.func.isRequired,
};

export default ChangePackageDialog;

const DEFAULT_PACKAGE_DETAIL = {
  userId: 0,
  plan: 0,
  orderNumber: 0,
  period: 0,
  startDate: 0,
  expiryDate: 0,
  price: 0,
  total: 0,
  totalPrice: 0,
  packageDetails: [],
};

const getNewFeatureDetailData = (newSelected, data) => {
  let result = {};

  if (data && data.userId && newSelected) {
    result = {
      orderNumber: 1,
      period: 0,
      price: 0,
      startDate: data.startDate,
      expiryDate: data.expiryDate,
      total: 0,
      type: newSelected.type,
    };
    if (newSelected.isPackage) result.plan = newSelected.plan;
  }
  return result;
};

const useStyles = makeStyles({
  root: {
    width: 1000,
    maxWidth: "100%",
    padding: "16px 24px",
  },
});
