import React, { memo } from "react";
import { SvgIcon } from "@material-ui/core";

const WebsiteIcon = ({ className }) => {
  return (
    <SvgIcon className={className}>
      <path
        fill="#F1F3F6"
        fillRule="evenodd"
        d="M.033 24.05c0 13.074 10.637 23.711 23.711 23.711 13.075 0 23.71-10.637 23.71-23.711C47.455 10.976 36.82.34 23.745.34 10.67.34.034 10.975.034 24.05zm1.157 0c0-12.436 10.117-22.554 22.554-22.554 12.437 0 22.554 10.118 22.554 22.554 0 12.437-10.117 22.555-22.554 22.555-12.437 0-22.554-10.118-22.554-22.555z"
        clipRule="evenodd"
      />
      <circle
        cx="23.999"
        cy="24"
        r="10.909"
        stroke="#B1B8C2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.8"
      />
      <path
        stroke="#B1B8C2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.8"
        d="M31.131 16.028c-1.915 1.565-4.404 2.518-7.133 2.518s-5.218-.942-7.133-2.507M16.865 31.972c1.915-1.565 4.404-2.517 7.133-2.517s5.218.952 7.133 2.517"
      />
      <path
        fillRule="evenodd"
        stroke="#B1B8C2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.8"
        d="M23.998 13.09c-2.757 2.176-4.615 6.25-4.615 10.91s1.858 8.734 4.615 10.91c2.757-2.176 4.616-6.25 4.616-10.91s-1.859-8.734-4.616-10.91z"
        clipRule="evenodd"
      />
      <path stroke="#B1B8C2" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.8" d="M13.09 24.05h21.818" />
    </SvgIcon>
  );
};

export default memo(WebsiteIcon);
