import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Typography } from "@material-ui/core";
import clsx from "clsx";

const BGlobalJSC = ({ classes }) => {
  const defaultClasses = useStyles();

  return (
    <Box className={clsx(defaultClasses.root, classes?.root)}>
      <Typography variant="body1" classes={{ body1: "regular-sm-txt" }} color="inherit">
        Copyright © bGlobal Jsc. All Rights Reserved
      </Typography>
    </Box>
  );
};

BGlobalJSC.propTypes = {
  classes: PropTypes.object,
};

BGlobalJSC.defaultProps = {};

export default memo(BGlobalJSC);

const useStyles = makeStyles({
  root: {
    width: "100%",
    minHeight: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
