import React, { memo, useEffect, useState } from "react";
import { ApiConstant } from "const";
import { Alert } from "components";
import { useSelector, useDispatch } from "react-redux";
import ErrorAction from "redux/error.redux";
import { getError } from "utils";

const ErrorHandling = () => {
  const dispatch = useDispatch();

  // Get data from redux
  const errorRedux = useSelector(({ errorRedux }) => errorRedux);

  // Create state
  const [isError, setIsError] = useState(false);
  const [errorContent, setErrorContent] = useState("");
  useEffect(() => {
    const { status } = errorRedux;
    let errorContent = getError(errorRedux);
    let isAuthenticate = status === ApiConstant.STT_UNAUTHORIZED || status === ApiConstant.STT_FORBIDDEN;
    setIsError(Boolean(isAuthenticate || errorContent));
    setErrorContent(isAuthenticate ? "Bạn cần đăng nhập lại" : "Có lỗi không mong muốn xảy ra!");
  }, [errorRedux]);

  useEffect(() => {
    if (!isError && errorContent) {
      setTimeout(() => {
        // After show error, reset
        dispatch(ErrorAction.resetError());
      }, 1000);
    }
  }, [isError, errorContent, dispatch]);

  return <Alert isShow={isError} onClose={() => setIsError(false)} type="error" message={errorContent} />;
};

export default memo(ErrorHandling);
