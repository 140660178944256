import React, { memo } from "react";
import { Redirect, Route } from "react-router-dom";
import { PathConstant } from "const";
import PropTypes from "prop-types";
import { hasLogin } from "utils";

const AuthenticationRoute = props => {
  const { component: Component, ...rest } = props;
  // Check authentication with the page need to be protected
  const isChecked = hasLogin();

  return isChecked ? (
    <Route {...rest} render={matchProps => <Component {...matchProps} />} />
  ) : (
    <Redirect
      to={{
        pathname: PathConstant.LOGIN,
        state: {
          from: rest.path,
        },
      }}
    />
  );
};

AuthenticationRoute.propTypes = {
  component: PropTypes.any.isRequired,
  path: PropTypes.string.isRequired,
};

export default memo(AuthenticationRoute);
