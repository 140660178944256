import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles, Paper, Button, Box, Typography, Checkbox } from "@material-ui/core";
import clsx from "clsx";
import { AppConstant } from "const";
import HeaderSelector from "./HeaderSelector";

const HeaderBar = props => {
  const { data, onSelectCategory, onCheckDisplay, onSaveDraft } = props;
  const defaultClasses = useStyles();

  return (
    <Paper className={defaultClasses.root}>
      <Box className={defaultClasses.header}>
        <Typography className={clsx("semiBold-md-txt", defaultClasses.title)}>Thông tin câu hỏi</Typography>
        <Box className={defaultClasses.headerOption}>
          <Typography className={clsx("medium-md-txt", defaultClasses.title)}>Hiển thị trên HomePage</Typography>
          <Checkbox
            checked={data.display === AppConstant.STATUS_DISPLAY_HOME_PAGE.display}
            onChange={onCheckDisplay}
            color="primary"
            size="small"
            className={defaultClasses.checkBox}
          />
          <Button
            onClick={onSaveDraft}
            size="small"
            color="primary"
            variant="outlined"
            className={defaultClasses.buttonSaveDraft}
          >
            Lưu nháp
          </Button>
        </Box>
      </Box>
      <HeaderSelector
        data={{ category: data.category, state: data.state, createdTime: data?.createdTime }}
        onChangeSelected={onSelectCategory}
      />
    </Paper>
  );
};

HeaderBar.propTypes = {
  data: PropTypes.shape({
    category: PropTypes.any,
    state: PropTypes.number,
    createdTime: PropTypes.number,
    display: PropTypes.number,
  }),
  onSelectCategory: PropTypes.func,
  onCheckDisplay: PropTypes.func,
  onSaveDraft: PropTypes.func,
};

HeaderBar.defaultProps = {
  onSelectCategory: () => {},
  onCheckDisplay: () => {},
  onSaveDraft: () => {},
};

export default memo(HeaderBar);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    padding: "11px 34px",
    borderRadius: 0,
    boxShadow: "0 1px 6px 0 rgba(0, 0, 0, 0.16)",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 30,
  },
  headerOption: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    lineHeight: 1.57,
    color: "#3e4045",
  },
  checkBox: {
    width: 16,
    height: 16,
    padding: 0,
    marginLeft: 6,
  },
  buttonSaveDraft: {
    fontWeight: 600,
    marginLeft: 8,
    "&:hover": {
      color: theme.palette.white,
      backgroundColor: theme.palette.primary.main,
    },
  },
}));
