import React, { memo } from "react";
import { SvgIcon } from "@material-ui/core";

const GoogleIcon = ({ className }) => {
  return (
    <SvgIcon className={className}>
      <mask id="qvkn12va3a" width="48" height="47" x="1" y="1" maskUnits="userSpaceOnUse">
        <path fill="#fff" fillRule="evenodd" d="M1 1.817h47.346v46.067H1V1.817z" clipRule="evenodd" />
      </mask>
      <g mask="url(#qvkn12va3a)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M31.427 32.407c-1.707 1.532-4.027 2.426-6.787 2.426-4.013 0-7.453-2.243-9.147-5.5-.693-1.362-1.093-2.88-1.093-4.489 0-1.607.4-3.126 1.093-4.474 1.68-3.258 5.134-5.502 9.147-5.502 2.76 0 5.08.986 6.853 2.594L28.6 20.214c-.747-.676-2.04-1.493-3.96-1.493-2.707 0-5.013 1.751-5.84 4.151-.2.623-.333 1.286-.333 1.972 0 .688.133 1.363.346 1.972.814 2.413 3.12 4.153 5.827 4.153 1.52 0 2.68-.417 3.547-1 1.373-.935 1.986-2.31 2.093-3.23h-5.64v-3.71h9.613c.147.622.214 1.218.214 2.036 0 3.048-1.107 5.604-3.04 7.342zm-6.76-30.59C11.6 1.817 1 12.13 1 24.844c0 12.727 10.6 23.041 23.667 23.041 13.08 0 23.68-10.314 23.68-23.041 0-12.714-10.6-23.027-23.68-23.027z"
          clipRule="evenodd"
        />
      </g>
      <circle cx="25" cy="25" r="24" stroke="#F1F3F6" />
      <path
        fill="#B1B8C2"
        fillRule="evenodd"
        d="M34.467 25.064c0 3.05-1.107 5.605-3.04 7.343-1.707 1.532-4.027 2.426-6.787 2.426-4.013 0-7.453-2.243-9.146-5.5-.694-1.363-1.094-2.88-1.094-4.49 0-1.607.4-3.126 1.094-4.474l3.306 2.504c-.2.622-.333 1.284-.333 1.97 0 .688.133 1.364.347 1.974.813 2.412 3.12 4.151 5.826 4.151 1.52 0 2.68-.416 3.547-1 1.373-.933 1.987-2.31 2.093-3.23h-5.64v-3.71h9.614c.146.622.213 1.219.213 2.036z"
        clipRule="evenodd"
      />
      <path
        fill="#B1B8C2"
        fillRule="evenodd"
        d="M31.494 17.463l-2.893 2.75c-.747-.674-2.04-1.492-3.96-1.492-2.707 0-5.013 1.75-5.84 4.152l-3.307-2.504c1.68-3.258 5.134-5.5 9.147-5.5 2.76 0 5.08.985 6.853 2.594z"
        clipRule="evenodd"
      />
    </SvgIcon>
  );
};

export default memo(GoogleIcon);
