import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Dialog, Typography, Button, IconButton } from "@material-ui/core";
import { DesktopMac, TabletMac, Tablet, PhoneIphone, Language, Close } from "@material-ui/icons";
import clsx from "clsx";
import { SelectBar } from "components";
import { LangConstant, ApiConstant, AppConstant } from "const";

const DialogPreview = props => {
  const { isShow, data, tabValue, onChangeLanguage, onClose } = props;
  const defaultClasses = useStyles({
    color: data.backgroundType === AppConstant.BACKGROUND_OPTION.color ? data.background?.color[tabValue] : "",
    image:
      data.backgroundType === AppConstant.BACKGROUND_OPTION.image
        ? ApiConstant.HOST_ATTACHMENT + data.background?.image[tabValue]
        : "",
  });
  const arrayDevices = arrayDevice(defaultClasses.icon);
  const arrayLanguages = arrayLanguage(defaultClasses.icon);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedLanguageIndex, setSelectedLanguageIndex] = useState(
    arrayLanguages.findIndex(item => item.code === tabValue),
  );
  const [formClasses, setFormClasses] = useState({
    dialogBody: defaultClasses.dialogBodyDesktop,
    bodyLeft: defaultClasses.bodyLeft,
    title: defaultClasses.titleDesktop,
    content: defaultClasses.contentDesktop,
    bodyRight: defaultClasses.bodyRight,
  });

  const onChangeSelectedIndex = index => {
    let objClasses = convertObjectClasses(defaultClasses, arrayDevices[index].name);
    setSelectedIndex(index);
    setFormClasses(objClasses);
  };
  const onChangeTabLanguage = index => {
    setSelectedLanguageIndex(index);
    onChangeLanguage(arrayLanguages[index].code);
  };

  return (
    <Dialog open={isShow} fullScreen classes={{ paper: defaultClasses.dialogPaper }}>
      <Box className={defaultClasses.root}>
        <Box className={defaultClasses.dialogHeader}>
          <Typography className={clsx("medium-md-txt", defaultClasses.textPreview)}>Gboss Preview</Typography>
          <Box display="flex">
            <Box marginRight="8px">
              <SelectBar onChange={onChangeTabLanguage} arrayItem={arrayLanguages} indexItem={selectedLanguageIndex} />
            </Box>
            <SelectBar onChange={onChangeSelectedIndex} arrayItem={arrayDevices} indexItem={selectedIndex} />
            <IconButton className={defaultClasses.buttonClose} onClick={onClose}>
              <Close className={defaultClasses.iconClose} />
            </IconButton>
          </Box>
        </Box>
        <Box className={formClasses.dialogBody}>
          <Box className={formClasses.bodyLeft}>
            <Typography className={formClasses.title}>{data.title[tabValue]}</Typography>
            <Box className={formClasses.content}>
              <Typography className={defaultClasses.content}>{data.content[tabValue]}</Typography>
            </Box>

            <Box display="flex">
              <Button
                className={clsx(
                  defaultClasses.buttonSignUp,
                  arrayDevices[selectedIndex].name === NAME_DEVICE.mobile && defaultClasses.overrideMobile,
                )}
                variant="contained"
                color="primary"
              >
                Sign up for free
              </Button>
              <Button className={defaultClasses.buttonView} variant="outlined">
                View Pricing
              </Button>
            </Box>
          </Box>
          <Box className={formClasses.bodyRight}>
            {data.previewImageStatus === AppConstant.STATUS_PREVIEW.display && (
              <img
                src={data.previewImage[tabValue] ? ApiConstant.HOST_ATTACHMENT + data.previewImage[tabValue] : ""}
                alt="..."
                className={defaultClasses.img}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

DialogPreview.propTypes = {
  isShow: PropTypes.bool,
  tabValue: PropTypes.string,
  data: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.shape({
      vi: PropTypes.string,
      en: PropTypes.string,
    }),
    content: PropTypes.shape({
      vi: PropTypes.string,
      en: PropTypes.string,
    }),
    previewImage: PropTypes.shape({
      vi: PropTypes.string,
      en: PropTypes.string,
    }),
    previewImageStatus: PropTypes.number,
    background: PropTypes.shape({
      color: PropTypes.shape({
        vi: PropTypes.string,
        en: PropTypes.string,
      }),
      image: PropTypes.shape({
        vi: PropTypes.string,
        en: PropTypes.string,
      }),
      video: PropTypes.shape({
        vi: PropTypes.string,
        en: PropTypes.string,
      }),
    }),
    backgroundType: PropTypes.number,
    created: PropTypes.number,
    publishedTime: PropTypes.number,
    sortOrder: PropTypes.number,
    published: PropTypes.number,
  }),
  onChangeLanguage: PropTypes.func,
  onClose: PropTypes.func,
};

DialogPreview.defaultProps = {
  isShow: false,
  onChangeLanguage: () => {},
  onClose: () => {},
};

export default memo(DialogPreview);

const NAME_DEVICE = {
  desktop: "Desktop",
  ipadPro: "Ipad Pro",
  ipad: "Ipad",
  mobile: "Mobile",
};

const convertObjectClasses = (defaultClasses, name) => {
  let objClasses = {
    dialogBody: defaultClasses.dialogBodyDesktop,
    bodyLeft: defaultClasses.bodyLeft,
    title: defaultClasses.titleDesktop,
    content: defaultClasses.contentDesktop,
    bodyRight: defaultClasses.bodyRight,
  };

  switch (name) {
    case NAME_DEVICE.ipadPro:
      objClasses.dialogBody = defaultClasses.dialogBodyIpadPro;
      objClasses.title = defaultClasses.titleIpadPro;
      objClasses.content = defaultClasses.contentIpadPro;
      break;
    case NAME_DEVICE.ipad:
      objClasses.dialogBody = defaultClasses.dialogBodyIpad;
      objClasses.bodyLeft = defaultClasses.bodyLeftColumn;
      objClasses.title = defaultClasses.titleIpad;
      objClasses.content = defaultClasses.contentIpad;
      objClasses.bodyRight = defaultClasses.bodyRightColumn;
      break;
    case NAME_DEVICE.mobile:
      objClasses.dialogBody = defaultClasses.dialogBodyMobile;
      objClasses.bodyLeft = defaultClasses.bodyLeftColumn;
      objClasses.title = defaultClasses.titleMobile;
      objClasses.content = defaultClasses.contentMobile;
      objClasses.bodyRight = defaultClasses.bodyRightColumn;
      break;
    default:
      break;
  }
  return objClasses;
};

const arrayLanguage = className => {
  let ARR_LANGUAGE = [
    {
      name: LangConstant.OBJ_LANGUAGE.vi.lang,
      icon: <Language className={className} />,
      code: LangConstant.OBJ_LANGUAGE.vi.code,
    },
    {
      name: LangConstant.OBJ_LANGUAGE.en.lang,
      icon: <Language className={className} />,
      code: LangConstant.OBJ_LANGUAGE.en.code,
    },
  ];
  return ARR_LANGUAGE;
};

const arrayDevice = className => {
  let ARR_DEVICE = [
    {
      name: NAME_DEVICE.desktop,
      icon: <DesktopMac className={className} />,
    },
    {
      name: NAME_DEVICE.ipadPro,
      icon: <Tablet className={className} />,
    },
    {
      name: NAME_DEVICE.ipad,
      icon: <TabletMac className={className} />,
    },
    {
      name: NAME_DEVICE.mobile,
      icon: <PhoneIphone className={className} />,
    },
  ];
  return ARR_DEVICE;
};

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    width: "100%",
    borderRadius: 0,
  },
  root: {
    width: "100%",
    height: "100%",
    backgroundColor: "#3e4045",
  },
  dialogHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 46,
    padding: "0px 24px",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  textPreview: {
    height: 22,
    lineHeight: 1.57,
    color: theme.palette.white,
  },
  icon: {
    height: 22,
    width: 22,
    marginRight: 8,
  },
  buttonClose: {
    width: 46,
    height: 46,
    marginLeft: 8,
    borderRadius: 0,
    backgroundColor: theme.palette.black,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  iconClose: {
    width: 46,
    height: 46,
    padding: 7,
    color: theme.palette.white,
  },
  dialogBodyDesktop: {
    display: "flex",
    height: "calc(100vh - 46px)",
    backgroundColor: props => props?.color || "#0e151f",
    backgroundImage: props => `url(${props?.image || ""})`,
    padding: "0px 120px",
  },
  dialogBodyIpadPro: {
    display: "flex",
    height: "calc(100vh - 46px)",
    width: 1024,
    backgroundColor: props => props?.color || "#0e151f",
    backgroundImage: props => `url(${props?.image || ""})`,
    padding: "0px 32px",
    margin: "auto",
  },
  dialogBodyIpad: {
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    height: "calc(100vh - 46px)",
    width: 768,
    backgroundColor: props => props?.color || "#0e151f",
    backgroundImage: props => `url(${props?.image || ""})`,
    padding: "0px 28px",
    margin: "auto",
  },
  dialogBodyMobile: {
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    height: "calc(100vh - 46px)",
    width: 518,
    backgroundColor: props => props?.color || "#0e151f",
    backgroundImage: props => `url(${props?.image || ""})`,
    padding: "0px 89px",
    margin: "auto",
  },
  bodyLeft: {
    width: "40%",
  },
  bodyLeftColumn: {
    width: "100%",
  },
  titleDesktop: {
    marginTop: 78,
    color: theme.palette.white,
    wordBreak: "break-word",
    fontSize: 50,
    lineHeight: 1.2,
    letterSpacing: "0.2px",
    fontWeight: "bold",
  },

  titleIpadPro: {
    marginTop: 60,
    color: theme.palette.white,
    wordBreak: "break-word",
    fontSize: 42,
    lineHeight: 1.2,
    letterSpacing: "0.2px",
    fontWeight: "bold",
  },
  titleIpad: {
    maxWidth: 582,
    marginTop: 60,
    color: theme.palette.white,
    wordBreak: "break-word",
    fontSize: 42,
    lineHeight: 1.2,
    letterSpacing: "0.2px",
    fontWeight: "bold",
  },
  titleMobile: {
    width: "100%",
    marginTop: 34,
    color: theme.palette.white,
    wordBreak: "break-word",
    fontSize: 30,
    lineHeight: 1.33,
    letterSpacing: "0.2px",
    fontWeight: "bold",
  },
  content: {
    fontSize: 17,
    fontWeight: 500,
    lineHeight: 1.53,
    color: theme.palette.white,
    wordBreak: "break-word",
  },
  contentDesktop: {
    margin: "24px 0 54px",
    color: theme.palette.white,
  },
  contentIpadPro: {
    margin: "16px 0 54px",
    color: theme.palette.white,
  },
  contentIpad: {
    margin: "16px 0 48px",
    color: theme.palette.white,
  },
  contentMobile: {
    margin: "18px 0 48px",
    color: theme.palette.white,
  },
  buttonSignUp: {
    height: 48,
    marginRight: 24,
    padding: "12px 24px 10px",
    borderRadius: 8,
    fontSize: 17,
    fontWeight: 600,
    lineHeight: 1.53,
    letterSpacing: "0.2px",
  },
  overrideMobile: {
    marginRight: 8,
  },
  buttonView: {
    height: 48,
    padding: "11px 24px",
    borderRadius: 8,
    color: theme.palette.white,
    border: "1px solid " + theme.palette.grey[500],
    "&:hover": {
      border: "1px solid " + theme.palette.primary.main,
      backgroundColor: theme.palette.primary.main,
    },
  },
  bodyRight: {
    width: "60%",
    marginLeft: 24,
    marginTop: 20,
  },
  bodyRightColumn: {
    width: "100%",
    marginTop: 48,
  },
  img: {
    minWidth: "100%",
    maxWidth: "100%",
    maxHeight: "100%",
  },
}));
