import { ApiConstant } from "const";
import { call, put } from "redux-saga/effects";
import UploadAction from "redux/upload.redux";
import { UploadService } from "services";

export function* uploadImage(action) {
  try {
    const { data } = action;

    let formData = new FormData();
    formData.append("file", data.file[0]);
    formData.append("type", "image");

    let response = yield call(UploadService.uploadImage, formData);
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data.data;
      yield put(UploadAction.uploadSuccess({ dataImage: responseData }));
    } else {
      yield put(UploadAction.uploadFailure(response.data));
    }
  } catch (error) {
    yield put(UploadAction.uploadFailure(error));
  }
}
