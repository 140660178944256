import { call, put, select } from "redux-saga/effects";
import { toSnake, toCamel, getTimestamp } from "utils";
import { ApiConstant, AppConstant } from "const";
import { ManageOwnerService } from "services";
import ManageOwnerAction from "redux/manage-owner.redux";

export function* getListOwner(action) {
  try {
    const {
      manageOwnerRedux: { data: listOwner },
    } = yield select();
    const { data } = action;

    let formDataPage = formatPagination(listOwner);

    let filter = refactorFilter(data);
    let response = yield call(ManageOwnerService.getListOwner, toSnake({ ...formDataPage, ...filter }));
    if (response.status === ApiConstant.STT_OK) {
      let responseData = toCamel(response.data);

      let listOwner = restructureData(responseData, filter.page ? filter.page : formDataPage.page);
      yield put(ManageOwnerAction.manageOwnerSuccess({ data: listOwner }));
    } else {
      let responseData = response.data?.error;
      yield put(ManageOwnerAction.manageOwnerFailure(responseData));
    }
  } catch (error) {
    yield put(ManageOwnerAction.manageOwnerFailure(error));
  }
}

export function* updateOwnerStatus(action) {
  try {
    const {
      manageOwnerRedux: { data: listOwner },
    } = yield select();
    const { data } = action;

    let updateIndex = listOwner.data.findIndex(key => key.userId === data.userId);

    listOwner.data[updateIndex].state = data.state;

    let response = yield call(ManageOwnerService.updateOwnerStatus, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      yield put(ManageOwnerAction.manageOwnerSuccess({ data: listOwner, isSuccess: true }));
    } else {
      let responseData = response.data?.error;
      yield put(ManageOwnerAction.manageOwnerFailure(responseData));
    }
  } catch (error) {
    yield put(ManageOwnerAction.manageOwnerFailure(error));
  }
}

export function* updatePaymentStatus(action) {
  try {
    const {
      manageOwnerRedux: { data: listOwner },
    } = yield select();
    const { data } = action;
    let updateIndex = listOwner.data.findIndex(key => key.paymentUid === data.uid);

    listOwner.data[updateIndex].paymentStatus = data.status;
    let response = yield call(ManageOwnerService.updatePaymentStatus, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      yield put(ManageOwnerAction.manageOwnerSuccess({ data: listOwner, isSuccess: true }));
    } else {
      let responseData = response.data?.error;
      yield put(ManageOwnerAction.manageOwnerFailure(responseData));
    }
  } catch (error) {
    yield put(ManageOwnerAction.manageOwnerFailure(error));
  }
}

const refactorFilter = filter => {
  if (!filter || Object.keys(filter).length === 0) return {};
  let result = { ...filter };
  if (filter.from) {
    result.startDate = getTimestamp(filter.from);
    delete result.from;
  }
  if (filter.to) {
    result.expiryDate = getTimestamp(filter.to);
    delete result.to;
  }
  return result;
};

const formatPagination = data => ({
  page: data?.page || 1,
  size: data?.size || 10,
  asc: 0,
  paging: 1,
  orderBy: "created",
});

const restructureData = (objData, page) => {
  if (Object.keys(objData).length === 0) return {};
  let result = { ...objData };
  let listOwner = objData.data.map((dataMap, index) => {
    return {
      ...dataMap,
      from: dataMap.startDate,
      to: dataMap.expiryDate,
      shopAmount: dataMap.shops.length,
      paymentStatus:
        dataMap.plan === AppConstant.PACKAGE_TYPE.free ? AppConstant.PAYMENTS_STATUS.free : dataMap.paymentStatus,
      ownerPosition: index + 1 + (page - 1) * 10,
    };
  });

  result.data = listOwner;
  return result;
};
