import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { AppConstant } from "const";
import clsx from "clsx";

const StatusShop = props => {
  const { status } = props;

  const defaultClasses = useStyles();

  let bgClasses = "";
  if (status === AppConstant.STATUS_SHOP.disable) {
    bgClasses = defaultClasses.disable;
  } else {
    bgClasses = defaultClasses.active;
  }

  return (
    <Box className={clsx(defaultClasses.root, bgClasses)}>
      <Typography className={clsx("medium-sm-txt", defaultClasses.contentStatus)}>
        {status === AppConstant.STATUS_SHOP.active ? "Active" : "Disable"}
      </Typography>
    </Box>
  );
};

StatusShop.propTypes = {
  status: PropTypes.number.isRequired,
};
StatusShop.defaultProps = {};

export default memo(StatusShop);

const useStyles = makeStyles(() => ({
  root: {
    width: "auto",
    padding: "2px 16px",
    borderRadius: 4,
  },
  contentStatus: {
    textAlign: "center",
    lineHeight: 1.67,
    color: "#3e4045",
  },
  active: {
    width: 68,
    backgroundColor: "rgba(101, 179, 157, 0.2)",
  },
  disable: {
    width: 74,
    backgroundColor: "rgba(254, 186, 64, 0.2)",
  },
}));
