import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getListShop: ["data"],
  getCountReservation: ["data"],
  getShopRegistration: ["data"],
  putShopRegistration: ["data"],
  getShopLog: ["data"],

  postShop: ["data"],
  putShopDetail: ["data"],

  shopSuccess: ["data"],
  shopFailure: ["error"],
});

export const ShopTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  data: {
    data: [],
    paging: 1,
    page: 1,
    size: 10,
    asc: 1,
  },
  countReservation: null,
  shopRegistration: {
    data: [],
    paging: 1,
    page: 1,
    size: 10,
    asc: 1,
  },
  shopLog: {
    data: [],
    paging: 1,
    page: 1,
    size: 10,
    asc: 1,
  },
  isSuccess: false,
  isUpdateSuccess: false,
  isCreateSuccess: false,
  isFetching: false,
  error: null,
};

/* ------------- Reducers ------------- */
export const request = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
});

export const success = (state = INITIAL_STATE, action) => {
  let data = action.data || {};
  return {
    ...state,
    isFetching: false,
    isSuccess: true,
    ...data,
  };
};

export const failure = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: false,
});

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.GET_LIST_SHOP]: request,
  [Types.GET_COUNT_RESERVATION]: request,
  [Types.GET_SHOP_REGISTRATION]: request,
  [Types.PUT_SHOP_REGISTRATION]: request,
  [Types.GET_SHOP_LOG]: request,

  [Types.POST_SHOP]: request,
  [Types.PUT_SHOP_DETAIL]: request,

  [Types.SHOP_SUCCESS]: success,
  [Types.SHOP_FAILURE]: failure,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
