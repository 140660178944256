import React, { memo, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import clsx from "clsx";
import { PackageTimeline, ShopDetail, PackageDetail, PaymentHistory } from "./components";
import { PathConstant } from "const";
import OwnerDetailsAction from "redux/owner-details.redux";
import { AppLink } from "components";
import theme from "theme/material";

const OwnerDetail = () => {
  const classes = useStyles();
  const { ownerId } = useParams();
  const dispatch = useDispatch();

  const [listPackage, ownerDetails] = useSelector(
    ({ ownerDetailsRedux }) => [ownerDetailsRedux.listPackage, ownerDetailsRedux.ownerDetails],
    shallowEqual,
  );
  const isSuccess = useSelector(({ ownerDetailsRedux }) => ownerDetailsRedux.isHistorySuccess);

  const [timeLineData, setTimeLineData] = useState([]);
  const [packageDetail, setPackageDetail] = useState({});
  const [ownerData, setOwnerData] = useState({});
  const [isGettingSuccess, setIsGettingSuccess] = useState(false);

  const onSelectedPackage = uid => {
    dispatch(OwnerDetailsAction.getPaymentHistory({ userId: ownerData.ownerId, packageUid: uid }));

    let packageDetail = getPackageDetailData(uid, timeLineData);
    setPackageDetail(packageDetail);
  };

  useEffect(() => {
    if (ownerId) {
      dispatch(OwnerDetailsAction.getListPackage({ userId: ownerId }));
      dispatch(OwnerDetailsAction.getOwnerInfo({ id: ownerId }));
    }
  }, [ownerId]);

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        setIsGettingSuccess(isSuccess);
      }, 500);

      dispatch(OwnerDetailsAction.ownerDetailsSet({ isSuccess: false }));
    }
  }, [isSuccess]);

  useEffect(() => {
    if (listPackage.length !== 0 && Object.keys(ownerDetails).length !== 0) {
      setTimeLineData(listPackage);
      setOwnerData(ownerDetails);
      setPackageDetail(listPackage[0]);

      dispatch(OwnerDetailsAction.getPaymentHistory({ userId: ownerDetails.ownerId, packageUid: listPackage[0].uid }));
      if (isSuccess) dispatch(OwnerDetailsAction.resetOwnerDetails());
    }
  }, [listPackage, ownerDetails]);

  return (
    <Box className={classes.root}>
      <Box className={classes.headerRoot}>
        <AppLink to={PathConstant.OWNERS}>
          <ArrowBack className={classes.icon} />
        </AppLink>
        <Typography variant="subtitle2" className={clsx("semiBold-lg-txt", classes.headerText)}>
          Chi tiết gói dịch vụ của tài khoản
        </Typography>
      </Box>
      {isGettingSuccess && timeLineData.length !== 0 && (
        <Box className={classes.mainContent}>
          <Box className={classes.leftContentBox}>
            <PackageTimeline data={timeLineData} onSelected={uid => onSelectedPackage(uid)} />
            <ShopDetail data={ownerData} />
          </Box>
          <Box className={classes.rightContent}>
            <PackageDetail packageDetail={packageDetail} />
            <PaymentHistory />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default memo(OwnerDetail);

OwnerDetail.propTypes = {};

OwnerDetail.defaultProps = {};

const getPackageDetailData = (packageUid, arrData) => {
  if (!packageUid || arrData.length === 0) return {};
  return arrData.find(data => data.uid === packageUid);
};

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  headerRoot: {
    display: "flex",
    width: "inherit",
    height: 48,
    padding: "8px 32px",
    alignItems: "center",
    borderTop: "1px solid rgb(212, 213, 216, 0.5)",
    borderBottom: "1px solid rgb(212, 213, 216, 0.5)",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(101, 179, 157, 0.1)",
  },
  headerText: {
    position: "absolute",
    left: "50%",
  },

  mainContent: {
    display: "flex",
    padding: "24px 32px",
  },

  leftContentBox: {
    width: "100%",
  },

  rightContent: {
    width: "100%",
  },
  icon: {
    color: theme.palette.grey[500],
    cursor: "pointer",
  },
}));
