import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import PaymentDialogRow from "./PaymentDialogRow";
import { formatToFormCurrency } from "utils";

const PaymentDialogEditing = props => {
  const classes = useStyles();
  const { data, isEdit, onCallback } = props;
  const btnText = isEdit ? "Chỉnh sửa" : "Đóng";

  return (
    <Box className={classes.root}>
      <TableContainer className={classes.table}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {COLUMNS.map(column => (
                <TableCell key={column.id} style={{ minWidth: column.minWidth }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.packageDetails.map(dataRow => (
              <PaymentDialogRow key={dataRow.uid} data={dataRow} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box>
        <Typography variant="subtitle1" component="p" className={classes.paymentTotal}>
          TỔNG CỘNG
          <Typography variant="subtitle1" component="span">
            {formatToFormCurrency(data?.totalPrice) || 0}
          </Typography>
        </Typography>
        <Button size="small" color="primary" variant="outlined" onClick={onCallback}>
          {btnText}
        </Button>
      </Box>
    </Box>
  );
};

PaymentDialogEditing.propTypes = {
  data: PropTypes.object,

  onCallback: PropTypes.func,
};

export default PaymentDialogEditing;

const COLUMNS = [
  { id: "object", label: "Đối tượng", minWidth: 110 },
  { id: "amount", label: "Số lượng", minWidth: 80 },
  { id: "time", label: "Thời gian sử dụng", minWidth: 320 },
  { id: "month", label: "Số tháng", minWidth: 80 },
  { id: "price", label: "Đơn giá", minWidth: 140 },
  { id: "money", label: "Thành tiền", minWidth: 140 },
];

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      textAlign: "right",
    },
  },
  table: {
    width: "100%",
    maxHeight: 400,
    marginTop: 16,
    marginBottom: 16,
    borderTop: "dashed 1px #d4d5d8",
    borderBottom: "dashed 1px #d4d5d8",
    "& th": {
      paddingLeft: 0,
      color: theme.palette.grey[500],
    },
    "& td": {
      paddingLeft: 0,
      whiteSpace: "pre-wrap",
    },
  },
  paymentTotal: {
    fontSize: 18,
    marginBottom: 24,
    "& > span": {
      fontSize: 18,
      color: theme.palette.primary.main,
      marginLeft: 40,
      marginRight: 16,
    },
  },
}));
