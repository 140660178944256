import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, Paper, Box, IconButton, Tooltip, Typography } from "@material-ui/core";
import { EditOutlined, Replay, DeleteOutlined, HomeOutlined } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import StringFormat from "string-format";
import clsx from "clsx";
import { convertTimestampToDate } from "utils";
import { StatusItem, ConfirmDialog } from "components";
import { AppConstant } from "const";
import QuestionAction from "redux/question.redux";

const QuestionItemList = props => {
  const { data, onEditItem, disabledRemove } = props;
  const defaultClasses = useStyles();
  const dispatch = useDispatch();

  const isSuccess = useSelector(props => props.helpQuestionRedux.isSuccess);

  const [action, setAction] = useState(DEFAULT_ACTION);

  const onClickAction = state => {
    switch (state) {
      case ACTION_OPTION.noAction:
        setAction(DEFAULT_ACTION);
        break;
      case ACTION_OPTION.edit:
        onEditItem({ data: data, state: ACTION_OPTION.edit });
        break;
      case ACTION_OPTION.remove:
        setAction({
          state: ACTION_OPTION.remove,
          title: FM_TITLE.remove,
          message: StringFormat(FM_MESSAGE.remove, data.title.vi),
        });
        break;
      case ACTION_OPTION.delete:
        setAction({
          state: ACTION_OPTION.delete,
          title: FM_TITLE.delete,
          message: StringFormat(FM_MESSAGE.delete, data.title.vi),
        });
        break;
      default:
        break;
    }
  };

  const onConfirmAction = () => {
    if (action.state === ACTION_OPTION.remove) {
      dispatch(QuestionAction.updateHelpQuestion({ ...data, published: AppConstant.STATUS_OPTION.draft }));
    } else if (action.state === ACTION_OPTION.delete) {
      dispatch(QuestionAction.deleteHelpQuestion({ id: data.id }));
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setAction(DEFAULT_ACTION);
    }
  }, [isSuccess]);

  return (
    <Box display="flex">
      <Box className={defaultClasses.coverNumber}>
        <Typography className={clsx("medium-md-txt", defaultClasses.number)}>{data?.sortOrder}</Typography>
      </Box>
      <Paper className={defaultClasses.paperRoot}>
        <Box className={defaultClasses.mainContent}>
          <Box flexBasis="35%">
            <Typography
              className={clsx(
                "semiBold-lg-txt ellipsis",
                defaultClasses.highlightTitle,
                defaultClasses.hiddenTextTwoLine,
              )}
            >
              {data.title.vi}
            </Typography>
          </Box>
          <Box flexBasis="15%">
            <Typography
              className={clsx(
                "medium-md-txt ellipsis",
                defaultClasses.contentCategory,
                defaultClasses.hiddenTextTwoLine,
              )}
            >
              {data.helpCategoryList[0]?.title?.vi}
            </Typography>
          </Box>
          <Box flexBasis="17.5%">
            <Typography
              className={clsx(
                "medium-md-txt ellipsis",
                defaultClasses.greyTextTypography,
                defaultClasses.hiddenTextTwoLine,
              )}
            >
              Thời gian tạo: {data?.created ? convertTimestampToDate(data.created) : ""}
            </Typography>
          </Box>
          <Box flexBasis="17.5%">
            <Typography
              className={clsx(
                "medium-md-txt ellipsis",
                defaultClasses.greyTextTypography,
                defaultClasses.hiddenTextTwoLine,
              )}
            >
              Thời gian xuất bản: {data?.publishedTime ? convertTimestampToDate(data.publishedTime) : ""}
            </Typography>
          </Box>
          <Box flexBasis="10%">
            <StatusItem status={data.published} />
          </Box>
          <Box flexBasis="5%" textAlign="right">
            {data.flagShowToHomePage === AppConstant.STATUS_DISPLAY_HOME_PAGE.display && (
              <HomeOutlined className={defaultClasses.homeOutlinedIcon} />
            )}
          </Box>
          <Box className={defaultClasses.actionRoot}>
            <Tooltip title="Sửa" classes={{ tooltip: clsx("medium-sm-txt", defaultClasses.tooltip) }}>
              <IconButton
                component="div"
                onClick={() => onClickAction(ACTION_OPTION.edit)}
                className={defaultClasses.buttonIconEdit}
              >
                <EditOutlined className={defaultClasses.iconEdit} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Thu hồi" classes={{ tooltip: clsx("medium-sm-txt", defaultClasses.tooltip) }}>
              <IconButton
                component="div"
                onClick={() => onClickAction(ACTION_OPTION.remove)}
                disabled={disabledRemove}
                classes={{ root: defaultClasses.buttonIconDelete, disabled: defaultClasses.disabledButton }}
              >
                <Replay className={defaultClasses.iconDelete} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Xóa" classes={{ tooltip: clsx("medium-sm-txt", defaultClasses.tooltip) }}>
              <IconButton
                component="div"
                onClick={() => onClickAction(ACTION_OPTION.delete)}
                className={defaultClasses.buttonIconDelete}
              >
                <DeleteOutlined className={defaultClasses.iconDelete} />
              </IconButton>
            </Tooltip>
            {[ACTION_OPTION.remove, ACTION_OPTION.delete].includes(action.state) && (
              <ConfirmDialog
                isShow={true}
                onClose={() => onClickAction(ACTION_OPTION.noAction)}
                onSubmit={onConfirmAction}
                title={action.title}
                message={action.message}
              />
            )}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

QuestionItemList.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    sortOrder: PropTypes.number,
    thumbnail: PropTypes.shape({
      vi: PropTypes.string,
      en: PropTypes.string,
    }),
    helpCategoryList: PropTypes.array,
    title: PropTypes.shape({
      vi: PropTypes.string,
      en: PropTypes.string,
    }),
    content: PropTypes.shape({
      vi: PropTypes.string,
      en: PropTypes.string,
    }),
    description: PropTypes.shape({
      vi: PropTypes.string,
      en: PropTypes.string,
    }),
    created: PropTypes.number,
    publishedTime: PropTypes.number,
    published: PropTypes.number,
    flagShowToHomePage: PropTypes.number,
  }),
  onEditItem: PropTypes.func,
  disabledRemove: PropTypes.bool,
};

QuestionItemList.defaultProps = {
  onEditItem: () => {},
  disabledRemove: false,
};

export default memo(QuestionItemList);

export const ACTION_OPTION = {
  noAction: 0,
  edit: 1,
  remove: 2,
  delete: 3,
};

const DEFAULT_ACTION = {
  state: ACTION_OPTION.noAction,
  title: "",
  message: "",
};

const FM_TITLE = {
  remove: `Thu hồi câu hỏi`,
  delete: `Xóa câu hỏi`,
};

const FM_MESSAGE = {
  remove: `Bạn có chắc muốn thu hồi câu hỏi "{0}" trên website không?`,
  delete: `Bạn có chắc muốn xóa câu hỏi "{0}" không?`,
};

const useStyles = makeStyles(theme => ({
  coverNumber: {
    width: 24,
    height: 24,
    marginRight: 17,
    backgroundColor: theme.palette.white,
  },
  number: {
    textAlign: "center",
    color: "#3e4045",
    lineHeight: 1.57,
  },
  paperRoot: {
    width: "100%",
    height: 83,
    maxHeight: 83,
    display: "flex",
    borderRadius: 4,
    boxShadow: "0 1px 6px 0 rgba(0, 0, 0, 0.16)",
  },
  mainContent: {
    display: "flex",
    margin: theme.spacing(2, 1),
    flexGrow: 1,
    "& > div": {
      marginLeft: 8,
      marginRight: 8,
    },
  },
  highlightTitle: {
    color: "#3e4045",
    lineHeight: 1.5,
    wordBreak: "break-word",
  },
  greyTextTypography: {
    lineHeight: 1.57,
    color: theme.palette.grey[500],
    wordBreak: "break-word",
  },
  contentCategory: {
    color: "#3e4045",
    lineHeight: 1.57,
    wordBreak: "break-word",
  },
  hiddenTextTwoLine: {
    WebkitLineClamp: 2,
  },
  homeOutlinedIcon: {
    width: 24,
    height: 24,
    padding: 4,
    borderRadius: 2,
    backgroundColor: "rgba(101, 179, 157, 0.2)",
  },
  tooltip: {
    height: 20,
    marginLeft: 50,
    marginTop: 2,
    padding: "0 5px",
    backgroundColor: "#7f838c",
    lineHeight: 1.67,
    textAlign: "center",
    borderRadius: 0,
  },
  actionRoot: {
    display: "flex",
    justifyContent: "flex-end",
    width: "10%",
  },
  buttonIconEdit: {
    width: 24,
    height: 24,
    padding: 0,
    borderRadius: 0,
    border: "1px solid " + theme.palette.grey[200],
    "&:hover": {
      border: "1px solid #feba40",
    },
  },
  iconEdit: {
    width: 24,
    height: 24,
    padding: 2,
    color: theme.palette.grey[500],
    "&:hover": {
      color: "#feba40",
    },
  },
  buttonIconDelete: {
    width: 24,
    height: 24,
    padding: 0,
    borderRadius: 0,
    border: "1px solid " + theme.palette.grey[200],
    "&:hover": {
      border: "1px solid " + theme.palette.primary.main,
    },
  },
  iconDelete: {
    width: 24,
    height: 24,
    padding: 2,
    color: theme.palette.grey[500],
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  disabledButton: {
    opacity: 0.4,
  },
}));
