import React, { memo, useState, useEffect } from "react";
import { makeStyles, Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import StringFormat from "string-format";
import ManageLayout from "layouts/ManageLayout";
import { AppConstant, LangConstant } from "const";
import { EvaluateItemList, ACTION_OPTION, EvaluateDetail } from "./components";
import EvaluateAction from "redux/evaluate.redux";

const Evaluate = () => {
  const defaultClasses = useStyles();
  const dispatch = useDispatch();

  const arrayHomeEvaluate = useSelector(props => props.evaluateRedux.data);

  const [numberOfShowing, setNumberOfShowing] = useState(0);
  const [listEvaluates, setListEvaluates] = useState([]);
  const [formEvaluate, setFormEvaluate] = useState(DEFAULT_EVALUATE_FORM);

  const onCreateNew = () => {
    setFormEvaluate({ ...formEvaluate, state: ACTION_OPTION.create });
  };

  const onOpenEvaluateDetail = formEvaluate => {
    setFormEvaluate(formEvaluate);
  };

  const onResetForm = () => {
    setFormEvaluate(DEFAULT_EVALUATE_FORM);
  };

  useEffect(() => {
    dispatch(EvaluateAction.getHomeEvaluate());
  }, []);

  useEffect(() => {
    if (arrayHomeEvaluate) {
      let numberOfEvaluatesShowing = 0;
      arrayHomeEvaluate.forEach(item => {
        if (item.published === AppConstant.STATUS_OPTION.publish) {
          numberOfEvaluatesShowing++;
        }
      });
      setNumberOfShowing(numberOfEvaluatesShowing);
      setListEvaluates(arrayHomeEvaluate);
    }
  }, [arrayHomeEvaluate]);

  let isOpenEvaluate = [ACTION_OPTION.create, ACTION_OPTION.detail, ACTION_OPTION.edit].includes(formEvaluate.state);
  return isOpenEvaluate ? (
    <EvaluateDetail
      stateEvaluate={formEvaluate.state}
      numberOfShowing={numberOfShowing}
      data={formEvaluate.data}
      onEditEvaluate={() => setFormEvaluate({ ...formEvaluate, state: ACTION_OPTION.edit })}
      onComeBack={onResetForm}
    />
  ) : (
    <>
      <ManageLayout
        title="Quản lý đánh giá"
        subtitle={StringFormat(LangConstant.FM_SHOWING, numberOfShowing)}
        buttonProps={{
          onClick: onCreateNew,
        }}
      />
      <Box className={defaultClasses.listIntro}>
        {listEvaluates.map(item => (
          <Box key={item.id} marginBottom="16px">
            <EvaluateItemList data={item} onOpenEvaluateDetail={onOpenEvaluateDetail} />
          </Box>
        ))}
      </Box>
    </>
  );
};

export const DEFAULT_EVALUATE_FORM = {
  state: ACTION_OPTION.noAction,
  data: {
    id: null,
    image: {
      vi: "",
      en: "",
    },
    logo: {
      vi: "",
      en: "",
    },
    blog: {
      vi: "",
      en: "",
    },
    title: {
      vi: "",
      en: "",
    },
    content: {
      vi: "",
      en: "",
    },
    description: {
      vi: "",
      en: "",
    },
    created: null,
    publishedTime: null,
    published: AppConstant.STATUS_OPTION.draft,
  },
};

export default memo(Evaluate);

const useStyles = makeStyles(() => ({
  listIntro: {
    height: "calc(100vh - 150px)",
    padding: "24px 32px 8px",
    overflow: "auto",
  },
}));
