import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Typography, OutlinedInput } from "@material-ui/core";

const LineInfo = ({ isEditing, title, content, ...otherProps }) => {
  const defaultClasses = useStyles({ isEditing: isEditing });

  return isEditing ? (
    <Box className={defaultClasses.root}>
      <Typography className={defaultClasses.title}>{title}</Typography>
      <OutlinedInput
        fullWidth
        value={content}
        classes={{
          root: defaultClasses.contentLineEdit,
          input: defaultClasses.inputEdit,
          disabled: defaultClasses.disabled,
        }}
        {...otherProps}
      />
    </Box>
  ) : (
    <Box className={defaultClasses.root}>
      <Typography className={defaultClasses.title}>{title}</Typography>
      <Typography className={defaultClasses.contentLineEdit}>{content}</Typography>
    </Box>
  );
};

LineInfo.propTypes = {
  isEditing: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.string,
};

LineInfo.defaultProps = {};

export default memo(LineInfo);

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    width: 532,
    minHeight: props => (props.isEditing ? 40 : 22),
    marginBottom: props => (props.isEditing ? 8 : 18),
  },
  title: {
    width: 110,
    padding: 4,
    marginTop: props => (props.isEditing ? 6 : 0),
    marginRight: 24,
    fontSize: 12,
    color: theme.palette.grey[500],
    lineHeight: 1.67,
  },
  contentLineEdit: {
    height: props => (props.isEditing ? 40 : 22),
    fontSize: 14,
    padding: "4px 16px",
    color: "#3e4045",
    "&$disabled": {
      backgroundColor: "#d4d5d8",
      opacity: 0.3,
      color: "#565c6a",
      border: "none",
      cursor: "no-drop",
    },
  },
  disabled: {
    backgroundColor: "#d4d5d8",
    opacity: 0.3,
    border: "none",
    cursor: "no-drop",
  },
  inputEdit: {
    padding: 0,
  },
}));
