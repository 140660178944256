import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { CropFree, Clear } from "@material-ui/icons";
import { BackgroundBlogsImage } from "theme/images";
import clsx from "clsx";

const UploadImageCell = props => {
  const { id, url, onUploadBackground, onRemoveImage } = props;
  const defaultClasses = useStyle();

  const [hovered, setHovered] = useState(false);
  const [urlData, setUrlData] = useState(BackgroundBlogsImage);

  useEffect(() => {
    setUrlData(url);
  }, [url]);

  return (
    <Box
      className={defaultClasses.singleImageCell}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <img src={urlData} alt="..." className={defaultClasses.imgPreviewThumbnail} />
      {hovered && (
        <Box className={defaultClasses.hoverBox}>
          <Box className={defaultClasses.addImage}>
            <label htmlFor={id || UPLOAD_ID} className={defaultClasses.uploadLabel}>
              <CropFree className={defaultClasses.cameraIcon} />
              <Typography className={clsx("medium-sm-txt", defaultClasses.textCover)}>Tải ảnh cover</Typography>
            </label>
          </Box>
          <Clear onClick={onRemoveImage} className={defaultClasses.removeIcon} />
        </Box>
      )}
      <input
        id={id || UPLOAD_ID}
        accept=".png, .jpg, .jpeg"
        onChange={onUploadBackground}
        type="file"
        className={defaultClasses.fileUploadInput}
      />
    </Box>
  );
};

export const UPLOAD_ID = "upload-input";

UploadImageCell.propTypes = {
  id: PropTypes.string,
  url: PropTypes.string,
  onUploadBackground: PropTypes.func,
  onRemoveImage: PropTypes.func,
};

UploadImageCell.defaultProps = {
  url: BackgroundBlogsImage,
  onUploadBackground: () => {},
  onRemoveImage: () => {},
};

export default memo(UploadImageCell);

const useStyle = makeStyles(theme => ({
  singleImageCell: {
    maxWidth: "100%",
    borderRadius: 3,
    position: "relative",
  },
  imgPreviewThumbnail: {
    width: "100%",
    height: "100%",
    minHeight: 200,
  },
  hoverBox: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    justifyContent: "center",
    display: "flex",
    backgroundColor: "rgb(0, 0, 0, 0.7)",
  },
  addImage: {
    height: "inherit",
    width: "inherit",

    cursor: "pointer",
    "&>*": {
      color: "white",
    },
  },

  uploadLabel: {
    display: "flex",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  cameraIcon: {
    width: 20,
    height: 20,
  },
  textCover: {
    marginLeft: 8,
    color: theme.palette.white,
  },
  removeIcon: {
    position: "absolute",
    right: 8,
    top: 8,
    cursor: "pointer",
    width: 20,
    height: 20,
    color: theme.palette.white,
    display: "unset",
  },
  fileUploadInput: {
    display: "none",
  },
}));
