import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { BackgroundBlogsImage } from "theme/images";
import UploadImageCell, { UPLOAD_ID } from "./UploadImageCell";
import { Alert } from "components";
import { validateFileSize } from "utils";
import clsx from "clsx";

const UploadImage = props => {
  const { id, isShowWarning, classes, onChange, defaultUrl } = props;
  const defaultClasses = useStyle();

  const [url, setUrl] = useState(defaultUrl || "");
  const [isShowOutSize, setIsShowOutSize] = useState(false);

  const onUploadBackground = event => {
    let img = event.target.files;
    if (img[0]?.size && validateFileSize(img[0].size)) {
      onChange({ file: img, type: TYPE_IMAGE });
    } else {
      setIsShowOutSize(true);
      onChange({});
    }
  };

  const onRemoveItemImage = () => {
    onChange({ isRemove: true });
    setUrl("");
    document.getElementById(id || UPLOAD_ID).value = "";
  };

  useEffect(() => {
    setUrl(defaultUrl);
  }, [defaultUrl]);

  return (
    <form className={clsx(defaultClasses.root, classes?.root)}>
      <Box className={defaultClasses.boxImage}>
        <UploadImageCell
          id={id}
          url={url || BackgroundBlogsImage}
          onUploadBackground={onUploadBackground}
          onRemoveImage={onRemoveItemImage}
        />
        {isShowOutSize && isShowWarning && (
          <Alert
            isShow={true}
            onClose={() => setIsShowOutSize(false)}
            message="Ảnh có dung lượng vượt quá 2MB"
            type="warning"
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            action={
              <IconButton onClick={() => setIsShowOutSize(false)} className={defaultClasses.button}>
                <Close className={defaultClasses.iconClose} />
              </IconButton>
            }
            classes={{
              root: defaultClasses.rootAlert,
              action: defaultClasses.action,
              icon: defaultClasses.icon,
              message: clsx("regular-sm-txt", defaultClasses.message),
            }}
          />
        )}
      </Box>
    </form>
  );
};

const TYPE_IMAGE = "type";

UploadImage.propTypes = {
  id: PropTypes.string,
  isShowWarning: PropTypes.bool,
  classes: PropTypes.object,
  onChange: PropTypes.func,
  defaultUrl: PropTypes.string,
};

UploadImage.defaultProps = {
  isShowWarning: true,
  onChange: () => {},
};

export default memo(UploadImage);

const useStyle = makeStyles(() => ({
  root: {
    maxWidth: "100%",
    width: "100%",
  },
  boxImage: {
    height: "inherit",
  },
  imageDisplay: {
    width: "100%",
    height: "100%",
  },
  rootAlert: {
    width: 400,
    height: 30,
    padding: "5px 8px",
    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.16)",
    backgroundColor: "rgba(254, 186, 64, 0.5)",
    color: "#3e4045",
  },
  button: {
    padding: 0,
  },
  iconClose: {
    width: 20,
    height: 20,
  },
  icon: {
    display: "none",
  },
  action: {
    marginRight: 0,
    paddingLeft: 8,
    display: "unset",
  },
  message: {
    width: "100%",
    lineHeight: 1.67,
    padding: 0,
    textAlign: "center",
  },
}));
