import { ApiConstant } from "const";
import { call, put } from "redux-saga/effects";
import AuthAction from "redux/auth.redux";
import { AuthService } from "services";
import { handlingLogin, toCamel } from "utils";

export function* requestLogin(action) {
  try {
    const { data } = action;

    let response = yield call(AuthService.login, data);
    let dataLogin = toCamel(response.data?.data);
    if (response.status === ApiConstant.STT_OK && dataLogin) {
      handlingLogin(dataLogin);
      yield put(AuthAction.loginSuccess());
    } else {
      yield put(AuthAction.loginFailure(response.data));
    }
  } catch (error) {
    yield put(AuthAction.loginFailure(error));
  }
}
