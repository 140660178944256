import { ApiConstant } from "const";
import { createApiRegistration } from "api";
import StringFormat from "string-format";

export const getListAccount = data => createApiRegistration().get(ApiConstant.USER, data);

export const postAccount = data => createApiRegistration().post(ApiConstant.CREATE_USER, data);

export const putAccount = data => createApiRegistration().put(StringFormat(ApiConstant.USER_ID, data.id), data);

export const getAccount = data => createApiRegistration().get(StringFormat(ApiConstant.USER_ID, data.id));

export const changePassword = data => createApiRegistration().put(ApiConstant.CHANGE_PASSWORD, data);
