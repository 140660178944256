import React, { memo } from "react";
import PropTypes from "prop-types";
import { Box, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import { LangConstant } from "const";
import { getShopStatusColor } from "utils";

const ShopDetail = ({ data }) => {
  const classes = useStyles({ statusColor: getShopStatusColor(data?.state) });

  return (
    <Box>
      <Typography className={clsx("semiBold-md-txt", classes.shopInfoHeader)}>Thông tin shop của tài khoản</Typography>
      <LineInfo title="Tên:" content={data?.ownerName} otherProps={classes.textNormalColor} />
      <LineInfo title="Số điện thoại:" content={data?.phoneNumber} otherProps={classes.textNormalColor} />
      <LineInfo title="Email:" content={data?.email} otherProps={classes.textNormalColor} />
      <Box className={classes.lineBox}>
        <Typography className={classes.titleText}>Shop:</Typography>
        <Box>
          {data?.shops.map((shop, index) => (
            <Typography className={clsx("medium-md-txt", classes.contentText, classes.shopText)} key={index}>
              {shop.name}
            </Typography>
          ))}
        </Box>
      </Box>
      <LineInfo title="Trạng thái:" content={LangConstant.SHOP_STATUS[data?.state]} otherProps={classes.shopStatus} />
    </Box>
  );
};

export default memo(ShopDetail);

ShopDetail.propTypes = {
  data: PropTypes.object.isRequired,
};

ShopDetail.defaultProps = {
  data: {},
};

const LineInfo = ({ title, content, otherProps }) => {
  const classes = useStyles();

  return (
    <Box className={classes.lineBox}>
      <Typography className={classes.titleText}>{title}</Typography>
      <Typography className={clsx("medium-md-txt ellipsis", classes.contentText, otherProps)}>{content}</Typography>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  shopInfoHeader: {
    color: "#3e4045",
    lineHeight: 1.47,
    marginBottom: 20,
  },
  lineBox: {
    display: "flex",
    width: "100%",
    minHeight: 30,
    marginBottom: 4,
    paddingRight: 50,
    "& > *": {
      fontSize: 12,
      fontWeight: 500,
      color: "#6c7078",
      paddingBottom: 10,
    },
  },
  titleText: {
    minWidth: "30%",
    height: 22,
    fontSize: 12,
    marginTop: 4,
  },
  contentText: {
    minHeight: 22,
    fontSize: 13,
    marginTop: 4,
    paddingTop: 2,
  },
  shopNameBox: {
    display: "flex",
    flexDirection: "row",
  },

  shopInfoBox: {
    "& > *": {
      paddingBottom: 18,
    },
  },
  textNormalColor: {
    color: "#3e4045",
  },
  shopStatus: {
    color: props => props.statusColor,
  },
  shopText: {
    color: theme.palette.text.link,
    lineHeight: 1.57,
  },
}));
