import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Button, Typography, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { Prompt } from "react-router-dom";
import StringFormat from "string-format";
import clsx from "clsx";
import { LangConstant, AppConstant } from "const";
import { Alert, ConfirmDialog } from "components";
import HomePageLayout from "layouts/HomePageLayout";
import { TabFeatureInfo, ACTION_OPTION } from "../components";
import FeatureAction from "redux/feature.redux";
import { handleBeforeUnload } from "utils";
import { DEFAULT_FEATURE_FORM } from "pages/Feature";

const FeatureDetail = props => {
  const { isLastFeature, stateFeature, numberOfShowing, data, onComeBack, onEditFeature } = props;
  const defaultClasses = useStyles();
  const dispatch = useDispatch();

  const isSuccess = useSelector(props => props.featureRedux.isSuccess);

  const [formData, setFormData] = useState(data);
  const [alertForm, setAlertForm] = useState(DEFAULT_FORM_ALERT);
  const [isDisplayPreview, setIsDisplayPreview] = useState(false);
  const [statusMessage, setStatusMessage] = useState();
  const [isUpdateData, setIsUpdateData] = useState(false);
  const [isShowConfirm, setIsShowConfirm] = useState(false);
  const [isReset, setIsReset] = useState(false);

  const onClickButton = option => {
    if (!alertForm.isShowing && (formData.title.vi || formData.title.en)) {
      setStatusMessage(option);
      if (option === OPTION_CLICK.displayButton) {
        let submitData = { ...formData, published: AppConstant.STATUS_OPTION.publish };
        if (!submitData.id) {
          dispatch(FeatureAction.createHomeFeature(submitData));
        } else {
          dispatch(FeatureAction.updateHomeFeature(submitData));
        }
      } else if (option === OPTION_CLICK.saveDraft) {
        let submitData = { ...formData, published: AppConstant.STATUS_OPTION.draft };
        if (!submitData.id) {
          dispatch(FeatureAction.createHomeFeature(submitData));
        } else {
          dispatch(FeatureAction.updateHomeFeature(submitData));
        }
      } else {
        setIsDisplayPreview(true);
      }
    }
  };

  const onCloseAlert = () => {
    setAlertForm(DEFAULT_FORM_ALERT);
  };

  const onConfirmSave = () => {
    if (!formData.id) {
      dispatch(FeatureAction.createHomeFeature(formData));
    } else {
      dispatch(FeatureAction.updateHomeFeature(formData));
    }
    onComeBack();
  };

  const onChangeFormData = dataUpdate => {
    setFormData(dataUpdate);
    if (formData?.id || formData.title.vi || formData.title.en) {
      setIsUpdateData(true);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(FeatureAction.resetFeatureSuccess());
      if (statusMessage === OPTION_CLICK.displayButton) {
        setAlertForm(OBJ_ALERT_MESSAGE.display);
      } else if (statusMessage === OPTION_CLICK.saveDraft) {
        setAlertForm(OBJ_ALERT_MESSAGE.hidden);
      }
      if (!data?.id) {
        setFormData(DEFAULT_FEATURE_FORM.data);
        setIsUpdateData(false);
        setIsReset(true);
      }
    }
  }, [isSuccess]);

  useEffect(() => {
    if (checkInputDataBeforeChangePage(formData) || isUpdateData) {
      window.addEventListener("beforeunload", handleBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  }, [formData]);

  return (
    <>
      <Prompt
        when={checkInputDataBeforeChangePage(formData) || isUpdateData}
        message={() => `Các thay đổi bạn đã thực hiện có thể không được lưu.`}
      />
      <HomePageLayout
        title={
          <Button
            onClick={isUpdateData ? () => setIsShowConfirm(true) : onComeBack}
            className={defaultClasses.buttonManage}
          >
            Quản lý tính năng
          </Button>
        }
        header="Thông tin tính năng"
        subtitle={StringFormat(LangConstant.FM_SHOWING, numberOfShowing)}
        data={formData}
        onClickPreview={() => onClickButton(OPTION_CLICK.preview)}
        buttonDisplayProps={{
          onClick: () => onClickButton(OPTION_CLICK.displayButton),
        }}
        buttonSaveDraftProps={{
          content: stateFeature === ACTION_OPTION.detail ? "Chỉnh sửa" : "Lưu nháp",
          onClick: stateFeature === ACTION_OPTION.detail ? onEditFeature : () => onClickButton(OPTION_CLICK.saveDraft),
        }}
      >
        <Box className={defaultClasses.middleLayout}>
          <Typography variant="subtitle2" className={defaultClasses.title}>
            {TITLE_DISPLAY[stateFeature].title}
          </Typography>
        </Box>
      </HomePageLayout>
      {alertForm.isShowing && (
        <Alert
          isShow={true}
          onClose={onCloseAlert}
          message={alertForm.message}
          type={alertForm.type}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          action={
            <IconButton onClick={onCloseAlert} className={defaultClasses.button}>
              <Close className={defaultClasses.iconClose} />
            </IconButton>
          }
          classes={{
            root: clsx(
              defaultClasses.rootAlert,
              alertForm.type === OBJ_ALERT_MESSAGE.hidden.type && defaultClasses.colorWarning,
            ),
            action: defaultClasses.action,
            icon: defaultClasses.icon,
            message: clsx("regular-sm-txt", defaultClasses.message),
          }}
        />
      )}
      {isUpdateData && isShowConfirm && (
        <ConfirmDialog
          isShow={true}
          onClose={onComeBack}
          onSubmit={onConfirmSave}
          title="Lưu tính năng hiện tại"
          message={StringFormat(LangConstant.FM_MESSAGE_FEATURE_SAVE, formData.title.vi || formData.title.en)}
        />
      )}
      <TabFeatureInfo
        isReset={isReset}
        onResetSuccess={() => setIsReset(false)}
        data={formData}
        onChangeFormData={onChangeFormData}
        isDisplayPreview={isDisplayPreview}
        isLastFeature={isLastFeature}
        onClosePreview={() => setIsDisplayPreview(false)}
      />
    </>
  );
};

const OPTION_CLICK = {
  displayButton: 1,
  saveDraft: 2,
  preview: 3,
};

const DEFAULT_FORM_ALERT = {
  isShowing: false,
  message: "",
  type: "",
};

const OBJ_ALERT_MESSAGE = {
  display: {
    isShowing: true,
    message: "Tính năng đã được hiển thị/cập nhật",
    type: "success",
  },
  hidden: {
    isShowing: true,
    message: "Tính năng chưa được hiển thị/cập nhật",
    type: "warning",
  },
};

const TITLE_DISPLAY = {
  [ACTION_OPTION.detail]: {
    title: "Chi tiết tính năng",
  },
  [ACTION_OPTION.create]: {
    title: "Tạo mới tính năng",
  },
  [ACTION_OPTION.edit]: {
    title: "Chỉnh sửa tính năng",
  },
};

FeatureDetail.propTypes = {
  isLastFeature: PropTypes.bool,
  stateFeature: PropTypes.number,
  numberOfShowing: PropTypes.number,
  data: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.shape({
      vi: PropTypes.string,
      en: PropTypes.string,
    }),
    highlightTitle: PropTypes.shape({
      vi: PropTypes.string,
      en: PropTypes.string,
    }),
    content: PropTypes.shape({
      vi: PropTypes.string,
      en: PropTypes.string,
    }),
    description: PropTypes.shape({
      vi: PropTypes.string,
      en: PropTypes.string,
    }),
    published: PropTypes.number,
    sortOrder: PropTypes.number,
    created: PropTypes.number,
    publishedTime: PropTypes.number,
    slide: PropTypes.array,
    slideType: PropTypes.number,
  }),
  onComeBack: PropTypes.func,
  onEditFeature: PropTypes.func,
};

FeatureDetail.defaultProps = {
  isLastFeature: false,
  onComeBack: () => {},
  onEditFeature: () => {},
};

export default memo(FeatureDetail);

const checkInputDataBeforeChangePage = data => {
  if (
    data.title.vi ||
    data.title.en ||
    data.highlightTitle.vi ||
    data.highlightTitle.en ||
    data.description.vi ||
    data.description.en ||
    data.content.vi ||
    data.content.en
  ) {
    return true;
  }
  return false;
};

const useStyles = makeStyles(theme => ({
  buttonManage: {
    height: 24,
    marginTop: 3,
    padding: 0,
    color: theme.palette.grey[500],
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: 16,
    "&:hover": {
      color: theme.palette.grey[700],
      backgroundColor: "unset",
    },
  },
  middleLayout: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
  },
  title: {
    height: 24,
    margin: "3px 0 3px 24px",
    lineHeight: 1.5,
  },
  rootAlert: {
    width: 400,
    height: 30,
    padding: "5px 8px",
    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.16)",
    backgroundColor: "rgba(101, 179, 157, 0.5)",
    color: "#3e4045",
  },
  colorWarning: {
    backgroundColor: "rgba(254, 186, 64, 0.5)",
  },
  button: {
    padding: 0,
  },
  iconClose: {
    width: 20,
    height: 20,
  },
  icon: {
    display: "none",
  },
  action: {
    marginRight: 0,
    paddingLeft: 8,
    display: "unset",
  },
  message: {
    width: "100%",
    lineHeight: 1.67,
    padding: 0,
    textAlign: "center",
  },
}));
