import React, { memo, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Box, makeStyles } from "@material-ui/core";
import ShopDetailLayout from "./ShopDetailLayout";
import ShopInformationForm from "./ShopInformationForm";
import ShopAction from "redux/shop.redux";

const ShopDetails = ({ shopDetail, onBack }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isUpdateSuccess = useSelector(state => state.shopRedux.isUpdateSuccess, shallowEqual);

  const [isEditing, setIsEditing] = useState(false);
  const [isClickCancel, setIsClickCancel] = useState(false);
  const [displayData, setDisplayData] = useState(shopDetail);

  const onChangeEditStatus = () => {
    setIsEditing(!isEditing);
  };

  const onUpdateShopDetail = data => {
    let dataToUpdate = {
      ...shopDetail,
      ...data,
      social: {
        website: data.website,
        facebook: data.facebook,
        google: data.google,
      },
    };

    setIsClickCancel(false);
    dispatch(ShopAction.putShopDetail(dataToUpdate));
    setDisplayData(dataToUpdate);
  };

  const onCancelAction = () => {
    setIsEditing(false);
    setIsClickCancel(false);
    onBack();
  };

  useEffect(() => {
    dispatch(ShopAction.shopSuccess({ isUpdateSuccess: false }));
    if (isUpdateSuccess) {
      setIsEditing(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateSuccess]);

  return (
    <Box className={classes.root}>
      <ShopDetailLayout
        buttonProps={isEditing ? {} : { content: "Chỉnh sửa", onClick: onChangeEditStatus }}
        onBack={() => {
          isEditing ? setIsClickCancel(true) : onCancelAction();
        }}
        title="Thông tin shop"
      >
        <Box className={classes.shopDetail}>
          <ShopInformationForm
            renderCondition={{
              isEditing: isEditing,
              data: displayData,
              isClickCancel: isClickCancel,
            }}
            actionContent={{
              onCancel: () => {
                setIsEditing(false);
                setIsClickCancel(false);
              },
              onConfirm: onUpdateShopDetail,
            }}
          />
        </Box>
      </ShopDetailLayout>
    </Box>
  );
};

export default memo(ShopDetails);

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  shopDetail: {
    width: "100%",
    display: "flex",
    padding: "24px 34px",
  },
  backButton: {
    padding: 4,
    width: 28,
    height: 28,
    "&>span>svg": {},
  },
}));
