import { ApiConstant } from "const";
import { createApiRegistration } from "api";
import StringFormat from "string-format";

export const postQuestion = data => createApiRegistration().post(ApiConstant.HELP_QUESTION, data);

export const putQuestion = data =>
  createApiRegistration().put(StringFormat(ApiConstant.HELP_QUESTION_ID, data.id), data);

export const getQuestion = data => createApiRegistration().get(ApiConstant.HELP_QUESTION, data);

export const getQuestionById = data => createApiRegistration().get(StringFormat(ApiConstant.HELP_QUESTION_ID, data.id));

export const deleteQuestion = data =>
  createApiRegistration().delete(StringFormat(ApiConstant.HELP_QUESTION_ID, data.id));
