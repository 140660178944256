import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, Paper, IconButton, Box, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import { FilterIcon } from "icons";
import { SearchBar } from "components";
import HeaderFilter from "./HeaderFilter";
import QuestionAction from "redux/question.redux";

const HeaderBar = props => {
  const { dataFilter, onChangeFilter, isShowFilter, onShowFilter } = props;
  const defaultClasses = useStyles({ isShowFilter });
  const dispatch = useDispatch();

  const [objectValue, setObjectValue] = useState(dataFilter);

  const onSubmitSearch = name => {
    let formFilter = { filter: name, page: 1 };
    setObjectValue(currentData => ({ ...currentData, ...formFilter }));
    if (objectValue.helpCategoryId !== OPT_ALL_ITEMS_ID) {
      formFilter.helpCategoryId = objectValue.helpCategoryId;
    }
    if (objectValue.published !== OPT_ALL_ITEMS_ID) {
      formFilter.published = objectValue.published;
    }
    if (objectValue.flagShowToHomePage !== OPT_ALL_ITEMS_ID) {
      formFilter.flagShowToHomePage = objectValue.flagShowToHomePage;
    }
    onChangeFilter({ ...objectValue, filter: name });
    dispatch(QuestionAction.getHelpQuestion(formFilter));
  };

  const onChangeSelected = (e, name) => {
    let formData = { ...objectValue, [name]: e.target.value };
    onChangeFilter(formData);
    setObjectValue(formData);
  };

  useEffect(() => {
    setObjectValue(dataFilter);
  }, [dataFilter]);

  return (
    <Paper className={defaultClasses.root}>
      <Box className={defaultClasses.titleBox}>
        <Box display="flex">
          <Typography className={clsx("medium-md-txt", defaultClasses.titleSearch)}>Tìm kiếm câu hỏi</Typography>
          <SearchBar onSearch={onSubmitSearch} />
        </Box>
        <IconButton onClick={onShowFilter} className={defaultClasses.buttonFilter}>
          {isShowFilter ? (
            <Close className={defaultClasses.iconFilter} />
          ) : (
            <FilterIcon className={defaultClasses.iconFilter} />
          )}
        </IconButton>
      </Box>
      {isShowFilter && <HeaderFilter data={objectValue} onChangeSelected={onChangeSelected} />}
    </Paper>
  );
};

const OPT_ALL_ITEMS_ID = "all";

HeaderBar.propTypes = {
  dataFilter: PropTypes.shape({
    filter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    helpCategoryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    published: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    flagShowToHomePage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  onChangeFilter: PropTypes.func,
  isShowFilter: PropTypes.bool,
  onShowFilter: PropTypes.func,
};

HeaderBar.defaultProps = {
  onChangeFilter: () => {},
  isShowFilter: false,
  onShowFilter: () => {},
};

export default memo(HeaderBar);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    padding: "11px 34px",
    borderRadius: 0,
    boxShadow: "0 1px 6px 0 rgba(0, 0, 0, 0.16)",
  },
  titleBox: {
    display: "flex",
    justifyContent: "space-between",
  },
  titleSearch: {
    margin: "8px 13px 8px 0",
    lineHeight: "22px",
    color: "#3e4045",
  },
  buttonFilter: {
    width: 30,
    height: 30,
    padding: 0,
    borderRadius: 0,
    border: "1px solid " + theme.palette.grey[200],
  },
  iconFilter: {
    width: props => (props?.isShowFilter ? 15 : 20),
    height: props => (props?.isShowFilter ? 15 : 20),
    color: "#4b4d53",
    opacity: props => (props?.isShowFilter ? 0.7 : 1),
  },
}));
