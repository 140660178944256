import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  makeStyles,
  DialogTitle,
  IconButton,
  OutlinedInput,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { SelectedItem } from "components";
import { AppConstant, LangConstant } from "const";

const DialogAccount = ({ isShow, onClose, onChange, onSubmit, data }) => {
  const classes = useStyles();

  const [dataInput, setDataInput] = useState();

  let hasData = Boolean(data?.email);

  const onTypingData = e => {
    let name = e.target.name;
    let value = e.target.value;
    setDataInput({
      ...dataInput,
      [name]: value,
    });
    if (hasData) {
      onChange();
    }
  };

  const onChangeGender = e => {
    let value = e.target.value;
    setDataInput({
      ...dataInput,
      gender: value,
    });
    if (hasData) {
      onChange();
    }
  };

  useEffect(() => {
    if (hasData) {
      setDataInput({ ...data, password: "" });
    } else {
      setDataInput(FORM_INPUT_DEFAULT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Dialog open={isShow} classes={{ paperScrollPaper: classes.dialogContainer }}>
      <DialogTitle className={classes.dialogTitle}>
        <Typography classes={{ body1: classes.textDialogTitle }}>
          {data?.email ? "Chỉnh sửa tài khoản" : "Tạo mới tài khoản"}
        </Typography>
        <IconButton className={classes.closeButton} onClick={() => onClose(dataInput)}>
          <Close className={classes.closeIcon} />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography className={classes.typographyContent}>Email/Username *</Typography>
        <OutlinedInput
          placeholder="Nhập Email/Username"
          classes={{ root: classes.contentLineEdit, input: classes.inputEdit, disabled: classes.disabled }}
          required
          inputProps={{
            name: "email",
          }}
          value={dataInput?.email || ""}
          onChange={onTypingData}
        />
        <Typography className={classes.typographyContent}>Mật khẩu *</Typography>
        <OutlinedInput
          placeholder="Mật khẩu"
          classes={{ root: classes.contentLineEdit, input: classes.inputEdit, disabled: classes.disabled }}
          required
          type="password"
          inputProps={{
            name: "password",
          }}
          value={dataInput?.password || ""}
          onChange={onTypingData}
        />
        <Typography className={classes.typographyContent}>Tên tài khoản *</Typography>
        <OutlinedInput
          placeholder="Nhập tên tài khoản"
          classes={{ root: classes.contentLineEdit, input: classes.inputEdit, disabled: classes.disabled }}
          required
          inputProps={{
            name: "name",
          }}
          value={dataInput?.name || ""}
          onChange={onTypingData}
        />

        <Typography className={classes.typographyContent}>Nhập số điện thoại</Typography>
        <OutlinedInput
          placeholder="Nhập số điện thoại"
          classes={{ root: classes.contentLineEdit, input: classes.inputEdit, disabled: classes.disabled }}
          required
          inputProps={{
            name: "phoneNumber",
          }}
          value={dataInput?.phoneNumber || ""}
          onChange={onTypingData}
        />
        <Typography className={classes.typographyContent}>Giới tính</Typography>
        <SelectedItem
          onChangeInput={onChangeGender}
          value={dataInput?.gender || 0}
          data={LangConstant.ARRAY_GENDER}
          isShow={false}
        />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button className={classes.buttonCancel} onClick={() => onClose(dataInput)}>
          Hủy
        </Button>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          onClick={() => onSubmit(dataInput)}
          className={classes.buttonSubmit}
        >
          {hasData ? "Lưu" : "Tạo mới"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const FORM_INPUT_DEFAULT = {
  email: "",
  password: AppConstant.DEFAULT_PASSWORD,
  name: "",
  avatar: null,
  address: "",
  gender: null,
  birthday: null,
  state: AppConstant.STATUS_ACCOUNT.active,
  phoneNumber: "",
};

DialogAccount.propTypes = {
  isShow: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  data: PropTypes.object,
};
DialogAccount.defaultProps = {};

export default memo(DialogAccount);

const useStyles = makeStyles(theme => ({
  dialogContainer: {
    width: 450,
    objectFit: "contain",
    boxShadow: "0 1px 6px 0 rgba(0, 0, 0, 0.1)",
    backgroundColor: theme.palette.white,
    borderRadius: 5,
    "@media only screen and (max-width: 515px)": {
      width: "100%",
      marginTop: 0,
      marginLeft: 0,
      marginRight: 0,
      height: "100%",
      marginBottom: 0,
      maxHeight: "none",
    },
  },
  dialogTitle: {
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    position: "fixed",
    width: 450,
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    background: "#65b39d",
    color: theme.palette.white,
    height: 40,
    padding: "8px 10px 8px 24px",
    zIndex: 100,
    "@media only screen and (max-width: 515px)": {
      width: "100%",
    },
  },
  textDialogTitle: {
    color: theme.palette.white,
    fontSize: 16,
    lineHeight: 1.5,
    fontWeight: 600,
  },
  closeButton: {
    position: "absolute",
    right: 10,
    top: 10,
    color: theme.palette.grey[500],
    height: 24,
    width: 24,
    padding: 0,
  },
  closeIcon: {
    fontSize: 24,
    color: theme.palette.white,
  },
  dialogContent: {
    marginTop: 40,
    padding: "16px 24px",
    display: "flex",
    flexDirection: "column",
    "&:first-child": {
      paddingTop: 26,
      "@media only screen and (max-width: 515px)": {
        padding: "26px 10px 20px",
        borderBottom: "none",
      },
    },
  },
  typographyContent: {
    marginBottom: 8,
    fontSize: 13,
    fontWeight: 600,
    color: "#3e4045",
    lineHeight: "22px",
  },
  contentLineEdit: {
    width: "100%",
    height: 40,
    fontSize: 14,
    padding: "9px 16px",
    marginBottom: 16,
    "&$disabled": {
      backgroundColor: "#d4d5d8",
      opacity: 0.3,
      color: "#565c6a",
      border: "none",
      cursor: "no-drop",
    },
  },
  disabled: {
    backgroundColor: "#d4d5d8",
    opacity: 0.3,
    border: "none",
    cursor: "no-drop",
  },
  inputEdit: {
    padding: 0,
  },
  dialogActions: {
    display: "block",
    textAlign: "right",
    padding: "16px 24px",
  },
  buttonCancel: {
    textTransform: "none",
    width: 58,
    height: 30,
    margin: 0,
    padding: "3px 16px",
    borderRadius: 2,
    fontWeight: 500,
    minHeight: "unset",
  },
  buttonSubmit: {
    textTransform: "none",
    height: 30,
    padding: "3px 16px",
    borderRadius: 2,
    fontSize: 14,
    fontWeight: 500,
    minHeight: "unset",
  },
}));
