import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Grid, OutlinedInput, Typography } from "@material-ui/core";
import stringFormat from "string-format";
import clsx from "clsx";
import { LangConstant } from "const";

const InformationBlogs = props => {
  const { data, onChange, langValue, children } = props;
  const defaultClasses = useStyles();

  let langString = langValue.toUpperCase();

  return (
    <Grid item xs={9}>
      <FormInput
        title="Tên bài viết"
        multiline
        name="title"
        onChange={onChange}
        value={data?.title}
        placeholder={stringFormat(LangConstant.FM_INPUT_TITLE, langString)}
        classes={{
          root: defaultClasses.rootInputTitle,
          input: defaultClasses.inputTitle,
          multiline: defaultClasses.inputMultipleLine,
        }}
      />
      <FormInput
        title="Mô tả bài viết"
        multiline
        name="description"
        onChange={onChange}
        value={data?.description}
        placeholder={stringFormat(LangConstant.FM_INPUT_DESCRIPTION, langString)}
        classes={{
          root: defaultClasses.rootInputDescription,
          input: defaultClasses.inputDescription,
          multiline: defaultClasses.inputMultipleLine,
        }}
      />
      <Box>
        <Typography className={clsx("regular-md-txt", defaultClasses.title)}>Nội dung</Typography>
        {children}
      </Box>
    </Grid>
  );
};

InformationBlogs.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  langValue: PropTypes.string,
  onChange: PropTypes.func,
};

InformationBlogs.defaultProps = {};
export default memo(InformationBlogs);

const FormInput = props => {
  const { title, ...inputProps } = props;
  const defaultClasses = useStyles();

  return (
    <Box paddingBottom="36px">
      <Typography className={clsx("regular-md-txt", defaultClasses.title)}>{title}</Typography>
      <OutlinedInput {...inputProps} />
    </Box>
  );
};

FormInput.propTypes = {};

FormInput.defaultProps = {};

const useStyles = makeStyles(() => ({
  rootInputTitle: {
    width: "100%",
    minHeight: 64,
    fontSize: 28,
    fontWeight: "bold",
  },
  inputTitle: {
    padding: "12px 16px",
    textAlign: "center",
  },
  rootInputDescription: {
    width: "100%",
    minHeight: 72,
  },
  inputDescription: {
    padding: "12px 16px",
    minHeight: 43,
  },
  inputMultipleLine: {
    padding: 0,
  },
  title: {
    marginBottom: 12,
    color: "#3e4045",
    lineHeight: "22px",
  },
}));
