import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { AppConstant } from "const";
import StatusShop from "./StatusShop";
import clsx from "clsx";

const ShopListItem = props => {
  const { data } = props;
  const defaultClasses = useStyles();

  return (
    <Box className={defaultClasses.root}>
      <Box flexBasis="5%" paddingRight="12px">
        <Typography className={clsx(defaultClasses.fontText, defaultClasses.fontTextCenter)}>{data.number}</Typography>
      </Box>
      <Box flexBasis="13.25%" paddingRight="12px">
        <Typography className={defaultClasses.fontText}>{data.name}</Typography>
      </Box>
      <Box flexBasis="9%" paddingRight="12px">
        <Typography className={defaultClasses.fontText}>{AppConstant.BUSINESS_AREAS[data.businessId]}</Typography>
      </Box>
      <Box flexBasis="18.2%" paddingRight="12px">
        <Typography className={defaultClasses.fontText}>{data.ownerName}</Typography>
        <Typography className={defaultClasses.fontText}>{data.email}</Typography>
        <Typography className={defaultClasses.fontText}>{data.phoneNumber}</Typography>
      </Box>
      <Box flexBasis="18.2%" paddingRight="12px">
        <Typography className={defaultClasses.fontText}>{data.address}</Typography>
      </Box>
      <Box flexBasis="18.4%" paddingRight="12px">
        <Typography className={defaultClasses.fontText}>
          Website:&emsp;
          <a className={clsx(defaultClasses.link)} href={data.social.website} target="_blank" rel="noopener noreferrer">
            website
          </a>
        </Typography>
        <Typography className={defaultClasses.fontText}>
          Facebook:&emsp;
          <a
            className={clsx(defaultClasses.link)}
            href={data.social.facebook}
            target="_blank"
            rel="noopener noreferrer"
          >
            facebook
          </a>
        </Typography>
        <Typography className={defaultClasses.fontText}>
          Google map:&emsp;
          <a className={clsx(defaultClasses.link)} href={data.social.google} target="_blank" rel="noopener noreferrer">
            google
          </a>
        </Typography>
      </Box>
      <Box flexBasis="9%" paddingRight="12px">
        <StatusShop status={data.state} />
      </Box>
      <Box flexBasis="9%" paddingRight="12px">
        <Typography className={clsx(defaultClasses.fontText, defaultClasses.fontTextCenter)}>
          {data.numberReservation}
        </Typography>
      </Box>
    </Box>
  );
};

ShopListItem.propTypes = {
  data: PropTypes.object,
};

ShopListItem.defaultProps = {};

export default memo(ShopListItem);

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    minHeight: 106,
    padding: "16px 14px",
    boxShadow: "0 1px 6px 0 rgba(0, 0, 0, 0.16)",
    borderRadius: 4,
  },
  fontText: {
    lineHeight: 1.57,
    color: "#3e4045",
    fontSize: 14,
    fontWeight: 400,
    lineBreak: "anywhere",
  },
  fontTextCenter: {
    textAlign: "center",
  },
  link: {
    maxWidth: 80,
    lineBreak: "anywhere",
    color: theme.palette.text.link,
    textDecoration: "unset",
    [theme.breakpoints.up(1500)]: {
      maxWidth: 100,
    },
  },
}));
