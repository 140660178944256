import { ApiConstant } from "const";
import { call, put, select } from "redux-saga/effects";
import IntroductionAction from "redux/introduction.redux";
import { IntroductionService } from "services";
import { toCamel, toSnake } from "utils";

export function* getListHomeIntro() {
  try {
    let response = yield call(IntroductionService.getHomeIntro);
    if (response.status === ApiConstant.STT_OK) {
      let responseData = toCamel(response.data.data);
      yield put(IntroductionAction.homeIntroSuccess({ data: responseData }));
    } else {
      let responseData = response.data?.error;
      yield put(IntroductionAction.homeIntroFailure(responseData));
    }
  } catch (error) {
    yield put(IntroductionAction.homeIntroFailure(error));
  }
}

export function* createHomeIntro(action) {
  try {
    const {
      introductionRedux: { data: reduxData },
    } = yield select();
    const { data } = action;
    let formData = { ...data };
    if (!formData?.id) {
      delete formData["id"];
    }
    let response = yield call(IntroductionService.postHomeIntro, toSnake(formData));
    if (response.status === ApiConstant.STT_OK) {
      let newData = [...reduxData, toCamel(response.data.data)];
      yield put(IntroductionAction.homeIntroSuccess({ data: newData, isSuccess: true }));
    } else {
      let responseData = response.data?.error;
      yield put(IntroductionAction.homeIntroFailure(responseData));
    }
  } catch (error) {
    yield put(IntroductionAction.homeIntroFailure(error));
  }
}

export function* updateHomeIntro(action) {
  try {
    const {
      introductionRedux: { data: reduxData },
    } = yield select();
    const { data } = action;

    let response = yield call(IntroductionService.putHomeIntro, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      let selectedIndex = reduxData.findIndex(key => key.id === data.id);

      let updateArray = [...reduxData];
      updateArray[selectedIndex] = Object.assign(updateArray[selectedIndex], toCamel(response.data.data));

      yield put(IntroductionAction.homeIntroSuccess({ data: updateArray, isSuccess: true }));
    } else {
      let responseData = response.data?.error;
      yield put(IntroductionAction.homeIntroFailure(responseData));
    }
  } catch (error) {
    yield put(IntroductionAction.homeIntroFailure(error));
  }
}

export function* deleteHomeIntro(action) {
  try {
    const {
      introductionRedux: { data: reduxData },
    } = yield select();
    const { data } = action;

    let response = yield call(IntroductionService.deleteHomeIntro, data);
    if (response.status === ApiConstant.STT_OK) {
      let newData = reduxData.filter(item => item.id !== data.id);
      yield put(IntroductionAction.homeIntroSuccess({ data: newData }));
    } else {
      let responseData = response.data?.error;
      yield put(IntroductionAction.homeIntroFailure(responseData));
    }
  } catch (error) {
    yield put(IntroductionAction.homeIntroFailure(error));
  }
}

export function* sortHomeIntro(action) {
  try {
    const {
      introductionRedux: { data: reduxData },
    } = yield select();
    const { data } = action;
    let arrayData = [...reduxData];
    let indexItem = arrayData.findIndex(item => item.id === data.id);
    if (data.isPushUp) {
      if (indexItem > 0) {
        let mapItem = arrayData[indexItem];
        arrayData[indexItem] = arrayData[indexItem - 1];
        arrayData[indexItem - 1] = mapItem;
      }
    } else {
      if (indexItem < arrayData.length - 1) {
        let mapItem = arrayData[indexItem];
        arrayData[indexItem] = arrayData[indexItem + 1];
        arrayData[indexItem + 1] = mapItem;
      }
    }
    let response = yield call(IntroductionService.sortHomeIntro, arrayData);
    if (response.status === ApiConstant.STT_OK) {
      let responseData = toCamel(response.data.data);
      yield put(IntroductionAction.homeIntroSuccess({ data: responseData }));
    } else {
      let responseData = response.data?.error;
      yield put(IntroductionAction.homeIntroFailure(responseData));
    }
  } catch (error) {
    yield put(IntroductionAction.homeIntroFailure(error));
  }
}
