import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getListOwner: ["data"],
  updateOwnerStatus: ["data"],
  updatePaymentStatus: ["data"],

  manageOwnerSuccess: ["data"],
  manageOwnerFailure: ["error"],
  resetManageOwner: [],
});

export const ManageOwnerTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  data: {
    data: [],
    paging: 1,
    page: 1,
    size: 10,
    asc: 0,
  },

  isSuccess: false,
  isFetching: false,
  error: null,
};

/* ------------- Reducers ------------- */
export const request = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
});

export const success = (state = INITIAL_STATE, action) => {
  let data = action.data || {};
  return { ...state, isFetching: false, ...data };
};

export const failure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  error: action.error,
});

export const reset = (state = INITIAL_STATE) => ({ ...state, isSuccess: false });

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.GET_LIST_OWNER]: request,
  [Types.UPDATE_OWNER_STATUS]: request,
  [Types.UPDATE_PAYMENT_STATUS]: request,

  [Types.MANAGE_OWNER_SUCCESS]: success,
  [Types.MANAGE_OWNER_FAILURE]: failure,
  [Types.RESET_MANAGE_OWNER]: reset,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
