import { createStore, applyMiddleware, combineReducers } from "redux";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../sagas";
import * as AuthRedux from "./auth.redux";
import * as ErrorRedux from "./error.redux";
import * as BlogsCategoryRedux from "./blogs-category.redux";
import * as BlogItemRedux from "./blog-item.redux";
import * as UploadRedux from "./upload.redux";
import * as ShopRedux from "./shop.redux";
import * as AccountRedux from "./account.redux";
import * as IntroductionRedux from "./introduction.redux";
import * as FeatureRedux from "./feature.redux";
import * as EvaluateRedux from "./evaluate.redux";
import * as HelpCategoryRedux from "./question-category.redux";
import * as HelpQuestionRedux from "./question.redux";
import * as ManageOwnerRedux from "./manage-owner.redux";
import * as OwnerDetailsRedux from "./owner-details.redux";

/* ------------- Assemble The Reducers ------------- */
export const appReducer = combineReducers({
  errorRedux: ErrorRedux.reducer,
  authRedux: AuthRedux.reducer,
  blogsCategoryRedux: BlogsCategoryRedux.reducer,
  blogItemRedux: BlogItemRedux.reducer,
  uploadRedux: UploadRedux.reducer,
  shopRedux: ShopRedux.reducer,
  accountRedux: AccountRedux.reducer,
  introductionRedux: IntroductionRedux.reducer,
  featureRedux: FeatureRedux.reducer,
  evaluateRedux: EvaluateRedux.reducer,
  helpCategoryRedux: HelpCategoryRedux.reducer,
  helpQuestionRedux: HelpQuestionRedux.reducer,
  manageOwnerRedux: ManageOwnerRedux.reducer,
  ownerDetailsRedux: OwnerDetailsRedux.reducer,
});

export const rootReducer = (state, action) => {
  // Action logout
  // if (action.type === KeyConstant.LOGOUT_REQUEST) {
  //   state = undefined;
  // }
  return appReducer(state, action);
};

/* ------------- Redux Configuration ------------- */

/* ------------- Saga Middleware ------------- */
const sagaMiddleware = createSagaMiddleware();

// Create store
const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));

// kick off root saga
sagaMiddleware.run(rootSaga);

export default store;
