import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Box, makeStyles } from "@material-ui/core";
import { AppDialog } from "components";
import PaymentDialogHeader from "./PaymentDialogHeader";
import PaymentDialogViewing from "./PaymentDialogViewing";
import PaymentDialogEditing from "./PaymentDialogEditing";
import OwnerDetailsAction from "redux/owner-details.redux";
import { AppConstant } from "const";

const PaymentDialog = props => {
  const classes = useStyles();
  const { data, ...dialogProps } = props;
  const { onClose } = dialogProps;
  const dispatch = useDispatch();

  const paymentDetails = useSelector(({ ownerDetailsRedux }) => ownerDetailsRedux.paymentDetail, shallowEqual);

  const [paymentDetail, setPaymentDetail] = useState({});
  const [displayMode, setDisplayMode] = useState(
    data?.status === AppConstant.PAYMENTS_STATUS.paid ? DISPLAY_MODE.readOnly : DISPLAY_MODE.view,
  );

  const onChangeMode = () => {
    if (displayMode === DISPLAY_MODE.view) {
      setDisplayMode(DISPLAY_MODE.edit);
    } else {
      onClose();
    }
  };

  const onHandle = data => {
    let cloneData = { ...paymentDetail };
    if (Array.isArray(data)) {
      let packageDetailIndex = data.findIndex(item => item.type === AppConstant.PACKAGE_DETAIL_TYPE.package);

      if (packageDetailIndex > -1) {
        cloneData.startDate = data[packageDetailIndex].startDate;
        cloneData.expiryDate = data[packageDetailIndex].expiryDate;
        cloneData.total = data[packageDetailIndex].total;
        cloneData.period = data[packageDetailIndex].period;
      }
      // Update total price of package
      let sum = 0;
      data.forEach(item => (sum += item.total));
      cloneData.totalPrice = sum;

      setPaymentDetail({ ...cloneData, packageDetails: data });
    } else onClose();
  };

  useEffect(() => {
    let isValidData = data && data.uid;
    if (isValidData) {
      setPaymentDetail(data);
      setDisplayMode(data.status === AppConstant.PAYMENTS_STATUS.paid ? DISPLAY_MODE.readOnly : DISPLAY_MODE.view);

      if (data.packageDetails?.length <= 0) {
        dispatch(OwnerDetailsAction.getPaymentDetails({ uid: data.uid }));
      }
    }
  }, [data]);

  useEffect(() => {
    if (paymentDetails && paymentDetails.uid) {
      setPaymentDetail(paymentDetails);
      setDisplayMode(
        paymentDetails.status === AppConstant.PAYMENTS_STATUS.paid ? DISPLAY_MODE.readOnly : DISPLAY_MODE.view,
      );
    }
  }, [paymentDetails]);

  let isDisplayEdit = displayMode === DISPLAY_MODE.edit;

  return (
    <AppDialog title="Chi tiết thanh toán" {...dialogProps}>
      <Box className={classes.root}>
        <PaymentDialogHeader data={paymentDetail} />
        {isDisplayEdit ? (
          <PaymentDialogEditing data={paymentDetail} onCallback={onHandle} />
        ) : (
          <PaymentDialogViewing
            isEdit={displayMode === DISPLAY_MODE.view}
            onCallback={onChangeMode}
            data={paymentDetail}
          />
        )}
      </Box>
    </AppDialog>
  );
};

PaymentDialog.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.object,

  onClose: PropTypes.func.isRequired,
};

export default PaymentDialog;

const DISPLAY_MODE = {
  readOnly: 1,
  view: 2,
  edit: 3,
};

const useStyles = makeStyles({
  root: {
    width: 1000,
    maxWidth: "100%",
    padding: "16px 24px",
  },
});
