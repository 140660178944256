import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import StringFormat from "string-format";
import { Box, makeStyles } from "@material-ui/core";
import { isArrayNotEqual } from "utils";
import { PathConstant } from "const";
import ManageLayout from "layouts/ManageLayout";
import HeaderBar from "./HeaderBar";
import OwnerList from "./ManageOwner/index";
import { AppLink, PaginationFooter } from "components";
import ManageOwnerAction from "redux/manage-owner.redux";

const ManageOwner = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { data: listOwner, ...pagination } = useSelector(state => state.manageOwnerRedux.data);
  const isSuccess = useSelector(state => state.manageOwnerRedux.isSuccess);

  const [formDataFilter, setFormDataFilter] = useState(DEFAULT_DATA_FORM);
  const [isShowFilter, setIsShowFilter] = useState(false);
  const [pageData, setPageData] = useState(DEFAULT_PAGE);
  const [arrayOwner, setArrayOwner] = useState([]);

  const onChangePage = (_, newPage) => {
    let params = { page: newPage, filter: formDataFilter.filter };
    if (formDataFilter.plan !== OPT_ALL_ITEMS_ID) {
      params.plan = formDataFilter.plan;
    }
    if (formDataFilter.paymentStatus !== OPT_ALL_ITEMS_ID) {
      params.paymentStatus = formDataFilter.paymentStatus;
    }
    if (
      formDataFilter.from.toDateString() !== DEFAULT_DATA_FORM.from.toDateString() &&
      formDataFilter.to.toDateString() !== DEFAULT_DATA_FORM.to.toDateString()
    ) {
      params.from = formDataFilter.from;
      params.to = formDataFilter.to;
    }
    dispatch(ManageOwnerAction.getListOwner(params));
  };

  useEffect(() => {
    dispatch(ManageOwnerAction.getListOwner());
  }, []);

  useEffect(() => {
    if ((listOwner && isArrayNotEqual(arrayOwner, listOwner)) || isSuccess) {
      setArrayOwner(listOwner);
      setPageData({
        page: pagination.page,
        rowsPerPage: 10,
        total: pagination.total || 0,
        totalPage: pagination.totalPage,
      });
      if (isSuccess) {
        dispatch(ManageOwnerAction.resetManageOwner());
      }
    }
  }, [listOwner, isSuccess]);

  return (
    <ManageLayout title="Tài khoản sử dụng dịch vụ">
      <HeaderBar
        dataFilter={formDataFilter}
        onChangeFilter={filter => setFormDataFilter(filter)}
        isShowFilter={isShowFilter}
        onShowFilter={() => setIsShowFilter(!isShowFilter)}
      />
      <Box className={classes.paginationBox}>
        <PaginationFooter data={pageData} onChangePage={onChangePage} />
      </Box>
      <Box className={classes.mainContent}>
        {arrayOwner.map((data, index) => (
          <AppLink
            to={{ pathname: StringFormat(PathConstant.FM_OWNER_DETAIL, data.userId) }}
            key={index}
            underline="none"
          >
            <OwnerList data={data} />
          </AppLink>
        ))}
      </Box>
      <Box className={classes.paginationBox}>
        <PaginationFooter data={pageData} onChangePage={onChangePage} />
      </Box>
    </ManageLayout>
  );
};

export default memo(ManageOwner);

const OPT_ALL_ITEMS_ID = "all";

const DEFAULT_DATA_FORM = {
  filter: "",
  plan: OPT_ALL_ITEMS_ID,
  paymentStatus: OPT_ALL_ITEMS_ID,
  from: new Date(),
  to: new Date(),
};

const DEFAULT_PAGE = {
  total: 0,
  page: 1,
  rowsPerPage: 10,
  totalPage: 0,
};

const useStyles = makeStyles(() => ({
  mainContent: {
    "& > *": {
      padding: "0 8px",
    },
  },
  paginationBox: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));
