import React, { memo, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles, Paper, Box, IconButton, Tooltip, Typography, CardMedia } from "@material-ui/core";
import { EditOutlined, Replay, HomeOutlined } from "@material-ui/icons";
import StringFormat from "string-format";
import clsx from "clsx";
import { AppConstant, ApiConstant } from "const";
import { convertTimestampToDate } from "utils";
import { StatusItem } from "components";
import ConfirmDialog from "components/dialog/ConfirmDialog";
import BlogItemRedux from "redux/blog-item.redux";

const BlogsInList = props => {
  const { data, onEditItem, disableOption } = props;
  const dispatch = useDispatch();
  const defaultClasses = useStyles();

  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);

  const onDeleteItem = () => {
    setIsOpenDeleteDialog(true);
  };

  const onCloseDeleteDialog = () => {
    setIsOpenDeleteDialog(false);
  };

  const onSubmitDeleteDialog = () => {
    let newData = { ...data };
    newData = { ...newData, published: AppConstant.STATUS_OPTION.draft };
    dispatch(BlogItemRedux.updateBlogItem(newData));
    setIsOpenDeleteDialog(false);
  };

  return (
    <Box display="flex">
      <Box className={defaultClasses.coverNumber}>
        <Typography className={clsx("medium-md-txt", defaultClasses.number)}>{data.number}</Typography>
      </Box>
      <Paper className={defaultClasses.paperRoot}>
        <Box className={defaultClasses.boxImage}>
          <CardMedia
            component="img"
            className={defaultClasses.image}
            alt={data?.name}
            src={data?.thumbnail && ApiConstant.HOST_ATTACHMENT + data.thumbnail}
          />
        </Box>
        <Box className={defaultClasses.mainContent}>
          <Box flexBasis="30%">
            <Typography
              className={clsx(
                "semiBold-lg-txt ellipsis",
                defaultClasses.highlightTitle,
                defaultClasses.hiddenTextTwoLine,
              )}
            >
              {data.title.vi}
            </Typography>
          </Box>
          <Box flexBasis="20%">
            <Typography
              className={clsx(
                "medium-md-txt ellipsis",
                defaultClasses.contentCategory,
                defaultClasses.hiddenTextTwoLine,
              )}
            >
              {data.description.vi}
            </Typography>
          </Box>
          <Box flexBasis="20%">
            <Typography
              className={clsx(
                "medium-md-txt ellipsis",
                defaultClasses.greyTextTypography,
                defaultClasses.hiddenTextTwoLine,
              )}
            >
              Ngày tạo: {convertTimestampToDate(data.createdTime)}
            </Typography>
          </Box>
          <Box flexBasis="10%">
            <StatusItem status={data.published} />
          </Box>
          <Box flexBasis="15%">
            <Typography
              className={clsx(
                "medium-md-txt ellipsis",
                defaultClasses.greyTextTypography,
                defaultClasses.hiddenTextTwoLine,
              )}
            >
              Ngày xuất bản: {data.publishTime ? convertTimestampToDate(data.publishTime) : ""}
            </Typography>
          </Box>
          <Box flexBasis="5%">
            {data.flagShowToHomePage === AppConstant.STATUS_DISPLAY_HOME_PAGE.display && (
              <HomeOutlined className={defaultClasses.homeOutlinedIcon} />
            )}
          </Box>
        </Box>
        <Box className={defaultClasses.actionRoot}>
          <Tooltip title="Sửa" classes={{ tooltip: clsx("medium-sm-txt", defaultClasses.tooltip) }}>
            <IconButton
              component="div"
              onClick={onEditItem}
              disabled={disableOption.isDisableEdit}
              classes={{ root: defaultClasses.buttonIconEdit, disabled: defaultClasses.disabledButton }}
            >
              <EditOutlined className={defaultClasses.iconEdit} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Thu hồi" classes={{ tooltip: clsx("medium-sm-txt", defaultClasses.tooltip) }}>
            <IconButton
              component="div"
              onClick={onDeleteItem}
              disabled={disableOption.isDisableDelete}
              classes={{ root: defaultClasses.buttonIconDelete, disabled: defaultClasses.disabledButton }}
            >
              <Replay className={defaultClasses.iconDelete} />
            </IconButton>
          </Tooltip>
          <ConfirmDialog
            isShow={isOpenDeleteDialog}
            onClose={onCloseDeleteDialog}
            onSubmit={onSubmitDeleteDialog}
            title="Thu hồi bài viết"
            message={StringFormat(FM_CONTENT, data.title.vi)}
          />
        </Box>
      </Paper>
    </Box>
  );
};

BlogsInList.propTypes = {
  data: PropTypes.shape({
    number: PropTypes.number,
    image: PropTypes.string,
    title: PropTypes.object,
    description: PropTypes.object,
    createdTime: PropTypes.number,
    flagShowToHomePage: PropTypes.number,
    publishTime: PropTypes.number,
    status: PropTypes.number,
  }),
  onEditItem: PropTypes.func,
  onDeleteItem: PropTypes.func,
  disableOption: PropTypes.shape({
    isDisableEdit: PropTypes.bool,
    isDisableDelete: PropTypes.bool,
  }),
};

BlogsInList.defaultProps = {
  onEditItem: () => {},
  onDeleteItem: () => {},
  disableOption: {
    isDisableEdit: false,
    isDisableDelete: false,
  },
};

export default memo(BlogsInList);

const FM_CONTENT = `Bạn có chắc muốn thu hồi bài viết "{0}" trên website không?`;

const useStyles = makeStyles(theme => ({
  coverNumber: {
    width: 24,
    height: 24,
    marginRight: 17,
    backgroundColor: "#ffffff",
  },
  number: {
    textAlign: "center",
    color: "#3e4045",
    lineHeight: 1.57,
  },
  paperRoot: {
    width: "100%",
    height: 83,
    maxHeight: 83,
    display: "flex",
    borderRadius: 4,
    boxShadow: "0 1px 6px 0 rgba(0, 0, 0, 0.16)",
  },
  boxImage: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: 83,
  },
  image: {
    maxWidth: 83,
    maxHeight: 83,
  },
  mainContent: {
    display: "flex",
    margin: theme.spacing(2, 1),
    flexGrow: 1,
    "& > div": {
      marginLeft: 8,
      marginRight: 8,
    },
  },
  highlightTitle: {
    color: "#3e4045",
    lineHeight: 1.5,
  },
  greyTextTypography: {
    lineHeight: 1.57,
    color: theme.palette.grey[500],
  },
  contentCategory: {
    color: "#3e4045",
    lineHeight: 1.57,
  },
  hiddenTextTwoLine: {
    WebkitLineClamp: 2,
  },
  homeOutlinedIcon: {
    width: 24,
    height: 24,
    padding: 4,
    borderRadius: 2,
    backgroundColor: "rgba(101, 179, 157, 0.2)",
  },
  tooltip: {
    height: 20,
    marginLeft: 50,
    marginTop: 2,
    padding: "0 5px",
    backgroundColor: "#7f838c",
    lineHeight: 1.67,
    textAlign: "center",
    borderRadius: 0,
  },
  actionRoot: {
    minWidth: 85,
    padding: 16,
  },
  buttonIconEdit: {
    width: 24,
    height: 24,
    padding: 0,
    borderRadius: 0,
    border: "1px solid " + theme.palette.grey[200],
    "&:hover": {
      border: "1px solid #feba40",
    },
  },
  iconEdit: {
    width: 19,
    height: 19,
    color: theme.palette.grey[500],
    "&:hover": {
      color: "#feba40",
    },
  },
  buttonIconDelete: {
    width: 24,
    height: 24,
    padding: 0,
    borderRadius: 0,
    border: "1px solid " + theme.palette.grey[200],
    "&:hover": {
      border: "1px solid " + theme.palette.primary.main,
    },
  },
  iconDelete: {
    width: 19,
    height: 19,
    color: theme.palette.grey[500],
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  disabledButton: {
    opacity: 0.4,
  },
}));
