import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, Paper, IconButton, Box, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { FilterIcon } from "icons";
import { SearchBar } from "components";
import { HeaderFilter } from "./index";
import { useDispatch } from "react-redux";
import BlogItemAction from "redux/blog-item.redux";
import clsx from "clsx";

const HeaderBlogsBar = props => {
  const { isShowFilter, onShowFilter } = props;
  const defaultClasses = useStyles({ isShowFilter });
  const dispatch = useDispatch();

  const [valueCategory, setValueCategory] = useState(OPT_ALL_ITEMS_ID);
  const [valueStatus, setValueStatus] = useState(OPT_ALL_ITEMS_ID);

  const onSubmitSearch = name => {
    let formatForm = { filter: name, page: 1 };

    if (valueCategory !== OPT_ALL_ITEMS_ID) {
      formatForm = { ...formatForm, blog_category_id: valueCategory };
    }

    if (valueStatus !== OPT_ALL_ITEMS_ID) {
      formatForm = { ...formatForm, status: valueStatus };
    }

    dispatch(BlogItemAction.getListBlogItem(formatForm));
  };

  const onChangeSelectedCategory = e => {
    setValueCategory(e.target.value);
  };

  const onChangeSelectedStatus = e => {
    setValueStatus(e.target.value);
  };

  return (
    <Paper className={defaultClasses.root}>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex">
          <Typography className={clsx("medium-md-txt", defaultClasses.titleSearch)}>Tìm kiếm bài đăng</Typography>
          <SearchBar onSearch={onSubmitSearch} />
        </Box>
        <IconButton onClick={onShowFilter} className={defaultClasses.buttonFilter}>
          {isShowFilter ? (
            <Close className={defaultClasses.iconFilter} />
          ) : (
            <FilterIcon className={defaultClasses.iconFilter} />
          )}
        </IconButton>
      </Box>
      {isShowFilter && (
        <Box padding="11px 10px 21px">
          <HeaderFilter
            valueCategory={valueCategory}
            valueStatus={valueStatus}
            onChangeCategory={onChangeSelectedCategory}
            onChangeStatus={onChangeSelectedStatus}
          />
        </Box>
      )}
    </Paper>
  );
};

const OPT_ALL_ITEMS_ID = "all";

HeaderBlogsBar.propTypes = {
  isShowFilter: PropTypes.bool,
  onShowFilter: PropTypes.func,
};

HeaderBlogsBar.defaultProps = {
  isShowFilter: false,
  onShowFilter: () => {},
};

export default memo(HeaderBlogsBar);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    padding: "11px 34px",
    borderRadius: 0,
    boxShadow: "0 1px 6px 0 rgba(0, 0, 0, 0.16)",
  },
  titleSearch: {
    margin: "8px 13px 8px 0",
    lineHeight: "22px",
    color: "#3e4045",
  },
  buttonFilter: {
    width: 30,
    height: 30,
    padding: 0,
    borderRadius: 0,
    border: "1px solid " + theme.palette.grey[200],
  },
  iconFilter: {
    width: props => (props?.isShowFilter ? 15 : 20),
    height: props => (props?.isShowFilter ? 15 : 20),
    color: "#4b4d53",
    opacity: props => (props?.isShowFilter ? 0.7 : 1),
  },
}));
