import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Button, Grid, ListItem, ListItemText, makeStyles, Typography } from "@material-ui/core";
import { PackageItem, SelectedItem } from "components";
import { AppConstant, LangConstant } from "const";
import { convertTimestampToDateRange } from "utils";

const ChangePackageDialogHeader = ({ data, onChange }) => {
  const classes = useStyles();

  const [isDisableSelectPackage, setIsDisableSelectPackage] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [packageSelected, setPackageSelected] = useState(null);
  const [featureSelected, setFeatureSelected] = useState(null);
  const [arrayFeature, setArrayFeature] = useState(LangConstant.ARRAY_PACKAGE_DETAILS);

  const onChangePackage = item => {
    if (item) {
      setPackageSelected(item);
    } else setPackageSelected(AppConstant.PACKAGE_TYPE.free);
  };

  const onChangeFeature = item => {
    setFeatureSelected(item);

    let features = [...arrayFeature];
    features = features.filter(feature => feature !== item);
    setArrayFeature(features);
  };

  const onAddPackage = () => {
    if (packageSelected) {
      setIsDisableSelectPackage(true);
      setIsDisable(false);
      onChange({ plan: Number(packageSelected), type: AppConstant.PACKAGE_DETAIL_TYPE.package, isPackage: true });
    }
  };

  useEffect(() => {
    setPackageSelected(AppConstant.PACKAGE_TYPE.free);
    if (data && data.userId) {
      let arrSelectedFeatures = [];
      let newArrFeatures = [];
      let isSelectedPackage = false;

      data.packageDetails.forEach(item => arrSelectedFeatures.push(item.type));

      // Disable add feature when all features already added
      if (arrSelectedFeatures.length === Object.keys(AppConstant.PACKAGE_DETAIL_TYPE).length) {
        setIsDisable(true);
      }

      if (arrSelectedFeatures.length > 0) {
        isSelectedPackage = arrSelectedFeatures.includes(AppConstant.PACKAGE_DETAIL_TYPE.package);
        newArrFeatures = LangConstant.ARRAY_PACKAGE_DETAILS.filter(item => !arrSelectedFeatures.includes(item.value));
      }

      // After each feature be added, set new default selected feature and set new display features selection array
      if (newArrFeatures.length > 0) {
        setArrayFeature(newArrFeatures);
        setFeatureSelected(newArrFeatures[0].value);
      }

      // After new data passed from props, check whether package selected or not to modify isDisableSelectPackage state
      if (!isSelectedPackage) {
        setIsDisableSelectPackage(false);
      }
    }
  }, [data]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={4} className={isDisableSelectPackage && classes.disable}>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={11}>
              <Typography variant="subtitle1" component="p" className={classes.selectTitle}>
                Chọn gói
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <SelectedItem
                value={packageSelected}
                data={LangConstant.ARRAY_PLAN_PACKAGES}
                isShow={false}
                onChangeInput={event => onChangePackage(event)}
              />
            </Grid>
            <Grid item xs={4}>
              <Button variant="outlined" color="primary" onClick={onAddPackage}>
                OK
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12} sm={4} className={isDisable && classes.disable}>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={11}>
              <Typography variant="subtitle1" component="p" className={classes.selectTitle}>
                Thêm mới
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <SelectedItem
                data={arrayFeature}
                isShow={false}
                onChangeInput={event => onChangeFeature(event)}
                value={featureSelected}
              />
            </Grid>
            <Grid item xs={4}>
              <Button variant="outlined" color="primary" onClick={() => onChange({ type: featureSelected })}>
                Thêm
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Box className={classes.packageInfo}>
          <ListItem>
            <ListItemText>
              <Typography variant="body2" component="p" className={classes.packageText}>
                Đang dùng gói
              </Typography>
            </ListItemText>
            <PackageItem packageType={data.plan} />
          </ListItem>
          <Typography variant="body2" component="p" className={classes.smallText}>
            {convertTimestampToDateRange(data.startDate, data.expiryDate)}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

ChangePackageDialogHeader.propTypes = { data: PropTypes.object };

export default ChangePackageDialogHeader;

const useStyles = makeStyles(theme => ({
  root: {
    width: 980,
    maxWidth: "100%",
  },
  disableTitle: {
    fontSize: 14,
    opacity: 0.4,
    "& > span": {
      fontSize: 14,
      color: "#3e4045",
    },
  },
  selectTitle: {
    fontSize: 14,
    "& > span": {
      fontSize: 14,
      color: "#3e4045",
    },
  },
  packageInfo: {
    background: "rgba(212, 213, 216, 0.2)",
    borderRadius: 8,
    textAlign: "center",
    float: "right",

    [theme.breakpoints.down("xs")]: {
      float: "left",
      marginTop: 16,
    },

    "& $smallText": {
      padding: 8,
    },
  },
  packageText: { fontSize: 12, fontWeight: "normal", marginRight: 8 },
  smallText: {
    fontSize: 12,
    color: theme.palette.grey[500],
  },
  highlight: {
    color: "#ff4a5e",
  },
  disable: {
    opacity: 0.4,
    pointerEvents: "none",
  },
}));
