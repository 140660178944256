import React, { memo } from "react";
import { SvgIcon } from "@material-ui/core";

const MarkAsPaidIcon = ({ className }) => {

  return (
    <SvgIcon className={className} viewBox=" 0 0 16 16">
      <path d="M8 1.333A6.67 6.67 0 0 0 1.333 8 6.67 6.67 0 0 0 8 14.667 6.67 6.67 0 0 0 14.667 8 6.67 6.67 0 0 0 8 1.333zm0 12A5.34 5.34 0 0 1 2.667 8 5.34 5.34 0 0 1 8 2.667 5.34 5.34 0 0 1 13.334 8 5.34 5.34 0 0 1 8 13.333zM8.594 7.4c-1.187-.393-1.76-.64-1.76-1.267 0-.68.74-.926 1.206-.926.874 0 1.194.66 1.267.893l1.053-.447c-.1-.293-.546-1.273-1.773-1.486v-.834H7.42v.84c-1.733.374-1.747 1.9-1.747 1.974 0 1.513 1.5 1.94 2.234 2.206 1.053.374 1.52.714 1.52 1.354 0 .753-.7 1.073-1.32 1.073-1.213 0-1.56-1.247-1.6-1.393L5.4 9.833c.42 1.46 1.52 1.854 2.013 1.974v.86H8.58v-.827c.347-.06 2.014-.393 2.014-2.147.006-.926-.4-1.74-2-2.293z" />
    </SvgIcon>
  );
};

export default memo(MarkAsPaidIcon);
