import MuiButton from "./MuiButton";
import MuiContainer from "./MuiContainer";
import MuiAppBar from "./MuiAppBar";
import MuiPaper from "./MuiPaper";

export default {
  MuiButton,
  MuiContainer,
  MuiAppBar,
  MuiPaper,
};
