import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Dialog, Typography, IconButton, Avatar } from "@material-ui/core";
import { DesktopMac, TabletMac, Tablet, PhoneIphone, Close, Language } from "@material-ui/icons";
import clsx from "clsx";
import { LangConstant, ApiConstant } from "const";
import { SelectBar } from "components";

const DialogPreview = props => {
  const { isShow, data, tabValue, onChangeLanguage, onClose } = props;
  const defaultClasses = useStyles();
  const arrayDevices = arrayDevice(defaultClasses.icon);
  const arrayLanguages = arrayLanguage(defaultClasses.icon);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedLanguageIndex, setSelectedLanguageIndex] = useState(
    arrayLanguages.findIndex(item => item.code === tabValue),
  );
  const [objectClasses, setObjectClasses] = useState({
    body: defaultClasses.body,
    header: defaultClasses.header,
    bodyInfo: defaultClasses.bodyInfo,
  });

  const onChangeSelectedIndex = index => {
    let objClasses = convertObjectClasses(defaultClasses, arrayDevices[index].name);
    setObjectClasses(objClasses);
    setSelectedIndex(index);
  };

  const onChangeTabLanguage = index => {
    setSelectedLanguageIndex(index);
    onChangeLanguage(arrayLanguages[index].code);
  };

  let isMobile = arrayDevices[selectedIndex].name === NAME_DEVICE.mobile;
  return (
    <Dialog open={isShow} fullScreen classes={{ paper: defaultClasses.dialogPaper }}>
      <Box className={defaultClasses.root}>
        <Box className={defaultClasses.dialogHeader}>
          <Typography className={clsx("medium-md-txt", defaultClasses.textPreview)}>Gboss Preview</Typography>
          <Box display="flex">
            <Box marginRight="8px">
              <SelectBar onChange={onChangeTabLanguage} arrayItem={arrayLanguages} indexItem={selectedLanguageIndex} />
            </Box>
            <SelectBar onChange={onChangeSelectedIndex} arrayItem={arrayDevices} indexItem={selectedIndex} />
            <IconButton className={defaultClasses.buttonClose} onClick={onClose}>
              <Close className={defaultClasses.iconClose} />
            </IconButton>
          </Box>
        </Box>
        <Box className={objectClasses.body}>
          <Typography className={objectClasses.header}>
            16,000+ salon professionals across the world trust our scheduling software
          </Typography>
          <Box className={objectClasses.bodyInfo}>
            <Box display="flex">
              <Avatar
                className={clsx(defaultClasses.avatar, isMobile && defaultClasses.avatarMobile)}
                src={ApiConstant.HOST_ATTACHMENT + data.image[tabValue]}
              />
              <Box>
                <Typography
                  className={clsx("ellipsis", defaultClasses.deputy, isMobile && defaultClasses.deputyMobile)}
                >
                  {data.description[tabValue]}
                </Typography>
                <Typography className={clsx("ellipsis", defaultClasses.title)}>{data.title[tabValue]}</Typography>
              </Box>
            </Box>
            <Box
              className={clsx(
                "ellipsis",
                defaultClasses.content,
                defaultClasses.limitFourLine,
                isMobile && defaultClasses.limitEightLine,
              )}
            >
              <Typography className={defaultClasses.title}>{data.content[tabValue]}</Typography>
            </Box>
            <Box className={defaultClasses.parentBottom}>
              <Box className={defaultClasses.boxViewMore}>
                <Typography className={defaultClasses.typoViewMore}>View more</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

DialogPreview.propTypes = {
  isShow: PropTypes.bool,
  tabValue: PropTypes.string,
  data: PropTypes.shape({
    id: PropTypes.number,
    image: PropTypes.shape({
      vi: PropTypes.string,
      en: PropTypes.string,
    }),
    logo: PropTypes.shape({
      vi: PropTypes.string,
      en: PropTypes.string,
    }),
    blog: PropTypes.shape({
      vi: PropTypes.string,
      en: PropTypes.string,
    }),
    title: PropTypes.shape({
      vi: PropTypes.string,
      en: PropTypes.string,
    }),
    content: PropTypes.shape({
      vi: PropTypes.string,
      en: PropTypes.string,
    }),
    description: PropTypes.shape({
      vi: PropTypes.string,
      en: PropTypes.string,
    }),
    created: PropTypes.number,
    publishedTime: PropTypes.number,
    published: PropTypes.number,
  }),
  onChangeLanguage: PropTypes.func,
  onClose: PropTypes.func,
};

DialogPreview.defaultProps = {
  isShow: false,
  onChangeLanguage: () => {},
  onClose: () => {},
};

export default memo(DialogPreview);

const NAME_DEVICE = {
  desktop: "Desktop",
  ipadPro: "Ipad Pro",
  ipad: "Ipad",
  mobile: "Mobile",
};

const convertObjectClasses = (defaultClasses, name) => {
  let objClasses = {
    body: defaultClasses.body,
    header: defaultClasses.header,
    bodyInfo: defaultClasses.bodyInfo,
  };
  switch (name) {
    case NAME_DEVICE.ipadPro:
      objClasses.body = clsx(defaultClasses.body, defaultClasses.bodyIpadPro);
      objClasses.header = clsx(defaultClasses.header, defaultClasses.headerIpadPro);
      objClasses.bodyInfo = clsx(defaultClasses.bodyInfo, defaultClasses.bodyInfoIpadPro);
      break;
    case NAME_DEVICE.ipad:
      objClasses.body = clsx(defaultClasses.body, defaultClasses.bodyIpad);
      objClasses.header = clsx(defaultClasses.header, defaultClasses.headerIpad);
      objClasses.bodyInfo = clsx(defaultClasses.bodyInfo, defaultClasses.bodyInfoIpad);
      break;
    case NAME_DEVICE.mobile:
      objClasses.body = clsx(defaultClasses.body, defaultClasses.bodyMobile);
      objClasses.header = clsx(defaultClasses.header, defaultClasses.headerMobile);
      objClasses.bodyInfo = clsx(defaultClasses.bodyInfo, defaultClasses.bodyInfoMobile);
      break;
    default:
      break;
  }
  return objClasses;
};

const arrayLanguage = className => {
  let ARR_LANGUAGE = [
    {
      name: LangConstant.OBJ_LANGUAGE.vi.lang,
      icon: <Language className={className} />,
      code: LangConstant.OBJ_LANGUAGE.vi.code,
    },
    {
      name: LangConstant.OBJ_LANGUAGE.en.lang,
      icon: <Language className={className} />,
      code: LangConstant.OBJ_LANGUAGE.en.code,
    },
  ];
  return ARR_LANGUAGE;
};

const arrayDevice = className => {
  let ARR_DEVICE = [
    {
      name: NAME_DEVICE.desktop,
      icon: <DesktopMac className={className} />,
    },
    {
      name: NAME_DEVICE.ipadPro,
      icon: <Tablet className={className} />,
    },
    {
      name: NAME_DEVICE.ipad,
      icon: <TabletMac className={className} />,
    },
    {
      name: NAME_DEVICE.mobile,
      icon: <PhoneIphone className={className} />,
    },
  ];
  return ARR_DEVICE;
};

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    width: "100%",
    borderRadius: 0,
  },
  root: {
    width: "100%",
    height: "100%",
    backgroundColor: "#3e4045",
  },
  dialogHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 46,
    padding: "0 24px",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
  textPreview: {
    height: 22,
    lineHeight: 1.57,
    color: theme.palette.white,
  },
  icon: {
    height: 22,
    width: 22,
    marginRight: 8,
  },
  buttonClose: {
    width: 46,
    height: 46,
    marginLeft: 8,
    borderRadius: 0,
    backgroundColor: theme.palette.black,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  iconClose: {
    width: 46,
    height: 46,
    padding: 7,
    color: theme.palette.white,
  },
  body: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 46px)",
    overflow: "auto",
    alignItems: "center",
    backgroundColor: theme.palette.white,
  },
  bodyIpadPro: {
    width: 1024,
    margin: "auto",
  },
  bodyIpad: {
    width: 768,
    margin: "auto",
  },
  bodyMobile: {
    width: 518,
    margin: "auto",
  },
  header: {
    width: 996,
    marginTop: 100,
    textAlign: "center",
    fontSize: 42,
    lineHeight: 1.19,
    fontWeight: 800,
    color: "#3e4045",
  },
  headerIpadPro: {
    width: 960,
  },
  headerIpad: {
    width: 712,
  },
  headerMobile: {
    width: 340,
    fontSize: 36,
    lineHeight: 1.28,
    letterSpacing: "0.4px",
  },
  bodyInfo: {
    width: 1200,
    height: 480,
    marginTop: 40,
    marginBottom: 100,
    padding: "76px 204px 83px",
    borderRadius: 20,
    backgroundColor: "#303236",
  },
  bodyInfoIpadPro: {
    width: 960,
    padding: "77px 164px",
  },
  bodyInfoIpad: {
    width: 712,
    padding: "54px 92px 60px",
  },
  bodyInfoMobile: {
    width: 340,
    minHeight: 607,
    padding: "54px 24px 154px",
  },
  avatar: {
    width: 82,
    height: 82,
    marginRight: 24,
  },
  avatarMobile: {
    width: 68,
    height: 68,
    marginRight: 16,
  },
  deputy: {
    fontSize: 30,
    lineHeight: 1.47,
    fontWeight: 800,
    color: "#d4d5d8",
  },
  deputyMobile: {
    fontSize: 24,
    lineHeight: 1.25,
  },
  title: {
    marginTop: 3,
    fontSize: 17,
    lineHeight: 1.53,
    letterSpacing: "0.2px",
    color: "#d4d5d8",
    wordBreak: "break-word",
  },
  content: {
    maxHeight: 104,
    minHeight: 104,
    margin: "36px 0 48px",
  },
  limitFourLine: {
    WebkitLineClamp: 4,
  },
  limitEightLine: {
    maxHeight: 208,
    minHeight: 208,
    WebkitLineClamp: 8,
  },
  parentBottom: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    height: 57,
  },
  boxViewMore: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 141,
    height: 40,
    borderRadius: 8,
    border: "1px solid " + theme.palette.grey[500],
  },
  typoViewMore: {
    width: 79,
    height: 24,
    lineHeight: 1.6,
    letterSpacing: 0.2,
    color: theme.palette.white,
    fontSize: 15,
    fontWeight: 500,
  },
}));
