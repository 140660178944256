import { ApiConstant } from "const";
import { call, put, select } from "redux-saga/effects";
import QuestionCategoryAction from "redux/question-category.redux";
import { QuestionCategoryService } from "services";
import { toCamel, toSnake } from "utils";

export function* getListQuestionCategory() {
  try {
    let response = yield call(QuestionCategoryService.getCategories);
    if (response.status === ApiConstant.STT_OK) {
      let responseData = toCamel(response.data.data);
      yield put(QuestionCategoryAction.helpCategorySuccess({ data: responseData }));
    } else {
      let responseData = response.data?.error;
      yield put(QuestionCategoryAction.helpCategoryFailure(responseData));
    }
  } catch (error) {
    yield put(QuestionCategoryAction.helpCategoryFailure(error));
  }
}

export function* createQuestionCategory(action) {
  try {
    const {
      helpCategoryRedux: { data: reduxData },
    } = yield select();
    const { data } = action;
    let formData = { ...data };
    if (!formData?.id) {
      delete formData["id"];
    }
    let response = yield call(QuestionCategoryService.postCategory, toSnake(formData));
    if (response.status === ApiConstant.STT_OK) {
      let newData = [...reduxData, toCamel(response.data.data)];
      yield put(QuestionCategoryAction.helpCategorySuccess({ data: newData }));
    } else {
      let responseData = response.data?.error;
      yield put(QuestionCategoryAction.helpCategoryFailure(responseData));
    }
  } catch (error) {
    yield put(QuestionCategoryAction.helpCategoryFailure(error));
  }
}

export function* updateQuestionCategory(action) {
  try {
    const {
      helpCategoryRedux: { data: reduxData },
    } = yield select();
    const { data } = action;

    let response = yield call(QuestionCategoryService.putCategory, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      let selectedIndex = reduxData.findIndex(key => key.id === data.id);

      let updateArray = [...reduxData];
      updateArray[selectedIndex] = Object.assign(updateArray[selectedIndex], toCamel(response.data.data));

      yield put(QuestionCategoryAction.helpCategorySuccess({ data: updateArray }));
    } else {
      let responseData = response.data?.error;
      yield put(QuestionCategoryAction.helpCategoryFailure(responseData));
    }
  } catch (error) {
    yield put(QuestionCategoryAction.helpCategoryFailure(error));
  }
}

export function* deleteQuestionCategory(action) {
  try {
    const {
      helpCategoryRedux: { data: reduxData },
    } = yield select();
    const { data } = action;

    let response = yield call(QuestionCategoryService.deleteCategory, data);
    if (response.status === ApiConstant.STT_OK) {
      let newData = reduxData.filter(item => item.id !== data.id);
      yield put(QuestionCategoryAction.helpCategorySuccess({ data: newData }));
    } else {
      let responseData = response.data?.error;
      yield put(QuestionCategoryAction.helpCategoryFailure(responseData));
    }
  } catch (error) {
    yield put(QuestionCategoryAction.helpCategoryFailure(error));
  }
}
