import React, { useState, useEffect } from "react";
import { PathConstant } from "const";
import { Redirect } from "react-router-dom";
import AuthAction from "redux/auth.redux";
import ErrorAction from "redux/error.redux";
import { Avatar, Box, Button, Container, CssBaseline, TextField, Typography, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { LockOutlined } from "@material-ui/icons";
import { getError, hasLogin } from "utils";
import { Alert } from "components";

const Login = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isResultApi = useSelector(({ authRedux }) => authRedux.isLogin);
  const errorContent = useSelector(({ errorRedux }) => getError(errorRedux));
  let isLogin = hasLogin() || isResultApi;

  const [account, setAccount] = useState(ACC_LOGIN_DEFAULT);
  const [isError, setIsError] = useState(false);

  const onChange = event => {
    let newAccount = {};
    const dataKey = event.target.name;
    const dataValue = event.target.value;
    newAccount[dataKey] = dataValue;
    setAccount({
      ...account,
      ...newAccount,
    });
  };

  const onLogin = event => {
    dispatch(ErrorAction.resetError());
    dispatch(AuthAction.requestLogin(account));
    event.preventDefault();
  };

  useEffect(() => {
    setIsError(Boolean(errorContent));
  }, [errorContent]);

  useEffect(() => {
    return () => dispatch(AuthAction.loginReset());
  }, [dispatch]);

  return isLogin ? (
    <Redirect to={PathConstant.ROOT} />
  ) : (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
          Đăng nhập
        </Typography>
        <form className={classes.form} onSubmit={onLogin}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            onChange={onChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={onChange}
          />
          <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
            Đăng nhập
          </Button>
        </form>
      </Box>
      <Alert
        isShow={isError}
        onClose={() => setIsError(false)}
        type="error"
        message="Kiểm tra lại username/ password"
      />
    </Container>
  );
};

export default Login;

const ACC_LOGIN_DEFAULT = { username: "", password: "" };

const useStyles = makeStyles(theme => ({
  paper: {
    paddingTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
