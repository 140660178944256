import { ApiConstant } from "const";
import { call, put, select } from "redux-saga/effects";
import BlogItemAction from "redux/blog-item.redux";
import { BlogItemService } from "services";
import { toCamel, toSnake } from "utils";

export function* getListBlogItem(action) {
  try {
    const {
      blogItemRedux: { data: dataBlogItem },
    } = yield select();
    const { data } = action;

    let formDataPage = formatPage(dataBlogItem);

    let response = yield call(BlogItemService.getListBlogItem, { ...formDataPage, ...data });
    if (response.status === ApiConstant.STT_OK) {
      let responseData = toCamel(response.data);
      yield put(BlogItemAction.blogItemSuccess({ data: responseData }));
    } else {
      let responseData = response.data?.error;
      yield put(BlogItemAction.blogItemFailure(responseData));
    }
  } catch (error) {
    yield put(BlogItemAction.blogItemFailure(error));
  }
}

export function* updateBlogItem(action) {
  try {
    let newBlogItem = action.data || {};

    const {
      blogItemRedux: { data: reduxData },
    } = yield select();

    let response = yield call(BlogItemService.updateBlogItem, toSnake(newBlogItem));

    if (response.status === ApiConstant.STT_OK) {
      let listBlogs = reduxData.data;
      let selectedIndex = listBlogs.findIndex(key => key.id === newBlogItem.id);

      let updateBlog = [...listBlogs];
      updateBlog[selectedIndex] = Object.assign(updateBlog[selectedIndex], newBlogItem);

      let updateReduxData = { ...reduxData, data: updateBlog };
      yield put(BlogItemAction.blogItemSuccess({ data: updateReduxData, isUpdateSuccess: true }));
    } else {
      yield put(BlogItemAction.blogItemFailure(response.data));
    }
  } catch (error) {
    yield put(BlogItemAction.blogItemFailure(error));
  }
}

export function* createBlogItem(action) {
  try {
    const { data } = action;

    let response = yield call(BlogItemService.postBlogItem, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      yield put(BlogItemAction.blogItemSuccess({ isCreateSuccess: true }));
    } else {
      let responseData = response.data?.error;
      yield put(BlogItemAction.blogItemFailure(responseData));
    }
  } catch (error) {
    yield put(BlogItemAction.blogItemFailure(error));
  }
}

const formatPage = data => ({
  page: data?.page || 1,
  size: data?.size || 10,
  asc: 1,
  filter: data?.filter || "",
});
