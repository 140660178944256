import React, { memo, useState, useEffect } from "react";
import { makeStyles, Box, Typography, Avatar, Button } from "@material-ui/core";
import { AppConstant, LangConstant } from "const";
import ManageLayout from "layouts/ManageLayout";
import { SelectedItem } from "components";
import { LineInfo } from "./components";
import { useSelector, useDispatch } from "react-redux";
import AccountAction from "redux/account.redux";
import clsx from "clsx";
import Cookies from "js-cookie";

const ManageProfile = () => {
  const defaultClasses = useStyles();
  const dispatch = useDispatch();
  const userProfile = useSelector(state => state.accountRedux.accountDetails);
  const isUpdateProfileSuccess = useSelector(state => state.accountRedux.isUpdateSuccess);

  const [profile, setProfile] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [passwordForm, setPasswordForm] = useState({
    password: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [isShowAlert, setIsShowAlert] = useState(false);

  const onEditProfile = () => {
    setIsEditing(true);
  };

  const onChangeProfile = e => {
    let name = e.target.name;
    let value = e.target.value;
    setProfile({ ...profile, [name]: value });
  };

  const onChangeGender = e => {
    let value = e.target.value;
    setProfile({
      ...profile,
      gender: value,
    });
  };

  const onChangePassword = e => {
    let name = e.target.name;
    let value = e.target.value;
    setPasswordForm({ ...passwordForm, [name]: value });
  };

  const onSubmitForm = () => {
    let isNotChangingPassword = !passwordForm.password && !passwordForm.newPassword && !passwordForm.confirmPassword;
    if (isNotChangingPassword) {
      dispatch(AccountAction.updateAccount(profile));
    } else if (checkPasswordIsValid(passwordForm)) {
      dispatch(AccountAction.updateAccount(profile));
      dispatch(AccountAction.changePassword(passwordForm));
    } else {
      setIsShowAlert(true);
    }
  };

  const onCancelProfile = () => {
    setProfile(userProfile);
    setPasswordForm({
      password: "",
      newPassword: "",
      confirmPassword: "",
    });
    setIsEditing(false);
    setIsShowAlert(false);
  };

  useEffect(() => {
    let userId = Cookies.get(AppConstant.KEY_USER_ID);
    dispatch(AccountAction.getAccountDetails({ id: userId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userProfile) {
      setProfile(userProfile);
    }
  }, [userProfile]);

  useEffect(() => {
    if (isUpdateProfileSuccess) {
      dispatch(AccountAction.resetAccount());
      setIsEditing(false);
      setIsShowAlert(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateProfileSuccess]);

  return (
    <Box>
      <ManageLayout
        title={isEditing ? "Chỉnh sửa thông tin" : "Quản lý tài khoản"}
        buttonProps={{ onClick: onEditProfile, content: "Chỉnh sửa" }}
      />
      <Box className={defaultClasses.root}>
        <Box width="268px">
          <Avatar
            classes={{ root: defaultClasses.avatar, colorDefault: defaultClasses.colorAvatar }}
            alt={profile?.name || ""}
            src={profile?.avatar || profile?.name || ""}
          />
        </Box>
        <Box>
          <Typography className={defaultClasses.headerTitle}>Thông tin tài khoản</Typography>
          <LineInfo
            isEditing={isEditing}
            title="Tên tài khoản"
            name="name"
            onChange={onChangeProfile}
            content={profile?.name || ""}
            placeholder="Nhập tên tài khoản"
          />
          <LineInfo
            isEditing={isEditing}
            title="Email"
            name="email"
            onChange={onChangeProfile}
            content={profile?.email || ""}
            placeholder="Nhập email"
          />
          <LineInfo
            isEditing={isEditing}
            title="Số điện thoại"
            name="phoneNumber"
            onChange={onChangeProfile}
            content={profile?.phoneNumber || ""}
            placeholder="Nhập số điện thoại"
          />
          <Box className={clsx(defaultClasses.parentBox, isEditing && defaultClasses.parentEdit)}>
            <Typography className={clsx(defaultClasses.title, isEditing && defaultClasses.titleEdit)}>
              Giới tính
            </Typography>
            {isEditing ? (
              <SelectedItem
                classes={{ root: defaultClasses.selectedItem }}
                value={profile?.gender || 0}
                data={LangConstant.ARRAY_GENDER}
                onChangeInput={onChangeGender}
                isShow={false}
              />
            ) : (
              <Typography className={clsx(defaultClasses.content, defaultClasses.contentText)}>
                {profile?.gender ? AppConstant.GENDER[profile.gender] : "-"}
              </Typography>
            )}
          </Box>
          <Box className={defaultClasses.parentBox}>
            <Typography className={clsx(defaultClasses.title, isEditing && defaultClasses.titleEditStatus)}>
              Trạng thái
            </Typography>
            <Typography
              className={clsx(
                defaultClasses.content,
                profile?.state === AppConstant.STATUS_ACCOUNT.inactive && defaultClasses.colorContent,
              )}
            >
              {profile?.state === AppConstant.STATUS_ACCOUNT.inactive ? "Inactive" : "Active"}
            </Typography>
          </Box>
          <Box marginTop="32px">
            <Typography className={defaultClasses.headerTitle}>Thông tin bảo mật</Typography>
            <LineInfo
              isEditing={isEditing}
              title={isEditing ? "Mật khẩu cũ" : "Mật khẩu"}
              name="password"
              type="password"
              onChange={onChangePassword}
              content={isEditing ? passwordForm.password : "********"}
              placeholder="Nhập mật khẩu cũ"
            />
            {isEditing && (
              <Box>
                <LineInfo
                  isEditing={isEditing}
                  title="Mật khẩu mới"
                  name="newPassword"
                  type="password"
                  onChange={onChangePassword}
                  content={passwordForm.newPassword || ""}
                  placeholder="Nhập mật khẩu mới"
                />
                <LineInfo
                  isEditing={isEditing}
                  title="Mật khẩu mới"
                  name="confirmPassword"
                  type="password"
                  onChange={onChangePassword}
                  content={passwordForm.confirmPassword || ""}
                  placeholder="Nhập mật khẩu mới"
                />
              </Box>
            )}
            {isShowAlert && <Typography className={defaultClasses.message}>Thông tin không hợp lệ!</Typography>}
            {isEditing && (
              <Box className={defaultClasses.formAction}>
                <Button
                  className={defaultClasses.cancelButton}
                  color="primary"
                  variant="outlined"
                  onClick={onCancelProfile}
                >
                  Hủy
                </Button>
                <Button
                  className={defaultClasses.confirmButton}
                  color="primary"
                  variant="contained"
                  onClick={onSubmitForm}
                >
                  Lưu
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const checkPasswordIsValid = data => {
  let isValidData = data.password && data.newPassword && data.confirmPassword;
  let isNewPasswordValid = data.password !== data.newPassword && data.newPassword === data.confirmPassword;

  return isValidData && isNewPasswordValid;
};

export default memo(ManageProfile);

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    marginTop: 24,
    height: "calc(100vh - 190px)",
  },
  headerTitle: {
    marginBottom: 18,
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.57,
    color: "#3e4045",
  },
  avatar: {
    width: 120,
    height: 120,
    marginLeft: 100,
    fontSize: 48,
    fontWeight: 600,
  },
  colorAvatar: {
    backgroundColor: theme.palette.secondary.main,
  },
  parentBox: {
    display: "flex",
    width: 532,
    minHeight: 22,
    marginBottom: 18,
  },
  selectedItem: {
    width: "100%",
    minWidth: "unset",
    marginLeft: 24,
  },
  parentEdit: {
    marginBottom: 8,
  },
  title: {
    width: 110,
    padding: 4,
    marginRight: 24,
    fontSize: 12,
    color: theme.palette.grey[500],
    lineHeight: 1.67,
  },
  titleEdit: {
    marginRight: 0,
    marginTop: 6,
  },
  titleEditStatus: {
    marginRight: 0,
  },
  content: {
    height: 22,
    fontSize: 14,
    marginTop: 3,
    padding: "0px 16px",
    color: theme.palette.secondary.main,
    "&$disabled": {
      backgroundColor: "#d4d5d8",
      opacity: 0.3,
      color: "#565c6a",
      border: "none",
      cursor: "no-drop",
    },
  },
  contentText: {
    fontSize: 14,
    color: "#3e4045",
    lineHeight: 1.57,
  },
  colorContent: {
    color: theme.palette.primary.dark,
  },
  formAction: {
    display: "flex",
    justifyContent: "center",
    marginTop: 48,
  },
  cancelButton: {
    borderRadius: 4,
    marginRight: 8,
  },
  confirmButton: {
    borderRadius: 4,
    marginLeft: 8,
  },
  message: {
    fontSize: 14,
    lineHeight: 1.57,
    color: theme.palette.primary.dark,
    textAlign: "center",
  },
}));
