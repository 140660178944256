import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Typography, Tooltip, IconButton } from "@material-ui/core";
import { EditOutlined, AccountCircleOutlined } from "@material-ui/icons";
import { NoAccountIcon, RestartIcon } from "icons";
import StatusAccount from "./StatusAccount";
import { AppConstant } from "const";
import clsx from "clsx";

const BodyRow = props => {
  const { data, onClick } = props;
  const defaultClasses = useStyles();
  let className = clsx("medium-md-txt", defaultClasses.fontText);

  return (
    <Box className={defaultClasses.root}>
      <Box flexBasis="6%" paddingRight="12px">
        <Typography className={clsx(className, defaultClasses.titleCenter)}>{data.number}</Typography>
      </Box>
      <Box flexBasis="22%" paddingRight="12px">
        <Typography className={className}>{data.name}</Typography>
      </Box>
      <Box flexBasis="22%" paddingRight="12px">
        <Typography className={className}>{data.email}</Typography>
      </Box>
      <Box flexBasis="16%" paddingRight="12px">
        <Typography className={className}>{data.phoneNumber}</Typography>
      </Box>
      <Box flexBasis="10%" paddingRight="12px">
        <Typography className={className}>{data.gender ? AppConstant.GENDER[data.gender] : ""}</Typography>
      </Box>
      <Box flexBasis="10%" paddingRight="12px">
        <StatusAccount status={data.state} />
      </Box>
      <Box flexBasis="14%" paddingRight="12px" textAlign="right">
        <Tooltip title="Sửa" classes={{ tooltip: clsx("medium-sm-txt", defaultClasses.tooltip) }}>
          <IconButton
            onClick={() => onClick(data, OPTION_ONCLICK.isEdit)}
            classes={{ root: defaultClasses.buttonIconEdit, disabled: defaultClasses.disabledButton }}
          >
            <EditOutlined className={defaultClasses.iconEdit} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Reset mật khẩu" classes={{ tooltip: clsx("medium-sm-txt", defaultClasses.tooltip) }}>
          <IconButton
            disabled={!Boolean(data.password)}
            component="div"
            onClick={() => onClick(data, OPTION_ONCLICK.isResetPassword)}
            classes={{ root: defaultClasses.buttonIconDisabled, disabled: defaultClasses.disabledButton }}
          >
            <RestartIcon className={defaultClasses.iconDisabled} />
          </IconButton>
        </Tooltip>
        <Tooltip
          title={data.state === AppConstant.STATUS_ACCOUNT.inactive ? "Kích hoạt" : "Vô hiệu hóa"}
          classes={{ tooltip: clsx("medium-sm-txt", defaultClasses.tooltip) }}
        >
          <IconButton
            component="div"
            onClick={() => onClick(data, OPTION_ONCLICK.isDisabled)}
            classes={{ root: defaultClasses.buttonIconDisabled, disabled: defaultClasses.disabledButton }}
          >
            {data.state === AppConstant.STATUS_ACCOUNT.inactive ? (
              <AccountCircleOutlined className={defaultClasses.iconDisabled} />
            ) : (
              <NoAccountIcon className={defaultClasses.iconDisabled} />
            )}
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

const OPTION_ONCLICK = {
  isEdit: 1,
  isResetPassword: 2,
  isDisabled: 3,
};

BodyRow.propTypes = {
  data: PropTypes.object,
  onClick: PropTypes.func,
};
BodyRow.defaultProps = {};

export default memo(BodyRow);

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    marginTop: 8,
    padding: 16,
    boxShadow: "0 1px 6px 0 rgba(0, 0, 0, 0.16)",
    borderRadius: 4,
  },
  fontText: {
    lineHeight: 1.57,
    color: "#3e4045",
    fontSize: 14,
    fontWeight: 400,
    lineBreak: "anywhere",
  },
  titleCenter: {
    textAlign: "center",
  },
  tooltip: {
    height: 20,
    marginLeft: 50,
    marginTop: 2,
    padding: "0 5px",
    backgroundColor: "#7f838c",
    lineHeight: 1.67,
    textAlign: "center",
    borderRadius: 0,
  },
  buttonIconEdit: {
    width: 24,
    height: 24,
    padding: 0,
    borderRadius: 0,
    border: "1px solid " + theme.palette.grey[200],
    "&:hover": {
      border: "1px solid #feba40",
    },
  },
  iconEdit: {
    width: 19,
    height: 19,
    color: theme.palette.grey[500],
    "&:hover": {
      color: "#feba40",
    },
  },
  buttonIconDisabled: {
    width: 24,
    height: 24,
    padding: 0,
    borderRadius: 0,
    border: "1px solid " + theme.palette.grey[200],
    "&:hover": {
      border: "1px solid " + theme.palette.primary.main,
    },
  },
  iconDisabled: {
    width: 19,
    height: 19,
    color: theme.palette.grey[500],
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  disabledButton: {
    opacity: 0.4,
  },
}));
