import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Grid, OutlinedInput, Typography } from "@material-ui/core";
import stringFormat from "string-format";
import clsx from "clsx";
import { LangConstant } from "const";

const InformationEvaluate = props => {
  const { data, onChange, langValue } = props;
  const defaultClasses = useStyles();

  let langString = langValue.toUpperCase();

  return (
    <Grid item xs={9}>
      <FormInput
        title="Người đại diện"
        multiline
        name="description"
        onChange={onChange}
        value={data?.description}
        limit={55}
        placeholder={stringFormat(LangConstant.FM_INPUT_DEPUTY_EVALUATE, langString)}
        classes={{
          root: defaultClasses.rootInput,
          input: defaultClasses.input,
          multiline: defaultClasses.inputMultipleLine,
        }}
      />
      <FormInput
        title="Chức danh"
        multiline
        name="title"
        onChange={onChange}
        value={data?.title}
        limit={100}
        placeholder={stringFormat(LangConstant.FM_INPUT_TITLE_EVALUATE, langString)}
        classes={{
          root: defaultClasses.rootInput,
          input: defaultClasses.input,
          multiline: defaultClasses.inputMultipleLine,
        }}
      />
      <FormInput
        title="Nội dung"
        multiline
        name="content"
        onChange={onChange}
        value={data?.content}
        limit={280}
        placeholder={stringFormat(LangConstant.FM_CONTENT_FEATURE, langString)}
        classes={{
          root: defaultClasses.rootInput,
          input: defaultClasses.input,
          multiline: defaultClasses.inputMultipleLine,
        }}
      />
      <FormInput
        title="Link tới bài viết"
        multiline
        name="blog"
        onChange={onChange}
        value={data?.blog}
        isHidden
        placeholder={stringFormat(LangConstant.FM_LINK_TO_BLOG, langString)}
        classes={{
          root: clsx("medium-md-txt", defaultClasses.root),
          input: defaultClasses.input,
          multiline: defaultClasses.inputMultipleLine,
        }}
      />
    </Grid>
  );
};

InformationEvaluate.propTypes = {
  data: PropTypes.shape({
    description: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.string,
    blog: PropTypes.string,
  }),
  langValue: PropTypes.string,
  onChange: PropTypes.func,
};

InformationEvaluate.defaultProps = {};

export default memo(InformationEvaluate);

const FormInput = props => {
  const { title, limit, isHidden, ...inputProps } = props;
  const defaultClasses = useStyles();

  return (
    <Box paddingBottom="36px">
      <Typography className={clsx("regular-md-txt", defaultClasses.title)}>{title}</Typography>
      <OutlinedInput {...inputProps} />
      {!isHidden && (
        <Box className={defaultClasses.borderCountWord}>
          <Typography className={defaultClasses.contentCountWord}>
            {stringFormat(LangConstant.FM_LIMIT_WORD, inputProps?.value?.length, limit)}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

FormInput.propTypes = {};

FormInput.defaultProps = {};

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    minHeight: 54,
  },
  rootInput: {
    width: "100%",
    minHeight: 54,
    fontSize: 28,
    fontWeight: 600,
  },
  input: {
    minHeight: 54,
    padding: "12px 16px",
  },
  inputMultipleLine: {
    padding: 0,
  },
  title: {
    marginBottom: 12,
    color: "#3e4045",
    lineHeight: "22px",
  },
  borderCountWord: {
    height: 30,
    padding: "5px 18px",
    backgroundColor: "rgba(212, 213, 216, 0.5)",
  },
  contentCountWord: {
    fontSize: 12,
    fontWeight: 400,
    height: 20,
    lineHeight: 1.67,
    color: "#3e4045",
  },
}));
