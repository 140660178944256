import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Button, Typography, Checkbox } from "@material-ui/core";
import clsx from "clsx";
import { FilterLayout } from "./components";
import { AppConstant } from "const";

const BlogsLayout = props => {
  const {
    classes,
    isDisplaySaveDraft,
    valueHomePage,
    title,
    onCreateBlogs,
    onCheckDisplay,
    statusValue,
    propsCategory,
    children,
  } = props;

  const defaultClasses = useStyles();

  return (
    <Box className={clsx(defaultClasses.root, classes?.root)}>
      <Box className={defaultClasses.header}>
        {title}
        {children}
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={() => onCreateBlogs(AppConstant.STATUS_OPTION.publish)}
        >
          Đăng bài
        </Button>
      </Box>
      <Box className={defaultClasses.action}>
        <Box className={defaultClasses.information}>
          <Typography className={clsx("bold-md-txt", defaultClasses.textInformation)}>Thông tin bài đăng</Typography>
          {isDisplaySaveDraft && (
            <Box className="center-root">
              <Typography className={clsx("medium-md-txt", defaultClasses.titleDisplay)}>
                Hiển thị trên HomePage
              </Typography>
              <Checkbox
                checked={valueHomePage === AppConstant.STATUS_DISPLAY_HOME_PAGE.display}
                onChange={onCheckDisplay}
                color="primary"
                size="small"
                className={defaultClasses.checkBox}
              />
              <Button
                onClick={() => onCreateBlogs(AppConstant.STATUS_OPTION.draft)}
                size="small"
                color="primary"
                variant="outlined"
                className={defaultClasses.buttonSaveDraft}
              >
                Lưu nháp
              </Button>
            </Box>
          )}
        </Box>
        <FilterLayout statusValue={statusValue} {...propsCategory} />
      </Box>
    </Box>
  );
};

BlogsLayout.propTypes = {
  classes: PropTypes.object,
  isDisplaySaveDraft: PropTypes.bool,
  valueHomePage: PropTypes.number,
  title: PropTypes.any,
  onCreateBlogs: PropTypes.func,
  onCheckDisplay: PropTypes.func,
  statusValue: PropTypes.string,
  propsCategory: PropTypes.object,
};

BlogsLayout.defaultProps = {};

export default memo(BlogsLayout);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexGrow: 1,
    height: 48,
    padding: "8px 32px",
    borderTop: "1px solid rgb(212, 213, 216, 0.5)",
    borderBottom: "1px solid rgb(212, 213, 216, 0.5)",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(101, 179, 157, 0.1)",
  },
  title: {
    height: 24,
    margin: "3px 0",
    lineHeight: 1.5,
  },
  action: {
    width: "inherit",
    padding: "19px 32px 16px",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.08)",
    backgroundColor: theme.palette.white,
  },
  information: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 32,
  },
  textInformation: {
    marginTop: 3,
    lineHeight: "24px",
  },
  titleDisplay: {
    lineHeight: 1.57,
    color: "#3e4045",
  },
  checkBox: {
    width: 16,
    height: 16,
    padding: 0,
    margin: "0 6px",
  },
  buttonSaveDraft: {
    fontWeight: 600,
    "&:hover": {
      color: theme.palette.white,
      backgroundColor: theme.palette.primary.main,
    },
  },
}));
