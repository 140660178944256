import { format as dateFormat } from "date-fns";
import { AppConstant } from "const";
import Cookies from "js-cookie";

export const uuid = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx"
    .replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    })
    .replace(/-/g, "");
};

export const getTimestamp = date => {
  let tmpDate = new Date(date);
  return Math.floor(tmpDate.getTime() / 1000);
};

export const convertTimestampToDate = timestamp => {
  let resultDate =
    dateFormat(new Date(timestamp * 1000), AppConstant.FM_DD_MM_YYYY) +
    " - " +
    dateFormat(new Date(timestamp * 1000), AppConstant.FM_HH_MM);
  return resultDate;
};

export const getError = response => {
  let errorContent = "";
  if (response) {
    const { data, error } = response;
    if (data) {
      errorContent = data.message + data.error || "Có lỗi xảy ra!!!";
    } else if (error) errorContent = error;
  }

  return errorContent;
};

export const hasLogin = () => Boolean(Cookies.get(AppConstant.KEY_TOKEN));
export const handlingLogin = data => {
  Cookies.set(AppConstant.KEY_TOKEN, data.accessToken);
  Cookies.set(AppConstant.KEY_USER_ID, data.userId);
};
export const handlingLogout = () => {
  Cookies.remove(AppConstant.KEY_TOKEN);
  Cookies.remove(AppConstant.KEY_USER_ID);
};
export const isArrayNotEqual = (firstArray, secondArray) =>
  firstArray && Array.isArray(firstArray) && JSON.stringify(firstArray) !== JSON.stringify(secondArray);

export const isNotEqual = (firstValue, secondValue) =>
  firstValue !== secondValue ||
  (firstValue && secondValue && JSON.stringify(firstValue) !== JSON.stringify(secondValue));

export const snakeToCamelCase = str =>
  str.toLowerCase().replace(/([-_][a-z])/g, group => group.toUpperCase().replace("-", "").replace("_", ""));

export const camelToSnakeCase = str =>
  (str.charAt(0).toLowerCase() + str.slice(1) || str).replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

export const toCamel = obj => {
  var newObj, origKey, newKey, value;
  if (obj instanceof Array) {
    return obj.map(function (value) {
      if (typeof value === "object") {
        value = toCamel(value);
      }
      return value;
    });
  } else {
    newObj = {};
    for (origKey in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, origKey)) {
        newKey = snakeToCamelCase(origKey);
        value = obj[origKey];
        if (value instanceof Array || (value && value.constructor === Object)) {
          value = toCamel(value);
        }
        newObj[newKey] = value;
      }
    }
  }
  return newObj;
};

export const toSnake = obj => {
  var newObj, origKey, newKey, value;
  if (obj instanceof Array) {
    return obj.map(function (value) {
      if (typeof value === "object") {
        value = toSnake(value);
      }
      return value;
    });
  } else {
    newObj = {};
    for (origKey in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, origKey)) {
        newKey = camelToSnakeCase(origKey);
        value = obj[origKey];
        if (value instanceof Array || (value && value.constructor === Object)) {
          value = toSnake(value);
        }
        newObj[newKey] = value;
      }
    }
  }
  return newObj;
};

export const validateFileSize = (fileSize, limit = 2) => {
  let limitSizeMb = limit * 1024 * 1024;
  return fileSize <= limitSizeMb;
};

export const handleBeforeUnload = (event, message = "") => {
  event.preventDefault();
  event.returnValue = message;
};

export const formatPage = data => ({
  paging: data?.paging || 1,
  page: data?.page || 1,
  size: data?.size || 10,
  asc: data?.asc || 1,
});

export const removeTags = str => {
  if (str === null || str === "") return false;
  else str = str.toString();
  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace(/(<([^>]+)>)/gi, "");
};

export const removeRegularHTMLCodeAndCalculator = str => {
  if (str === null || str === "") return false;
  else str = str.toString();
  let countRegularHTMLCode = 0;
  // Replace regular HTML code by null string and count number of code
  let newString = str.replace(/(&([^;]+);)/gi, () => {
    countRegularHTMLCode += 1;
    return "";
  });
  // Step 1: Regex expression will remove the start and end spaces of the given string
  // Step 2: Try to reduce multiple spaces to a single space
  // Step 3: Try to exclude a new line with a start spacing
  let result = newString
    .replace(/(^\s*)|(\s*$)/gi, "")
    .replace(/[ ]{2,}/gi, "")
    .replace(/(\r\n|\n|\r)/gm, "");
  return result.length + countRegularHTMLCode;
};

export const joinQueryStringArray = (path, array, key) => {
  let newQueryString = path + "?";
  array.forEach((item, index) => {
    if (index === 0) {
      newQueryString += key + "=" + item.id;
    } else {
      newQueryString += "&" + key + "=" + item.id;
    }
  });
  return newQueryString;
};

export const convertTimestampToDateRange = (from, to) => {
  if (!from || !to) return "";
  let dateRange =
    dateFormat(new Date(from * 1000), AppConstant.FM_DD_MM_YYYY) +
    " - " +
    dateFormat(new Date(to * 1000), AppConstant.FM_DD_MM_YYYY);

  return dateRange;
};

export const getMonthDiff = (startDate, endDate) => {
  if (!startDate || !endDate) return "";

  startDate = new Date(startDate * 1000);
  endDate = new Date(endDate * 1000);

  let dateRange =
    dateFormat(startDate, AppConstant.FM_DD_MM_YYYY) + " - " + dateFormat(endDate, AppConstant.FM_DD_MM_YYYY);

  let months = Math.max(
    (endDate.getFullYear() - startDate.getFullYear()) * 12 + endDate.getMonth() - startDate.getMonth(),
    0,
  );
  return months > 0 ? dateRange + " (" + months + " tháng)" : "";
};

export const formatCurrency = str => {
  if (!str) return "-";
  let strCurrency = str + ""; // Ensure that variable is String
  return strCurrency.replace(/\B(?=(\d{3})+(?!\d))/g, ".") + "đ";
};

export const getDateFromTimeStamp = time => {
  if (!time || time === null) return "-";
  return dateFormat(new Date(time * 1000), AppConstant.FM_DD_MM_YYYY);
};

export const getShopStatusColor = status => {
  if (!status) return "#3e4045";
  let shopActive = status === AppConstant.SHOP_STATUS.active;
  return shopActive ? "#65b39d" : "#ffaa6e";
};

export const getPaymentStatusColor = status => {
  if (!status) return "#3e4045";
  let paid = status === AppConstant.PAYMENTS_STATUS.paid;
  return paid ? "#65b39d" : "#ff4a5e";
};

export const getPeriod = (startDate, endDate) => {
  if (!startDate || !endDate) return "";

  startDate = new Date(startDate * 1000);
  endDate = new Date(endDate * 1000);

  let months = Math.max(
    (endDate.getFullYear() - startDate.getFullYear()) * 12 + endDate.getMonth() - startDate.getMonth(),
    0,
  );
  return months;
};

export const formatToFormCurrency = str => {
  if (!str) return "";
  let strCurrency = str + ""; // Ensure that variable is String
  return strCurrency.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export const createCloneObj = obj => (obj ? JSON.parse(JSON.stringify(obj)) : {});
