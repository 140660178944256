import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import DateFnsUtils from "@date-io/date-fns";
import { enUS, vi } from "date-fns/locale";
import { addMonths, format, isBefore, isSameDay, isWithinInterval, subMonths, isSameMonth } from "date-fns";
import clsx from "clsx";
import { Box, Button, IconButton, makeStyles } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import { LangConstant, AppConstant } from "const";

const RangeDatePicker = ({ dateRange, onChange, ...otherProps }) => {
  const classes = useStyles();
  const { i18n } = useTranslation();

  const [startDate, setStartDate] = useState(dateRange?.from || new Date());
  const [endDate, setEndDate] = useState(dateRange?.to || new Date());
  const [selectedRangeDate, setSelectedRangeDate] = useState(dateRange);
  const [isPickTwoDateRight, setIsPickTwoDateRight] = useState(false);
  const [isPickTwoDateLeft, setIsPickTwoDateLeft] = useState(false);
  const [isCheckEndDate, setIsCheckEndDate] = useState(false);
  const [calendarValue, setCalendarValue] = useState({
    from: null,
    to: null,
  });

  const onSubmit = () => {
    if (selectedRangeDate.from && selectedRangeDate.to) {
      onChange(selectedRangeDate);
    } else {
      onChange({
        from: startDate || endDate,
        to: startDate || endDate,
      });
    }
    setEndDate(null);
    setStartDate(null);
  };

  const sortDateObject = (firstDate, secondDate) => {
    let result = {};
    if (isBefore(firstDate, secondDate)) {
      result = {
        from: firstDate,
        to: secondDate,
      };
    } else {
      result = {
        from: secondDate,
        to: isSameDay(firstDate, secondDate) ? null : firstDate,
      };
    }
    return result;
  };

  const onChangeStartDate = date => {
    setIsPickTwoDateRight(false);
    if (startDate) {
      if (!isSameDay(startDate, date)) {
        setSelectedRangeDate(sortDateObject(date, startDate));
        setStartDate(null);
        setIsPickTwoDateLeft(true);
      }
    } else {
      setSelectedRangeDate({
        from: null,
        to: null,
      });
      setStartDate(date);
      setIsPickTwoDateLeft(false);
    }
  };

  const onChangeEndDate = date => {
    setIsPickTwoDateLeft(false);
    setIsCheckEndDate(true);
    if (endDate) {
      if (!isSameDay(endDate, date)) {
        setSelectedRangeDate(sortDateObject(date, endDate));
        setEndDate(null);
        setIsPickTwoDateRight(true);
      }
    } else {
      setSelectedRangeDate({
        from: null,
        to: null,
      });
      setEndDate(date);
      setIsPickTwoDateRight(false);
    }
  };

  const getCalendarValue = () => {
    let valueCalendar = {
      from: null,
      to: null,
    };
    let tmpSelectedDates = { ...selectedRangeDate };

    if (startDate) {
      // If user only pick one value at the left-side calendar
      valueCalendar.from = startDate;
    } else {
      if (isPickTwoDateRight) {
        // If user pick two values at the right-side calendar
        valueCalendar.from = subMonths(tmpSelectedDates.to, 1);
      } else {
        if (endDate) {
          // If user picked only one value at the right-side calendar
          valueCalendar.from = subMonths(endDate, 1);
        } else {
          // If user fully picked two time stamp
          valueCalendar.from = tmpSelectedDates.from;
        }
      }
    }

    if (endDate) {
      // If user only pick one value at the right-side calendar
      valueCalendar.to = endDate;
    } else {
      if (isPickTwoDateLeft) {
        // If user pick two values at the left-side calendar
        valueCalendar.to = addMonths(tmpSelectedDates.from, 1);
      } else {
        if (startDate) {
          // If user picked only one value at the left-side calendar
          valueCalendar.to = addMonths(startDate, 1);
        } else {
          if (isCheckEndDate) {
            // If user fully picked two time stamp with end time is picked from right-side calendar
            valueCalendar.to = tmpSelectedDates.to;
          } else {
            // If user only pick one value
            valueCalendar.to = addMonths(tmpSelectedDates.from, 1);
          }
        }
      }
    }
    setCalendarValue(valueCalendar);
  };

  const renderWrappedWeekDay = (date, _, dayInCurrentMonth) => {
    let dayIsBetween, wrapperClassName, dayClassName, isFirstDay, isLastDay, dayBetweenInterval;
    let isCurrent = isSameDay(date, new Date());

    if (selectedRangeDate.from && selectedRangeDate.to) {
      const start = new Date(selectedRangeDate.from);
      const end = new Date(selectedRangeDate.to);

      dayIsBetween = isWithinInterval(date, { start: start, end: end });
      isFirstDay = isSameDay(date, start);
      isLastDay = isSameDay(date, end);

      wrapperClassName = clsx({
        [classes.firstHighlight]: isFirstDay,
        [classes.endHighlight]: isLastDay,
      });
    }

    dayBetweenInterval = clsx({
      [isCurrent ? classes.currentInsideInterval : classes.highlight]: dayIsBetween,
      [classes.firstDay]: isFirstDay,
      [classes.lastDay]: isLastDay,
    });

    dayClassName = clsx(classes.day, {
      [classes.isPicked]: isSameDay(date, startDate || endDate || selectedRangeDate.from),
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [dayIsBetween ||
      isFirstDay ||
      isLastDay ||
      isSameDay(date, startDate && startDate) ||
      isSameDay(date, endDate && endDate)
        ? ""
        : classes.currentHighlight]: isCurrent,
    });

    return (
      <Box width="100%" textAlign="center" className={dayBetweenInterval}>
        <IconButton className={dayClassName} disabled={!dayInCurrentMonth}>
          <Box className={wrapperClassName}>
            <span> {format(date, "d")} </span>
          </Box>
        </IconButton>
      </Box>
    );
  };

  useEffect(() => {
    let isClickButtonRight = (startDate && endDate && !isSameMonth(startDate, endDate)) || dateRange?.from;
    setTimeout(() => {
      onClickButtonDatePicker(!isClickButtonRight);
    }, 250);
  }, []);

  useEffect(() => {
    if (selectedRangeDate.from && selectedRangeDate.to) {
      const start = new Date(selectedRangeDate.from);
      const end = new Date(selectedRangeDate.to);
      if (isSameDay(start, end)) {
        setSelectedRangeDate({
          from: null,
          to: null,
        });
      }
    }
  }, [selectedRangeDate]);

  useEffect(() => {
    if (startDate && endDate) {
      setSelectedRangeDate(sortDateObject(startDate, endDate));
      setStartDate(null);
      setEndDate(null);
    }
    getCalendarValue();
  }, [startDate, endDate]);

  return (
    <>
      <Box className={classes.datePickerPaperRoot}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[i18n.language]}>
          <DatePicker
            className={classes.doubledDatePicker}
            disableToolbar
            variant="static"
            format={AppConstant.FM_DD_MM_YYYY}
            value={calendarValue.from || null}
            InputProps={{
              disableUnderline: true,
              placeholder: "placeholder",
              className: "regular-md-txt",
            }}
            renderDay={renderWrappedWeekDay}
            onChange={onChangeStartDate}
            leftArrowButtonProps={{
              classes: { root: classes.btnArrowLeft },
              id: "left-previous-arrow-btn",
            }}
            leftArrowIcon={<ArrowBackIos />}
            rightArrowButtonProps={{
              classes: { root: classes.buttonHidden },
              id: "right-previous-arrow-btn",
            }}
            {...otherProps}
          />
          <DatePicker
            className={classes.doubledDatePicker}
            disableToolbar
            variant="static"
            format={AppConstant.FM_DD_MM_YYYY}
            value={calendarValue.to || null}
            InputProps={{
              disableUnderline: true,
              placeholder: "placeholder",
              className: "regular-md-txt",
            }}
            renderDay={renderWrappedWeekDay}
            onChange={onChangeEndDate}
            leftArrowButtonProps={{
              classes: { root: classes.buttonHidden },
              id: "left-after-arrow-btn",
            }}
            rightArrowIcon={<ArrowForwardIos />}
            rightArrowButtonProps={{
              classes: { root: classes.btnArrowRight },
              id: "right-after-arrow-btn",
            }}
            {...otherProps}
          />
        </MuiPickersUtilsProvider>
      </Box>
      <Box className={classes.submitTimeRangeBox}>
        <Button
          className={`${classes.submitSelectedTimeRangedBtn} medium-md-txt`}
          variant="contained"
          onClick={onSubmit}
          color="primary"
        >
          Chọn
        </Button>
      </Box>
    </>
  );
};

const onClickButtonDatePicker = isClickButtonRight => {
  if (isClickButtonRight) {
    /*eslint no-unused-expressions: */
    document.getElementById("right-after-arrow-btn")?.click();
  }
  document.getElementById("left-previous-arrow-btn").addEventListener("click", function () {
    document.getElementById("left-after-arrow-btn")?.click();
  });
  document.getElementById("right-after-arrow-btn").addEventListener("click", function () {
    document.getElementById("right-previous-arrow-btn")?.click();
  });
};

const localeMap = {};

const months = [
  "Tháng 01 năm",
  "Tháng 02 năm",
  "Tháng 03 năm",
  "Tháng 04 năm",
  "Tháng 05 năm",
  "Tháng 06 năm",
  "Tháng 07 năm",
  "Tháng 08 năm",
  "Tháng 09 năm",
  "Tháng 10 năm",
  "Tháng 11 năm",
  "Tháng 12 năm",
];

const days = ["CN", "T2", "T3", "T4", "T5", "T6", "T7"];

const customVILocal = {
  localize: {
    day: (dirtyIndex, dirtyOptions) => {
      let strDay = days[dirtyIndex] || "";
      let options = dirtyOptions || {};
      if (options.width && "short" === options.width && strDay.length > 0) {
        return strDay.charAt(0) + strDay.charAt(strDay.length - 1);
      }
      return strDay;
    },
    month: n => months[n],
  },
};

localeMap[LangConstant.EN_LANG] = enUS;
localeMap[LangConstant.DEFAULT_LANG] = { ...vi, ...customVILocal };

RangeDatePicker.propTypes = {
  dateRange: PropTypes.shape({
    from: PropTypes.objectOf(Date),
    to: PropTypes.objectOf(Date),
  }),
  onChange: PropTypes.func,
};

RangeDatePicker.defaultProps = {
  dateRange: {
    from: new Date(),
    to: new Date(),
  },
};

export default RangeDatePicker;

const useStyles = makeStyles(theme => ({
  datePickerPaperRoot: {
    display: "flex",

    "& .MuiPickersCalendarHeader-switchHeader": {
      backgroundColor: "#343434",
      margin: 0,
    },

    "& .MuiPickersBasePicker-pickerView": {
      minHeight: "unset",
      minWidth: 330,
      padding: 0,
    },

    "& .MuiPickersSlideTransition-transitionContainer > * ": {
      color: "white",
    },

    "& .MuiPickersCalendarHeader-iconButton": {
      backgroundColor: "transparent",
      color: "white",
    },

    "& .MuiPickersCalendarHeader-daysHeader": {
      margin: "10px 10px 0 10px",
      border: "1px solid #d4d5d8",
      borderBottom: "unset",
      padding: "15px 3px 20px 3px",
      display: "grid",
      gridTemplateColumns: "repeat(7, minmax(40px, 1fr))",
      [theme.breakpoints.down("sm")]: {
        gridTemplateColumns: "repeat(7, minmax(15px, 1fr))",
        margin: "10px 5px 0 5px",
      },
    },

    "& .MuiPickersDay-daySelected > span:first-child": {
      width: 24,
      height: 24,
      borderRadius: "50%",
      backgroundColor: "#ef5845",
      [theme.breakpoints.down("sm")]: {
        width: 15,
        height: 15,
        margin: 0,
      },
    },

    "& .MuiPickersDay-daySelected": {
      backgroundColor: "transparent",
    },

    "& .MuiPickersDay-daySelected:hover": {
      backgroundColor: "transparent",
    },

    "& .MuiPickersCalendar-transitionContainer": {
      margin: "10px 10px 0 10px",
      border: "1px solid #d4d5d8",
      borderBottom: "unset",
      [theme.breakpoints.down("sm")]: {
        margin: "0 5px 10px 5px",
      },
    },

    "& .MuiPickersCalendarHeader-transitionContainer": {
      height: 20,
    },

    "& .MuiPickersCalendarHeader-transitionContainer > p": {
      fontSize: 14,
      fontWeight: 500,
      height: "inherit",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        fontSize: 13,
        fontWeight: 400,
      },
    },

    "& .MuiPickersCalendar-week": {
      alignItems: "center",
      display: "grid",
      gridTemplateColumns: "repeat(7, minmax(40px, 1fr))",
      margin: "0 3px",
      [theme.breakpoints.down("sm")]: {
        gridTemplateColumns: "repeat(7, minmax(15px, 1fr))",
      },
    },

    "& .MuiPickersCalendarHeader-dayLabel": {
      fontSize: 14,
      fontWeight: 500,
      color: "#343434",
      [theme.breakpoints.down("sm")]: {
        width: "auto",
      },
    },

    "& .MuiPickersCalendarHeader-daysHeader > span:last-of-type, .MuiPickersCalendarHeader-daysHeader > span:nth-last-of-type(2)":
      {
        color: "#ef5845",
      },

    "& .MuiPickersStaticWrapper-staticWrapperRoot": {
      [theme.breakpoints.down("sm")]: {
        minWidth: 100,
      },
    },
  },

  buttonHidden: {
    display: "none",
  },
  btnArrowLeft: {
    "& svg": {
      fontSize: 18,
      marginRight: -5,
    },
    "& > *:first-child": {
      width: 24,
      height: 24,
    },
    marginRight: -24,
    marginLeft: 24,
    "@media only screen and (max-width: 780px) ": {
      margin: 0,
    },
  },
  btnArrowRight: {
    "& svg": {
      fontSize: 18,
      marginRight: -5,
    },
    "& > *:first-child": {
      width: 24,
      height: 24,
    },
    marginRight: 24,
    marginLeft: -24,
    "@media only screen and (max-width: 780px) ": {
      margin: 0,
    },
  },
  dayWrapper: {
    position: "relative",
  },
  day: {
    width: 36,
    height: 36,
    color: "#6c7078",
    padding: "2px 0",
    fontSize: "14px",
    fontWeight: 500,
  },
  customDayHighlight: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: "2px",
    right: "2px",
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: "50%",
  },
  nonCurrentMonthDay: {
    "&:disabled": {
      color: "transparent",
    },
  },
  highlightNonCurrentMonthDay: {
    color: "#676767",
  },
  currentInsideInterval: {
    background: "#f1f3f6",
    width: "inherit",
    height: 24,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&>span": {
      width: 24,
      height: 24,
      backgroundColor: "#65b39d",
      color: "#ffffff",
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
    },
  },
  highlight: {
    background: "#f1f3f6",
    width: "inherit",
    height: 24,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "6px 0px",
  },
  firstDay: {
    background: "linear-gradient(to right, transparent 50%, #f1f3f6 50%)",
    width: "inherit",
    height: 24,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "6px 0px",
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  },
  lastDay: {
    background: "linear-gradient(to left, transparent 50%, #f1f3f6 50%)",
    width: "inherit",
    height: 24,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "6px 0px",
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  },
  firstHighlight: {
    "&>span": {
      borderRadius: "50%",
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: 24,
      height: 24,
    },
  },
  endHighlight: {
    "&>span": {
      borderRadius: "50%",
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: 24,
      height: 24,
    },
  },
  isPicked: {
    "&>span:first-child": {
      borderRadius: "50%",
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: 24,
      height: 24,
    },
  },
  currentHighlight: {
    "&>span:first-child": {
      width: 24,
      height: 24,
      backgroundColor: "#65b39d",
      color: "#ffffff",
      borderRadius: "50%",
    },
  },
  submitSelectedTimeRangedBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    width: 68,
    minHeight: 30,
    textTransform: "none",
    borderRadius: 2,
  },
  submitTimeRangeBox: {
    marginTop: 12,
    display: "flex",
    justifyContent: "center",
    marginBottom: 15,
  },
}));
