import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { makeStyles, ListItem, Collapse, ListItemText, Typography, Box, ListItemIcon } from "@material-ui/core";
import SidebarItem from "./SidebarItem";
import { AppConstant, PathConstant } from "const";
import Cookies from "js-cookie";
import clsx from "clsx";

const SidebarWithChildren = props => {
  const { data } = props;
  const location = useLocation();
  const classes = useStyles();

  let isRoot = parseInt(Cookies.get(AppConstant.KEY_USER_ID)) === AppConstant.ROOT_ID;

  const [isShowChildren, setIsShowChildren] = useState(false);

  const onClickItem = () => setIsShowChildren(!isShowChildren);

  useEffect(() => {
    let checkCurrentPage = false;
    if (data?.children && Array.isArray(data.children)) {
      checkCurrentPage = data.children.map(item => item.path).includes(location.pathname);
    } else {
      checkCurrentPage = data.path === location.pathname;
    }
    setIsShowChildren(checkCurrentPage);
  }, [data, location]);

  return (
    <Box className={clsx(isShowChildren && classes.root)}>
      <ListItem button className={classes.item} onClick={onClickItem}>
        {Boolean(data?.icon) && <ListItemIcon className={classes.itemIcon}>{data.icon}</ListItemIcon>}
        <ListItemText
          primary={
            <Typography variant="body2" color="inherit">
              {data.text}
            </Typography>
          }
        />
        {isShowChildren ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={isShowChildren} timeout="auto" unmountOnExit>
        {data?.children.map(
          (item, index) =>
            (isRoot || (!isRoot && item.path !== PathConstant.ACCOUNT)) && (
              <SidebarItem item={item} isChildren={true} key={index} />
            ),
        )}
      </Collapse>
    </Box>
  );
};

SidebarWithChildren.propTypes = {
  data: PropTypes.object.isRequired,
};
SidebarWithChildren.defaultProps = {};

export default memo(SidebarWithChildren);

const useStyles = makeStyles({
  root: {
    background: "rgba(239, 88, 69, 0.2)",
  },
  item: {
    minHeight: 48,
    padding: "8px 14px",
    color: "white",
    borderRadius: 4,
    "&:hover": { background: "rgba(239, 88, 69, 0.3)" },
    "& svg": {
      fontSize: 16,
    },
  },
  itemIcon: {
    minWidth: 30,
    color: "inherit",
  },
});
