import React, { memo } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";

const SaveConfirmDialog = ({ isShow, onClose, onConfirm }) => {
  const classes = useStyles();

  return (
    <Dialog open={isShow} classes={{ paper: classes.deleteDialog }}>
      <DialogTitle>Lưu thay đổi</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.dialogTitle}>
          Thông tin của shop chưa được lưu, bạn có muốn lưu những thay đổi này không?
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={onClose} className={clsx(classes.cancelBtn, "medium-md-txt")}>
          Hủy
        </Button>
        <Button
          onClick={onConfirm}
          autoFocus
          className={clsx(classes.confirmBtn, "medium-md-txt")}
          variant="contained"
          color="primary"
        >
          Xác nhận
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SaveConfirmDialog.propTypes = {
  isShow: PropTypes.bool,

  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
};

SaveConfirmDialog.defaultProps = {
  onClose: () => {},
  onConfirm: () => {},
};

export default memo(SaveConfirmDialog);

const useStyles = makeStyles(theme => ({
  dialogActions: {
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    paddingRight: 16,
  },
  dialogTitle: {
    color: theme.palette.grey[500],
    fontSize: "13px",
    display: "flex",
    alignItems: "center",
  },
  deleteDialog: {
    minHeight: "188px",
    width: "30%",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
    },
  },
  cancelBtn: {
    minHeight: "unset",
    padding: 0,
    color: "#3e4045",
    height: 30,
    borderRadius: 2,
  },
  confirmBtn: {
    minHeight: "unset",
    padding: 0,
    width: 95,
    height: 30,
    color: "#fff",
    borderRadius: 2,
  },
}));
