import React, { memo } from "react";
import PropTypes from "prop-types";
import { Snackbar } from "@material-ui/core/";
import { Alert as MuiAlert } from "@material-ui/lab";

const Alert = ({ isShow, onClose, message, anchorOrigin, type, ...otherProps }) => {
  return (
    <Snackbar open={isShow} autoHideDuration={3000} onClose={onClose} anchorOrigin={anchorOrigin || ANCHOR_ORIGIN}>
      <MuiAlert onClose={onClose} severity={type} variant="filled" {...otherProps}>
        {message}
      </MuiAlert>
    </Snackbar>
  );
};

Alert.propTypes = {
  isShow: PropTypes.bool.isRequired,
  message: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.string,

  onClose: PropTypes.func.isRequired,
};

Alert.defaultProps = {
  isShow: false,
  type: "info",
  message: "",
};

export default memo(Alert);

const ANCHOR_ORIGIN = {
  vertical: "top",
  horizontal: "center",
};
