import React, { memo, useState, useEffect } from "react";
import { makeStyles, Box } from "@material-ui/core";
import { ShopListHeader, ShopListItem } from "../components";
import { PaginationFooter } from "components";
import { useDispatch, useSelector } from "react-redux";
import ShopAction from "redux/shop.redux";
import { isArrayNotEqual } from "utils";
import StringFormat from "string-format";
import ShopDetails from "./ShopInformation/ShopDetails";

const BodyListShop = ({ setIsOpenShopDetail, isOpenShopDetail }) => {
  const dispatch = useDispatch();
  const defaultClasses = useStyles();

  const { data: listShop, ...pagination } = useSelector(state => state.shopRedux.data);
  const countReservation = useSelector(state => state.shopRedux.countReservation);

  const [arrayShop, setArrayShop] = useState([]);
  const [pageData, setPageData] = useState(DEFAULT_PAGE);
  const [shopDetail, setShopDetail] = useState({});

  const onOpenShopDetail = shop => {
    setIsOpenShopDetail(true);
    setShopDetail(shop);
  };

  const onCloseShopDetail = () => {
    setIsOpenShopDetail(false);
    setShopDetail({});
    dispatch(ShopAction.getListShop());
  };

  const onChangePage = (_, newPage) => {
    dispatch(ShopAction.getListShop({ page: newPage }));
  };

  useEffect(() => {
    if (listShop && isArrayNotEqual(listShop, arrayShop)) {
      let formatData = formatArrayShop(listShop, pagination.page);
      setArrayShop(formatData);
      setPageData({ ...pageData, ...pagination });
      let stringDataShopId = "";
      formatData.forEach((shop, index) => {
        if (index === 0) {
          let firstShopId = StringFormat(FM_SHOP_ID, shop.id);
          stringDataShopId += firstShopId.replace("&", "?");
        } else {
          stringDataShopId += StringFormat(FM_SHOP_ID, shop.id);
        }
      });
      dispatch(ShopAction.getCountReservation(stringDataShopId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listShop]);

  useEffect(() => {
    if (countReservation) {
      let newDataWithNumberReservation = arrayShop.map(data => ({
        ...data,
        numberReservation: countReservation[data.id].numberReservation,
      }));
      setArrayShop(newDataWithNumberReservation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countReservation]);

  return isOpenShopDetail ? (
    <ShopDetails shopDetail={shopDetail} setShopDetail={setShopDetail} onBack={onCloseShopDetail} />
  ) : (
    <Box className={defaultClasses.root}>
      <ShopListHeader />
      <Box height="calc(100vh - 330px)">
        {arrayShop.map((shop, index) => (
          <Box key={index} className={defaultClasses.itemShop} onClick={() => onOpenShopDetail(shop)}>
            <ShopListItem data={shop} />
          </Box>
        ))}
        <PaginationFooter data={pageData} onChangePage={onChangePage} />
      </Box>
    </Box>
  );
};

const DEFAULT_PAGE = {
  total: 0,
  page: 1,
  rowsPerPage: 10,
  totalPage: 0,
};

BodyListShop.propTypes = {};

BodyListShop.defaultProps = {};

export default memo(BodyListShop);

export const FM_SHOP_ID = "&shop_id={0}";

const formatArrayShop = (listShop, page) => {
  let formatArrayBlogs = listShop.map((blog, index) => ({
    ...blog,
    number: (page - 1) * 10 + index + 1,
  }));
  return formatArrayBlogs;
};

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    padding: "24px 32px 24px 24px",
    overflow: "auto",
  },
  itemShop: {
    marginTop: 8,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgba(79, 139, 255, 0.1)",
    },
  },
}));
