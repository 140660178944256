import { ApiConstant } from "const";
import { createApiRegistration } from "api";
import StringFormat from "string-format";

export const postHomeEvaluate = data => createApiRegistration().post(ApiConstant.HOME_EVALUATE, data);

export const putHomeEvaluate = data =>
  createApiRegistration().put(StringFormat(ApiConstant.HOME_EVALUATE_ID, data.id), data);

export const getHomeEvaluate = () => createApiRegistration().get(ApiConstant.HOME_EVALUATE);

export const getHomeEvaluateById = data =>
  createApiRegistration().get(StringFormat(ApiConstant.HOME_EVALUATE_ID, data.id));

export const deleteHomeEvaluate = data =>
  createApiRegistration().delete(StringFormat(ApiConstant.HOME_EVALUATE_ID, data.id));
