import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Grid, OutlinedInput, Typography } from "@material-ui/core";
import stringFormat from "string-format";
import clsx from "clsx";
import { LangConstant } from "const";

const InformationFeature = props => {
  const { data, onChange, langValue, children } = props;
  const defaultClasses = useStyles();

  let langString = langValue.toUpperCase();

  return (
    <Grid item xs={9}>
      {children}
      <FormInput
        title="Tiêu đề chính"
        multiline
        name="title"
        onChange={onChange}
        value={data?.title}
        limit={55}
        placeholder={stringFormat(LangConstant.FM_TITLE_FEATURE, langString)}
        classes={{
          root: defaultClasses.rootInputTitle,
          input: defaultClasses.inputTitle,
          multiline: defaultClasses.inputMultipleLine,
        }}
      />
      <FormInput
        title="Nội dung"
        multiline
        name="content"
        onChange={onChange}
        value={data?.content}
        limit={750}
        placeholder={stringFormat(LangConstant.FM_CONTENT_FEATURE, langString)}
        classes={{
          root: defaultClasses.rootInputTitle,
          input: defaultClasses.inputTitle,
          multiline: defaultClasses.inputMultipleLine,
        }}
      />
    </Grid>
  );
};

InformationFeature.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    highlightTitle: PropTypes.string,
    content: PropTypes.string,
  }),
  langValue: PropTypes.string,
  onChange: PropTypes.func,
  children: PropTypes.element,
};

InformationFeature.defaultProps = {};

export default memo(InformationFeature);

const FormInput = props => {
  const { title, limit, ...inputProps } = props;
  const defaultClasses = useStyles();

  return (
    <Box paddingBottom="36px">
      <Typography className={clsx("regular-md-txt", defaultClasses.title)}>{title}</Typography>
      <OutlinedInput {...inputProps} />
      <Box className={defaultClasses.borderCountWord}>
        <Typography className={defaultClasses.contentCountWord}>
          {stringFormat(LangConstant.FM_LIMIT_WORD, inputProps?.value?.length, limit)}
        </Typography>
      </Box>
    </Box>
  );
};

FormInput.propTypes = {};

FormInput.defaultProps = {};

const useStyles = makeStyles(() => ({
  rootInputSubTitle: {
    width: "100%",
    minHeight: 54,
    fontSize: 28,
    fontWeight: 600,
  },
  rootInputTitle: {
    width: "100%",
    minHeight: 104,
    fontSize: 28,
    fontWeight: 600,
  },
  inputSubTitle: {
    minHeight: 54,
    padding: "12px 16px",
  },
  inputTitle: {
    minHeight: 104,
    padding: "12px 16px",
  },
  inputMultipleLine: {
    padding: 0,
  },
  title: {
    marginBottom: 12,
    color: "#3e4045",
    lineHeight: "22px",
  },
  borderCountWord: {
    height: 30,
    padding: "5px 18px",
    backgroundColor: "rgba(212, 213, 216, 0.5)",
  },
  contentCountWord: {
    fontSize: 12,
    fontWeight: 400,
    height: 20,
    lineHeight: 1.67,
    color: "#3e4045",
  },
}));
