import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";

const AppDialog = props => {
  const { title, onClose, children } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const isHiddenClose = Boolean(onClose);

  return (
    <Dialog fullScreen={isMobile} scroll="paper" maxWidth="lg" classes={{ paper: classes.root }} {...props}>
      <DialogTitle className={classes.dialogTitleRoot} disableTypography>
        <Box className={classes.dialogTitle}>{title}</Box>
        {isHiddenClose && (
          <IconButton aria-label="close" onClick={onClose} className={classes.btnClose}>
            <Close fontSize="small" />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>{children}</DialogContent>
    </Dialog>
  );
};

AppDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func,
};

export default AppDialog;

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 300,
  },
  dialogTitleRoot: {
    minHeight: 40,
    display: "flex",
    alignItems: "center",
    padding: "0 24px",
    background: theme.palette.secondary.main,
    color: "white",
  },
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    fontSize: 16,
    fontWeight: 600,
  },
  dialogContent: {
    padding: 0,
  },
  btnClose: {
    marginRight: -24,
    color: "white",
  },
}));
