import React, { memo, useState, useEffect } from "react";
import { makeStyles, Box } from "@material-ui/core";
import ManageLayout from "layouts/ManageLayout";
import { HeaderRow, BodyRow } from "./components";
import { PaginationFooter } from "components";
import { AppConstant } from "const";
import { useDispatch, useSelector } from "react-redux";
import ShopAction from "redux/shop.redux";
import { isArrayNotEqual } from "utils";

const ManageRegistration = () => {
  const defaultClasses = useStyles();
  const dispatch = useDispatch();

  const { data, ...pagination } = useSelector(state => state.shopRedux.shopRegistration);

  const [arrayShopRegistration, setArrayShopRegistration] = useState([]);
  const [pageData, setPageData] = useState(DEFAULT_PAGE);
  const [arrayChecked, setArrayChecked] = useState([]);

  const onProcessed = () => {
    dispatch(ShopAction.putShopRegistration(arrayChecked));
  };

  const onCheckShopRegistration = (e, id) => {
    if (e.target.checked) {
      setArrayChecked(arrayChecked => [...arrayChecked, id]);
    } else {
      setArrayChecked(arrayChecked => arrayChecked.filter(idShopChecked => idShopChecked !== id));
    }
  };

  const onChangePage = (_, newPage) => {
    dispatch(ShopAction.getShopRegistration({ page: newPage }));
  };

  useEffect(() => {
    dispatch(ShopAction.getShopRegistration());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data && isArrayNotEqual(data, arrayShopRegistration)) {
      let formatData = formatArrayShop(data, pagination.page);
      setArrayShopRegistration(formatData);
      setPageData({ ...pageData, ...pagination });
      setArrayChecked([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Box>
      <ManageLayout
        title="Đăng ký"
        buttonProps={{
          content: "Đã liên hệ",
          onClick: onProcessed,
          disabled: arrayChecked.length === 0,
        }}
      />
      <Box className={defaultClasses.rootTable}>
        <HeaderRow />
        {arrayShopRegistration.map((shopRegistration, index) => (
          <BodyRow
            key={index}
            isChecked={Boolean(
              shopRegistration.state === AppConstant.STATE_REGISTRATION.contacted ||
                arrayChecked.includes(shopRegistration.id),
            )}
            data={shopRegistration}
            onChecked={e => onCheckShopRegistration(e, shopRegistration.id)}
          />
        ))}
        <PaginationFooter data={pageData} onChangePage={onChangePage} />
      </Box>
    </Box>
  );
};

const DEFAULT_PAGE = {
  total: 0,
  page: 1,
  rowsPerPage: 10,
  totalPage: 0,
};

const formatArrayShop = (arrayRegistration, page = 1) => {
  let formatArrayRegistration = arrayRegistration.map((blog, index) => ({
    ...blog,
    number: (page - 1) * 10 + index + 1,
  }));
  return formatArrayRegistration;
};

export default memo(ManageRegistration);

const useStyles = makeStyles(() => ({
  rootTable: {
    width: "100%",
    height: "calc(100vh - 190px)",
    padding: "24px 32px 24px 24px",
    overflow: "auto",
  },
}));
