import React, { memo } from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import clsx from "clsx";

const HeaderRow = () => {
  const defaultClasses = useStyles();

  return (
    <Box className={defaultClasses.root}>
      <Box flexBasis="5%" paddingRight="12px">
        <Typography className={clsx("medium-md-txt", defaultClasses.title, defaultClasses.titleCenter)}>STT</Typography>
      </Box>
      <Box flexBasis="13.2%" paddingRight="12px">
        <Typography className={clsx("medium-md-txt", defaultClasses.title)}>Tên shop</Typography>
      </Box>
      <Box flexBasis="9%" paddingRight="12px">
        <Typography className={clsx("medium-md-txt", defaultClasses.title)}>Lĩnh vực</Typography>
      </Box>
      <Box flexBasis="18.2%" paddingRight="12px">
        <Typography className={clsx("medium-md-txt", defaultClasses.title)}>Liên hệ</Typography>
      </Box>
      <Box flexBasis="18.2%" paddingRight="12px">
        <Typography className={clsx("medium-md-txt", defaultClasses.title)}>Địa chỉ</Typography>
      </Box>
      <Box flexBasis="9%" paddingRight="12px">
        <Typography className={clsx("medium-md-txt", defaultClasses.title)}>Thời gian</Typography>
      </Box>
      <Box flexBasis="20%" paddingRight="12px">
        <Typography className={clsx("medium-md-txt", defaultClasses.title)}>Thông tin khác</Typography>
      </Box>
    </Box>
  );
};

export default memo(HeaderRow);

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    width: "inherit",
    height: 46,
    backgroundColor: "rgba(101, 179, 157, 0.1)",
    padding: "12px 14px",
  },
  title: {
    height: 22,
    color: "#3e4045",
    lineHeight: 1.57,
  },
  titleCenter: {
    textAlign: "center",
  },
}));
