import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Button, Grid, ListItem, ListItemText, makeStyles, Typography } from "@material-ui/core";
import { PackageItem, SelectedItem } from "components";
import { LangConstant } from "const";
import { convertTimestampToDateRange } from "utils";
import clsx from "clsx";

const AddNewFeatureDialogHeader = ({ data, onAddNewFeature }) => {
  const classes = useStyles();

  const [newFeaturesArray, setNewFeatureArray] = useState([]);
  const [selectedFeature, setSelectedFeature] = useState("");

  const onChangeSelected = value => {
    setSelectedFeature(value);
  };

  useEffect(() => {
    let arrExistedFeatures = data.existedFeaturesArr || [];
    let displayFeatures = [];

    let displayFeaturesNumber = fullFeaturesArray.filter(item => !arrExistedFeatures.includes(item));
    // Convert array feature number into array feature string
    displayFeaturesNumber.forEach(element => displayFeatures.push(LangConstant.PACKAGE_TYPES_NAME[element]));

    setSelectedFeature(displayFeatures[0]);
    setNewFeatureArray(displayFeatures);
  }, [data]);

  return (
    <Grid container>
      <Grid item xs={12} sm={6}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="subtitle2" component="p">
              Thêm mới
            </Typography>

            <Box className={newFeaturesArray.length > 0 ? classes.headerBtnGroup : classes.disabledMode}>
              <Grid item xs={5}>
                <SelectedItem
                  value={selectedFeature}
                  isShow={false}
                  onChangeInput={event => onChangeSelected(event)}
                  data={newFeaturesArray}
                />
              </Grid>
              <Grid item xs={3}>
                <Button
                  color="primary"
                  onClick={() => onAddNewFeature(selectedFeature)}
                  variant="outlined"
                  size="medium"
                  className={clsx("semiBold-lg-txt", classes.btnAdd)}
                >
                  Thêm
                </Button>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box className={classes.packageInfo}>
          <ListItem>
            <ListItemText>
              <Typography variant="body2" component="p" className={classes.packageText}>
                Đang dùng gói
              </Typography>
            </ListItemText>
            <PackageItem packageType={data?.plan} />
          </ListItem>
          <Typography variant="body2" component="p" className={classes.smallText}>
            {convertTimestampToDateRange(data?.startDate, data?.expiryDate)}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

AddNewFeatureDialogHeader.propTypes = {
  data: PropTypes.object,
  onChangeSelected: PropTypes.func,
  onAddNewFeatures: PropTypes.func,
};

AddNewFeatureDialogHeader.defaultProps = {
  data: {},
  onChangeSelected: () => {},
  onAddNewFeature: () => {},
};

export default AddNewFeatureDialogHeader;

const fullFeaturesArray = Object.keys(LangConstant.PACKAGE_TYPES_NAME).map(key => Number(key));

const useStyles = makeStyles(theme => ({
  root: {
    width: 980,
    maxWidth: "100%",
  },

  packageInfo: {
    background: "rgba(212, 213, 216, 0.2)",
    borderRadius: 8,
    textAlign: "center",
    float: "right",

    [theme.breakpoints.down("xs")]: {
      float: "left",
      marginTop: 16,
    },

    "& $smallText": {
      padding: 8,
    },
  },
  packageText: {
    fontSize: 12,
    fontWeight: "normal",
    marginRight: 8,
  },
  smallText: {
    fontSize: 12,
    color: theme.palette.grey[500],
  },

  headerBtnGroup: {
    display: "flex",
    marginTop: 10,
    "& > *": {
      marginRight: 8,
    },
  },

  disabledMode: {
    display: "flex",
    opacity: 0.4,
    marginTop: 10,
    "& > *": {
      marginRight: 8,
    },
  },

  btnAdd: {
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white,
    },
  },
}));
