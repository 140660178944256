import { ApiConstant } from "const";
import { createApiRegistration } from "api";
import StringFormat from "string-format";

export const getListBlogItem = data => createApiRegistration().get(ApiConstant.BLOG_ITEM, data);

export const updateBlogItem = data =>
  createApiRegistration().put(StringFormat(ApiConstant.BLOG_ITEM_ID, data.id), data);

export const postBlogItem = data => createApiRegistration().post(ApiConstant.BLOG_ITEM, data);
