import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  makeStyles,
  DialogTitle,
  IconButton,
  OutlinedInput,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import clsx from "clsx";

const DialogAddLinkImage = ({ isShow, onClose, imageUrl }) => {
  const defaultClasses = useStyles();
  const [value, setValue] = useState(imageUrl || "");

  const onChangeLink = event => {
    setValue(event.target.value);
  };

  const onCopy = () => {
    let textField = document.getElementById(ID_IMAGE);
    textField.select();
    document.execCommand("copy");
    onClose();
  };

  useEffect(() => {
    setValue(imageUrl || "");
  }, [imageUrl]);

  return (
    <Dialog open={isShow} classes={{ paperScrollPaper: defaultClasses.dialogContainer }}>
      <DialogTitle className={defaultClasses.dialogTitle}>
        <Typography classes={{ body1: defaultClasses.textDialogTitle }}>Thêm ảnh</Typography>
        <IconButton className={defaultClasses.closeButton} onClick={onClose}>
          <Close className={defaultClasses.closeIcon} />
        </IconButton>
      </DialogTitle>
      <DialogContent className={defaultClasses.dialogContent}>
        <Typography className={clsx("semiBold-sm-txt", defaultClasses.typographyContent)}>Đường dẫn của ảnh</Typography>
        <Typography className={clsx("regular-sm-txt", defaultClasses.descriptionBackground)}>
          Định dạng JPG, PNG, GIF
        </Typography>
        <OutlinedInput
          id={ID_IMAGE}
          classes={{
            root: defaultClasses.contentLineEdit,
            input: defaultClasses.inputEdit,
            disabled: defaultClasses.disabled,
          }}
          required
          onChange={onChangeLink}
          value={value}
        />
      </DialogContent>
      <DialogActions className={defaultClasses.dialogActions}>
        <Button size="small" onClick={onClose}>
          Hủy
        </Button>
        <Button type="submit" size="small" color="primary" variant="contained" onClick={onCopy}>
          Copy link
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ID_IMAGE = "link-image";

DialogAddLinkImage.propTypes = {
  isShow: PropTypes.bool,
  onClose: PropTypes.func,
  imageUrl: PropTypes.string,
};
DialogAddLinkImage.defaultProps = {
  isShow: false,
  onClose: () => {},
  imageUrl: "",
};

export default memo(DialogAddLinkImage);

const useStyles = makeStyles(theme => ({
  dialogContainer: {
    width: 448,
    objectFit: "contain",
    boxShadow: "0 1px 6px 0 rgba(0, 0, 0, 0.1)",
    backgroundColor: theme.palette.white,
    borderRadius: 5,
  },
  dialogTitle: {
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    position: "fixed",
    width: 448,
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    background: "#65b39d",
    color: theme.palette.white,
    height: 60,
    padding: "0 24px",
    zIndex: 100,
  },
  textDialogTitle: {
    color: theme.palette.white,
    fontSize: 20,
  },
  closeButton: {
    position: "absolute",
    right: 14,
    top: 18,
    color: theme.palette.grey[500],
    height: 24,
    width: 24,
    padding: 0,
  },
  closeIcon: {
    fontSize: 24,
    color: theme.palette.white,
  },
  dialogContent: {
    marginTop: 34,
    padding: "26px 24px 17px",
    display: "flex",
    flexDirection: "column",
    "&:first-child": {
      paddingTop: 26,
    },
  },
  typographyContent: {
    marginTop: 16,
    color: "#3e4045",
    lineHeight: 1.67,
  },
  descriptionBackground: {
    marginBottom: 8,
    lineHeight: 1.67,
    color: theme.palette.grey[500],
  },
  contentLineEdit: {
    width: "100%",
    height: 40,
    fontSize: 13,
    padding: "9px 16px",
    "&$disabled": {
      backgroundColor: "#d4d5d8",
      opacity: 0.3,
      color: "#565c6a",
      border: "none",
      cursor: "no-drop",
    },
  },
  disabled: {
    backgroundColor: "#d4d5d8",
    opacity: 0.3,
    border: "none",
    cursor: "no-drop",
  },
  inputEdit: {
    padding: 0,
  },
  dialogActions: {
    display: "block",
    textAlign: "right",
    padding: "16px 24px",
  },
}));
