export const KEY_TOKEN = "gboss-root_token";
export const KEY_USER_ID = "gboss-user_id";
export const KEY_APP = "gboss-root";
export const FM_DD_MM_YYYY = "dd/MM/yyyy";
export const FM_HH_MM = "HH:mm";

export const EXPIRES_TOKEN = 1;

export const STATUS_OPTION = {
  draft: 0,
  publish: 1,
  deleted: 2,
};

export const STATUS_SHOP = {
  active: 1,
  disable: 2,
};

export const STATUS_DISPLAY_HOME_PAGE = {
  display: 1,
  hidden: 0,
};

export const STATUS_PREVIEW = {
  hidden: 0,
  display: 1,
};

export const STATUS_ACCOUNT = {
  active: 1,
  inactive: 2,
};

export const GENDER = {
  1: "Nam",
  2: "Nữ",
  3: "Khác",
};

export const BUSINESS_AREAS = {
  0: "Khác",
  1: "Ăn uống",
  2: "Làm đẹp",
  3: "Thể thao",
  4: "Sức khỏe",
  5: "Thú cưng",
  6: "Sửa chữa",
};

export const STATE_REGISTRATION = {
  notYetContacted: 1,
  contacted: 4,
};

export const BACKGROUND_OPTION = {
  color: 1,
  image: 2,
  video: 3,
};

export const DEFAULT_PASSWORD = "12345678";

export const ROOT_ID = 1;

export const PACKAGE_TYPE = {
  free: 1,
  standard: 2,
  premium: 3,
  enterprise: 4,
};

export const PAYMENTS_STATUS = {
  free: 3, // Additional variable to control on frontend
  paid: 1,
  unpaid: 2,
};

export const SHOP_STATUS = {
  active: 1,
  inactive: 2,
};

export const PACKAGE_DETAIL_TYPE = {
  package: 1,
  shop: 2,
  order: 3,
  booking: 4,
  item: 5,
  staff: 6,
  place: 7,
};

export const USING_TIME_TYPE = {
  custom: 1,
  byPackage: 2,
};
