import React from "react";
import PropTypes from "prop-types";
import { Box, Grid, ListItem, ListItemText, makeStyles, Typography } from "@material-ui/core";
import { PackageItem } from "components";
import { AppConstant } from "const";
import { format as dateFormat } from "date-fns";
import { convertTimestampToDateRange } from "utils";

const PaymentDialogHeader = ({ data }) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12} sm={6}>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" component="p" className={classes.paymentCode}>
                Mã thanh toán
                <Typography variant="subtitle1" component="span">
                  {data?.strCode}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2" component="p" className={classes.smallText}>
                Trạng thái:
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="body2" component="p" className={classes.highlight}>
                {data?.strPaymentStatus}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2" component="p" className={classes.smallText}>
                Ngày thanh toán:
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="body2" component="p">
                {data.paymentDate ? dateFormat(new Date(data.paymentDate * 1000), AppConstant.FM_DD_MM_YYYY) : "-"}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box className={classes.packageInfo}>
          <ListItem>
            <ListItemText>
              <Typography variant="body2" component="p" className={classes.packageText}>
                Đang dùng gói
              </Typography>
            </ListItemText>
            <PackageItem packageType={data?.plan} />
          </ListItem>
          <Typography variant="body2" component="p" className={classes.smallText}>
            {convertTimestampToDateRange(data?.startDate, data?.expiryDate)}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

PaymentDialogHeader.propTypes = { data: PropTypes.object };

export default PaymentDialogHeader;

const useStyles = makeStyles(theme => ({
  root: {
    width: 980,
    maxWidth: "100%",
  },
  paymentCode: {
    fontSize: 14,
    "& > span": {
      fontSize: 14,
      color: "#4f8bff",
    },
  },
  packageInfo: {
    background: "rgba(212, 213, 216, 0.2)",
    borderRadius: 8,
    textAlign: "center",
    float: "right",

    [theme.breakpoints.down("xs")]: {
      float: "left",
      marginTop: 16,
    },

    "& $smallText": {
      padding: 8,
    },
  },
  packageText: {
    fontSize: 12,
    fontWeight: "normal",
    marginRight: 8,
  },
  smallText: {
    fontSize: 12,
    color: theme.palette.grey[500],
  },
  highlight: {
    color: "#ff4a5e",
  },
}));
