import { ApiConstant } from "const";
import { createApiRegistration } from "api";
import StringFormat from "string-format";

export const getListPackage = data => createApiRegistration().get(ApiConstant.LIST_PACKAGE, data);

export const getOwnerInfo = data => createApiRegistration().get(StringFormat(ApiConstant.OWNER_INFO, data.id));

export const getPaymentHistory = data => createApiRegistration().get(ApiConstant.PAYMENT_HISTORY, data);

export const getPaymentDetail = data =>
  createApiRegistration().get(StringFormat(ApiConstant.PAYMENT_DETAILS, data.uid));

export const updatePaymentDetail = data =>
  createApiRegistration().put(StringFormat(ApiConstant.PAYMENT_DETAIL_UID, data.uid), data);

export const updatePackageFeatures = data => createApiRegistration().put(ApiConstant.PACKAGE, data);

export const changePackage = data => createApiRegistration().post(ApiConstant.PACKAGE, data);

export const disableOwner = data => createApiRegistration().put(ApiConstant.OWNER_STATUS_ID, data);

export const updatePaymentAsPaid = data => createApiRegistration().put(ApiConstant.PAYMENTS_STATUS_ID, data);