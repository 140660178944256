import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getBlogsCategoryList: ["data"],
  deleteBlogsCategory: ["data"],
  createBlogsCategory: ["data"],
  updateBlogsCategory: ["data"],

  blogsCategorySuccess: ["data"],
  blogsCategoryFailure: ["data"],
  reset: [],
});

export const BlogsCategoryTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  data: [],
  isFetching: false,
};

/* ------------- Reducers ------------- */

/* ------------- Reducers Get Blogs Category List ------------- */
export const request = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
});

export const success = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetching: false,
    ...action,
  };
};

export const failure = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: false,
});

export const reset = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: false,
});

/* ------------- Mapping ------------- */

export const HANDLERS = {
  [Types.GET_BLOGS_CATEGORY_LIST]: request,
  [Types.DELETE_BLOGS_CATEGORY]: request,
  [Types.CREATE_BLOGS_CATEGORY]: request,
  [Types.UPDATE_BLOGS_CATEGORY]: request,

  [Types.BLOGS_CATEGORY_SUCCESS]: success,

  [Types.BLOGS_CATEGORY_FAILURE]: failure,
  [Types.RESET]: reset,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
