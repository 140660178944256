import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Paper, Typography, CardMedia, IconButton, Tooltip } from "@material-ui/core";
import { EditOutlined, DeleteOutlined, ArrowUpward, ArrowDownward, VisibilityOffOutlined } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import StringFormat from "string-format";
import clsx from "clsx";
import { StatusItem } from "components";
import ConfirmDialog from "components/dialog/ConfirmDialog";
import { AppConstant, LangConstant, ApiConstant } from "const";
import { convertTimestampToDate } from "utils";
import IntroductionAction from "redux/introduction.redux";

const IntroItemList = props => {
  const { data, onOpenSlideDetail } = props;
  const defaultClasses = useStyles();
  const dispatch = useDispatch();

  const isSuccess = useSelector(props => props.introductionRedux.isSuccess);

  const [action, setAction] = useState(DEFAULT_ACTION);

  const onClickAction = state => {
    switch (state) {
      case ACTION_OPTION.noAction:
        setAction(DEFAULT_ACTION);
        break;
      case ACTION_OPTION.pushUp:
        dispatch(IntroductionAction.sortHomeIntro({ id: data.id, isPushUp: true }));
        break;
      case ACTION_OPTION.pushDown:
        dispatch(IntroductionAction.sortHomeIntro({ id: data.id, isPushUp: false }));
        break;
      case ACTION_OPTION.detail:
        onOpenSlideDetail({ data: data, state: ACTION_OPTION.detail });
        break;
      case ACTION_OPTION.edit:
        onOpenSlideDetail({ data: data, state: ACTION_OPTION.edit });
        break;
      case ACTION_OPTION.hidden:
        setAction({
          state: ACTION_OPTION.hidden,
          title: "Ẩn slide",
          message: StringFormat(FM_MESSAGE.hidden, data?.title?.vi),
        });
        break;
      case ACTION_OPTION.delete:
        setAction({
          state: ACTION_OPTION.delete,
          title: "Xóa slide",
          message: StringFormat(FM_MESSAGE.delete, data?.title?.vi),
        });
        break;
      default:
        setAction(DEFAULT_ACTION);
    }
  };

  const onConfirmAction = () => {
    if (action.state === ACTION_OPTION.hidden) {
      dispatch(IntroductionAction.updateHomeIntro({ ...data, published: AppConstant.STATUS_OPTION.draft }));
    } else if (action.state === ACTION_OPTION.delete) {
      dispatch(IntroductionAction.deleteHomeIntro({ id: data.id }));
    }
  };

  useEffect(() => {
    if (isSuccess && action.state === ACTION_OPTION.hidden) {
      dispatch(IntroductionAction.resetIntroSuccess());
      setAction(DEFAULT_ACTION);
    }
  }, [isSuccess]);

  return (
    <Box className={defaultClasses.root}>
      <Box className={defaultClasses.coverNumber}>
        <Typography className={clsx("medium-md-txt", defaultClasses.number)}>{data.sortOrder}</Typography>
      </Box>
      <Paper className={defaultClasses.itemInfoRoot}>
        <Box onClick={() => onClickAction(ACTION_OPTION.detail)} className={defaultClasses.boxImage}>
          {data.previewImage?.vi && (
            <CardMedia
              component="img"
              className={defaultClasses.image}
              alt="..."
              src={StringFormat(FM_ATTACHMENT_IMAGE, data.previewImage.vi)}
            />
          )}
        </Box>
        <Box className={defaultClasses.mainContent}>
          <Box onClick={() => onClickAction(ACTION_OPTION.detail)} flexBasis="30%">
            <Typography
              className={clsx(
                "semiBold-lg-txt ellipsis",
                defaultClasses.highlightTitle,
                defaultClasses.hiddenTextTwoLine,
              )}
            >
              {data?.title?.vi || ""}
            </Typography>
          </Box>
          <Box onClick={() => onClickAction(ACTION_OPTION.detail)} flexBasis="20%">
            <Typography
              className={clsx(
                "medium-md-txt ellipsis",
                defaultClasses.greyTextTypography,
                defaultClasses.hiddenTextTwoLine,
              )}
            >
              Thời gian tạo: {data?.created ? convertTimestampToDate(data.created) : ""}
            </Typography>
          </Box>
          <Box onClick={() => onClickAction(ACTION_OPTION.detail)} flexBasis="20%">
            <Typography
              className={clsx(
                "medium-md-txt ellipsis",
                defaultClasses.greyTextTypography,
                defaultClasses.hiddenTextTwoLine,
              )}
            >
              Thời gian đăng: {data?.publishedTime ? convertTimestampToDate(data.publishedTime) : ""}
            </Typography>
          </Box>
          <Box onClick={() => onClickAction(ACTION_OPTION.detail)} flexBasis="10%">
            <StatusItem
              status={data.published}
              statusProps={{ customizedStatusArray: LangConstant.OBJ_STATUS_DISPLAY }}
            />
          </Box>
          <Box className={defaultClasses.action}>
            <Tooltip title="Lên" classes={{ tooltip: clsx("medium-sm-txt", defaultClasses.tooltip) }}>
              <IconButton
                component="div"
                onClick={() => onClickAction(ACTION_OPTION.pushUp)}
                className={defaultClasses.buttonIcon}
              >
                <ArrowUpward className={defaultClasses.iconAction} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Xuống" classes={{ tooltip: clsx("medium-sm-txt", defaultClasses.tooltip) }}>
              <IconButton
                component="div"
                onClick={() => onClickAction(ACTION_OPTION.pushDown)}
                className={defaultClasses.buttonIcon}
              >
                <ArrowDownward className={defaultClasses.iconAction} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Sửa" classes={{ tooltip: clsx("medium-sm-txt", defaultClasses.tooltip) }}>
              <IconButton
                component="div"
                onClick={() => onClickAction(ACTION_OPTION.edit)}
                className={defaultClasses.buttonIcon}
              >
                <EditOutlined className={defaultClasses.iconAction} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Ẩn" classes={{ tooltip: clsx("medium-sm-txt", defaultClasses.tooltip) }}>
              <IconButton
                component="div"
                onClick={() => onClickAction(ACTION_OPTION.hidden)}
                disabled={data.published !== AppConstant.STATUS_OPTION.publish}
                classes={{ root: defaultClasses.buttonIcon, disabled: defaultClasses.disabledButton }}
              >
                <VisibilityOffOutlined className={defaultClasses.iconAction} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Xóa" classes={{ tooltip: clsx("medium-sm-txt", defaultClasses.tooltip) }}>
              <IconButton
                component="div"
                onClick={() => onClickAction(ACTION_OPTION.delete)}
                className={defaultClasses.buttonIcon}
              >
                <DeleteOutlined className={defaultClasses.iconAction} />
              </IconButton>
            </Tooltip>
          </Box>
          {[ACTION_OPTION.hidden, ACTION_OPTION.delete].includes(action.state) && (
            <ConfirmDialog
              isShow={true}
              onClose={() => onClickAction(ACTION_OPTION.noAction)}
              onSubmit={onConfirmAction}
              title={action.title}
              message={action.message}
            />
          )}
        </Box>
      </Paper>
    </Box>
  );
};

const FM_ATTACHMENT_IMAGE = `${ApiConstant.HOST_ATTACHMENT}{0}`;

export const ACTION_OPTION = {
  noAction: 0,
  detail: 1,
  create: 2,
  pushUp: 3,
  pushDown: 4,
  edit: 5,
  hidden: 6,
  delete: 7,
};

const DEFAULT_ACTION = {
  state: ACTION_OPTION.noAction,
  title: "",
  message: "",
};

const FM_MESSAGE = {
  hidden: `Bạn có chắc muốn ẩn slide "{0}" trên website không?`,
  delete: `Bạn có chắc muốn xóa slide "{0}" không?`,
};

IntroItemList.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.shape({
      vi: PropTypes.string,
      en: PropTypes.string,
    }),
    content: PropTypes.shape({
      vi: PropTypes.string,
      en: PropTypes.string,
    }),
    previewImage: PropTypes.shape({
      vi: PropTypes.string,
      en: PropTypes.string,
    }),
    previewImageStatus: PropTypes.number,
    background: PropTypes.shape({
      color: PropTypes.shape({
        vi: PropTypes.string,
        en: PropTypes.string,
      }),
      image: PropTypes.shape({
        vi: PropTypes.string,
        en: PropTypes.string,
      }),
      video: PropTypes.shape({
        vi: PropTypes.string,
        en: PropTypes.string,
      }),
    }),
    backgroundType: PropTypes.number,
    created: PropTypes.number,
    publishedTime: PropTypes.number,
    sortOrder: PropTypes.number,
    published: PropTypes.number,
  }).isRequired,
  onOpenSlideDetail: PropTypes.func.isRequired,
};

IntroItemList.defaultProps = {
  onOpenSlideDetail: () => {},
};

export default memo(IntroItemList);

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  coverNumber: {
    width: 24,
    height: 24,
    marginRight: 17,
    backgroundColor: theme.palette.white,
  },
  number: {
    textAlign: "center",
    color: "#3e4045",
    lineHeight: 1.57,
  },
  itemInfoRoot: {
    width: "100%",
    height: 82,
    maxHeight: 82,
    display: "flex",
    borderRadius: 4,
    boxShadow: "0 1px 6px 0 rgba(0, 0, 0, 0.16)",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgba(79, 139, 255, 0.1)",
    },
  },
  boxImage: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: 113,
  },
  image: {
    maxWidth: 113,
    maxHeight: 82,
  },
  mainContent: {
    display: "flex",
    margin: theme.spacing(2, 0),
    flexGrow: 1,
    "& > div": {
      marginLeft: 16,
      marginRight: 16,
    },
  },
  highlightTitle: {
    color: "#3e4045",
    lineHeight: 1.5,
    wordBreak: "break-word",
  },
  greyTextTypography: {
    lineHeight: 1.57,
    color: theme.palette.grey[500],
  },
  hiddenTextTwoLine: {
    WebkitLineClamp: 2,
  },
  action: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-end",
    flexBasis: "20%",
  },
  tooltip: {
    height: 20,
    marginLeft: 50,
    marginTop: 2,
    padding: "0 5px",
    backgroundColor: "#7f838c",
    lineHeight: 1.67,
    textAlign: "center",
    borderRadius: 0,
  },
  buttonIcon: {
    width: 24,
    height: 24,
    padding: 0,
    borderRadius: 0,
    border: "1px solid #d4d5d8",
    "&:hover": {
      border: "1px solid " + theme.palette.primary.main,
    },
  },
  iconAction: {
    width: 24,
    height: 24,
    padding: 4,
    color: theme.palette.grey[500],
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  disabledButton: {
    opacity: 0.4,
  },
}));
