import React, { memo } from "react";
import ProtoType from "prop-types";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, makeStyles } from "@material-ui/core";
import clsx from "clsx";

const ConfirmDialog = ({ isShow, title, message, onSubmit, onClose }) => {
  const classes = useStyles();

  return (
    <Dialog open={isShow} classes={{ root: classes.dialogRoot, paper: classes.dialogPaper }}>
      <DialogTitle className={clsx(classes.dialogTitle, "medium-xl-txt")}>{title}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography classes={{ body1: classes.textContent }}>{message}</Typography>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Button onClick={onClose} className={classes.button}>
          Hủy
        </Button>
        <Button onClick={onSubmit} variant="contained" color="primary" size="small">
          Xác nhận
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.protoType = {
  isShow: ProtoType.bool.isRequired,
  title: ProtoType.string,
  message: ProtoType.string,

  onClose: ProtoType.func.isRequired,
  onSubmit: ProtoType.func.isRequired,
};

ConfirmDialog.defaultProps = {
  title: "",
  message: "",
};

export default memo(ConfirmDialog);

const useStyles = makeStyles(theme => ({
  dialogRoot: { "& > div:first-child": { backgroundColor: "rgba(0, 0, 0, 0.25)" } },
  dialogPaper: {
    width: 470,
    borderRadius: 0,
    margin: 0,
  },
  dialogTitle: {
    paddingTop: 24,
    alignItems: "flex-start",
    color: theme.palette.grey[600],
  },
  dialogContent: {
    padding: "0 24px 24px 24px",
    color: theme.palette.grey[500],
    borderBottom: "1px solid" + theme.palette.grey[200],
  },
  textContent: {
    color: "inherit",
    fontSize: 13,
    fontWeight: 500,
  },
  dialogAction: {
    padding: " 10px 27px 10px 0px",
  },
  button: {
    color: theme.palette.grey[400],
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: "transparent",
    },
  },
}));
