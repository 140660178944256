import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Box,
  Grid,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  OutlinedInput,
  IconButton,
} from "@material-ui/core";
import { VisibilityOutlined, VisibilityOffOutlined } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import StringFormat from "string-format";
import { UploadImage } from "components";
import { LangConstant, AppConstant, ApiConstant } from "const";
import UploadAction from "redux/upload.redux";

const BackgroundSlide = props => {
  const { langValue, data, onChange } = props;
  const defaultClasses = useStyles();
  const dispatch = useDispatch();

  const imageURI = useSelector(state => state.uploadRedux.dataImage);

  const [formBackground, setFormBackground] = useState(data);
  const [typeImage, setTypeImage] = useState();

  const onChangeTypeBackground = e => {
    let type = parseInt(e.target.value);
    setFormBackground({ ...formBackground, backgroundType: type });
    onChange(type, NM_BACKGROUND_TYPE);
  };

  const onChangeFormData = e => {
    if (formBackground.backgroundType === AppConstant.BACKGROUND_OPTION.color) {
      let color = e.target.value;
      let newFormBackground = {
        ...formBackground.background,
        color: {
          ...formBackground.background.color,
          [langValue]: color,
        },
      };
      setFormBackground({
        ...formBackground,
        background: newFormBackground,
      });
      onChange(newFormBackground, NM_BACKGROUND);
    }
  };

  const onChangeTypeDisplay = () => {
    let isDisplay = AppConstant.STATUS_PREVIEW.display;
    if (formBackground.previewImageStatus === AppConstant.STATUS_PREVIEW.display) {
      isDisplay = AppConstant.STATUS_PREVIEW.hidden;
    }
    setFormBackground({ ...formBackground, previewImageStatus: isDisplay });
    onChange(isDisplay, NM_PREVIEW_IMAGE_STATUS);
  };

  const onChangeImagePreview = dataForm => {
    if (dataForm?.file) {
      setTypeImage(NM_PREVIEW_IMAGE);
      dispatch(UploadAction.uploadImage(dataForm));
    } else if (dataForm?.isRemove) {
      let newFormPreviewImage = {
        ...formBackground.previewImage,
        [langValue]: "",
      };
      setFormBackground(currentData => ({ ...currentData, previewImage: newFormPreviewImage }));
      onChange(newFormPreviewImage, NM_PREVIEW_IMAGE);
    }
  };

  const onChangeImageBackground = dataForm => {
    if (dataForm?.file) {
      setTypeImage(NM_BACKGROUND);
      dispatch(UploadAction.uploadImage(dataForm));
    } else if (dataForm?.isRemove) {
      let newFormBackgroundImage = {
        ...formBackground.background,
        image: {
          ...formBackground.background.image,
          [langValue]: "",
        },
      };
      setFormBackground(currentData => ({ ...currentData, background: newFormBackgroundImage }));
      onChange(newFormBackgroundImage, NM_BACKGROUND);
    }
  };

  useEffect(() => {
    if (imageURI) {
      if (typeImage === NM_PREVIEW_IMAGE) {
        let newFormPreviewImage = {
          ...formBackground.previewImage,
          [langValue]: imageURI.uri[0],
        };
        setFormBackground(currentData => ({ ...currentData, previewImage: newFormPreviewImage }));
        onChange(newFormPreviewImage, NM_PREVIEW_IMAGE);
      } else if (typeImage === NM_BACKGROUND) {
        let newFormBackgroundImage = {
          ...formBackground.background,
          image: {
            ...formBackground.background.image,
            [langValue]: imageURI.uri[0],
          },
        };
        setFormBackground(currentData => ({ ...currentData, background: newFormBackgroundImage }));
        onChange(newFormBackgroundImage, NM_BACKGROUND);
      }
    }
  }, [imageURI]);

  useEffect(() => {
    setFormBackground(data);
  }, [data]);

  return (
    <Grid item xs={3} className={defaultClasses.root}>
      <UploadImage
        id={"upload-preview" + langValue}
        onChange={onChangeImagePreview}
        defaultUrl={
          formBackground.previewImage[langValue] &&
          StringFormat(FM_ATTACHMENT_IMAGE, formBackground.previewImage[langValue])
        }
      />
      <Box className={defaultClasses.boxIllustration}>
        <IconButton onClick={onChangeTypeDisplay} className={defaultClasses.iconTypeDisplay}>
          {Boolean(formBackground.previewImageStatus) ? (
            <VisibilityOutlined className={defaultClasses.iconIllustration} />
          ) : (
            <VisibilityOffOutlined className={defaultClasses.iconIllustration} />
          )}
        </IconButton>
        <Typography className={defaultClasses.contentIllustration}>
          {StringFormat(LangConstant.FM_ILLUSTRATION, langValue.toUpperCase())}
        </Typography>
      </Box>
      <UploadImage
        id={"background" + langValue}
        onChange={onChangeImageBackground}
        defaultUrl={
          formBackground.background?.image[langValue] &&
          StringFormat(FM_ATTACHMENT_IMAGE, formBackground.background.image[langValue])
        }
      />
      <RadioGroup value={formBackground.backgroundType} onChange={onChangeTypeBackground}>
        <FormControlLabel
          value={AppConstant.BACKGROUND_OPTION.color}
          control={<Radio size="small" color="default" />}
          label={
            <Typography className={defaultClasses.contentIllustration}>
              {StringFormat(LangConstant.FM_BACKGROUND_COLOR, langValue.toUpperCase())}
            </Typography>
          }
        />
        <OutlinedInput
          value={
            formBackground.backgroundType === AppConstant.BACKGROUND_OPTION.color
              ? formBackground.background?.color[langValue]
              : ""
          }
          onChange={onChangeFormData}
          disabled={formBackground.backgroundType !== AppConstant.BACKGROUND_OPTION.color}
          classes={{ root: defaultClasses.rootOutlineColor, input: defaultClasses.inputOutlineColor }}
        />
        <FormControlLabel
          value={AppConstant.BACKGROUND_OPTION.image}
          control={<Radio size="small" color="default" />}
          label={
            <Typography className={defaultClasses.contentIllustration}>
              {StringFormat(LangConstant.FM_IMAGE_VIDEO, langValue.toUpperCase())}
            </Typography>
          }
        />
      </RadioGroup>
    </Grid>
  );
};

const FM_ATTACHMENT_IMAGE = `${ApiConstant.HOST_ATTACHMENT}{0}`;
const NM_BACKGROUND = "background";
const NM_BACKGROUND_TYPE = "backgroundType";
const NM_PREVIEW_IMAGE = "previewImage";
const NM_PREVIEW_IMAGE_STATUS = "previewImageStatus";

BackgroundSlide.propTypes = {
  langValue: PropTypes.string,
  data: PropTypes.shape({
    previewImage: PropTypes.shape({
      vi: PropTypes.string,
      en: PropTypes.string,
    }),
    previewImageStatus: PropTypes.number,
    background: PropTypes.shape({
      color: PropTypes.shape({
        vi: PropTypes.string,
        en: PropTypes.string,
      }),
      image: PropTypes.shape({
        vi: PropTypes.string,
        en: PropTypes.string,
      }),
      video: PropTypes.shape({
        vi: PropTypes.string,
        en: PropTypes.string,
      }),
    }),
    backgroundType: PropTypes.number,
  }),
  onChange: PropTypes.func,
  url: PropTypes.string,
};

BackgroundSlide.defaultProps = {
  onChange: () => {},
};

export default memo(BackgroundSlide);

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 34,
  },
  boxIllustration: {
    display: "flex",
    height: 20,
    margin: "8px 0 24px",
  },
  iconTypeDisplay: {
    padding: 0,
    height: 20,
    width: 20,
    marginRight: 8,
  },
  iconIllustration: {
    width: 16,
    height: 16,
    margin: 2,
    color: theme.palette.grey[500],
  },
  contentIllustration: {
    height: 20,
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.grey[500],
    lineHeight: 1.67,
  },
  rootOutlineColor: {
    marginLeft: 26,
    height: 22,
    fontSize: 12,
    fontWeight: 400,
  },
  inputOutlineColor: {
    padding: 5,
  },
}));
