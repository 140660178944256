import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import { AppConstant } from "const";
import ShopInfoLine from "./ShopInfoLine";
import SaveConfirmDialog from "./SaveConfirmDialog";

const ShopInformationForm = ({ renderCondition, actionContent }) => {
  const classes = useStyles();

  let { data: shopData } = renderCondition;
  let isEditing = renderCondition.isEditing;

  const [shopInfoForm, setShopInfoForm] = useState(formatShopInfo(shopData));
  const [isEdited, setIsEdited] = useState(false);
  const [isShowConfirm, setIsShowConfirm] = useState(false);

  const onChangeBusinessType = value => {
    let businessArray = Object.entries(AppConstant.BUSINESS_AREAS);
    businessArray.forEach(element => {
      if (element[1] === value) setShopInfoForm({ ...shopInfoForm, businessId: parseInt(element[0]) });
    });
  };

  const onChangeShopStatus = value => {
    let statusArray = Object.entries(STATUS_SHOP);
    statusArray.forEach(element => {
      if (element[1] === value) setShopInfoForm({ ...shopInfoForm, state: parseInt(element[0]) });
    });
  };

  const onChangeShopInfo = (name, value) => {
    setShopInfoForm({ ...shopInfoForm, [name]: value });
  };
  const onCancelConfirm = () => {
    setIsShowConfirm(false);
    setShopInfoForm(formatShopInfo(shopData));
    actionContent.onCancel();
  };

  const onConfirmSave = () => {
    actionContent.onConfirm(shopInfoForm);
    setIsShowConfirm(false);
  };

  let statusClass =
    shopInfoForm.state === AppConstant.STATUS_SHOP.active
      ? classes.activeShopStatus
      : shopInfoForm.state === AppConstant.STATUS_SHOP.disable
      ? classes.deActiveShopStatus
      : "";

  let isValidForm = Boolean(
    shopInfoForm.name.trim() &&
      shopInfoForm.address.trim() &&
      shopInfoForm.ownerName.trim() &&
      shopInfoForm.phoneNumber.trim() &&
      shopInfoForm.email.trim() &&
      shopInfoForm.businessId,
  );

  useEffect(() => {
    if (JSON.stringify(shopInfoForm) !== JSON.stringify(formatShopInfo(shopData)) && isValidForm) {
      setIsEdited(true);
    } else {
      setIsEdited(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopData, shopInfoForm]);

  useEffect(() => {
    if (renderCondition.isClickCancel) {
      if (isEdited) setIsShowConfirm(true);
      else {
        setShopInfoForm(formatShopInfo(shopData));
        actionContent.onCancel();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderCondition.isClickCancel]);

  return (
    <Box className={classes.formRoot}>
      <Box className={classes.formContainer}>
        <Box className={classes.leftSideBox}>
          <Box>
            <Typography className={clsx(classes.title, "semiBold-md-txt")}>Thông tin shop</Typography>
            <ShopInfoLine
              title={`Tên ${isEditing ? " *" : ":"}`}
              isEditing={isEditing}
              placeHolder="Nhập tên shop"
              onChange={value => onChangeShopInfo("name", value)}
              value={shopInfoForm.name}
            />
            <ShopInfoLine
              title={`Lĩnh vực ${isEditing ? " *" : ":"}`}
              isEditing={isEditing}
              placeHolder="Chọn lĩnh vực kinh doanh"
              isMenuSelect={true}
              selectArray={Object.values(AppConstant.BUSINESS_AREAS)}
              onChange={onChangeBusinessType}
              selectedMenuItem={AppConstant.BUSINESS_AREAS[shopInfoForm.businessId]}
              classes={{
                menuItem: classes.shopFieldMenuItem,
              }}
              value={AppConstant.BUSINESS_AREAS[shopInfoForm.businessId]}
            />
            <ShopInfoLine
              title={`Địa chỉ ${isEditing ? " *" : ":"}`}
              isEditing={isEditing}
              placeHolder="Nhập địa chỉ của shop"
              classes={{
                inputRoot: classes.biggerInputRoot,
                input: classes.biggerInput,
              }}
              onChange={value => onChangeShopInfo("address", value)}
              value={shopInfoForm.address}
            />
            <ShopInfoLine
              title={`Trạng thái${isEditing ? "" : ":"}`}
              isEditing={isEditing}
              isMenuSelect={true}
              selectArray={Object.values(STATUS_SHOP)}
              onChange={onChangeShopStatus}
              selectedMenuItem={STATUS_SHOP[shopInfoForm.state]}
              classes={{
                input: statusClass,
                textClass: statusClass,
              }}
              value={STATUS_SHOP[shopInfoForm.state]}
            />
          </Box>
          <Box>
            <Typography className={clsx(classes.title, "semiBold-md-txt")}>Chủ sở hữu</Typography>
            <ShopInfoLine
              title={`Tên ${isEditing ? " *" : ":"}`}
              isEditing={isEditing}
              placeHolder="Nhập tên chủ sở hữu"
              onChange={value => onChangeShopInfo("ownerName", value)}
              value={shopInfoForm.ownerName}
            />

            <ShopInfoLine
              title={`Số điện thoại ${isEditing ? " *" : ":"}`}
              isEditing={isEditing}
              placeHolder="Nhập số điện thoại"
              onChange={value => onChangeShopInfo("phoneNumber", value)}
              value={shopInfoForm.phoneNumber}
            />

            <ShopInfoLine
              title={`Email ${isEditing ? " *" : ":"}`}
              isEditing={isEditing}
              placeHolder="Nhập email"
              onChange={value => onChangeShopInfo("email", value)}
              value={shopInfoForm.email}
            />
          </Box>
        </Box>
        <Box className={classes.rightSideBox}>
          <Typography className={clsx(classes.title, "semiBold-md-txt")}>Thông tin khác</Typography>
          <ShopInfoLine
            title={`Website${isEditing ? "" : ":"}`}
            isEditing={isEditing}
            placeHolder="Nhập link website"
            onChange={value => onChangeShopInfo("website", value)}
            value={shopInfoForm.website}
            isLink={true}
          />
          <ShopInfoLine
            title={`Facebook${isEditing ? "" : ":"}`}
            isEditing={isEditing}
            placeHolder="Nhập link Fanpage Facebook"
            onChange={value => onChangeShopInfo("facebook", value)}
            value={shopInfoForm.facebook}
            isLink={true}
          />
          <ShopInfoLine
            title={`Google map${isEditing ? "" : ":"}`}
            isEditing={isEditing}
            placeHolder="Nhập link Google map"
            onChange={value => onChangeShopInfo("google", value)}
            value={shopInfoForm.google}
            isLink={true}
          />
          <ShopInfoLine
            title={`Thông tin bổ sung${isEditing ? "" : ":"}`}
            isEditing={isEditing}
            placeHolder="Nhập thông tin bổ sung"
            classes={{
              inputRoot: classes.biggerInputRoot,
              input: classes.biggerInput,
            }}
            onChange={value => onChangeShopInfo("otherInformation", value)}
            value={shopInfoForm.otherInformation}
          />
          {!renderCondition.isCreating && (
            <>
              <ShopInfoLine
                title={`Code${isEditing ? "" : ":"}`}
                isEditing={isEditing}
                placeHolder="Code"
                onChange={value => onChangeShopInfo("code", value)}
                value={shopInfoForm.code}
              />
              <ShopInfoLine
                title={`Alias${isEditing ? "" : ":"}`}
                isEditing={isEditing}
                placeHolder="Alias"
                onChange={value => onChangeShopInfo("alias", value)}
                value={shopInfoForm.alias}
              />
              <ShopInfoLine
                title={`Số giao dịch${isEditing ? "" : ":"}`}
                isEditing={false}
                placeHolder="Alias"
                value={shopInfoForm.numberReservation}
                classes={{
                  inputLineRoot: classes.inputLineRoot,
                }}
              />
            </>
          )}
        </Box>
      </Box>
      {isEditing && (
        <Box className={classes.formAction}>
          <Button
            className={classes.cancelButton}
            color="primary"
            variant="outlined"
            onClick={() => {
              setShopInfoForm(formatShopInfo(shopData));
              actionContent.onCancel();
            }}
          >
            {actionContent?.cancelContent || "Hủy"}
          </Button>
          <Button
            className={classes.confirmButton}
            color="primary"
            variant="contained"
            onClick={() => actionContent.onConfirm(shopInfoForm)}
            disabled={!isEdited}
          >
            {actionContent?.confirmContent || "Lưu"}
          </Button>
        </Box>
      )}

      {isEdited && <SaveConfirmDialog isShow={isShowConfirm} onConfirm={onConfirmSave} onClose={onCancelConfirm} />}
    </Box>
  );
};

ShopInformationForm.propTypes = {
  renderCondition: PropTypes.shape({
    isEditing: PropTypes.bool,
    isCreating: PropTypes.bool,
    isClickCancel: PropTypes.bool,
    data: PropTypes.object,
  }),
  actionContent: PropTypes.shape({
    cancelContent: PropTypes.string,
    confirmContent: PropTypes.string,

    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
  }),
};

ShopInformationForm.defaultProps = {
  renderCondition: {
    isEditing: false,
    isCreating: false,
    isClickCancel: false,
    data: {},
  },

  actionContent: {
    onCancel: () => {},
    onConfirm: () => {},
  },
};

const STATUS_SHOP = {
  1: "Active",
  2: "Disable",
};

export default memo(ShopInformationForm);

const formatShopInfo = data => {
  let socialInfo = data?.social;

  return {
    name: data?.name || "",
    businessId: data?.businessId || null,
    address: data?.address || "",
    state: data?.state || 1,
    ownerName: data?.ownerName || "",
    phoneNumber: data?.phoneNumber || "",
    email: data?.email || "",
    website: socialInfo?.website || "",
    facebook: socialInfo?.facebook || "",
    google: socialInfo?.google || "",
    otherInformation: data?.otherInformation || "",
    code: data?.code || "",
    alias: data?.alias || "",
    numberReservation: data?.numberReservation || 0,
  };
};

const useStyles = makeStyles(theme => ({
  leftSideBox: {
    height: "100%",
    width: "49%",
    minWidth: "49%",
    marginRight: "1%",
  },

  rightSideBox: {
    height: "100%",
    width: "49%",
    minWidth: "49%",
    marginLeft: "1%",
  },

  biggerInput: {
    height: 62,
  },

  biggerInputRoot: {
    height: 62,
  },

  activeShopStatus: {
    color: theme.palette.secondary.dark,
  },

  deActiveShopStatus: {
    color: "#feba40",
  },

  shopFieldMenuItem: {
    "&:hover": {
      color: "#fff",
    },
  },
  title: {
    marginBottom: 18,
  },
  inputLineRoot: {
    marginTop: 18,
  },
  formRoot: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  formContainer: {
    display: "flex",
  },
  formAction: {
    display: "flex",
    justifyContent: "center",
    marginTop: 65,
  },
  cancelButton: {
    borderRadius: 4,
    marginRight: 8,
  },
  confirmButton: {
    borderRadius: 4,
    marginLeft: 8,
  },
}));
