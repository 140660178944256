import React, { memo, useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { makeStyles, Box, Typography } from "@material-ui/core";
import ODPaymentItem from "./ODPaymentItem";
import { isArrayNotEqual } from "utils";

const PaymentHistory = () => {
  const classes = useStyles();

  const paymentHistory = useSelector(({ ownerDetailsRedux }) => ownerDetailsRedux.paymentHistory, shallowEqual);

  // paymentList contains all data from server
  const [paymentList, setPaymentList] = useState([]);
  // displayData includes showing item by pagination (pagination is see more action)
  const [displayData, setDisplayData] = useState([]);
  const [isSeeAll, setIsSeeAll] = useState(false);

  const onCollapse = () => {
    setIsSeeAll(false);
    setDisplayData(paymentList.slice(0, DEFAULT_SIZE_DATA));
  };
  const onSeeMore = () => {
    let dataSize = displayData.length + SEE_MORE_SIZE_DATA;

    setDisplayData(paymentList.slice(0, dataSize));
    if (dataSize >= paymentList.length) setIsSeeAll(true);
  };

  useEffect(() => {
    if (isArrayNotEqual(paymentHistory, paymentList)) {
      setPaymentList(paymentHistory);
      // At first render 2 latest payments
      setDisplayData(paymentHistory.slice(0, DEFAULT_SIZE_DATA));
    }
  }, [paymentHistory]);

  return (
    <Box className={classes.paymentHistoryBox}>
      <Typography className={classes.headerText}>Lịch sử thanh toán</Typography>
      {displayData.map((dataMap, index) => (
        <ODPaymentItem key={index} data={dataMap} />
      ))}
      {isSeeAll ? (
        <Typography
          color="primary"
          className={paymentList.length > DEFAULT_SIZE_DATA ? "medium-md-txt" : classes.hiddenText}
          onClick={onCollapse}
        >
          Thu gọn
        </Typography>
      ) : (
        <Typography
          color="primary"
          className={paymentList.length > DEFAULT_SIZE_DATA ? "medium-md-txt" : classes.hiddenText}
          onClick={onSeeMore}
        >
          Xem thêm
        </Typography>
      )}
    </Box>
  );
};

PaymentHistory.propTypes = {};

PaymentHistory.defaultProps = {};

export default memo(PaymentHistory);

const DEFAULT_SIZE_DATA = 2;
const SEE_MORE_SIZE_DATA = 3;

const useStyles = makeStyles(() => ({
  paymentHistoryBox: {
    width: "100%",
    "& > *:last-child": {
      textAlign: "center",
      cursor: "pointer",
    },
  },
  headerText: {
    paddingBottom: 24,
    fontSize: 20,
    fontWeight: 600,
    color: "#3e4045",
  },
  hiddenText: {
    display: "none",
  },
}));
