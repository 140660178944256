import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles, Paper, Box, Typography } from "@material-ui/core";
import { SearchBar } from "components";
import { LangConstant } from "const";
import stringFormat from "string-format";
import { useDispatch } from "react-redux";
import ShopAction from "redux/shop.redux";
import clsx from "clsx";

const HeaderFilter = props => {
  const { totalShop } = props;
  const defaultClasses = useStyles();
  const dispatch = useDispatch();

  const onSubmitSearch = dataSearch => {
    dispatch(ShopAction.getListShop(formatDataSearch(dataSearch)));
  };

  return (
    <Paper className={defaultClasses.root}>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex">
          <Typography className={clsx("medium-md-txt", defaultClasses.titleSearch)}>Tìm kiếm</Typography>
          <SearchBar onSearch={onSubmitSearch} />
        </Box>
        <Typography className={clsx("medium-md-txt", defaultClasses.totalShop)}>
          {stringFormat(LangConstant.FM_TOTAL_SHOP, totalShop)}
        </Typography>
      </Box>
    </Paper>
  );
};

const formatDataSearch = dataSearch => ({
  username: dataSearch,
  name: dataSearch,
  address: dataSearch,
  owner_name: dataSearch,
  phone_number: dataSearch,
  email: dataSearch,
});

HeaderFilter.propTypes = {
  totalShop: PropTypes.number,
};

HeaderFilter.defaultProps = {
  totalShop: 0,
};

export default memo(HeaderFilter);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    padding: "11px 34px",
    borderRadius: 0,
    boxShadow: "0 1px 6px 0 rgba(0, 0, 0, 0.16)",
  },
  titleSearch: {
    margin: "8px 13px 8px 0",
    lineHeight: 1.57,
    color: "#3e4045",
  },
  totalShop: {
    padding: "9px 0",
    lineHeight: 1.57,
    color: "#3e4045",
  },
}));
