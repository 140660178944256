import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getHomeEvaluate: [],
  createHomeEvaluate: ["data"],
  updateHomeEvaluate: ["data"],
  deleteHomeEvaluate: ["data"],

  homeEvaluateSuccess: ["data"],
  homeEvaluateFailure: ["error"],
  resetEvaluateSuccess: [],
});

export const EvaluateTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  data: [],

  isSuccess: false,
  isFetching: false,
  error: null,
};

/* ------------- Reducers ------------- */
export const request = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
  isSuccess: false,
});

export const success = (state = INITIAL_STATE, action) => {
  let data = action.data || {};
  return { ...state, isFetching: false, ...data };
};

export const failure = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: false,
});

export const reset = (state = INITIAL_STATE) => ({ ...state, isSuccess: false });

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.GET_HOME_EVALUATE]: request,
  [Types.CREATE_HOME_EVALUATE]: request,
  [Types.UPDATE_HOME_EVALUATE]: request,
  [Types.DELETE_HOME_EVALUATE]: request,

  [Types.HOME_EVALUATE_SUCCESS]: success,
  [Types.HOME_EVALUATE_FAILURE]: failure,
  [Types.RESET_EVALUATE_SUCCESS]: reset,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
