import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, Box, Typography, Button } from "@material-ui/core";
import clsx from "clsx";
import { getMonthDiff } from "utils";
import { AppConstant } from "const";
import { Alert, PackageItem, PaymentStatus } from "components";
import LineInfo from "../LineInfo";
import ChangePackageDialog from "../ChangePackageDialog";
import OwnerDetailsAction from "redux/owner-details.redux";

const ODPackageService = ({ data }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isSuccess = useSelector(({ ownerDetailsRedux }) => ownerDetailsRedux.isSuccess);

  const [isOpen, setIsOpen] = useState(false);
  const [isChangePackageSuccess, setIsChangePackageSuccess] = useState(false);

  const onChangePackage = () => {
    setIsOpen(true);
  };
  const onCloseDialog = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (isSuccess) {
      setIsChangePackageSuccess(true);
      dispatch(OwnerDetailsAction.ownerDetailsSuccess({ isSuccess: false }));
    }
  }, [isSuccess]);

  return (
    <Box className={classes.packageBox}>
      <Typography className="semiBold-md-txt">Gói dịch vụ</Typography>
      <Box className={classes.textInfoBox}>
        <LineInfo
          title="Tên gói:"
          content={<PackageItem packageType={data?.plan || AppConstant.PACKAGE_TYPE.free} />}
        />
        <LineInfo
          title="Thời gian:"
          content={getMonthDiff(data?.startDate, data?.expiryDate)}
          otherProps={clsx("medium-md-txt", classes.textNormalColor)}
        />
        <LineInfo title="Trạng thái:" content={<PaymentStatus paymentStatus={data?.paymentStatus} />} />
        <LineInfo
          title="Mã thanh toán:"
          content={data?.paymentCode}
          otherProps={clsx("medium-md-txt", classes.codeText)}
        />
      </Box>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        className={clsx("semiBold-md-txt", classes.buttonChangePackage)}
        onClick={onChangePackage}
      >
        Thay đổi gói
      </Button>
      {isOpen && (
        <ChangePackageDialog
          open={isOpen}
          data={{
            userId: Number(data.userId),
            startDate: data.startDate,
            expiryDate: data.expiryDate,
            plan: data.plan,
          }}
          onClose={onCloseDialog}
        />
      )}
      {isChangePackageSuccess && (
        <Alert
          isShow={isChangePackageSuccess}
          onClose={() => setIsChangePackageSuccess(false)}
          type="success"
          message="Thay đổi gói thành công"
        />
      )}
    </Box>
  );
};

ODPackageService.propTypes = {
  data: PropTypes.object.isRequired,
};

ODPackageService.defaultProps = {
  data: {},
};

export default memo(ODPackageService);

const useStyles = makeStyles(theme => ({
  packageBox: {
    width: "100%",
    height: 260,
    padding: "12px 30px 12px 13px",
    backgroundColor: "rgba(212, 213, 216, 0.25)",
    borderRadius: 8,
    "& > *:first-child": {
      marginBottom: 18,
    },
  },

  buttonChangePackage: {
    position: "absolute",
    right: 62,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white,
    },
  },

  textNormalColor: {
    color: "#3e4045",
  },
  codeText: {
    color: theme.palette.text.link,
    lineHeight: 1.57,
  },
  root: {
    width: 1000,
    maxWidth: "100%",
    padding: "16px 24px",
  },
}));
