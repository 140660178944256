import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { formatToFormCurrency, isArrayNotEqual } from "utils";
import OwnerDetailsAction from "redux/owner-details.redux";
import { Alert } from "components";
import PaymentDialogEditingRow from "../PaymentDialog/PaymentDialogEditingRow";

const AddNewFeatureDialogBody = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { data, onCallback } = props;

  const isUpdateSuccess = useSelector(({ ownerDetailsRedux }) => ownerDetailsRedux.isSuccess);

  const [listFeatures, setListFeatures] = useState(data?.packageDetails || []);
  const [isNoticePayment, setIsNoticePayment] = useState(true);
  const [isUpdateFailure, setIsUpdateFailure] = useState(false);
  const [isCheckUpdate, setIsCheckUpdate] = useState(false);

  const onDeleteFunction = featureIndex => {
    let cloneFeatures = JSON.parse(JSON.stringify(listFeatures));
    cloneFeatures.splice(featureIndex, 1);
    setListFeatures(cloneFeatures);
    onCallback(cloneFeatures);
  };

  const onChangeFeature = (index, feature) => {
    let cloneFeatures = JSON.parse(JSON.stringify(listFeatures));
    cloneFeatures[index] = feature;
    setListFeatures(cloneFeatures);
    onCallback(cloneFeatures);
  };

  const onSubmit = () => {
    setIsCheckUpdate(true);
    let updatedData = { ...data };
    updatedData.packageDetails = listFeatures;
    if (isNoticePayment) updatedData.isRequestPayment = Number(isNoticePayment);
    dispatch(OwnerDetailsAction.updatePackageFeature({ ...updatedData }));
  };

  useEffect(() => {
    if (data && isArrayNotEqual(data.packageDetails, listFeatures)) {
      setListFeatures(data.packageDetails);
    }
  }, [data]);

  useEffect(() => {
    if (isUpdateSuccess) {
      onCallback();
    } else if (isCheckUpdate) {
      setIsUpdateFailure(true);
    }
  }, [isUpdateSuccess]);

  return (
    <Box className={classes.root}>
      <TableContainer className={classes.table}>
        {listFeatures.length > 0 ? (
          <Table stickyHeader aria-label="sticky table">
            <TableHead data={data}>
              <TableRow>
                {COLUMNS.map((column, index) => (
                  <TableCell key={column.id} style={{ minWidth: column.minWidth, paddingLeft: index === 0 ? 0 : 16 }}>
                    {column.label}
                  </TableCell>
                ))}
                <TableCell padding="checkbox" />
              </TableRow>
            </TableHead>

            <TableBody>
              {listFeatures.map((dataRow, index) => (
                <PaymentDialogEditingRow
                  key={index}
                  data={{ ...dataRow }}
                  onDelete={() => onDeleteFunction(index)}
                  onChange={data => onChangeFeature(index, data)}
                />
              ))}
            </TableBody>
          </Table>
        ) : (
          <Box className={classes.emptyData}>Vui lòng chọn tính năng muốn thêm</Box>
        )}
      </TableContainer>

      <Grid container>
        <Grid item xs={12}>
          <Typography variant="subtitle1" component="p" className={classes.paymentTotal}>
            TỔNG CỘNG
            <Typography variant="subtitle1" component="span">
              {formatToFormCurrency(data?.totalPrice)}
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={9} className={classes.checkboxRoot}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={event => setIsNoticePayment(event.target.checked)}
                checked={isNoticePayment}
                color="primary"
              />
            }
            label="Thêm tính năng đồng thời gửi yêu cầu thanh toán đến chủ tài khoản"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button size="small" color="primary" variant="text" onClick={onCallback}>
            Hủy
          </Button>
          <Button size="small" color="primary" variant="contained" onClick={onSubmit}>
            Tạo tính năng
          </Button>
        </Grid>
      </Grid>
      {isCheckUpdate && isUpdateFailure && (
        <Alert
          isShow={isUpdateFailure}
          onClose={() => setIsUpdateFailure(false)}
          type="error"
          message="Cập nhật chi tiết thanh toán không thành công, hãy thử lại sau vài giây"
        />
      )}
    </Box>
  );
};

AddNewFeatureDialogBody.propTypes = {
  data: PropTypes.object,

  onCallback: PropTypes.func,
};

export default AddNewFeatureDialogBody;

const COLUMNS = [
  { id: "object", label: "Đối tượng", minWidth: 110 },
  { id: "amount", label: "Số lượng", minWidth: 80 },
  { id: "time", label: "Thời gian sử dụng", minWidth: 320 },
  { id: "month", label: "Số tháng", minWidth: 80 },
  { id: "price", label: "Đơn giá", minWidth: 140 },
  { id: "money", label: "Thành tiền", minWidth: 140 },
];

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      textAlign: "right",
    },
  },
  table: {
    width: "100%",
    maxHeight: 400,
    marginTop: 16,
    marginBottom: 16,
    borderTop: "dashed 1px #d4d5d8",
    borderBottom: "dashed 1px #d4d5d8",
    "& th": {
      paddingLeft: 0,
      color: theme.palette.grey[500],
    },
    "& td": {
      paddingLeft: 0,
      whiteSpace: "pre-wrap",
    },
  },
  paymentTotal: {
    fontSize: 18,
    marginBottom: 24,

    "& > span": {
      fontSize: 18,
      color: theme.palette.primary.main,
      marginLeft: 40,
      marginRight: 16,
    },
  },
  checkboxRoot: {
    textAlign: "left",
    marginBottom: 16,
    [theme.breakpoints.up("sm")]: {
      marginBottom: 0,
    },
  },
  emptyData: {
    display: "flex",
    width: "100%",
    height: 40,
    padding: "20px 0px",
    alignItems: "center",
    justifyContent: "center",
  },
}));
