import React, { memo } from "react";
import { SvgIcon } from "@material-ui/core";

const FacebookIcon = ({ className }) => {
  return (
    <SvgIcon className={className}>
      <path
        fill="#F1F3F6"
        fillRule="evenodd"
        d="M0 24c0 13.234 10.603 24 23.636 24s23.637-10.766 23.637-24S36.669 0 23.636 0C10.603 0 0 10.766 0 24zm1.153 0c0-12.588 10.086-22.83 22.483-22.83C36.034 1.17 46.12 11.413 46.12 24c0 12.588-10.086 22.83-22.484 22.83C11.239 46.83 1.153 36.587 1.153 24z"
        clipRule="evenodd"
      />
      <circle cx="24" cy="24" r="24" fill="#3B5998" />
      <path
        fill="#fff"
        d="M18.5 20.43h2.181v-2.12c0-.934.024-2.375.703-3.268.715-.945 1.697-1.587 3.387-1.587 2.752 0 3.91.392 3.91.392l-.545 3.23s-.909-.263-1.757-.263c-.849 0-1.608.304-1.608 1.152v2.463h3.479l-.243 3.156h-3.236v10.96h-4.09v-10.96H18.5v-3.156z"
      />
    </SvgIcon>
  );
};

export default memo(FacebookIcon);
