import React, { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ProtoType from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  makeStyles,
  IconButton,
  TextField,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { AppConstant } from "const";
import BlogsCategoryAction from "redux/blogs-category.redux";

const CreateAndModifyCategoryDialog = ({ isShow, onClose, data, title }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let isEditCategory = Boolean(data.id);

  const [categoryData, setCategoryData] = useState(CATEGORY_DEFAULT);
  const [isDisabledSubmitButton, setIsDisabledSubmitButton] = useState(true);

  const onChangeCategoryTitle = e => {
    let name = e.target.name;
    let value = e.target.value;
    let tempTitle = { ...categoryData.title, [name]: value };

    setCategoryData({ ...categoryData, title: tempTitle });
  };

  const onChangeCategoryDescription = e => {
    let value = e.target.value;
    let tempDescription = { ...categoryData.description, vi: value };
    setCategoryData({ ...categoryData, description: tempDescription });
  };

  const onSubmit = isSubmit => {
    let newCategory = { ...categoryData };
    if (isSubmit) {
      newCategory = { ...newCategory, published: AppConstant.STATUS_OPTION.publish };
    }
    if (isEditCategory) {
      // Call api to update category
      dispatch(BlogsCategoryAction.updateBlogsCategory(newCategory));
    } else {
      // Call api to add category
      dispatch(BlogsCategoryAction.createBlogsCategory(newCategory));
    }
    onClose();
  };

  useEffect(() => {
    if (isEditCategory) {
      setCategoryData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    let isChanging = true;
    if (isEditCategory) {
      isChanging = data && Boolean(JSON.stringify(categoryData) === JSON.stringify(data));
      if (!data.published) {
        isChanging = false;
      }
    } else {
      let isObjectNotEmpty = categoryData.title.vi || categoryData.title.en || categoryData.description.vi;
      if (isObjectNotEmpty) isChanging = false;
    }

    setIsDisabledSubmitButton(isChanging);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryData, isEditCategory]);

  return (
    <Dialog open={isShow} classes={{ root: classes.dialogRoot, paper: classes.dialogPaper }} onBackdropClick={onClose}>
      <DialogTitle className={classes.dialogTitle}>
        <Typography className={`semiBold-lg-txt ${classes.titleText}`}>{title}</Typography>
        <IconButton onClick={onClose} className={classes.buttonIconClose} size="small">
          <Close className={classes.closeIcon} />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <Typography classes={{ body1: `semiBold-sm-txt ${classes.textContent}` }}>Tên danh mục</Typography>
        <TextField
          variant="outlined"
          name="vi"
          value={categoryData?.title?.vi || ""}
          placeholder="Nhập tên danh mục (VI)"
          InputProps={{
            classes: { root: `medium-md-txt ${classes.categoryOutlineRoot}`, input: classes.categoryOutlineInput },
          }}
          onChange={onChangeCategoryTitle}
        />
        <TextField
          variant="outlined"
          name="en"
          value={categoryData?.title?.en || ""}
          placeholder="Nhập tên danh mục (EN)"
          InputProps={{
            classes: { root: `medium-md-txt ${classes.categoryOutlineRoot}`, input: classes.categoryOutlineInput },
          }}
          onChange={onChangeCategoryTitle}
        />
        <Typography classes={{ body1: `semiBold-sm-txt ${classes.textContent}` }}>Mô tả danh mục</Typography>
        <TextField
          multiline
          variant="outlined"
          value={categoryData?.description?.vi || ""}
          placeholder="Nhập mô tả danh mục (VI)"
          InputProps={{
            classes: {
              root: `medium-md-txt ${classes.categoryDescriptionOutlineRoot}`,
              input: classes.categoryOutlineInput,
              multiline: classes.categoryDescriptionInput,
            },
          }}
          onChange={onChangeCategoryDescription}
        />
        <Typography classes={{ body1: `semiBold-sm-txt ${classes.textContent}` }}>Trạng thái</Typography>
        <TextField
          disabled
          variant="outlined"
          value={categoryStatus[categoryData?.published ? categoryData.published : 0]}
          InputProps={{
            readOnly: true,
            classes: {
              root: `medium-md-txt ${classes.categoryOutlineRoot}`,
              input: classes.categoryOutlineInput,
              disabled: classes.categoryReadOnly,
            },
          }}
        />
      </DialogContent>

      <DialogActions className={classes.dialogAction}>
        <Button
          onClick={() => onSubmit(false)}
          className={classes.button}
          size="small"
          disabled={isDisabledSubmitButton}
        >
          Lưu
        </Button>
        <Button
          onClick={() => onSubmit(true)}
          variant="contained"
          color="primary"
          size="small"
          disabled={isDisabledSubmitButton}
        >
          Xuất bản
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CreateAndModifyCategoryDialog.protoType = {
  isShow: ProtoType.bool.isRequired,
  data: ProtoType.object,
  title: ProtoType.string,

  onClose: ProtoType.func.isRequired,
};

CreateAndModifyCategoryDialog.defaultProps = {
  title: "",
  data: {},
};

export default memo(CreateAndModifyCategoryDialog);

const CATEGORY_DEFAULT = {
  title: { vi: "", en: "" },
  image: "",
  published: 0,
  description: { vi: "", en: "" },
};
const categoryStatus = ["Bản nháp", "Xuất bản"];

const useStyles = makeStyles(theme => ({
  dialogRoot: { "& > div:first-child": { backgroundColor: "rgba(0, 0, 0, 0.25)" } },
  dialogPaper: {
    width: 450,
    borderRadius: 5,
    margin: 0,
  },
  dialogTitle: {
    padding: "8px 10px 8px 24px",
    backgroundColor: theme.palette.secondary.dark,
    "&>*": {
      display: "flex",
      alignItems: "center",
      color: theme.palette.white,
    },
  },
  titleText: { color: theme.palette.white },
  dialogContent: {
    color: "#3e4045",
  },
  buttonIconClose: { marginLeft: "auto" },
  closeIcon: { width: 20, height: 20, color: theme.palette.white },
  textContent: {
    padding: "8px 0px",
    color: "inherit",
    fontSize: 13,
    fontWeight: 500,
  },
  categoryOutlineRoot: {
    width: 402,
    height: 40,
    marginBottom: 8,
  },
  categoryDescriptionOutlineRoot: {
    width: 402,
    minHeight: 60,
    marginBottom: 8,
  },
  categoryOutlineInput: {
    textOverflow: "ellipsis",
    padding: 0,
    margin: "0px 16px",
    borderRadius: 3,
  },
  categoryDescriptionInput: {
    alignItems: "flex-start",
    padding: "8px 0px",
  },
  categoryReadOnly: { color: "#3e4045" },
  dialogAction: {
    padding: " 26px 27px 16px 0px",
  },
  button: {
    color: "#3e4045",
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: "transparent",
    },
  },
}));
