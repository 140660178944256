import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { AppConstant, LangConstant } from "const";
import clsx from "clsx";

const PackageItem = ({ packageType }) => {
  const classes = useStyles();

  let bgClasses = "";
  switch (packageType) {
    case AppConstant.PACKAGE_TYPE.free:
      bgClasses = classes.free;
      break;
    case AppConstant.PACKAGE_TYPE.standard:
      bgClasses = classes.standard;
      break;
    case AppConstant.PACKAGE_TYPE.premium:
      bgClasses = classes.premium;
      break;
    case AppConstant.PACKAGE_TYPE.enterprise:
      bgClasses = classes.enterprise;
      break;
    default:
      break;
  }

  return (
    <Box className={clsx(classes.root, bgClasses)}>
      <Typography className={clsx("medium-sm-txt", classes.packageName)}>
        {LangConstant.OBJ_PACKAGES[packageType]?.name}
      </Typography>
    </Box>
  );
};

PackageItem.propTypes = {
  packageType: PropTypes.number,
};
PackageItem.defaultProps = { packageType: AppConstant.PACKAGE_TYPE.free };

export default memo(PackageItem);

const useStyles = makeStyles(() => ({
  root: {
    width: "auto",
    height: 24,
    padding: "2px 16px",
    borderRadius: 4,
  },
  packageName: {
    textAlign: "center",
    lineHeight: 1.67,
    color: "#3e4045",
  },

  free: {
    width: 85,
    backgroundColor: "rgba(101, 179, 157, 0.2)",
  },
  standard: {
    width: 85,
    backgroundColor: "rgba(79, 139, 255, 0.2)",
  },
  premium: {
    width: 85,
    backgroundColor: "rgba(254, 186, 64, 0.2)",
  },
  enterprise: {
    width: 85,
    backgroundColor: "rgba(255, 74, 94, 0.2)",
  },
}));
