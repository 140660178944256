import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box } from "@material-ui/core";
import ManageLayout from "layouts/ManageLayout";
import { CategoryInList, CreateAndModifyCategoryDialog } from "./components";
import BlogsCategoryAction from "redux/blogs-category.redux";
import { isArrayNotEqual } from "utils";

const ManageCategory = () => {
  const dispatch = useDispatch();
  const listCategories = useSelector(({ blogsCategoryRedux }) => blogsCategoryRedux.data);

  const [categoryData, setCategoryData] = useState([]);
  const [isOpenDialog, seIsOpenDialog] = useState(false);

  const onOpenCreateCategory = () => {
    seIsOpenDialog(true);
  };

  const onCloseCreatDialog = () => {
    seIsOpenDialog(false);
  };

  useEffect(() => {
    dispatch(BlogsCategoryAction.getBlogsCategoryList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (listCategories && isArrayNotEqual(listCategories, categoryData)) {
      setCategoryData(listCategories);
    }
  }, [listCategories]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <ManageLayout title="Quản lý danh mục" buttonProps={{ onClick: onOpenCreateCategory }} />
      <Box height="calc(100vh - 170px)" overflow="auto" padding="24px 32px 8px">
        {categoryData &&
          categoryData.map((data, index) => (
            <Box key={index} marginBottom="16px">
              <CategoryInList data={data} position={index + 1} />
            </Box>
          ))}
      </Box>

      <CreateAndModifyCategoryDialog isShow={isOpenDialog} onClose={onCloseCreatDialog} title="Thêm mới danh mục" />
    </>
  );
};

export default ManageCategory;
