import { ApiConstant } from "const";
import { call, put, select } from "redux-saga/effects";
import AccountAction from "redux/account.redux";
import { AccountService } from "services";
import { toCamel, toSnake } from "utils";

export function* getListAccount() {
  try {
    let data = { power: 1 };

    let response = yield call(AccountService.getListAccount, data);
    if (response.status === ApiConstant.STT_OK) {
      let responseData = toCamel(response.data.data);
      yield put(AccountAction.accountSuccess({ data: responseData }));
    } else {
      let responseData = response.data.error;
      yield put(AccountAction.accountFailure({ error: responseData }));
    }
  } catch (error) {
    yield put(AccountAction.accountFailure(error));
  }
}

export function* createAccount(action) {
  try {
    const {
      accountRedux: { data: dataAccount },
    } = yield select();
    const { data } = action;

    let response = yield call(AccountService.postAccount, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      let responseData = toCamel(response.data.data);
      yield put(
        AccountAction.accountSuccess({
          data: [...dataAccount, responseData],
          isCreateSuccess: true,
        }),
      );
    } else {
      let responseData = response.data.error;
      yield put(AccountAction.accountFailure({ error: responseData }));
    }
  } catch (error) {
    yield put(AccountAction.accountFailure(error));
  }
}

export function* updateAccount(action) {
  try {
    const {
      accountRedux: { data: dataAccount },
    } = yield select();
    const { data } = action;

    let response = yield call(AccountService.putAccount, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      let newArrayAccount = dataAccount.map(account =>
        account.id === data.id
          ? {
              ...data,
              state: data.state,
            }
          : account,
      );
      yield put(
        AccountAction.accountSuccess({
          data: newArrayAccount,
          isUpdateSuccess: true,
        }),
      );
    } else {
      let responseData = response.data.error;
      yield put(AccountAction.accountFailure({ error: responseData }));
    }
  } catch (error) {
    yield put(AccountAction.accountFailure(error));
  }
}

export function* getAccountDetails(action) {
  try {
    const { data } = action;

    let response = yield call(AccountService.getAccount, data);
    if (response.status === ApiConstant.STT_OK) {
      yield put(AccountAction.accountSuccess({ accountDetails: toCamel(response.data?.data) }));
    } else {
      let responseData = response.data.error;
      yield put(AccountAction.accountFailure({ error: responseData }));
    }
  } catch (error) {
    yield put(AccountAction.accountFailure(error));
  }
}

export function* changePassword(action) {
  try {
    const { data } = action;

    let response = yield call(AccountService.changePassword, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      yield put(AccountAction.accountSuccess({ isChangePasswordSuccess: true }));
    } else {
      let responseData = response.data.error;
      yield put(AccountAction.accountFailure({ error: responseData }));
    }
  } catch (error) {
    yield put(AccountAction.accountFailure(error));
  }
}
