import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { AppConstant, LangConstant } from "const";
import clsx from "clsx";

const PaymentStatus = ({ paymentStatus }) => {
  const classes = useStyles();

  let bgClasses = "";
  switch (paymentStatus) {
    case AppConstant.PAYMENTS_STATUS.unpaid:
      bgClasses = classes.unpaid;
      break;
    case AppConstant.PAYMENTS_STATUS.paid:
      bgClasses = classes.paid;
      break;
    case AppConstant.PAYMENTS_STATUS.free:
      bgClasses = classes.free;
      break;
    default:
      break;
  }

  return (
    <Box className={clsx(classes.root, bgClasses)}>
      <Typography className={clsx("medium-sm-txt", classes.contentStatus)}>
        {LangConstant.OBJ_PACKAGES_STATUS[paymentStatus]?.name}
      </Typography>
    </Box>
  );
};

PaymentStatus.propTypes = {
  paymentStatus: PropTypes.number.isRequired,
};
PaymentStatus.defaultProps = {};

export default memo(PaymentStatus);

const useStyles = makeStyles(() => ({
  root: {
    width: "auto",
    padding: "2px 16px",
    borderRadius: 4,
  },
  contentStatus: {
    textAlign: "center",
    lineHeight: 1.67,
    color: "#3e4045",
  },
  free: {
    width: 82,
    backgroundColor: "rgba(101, 179, 157, 0.2)",
  },
  paid: {
    width: 113,
    backgroundColor: "rgba(79, 139, 255, 0.2)",
  },
  unpaid: {
    width: 128,
    backgroundColor: "rgba(255, 74, 94, 0.2)",
  },
}));
