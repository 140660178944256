import React, { memo, useState, useEffect } from "react";
import { makeStyles, Box, Button, IconButton, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import BlogsLayout from "layouts/BlogsLayout";
import { Alert, ConfirmDialog } from "components";
import { TabBlogs } from "./components";
import { useDispatch, useSelector } from "react-redux";
import BlogItemAction from "redux/blog-item.redux";
import { AppConstant, LangConstant } from "const";
import stringFormat from "string-format";
import { Prompt } from "react-router-dom";
import { handleBeforeUnload } from "utils";
import clsx from "clsx";

const PostBlog = () => {
  const defaultClasses = useStyles();
  const dispatch = useDispatch();

  const isCreateSuccess = useSelector(state => state.blogItemRedux.isCreateSuccess);
  const listCategory = useSelector(state => state.blogsCategoryRedux.data);
  let listCategoryId = listCategory.map(dataMap => dataMap.id);

  const [formDataInput, setFormDataInput] = useState(DEFAULT_FORM_DATA);
  const [categoryValue, setCategoryValue] = useState(OPT_ALL_ITEMS_ID);
  const [flagHomeValue, setFlagHomeValue] = useState(AppConstant.STATUS_DISPLAY_HOME_PAGE.hidden);
  const [isDisplayAlert, setIsDisplayAlert] = useState(false);
  const [isOpenDialogConfirm, setIsOpenDialogConfirm] = useState(false);

  const onChangeCategory = e => {
    let valueCategory = e.target.value;
    let arrayCategory = [];
    if (valueCategory !== OPT_ALL_ITEMS_ID) {
      arrayCategory.push(valueCategory);
    } else {
      arrayCategory = listCategoryId;
    }
    setFormDataInput(currentData => ({ ...currentData, blogCategoryIdList: arrayCategory }));
    setCategoryValue(valueCategory);
  };

  const onCheckDisplayInHomePage = e => {
    let checkData = e.target.checked
      ? AppConstant.STATUS_DISPLAY_HOME_PAGE.display
      : AppConstant.STATUS_DISPLAY_HOME_PAGE.hidden;
    setFormDataInput(currentData => ({ ...currentData, flagShowToHomePage: checkData }));
    setFlagHomeValue(checkData);
  };

  const onCreateBlogs = status => {
    let arrayCategory = [];
    if (categoryValue !== OPT_ALL_ITEMS_ID) {
      arrayCategory.push(categoryValue);
    } else {
      arrayCategory = listCategoryId;
    }
    let newFormData = { ...formDataInput, blogCategoryIdList: arrayCategory, published: status };
    if (newFormData.title.vi && newFormData.content.vi) {
      dispatch(BlogItemAction.createBlogItem(newFormData));
    }
  };

  const onCreateNew = () => {
    if (formDataInput.title.vi && formDataInput.content.vi) {
      setIsOpenDialogConfirm(true);
    }
  };

  const onConfirmCreateNewBlogs = () => {
    let arrayCategory = [];
    if (categoryValue !== OPT_ALL_ITEMS_ID) {
      arrayCategory.push(categoryValue);
    } else {
      arrayCategory = listCategoryId;
    }
    let newFormData = { ...formDataInput, blogCategoryIdList: arrayCategory };
    dispatch(BlogItemAction.createBlogItem(newFormData));
  };

  const onChangeData = data => {
    setFormDataInput({ ...formDataInput, ...data });
  };

  const onCloseAlert = () => {
    setIsDisplayAlert(false);
  };

  useEffect(() => {
    if (isCreateSuccess) {
      dispatch(BlogItemAction.blogItemReset());
      if (isOpenDialogConfirm) {
        setIsOpenDialogConfirm(false);
      } else {
        setIsDisplayAlert(true);
      }
      setFormDataInput(DEFAULT_FORM_DATA);
      setCategoryValue(OPT_ALL_ITEMS_ID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateSuccess]);

  useEffect(() => {
    if (checkInputDataBeforeChangePage(formDataInput)) {
      window.addEventListener("beforeunload", handleBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  }, [formDataInput]);

  return (
    <Box>
      <Prompt
        when={checkInputDataBeforeChangePage(formDataInput)}
        message={() => `Các thay đổi bạn đã thực hiện có thể không được lưu.`}
      />
      <BlogsLayout
        title={
          <Typography variant="subtitle2" className={defaultClasses.title}>
            Tạo bài đăng mới
          </Typography>
        }
        isDisplaySaveDraft
        valueHomePage={flagHomeValue}
        statusValue="Bản nháp"
        onCreateBlogs={onCreateBlogs}
        onCheckDisplay={onCheckDisplayInHomePage}
        propsCategory={{
          value: categoryValue,
          onChangeInput: onChangeCategory,
        }}
      >
        <Box className={defaultClasses.middleLayout}>
          <Button
            onClick={onCreateNew}
            size="small"
            color="primary"
            variant="outlined"
            className={defaultClasses.buttonCreateNew}
          >
            Tạo mới
          </Button>
        </Box>
      </BlogsLayout>
      <TabBlogs onChangeData={onChangeData} data={{ isReset: isCreateSuccess }} />
      {isOpenDialogConfirm && formDataInput.title.vi && (
        <ConfirmDialog
          title="Lưu bài viết hiện tại"
          message={stringFormat(LangConstant.FM_MESSAGE_DIALOG_BLOGS, formDataInput.title.vi)}
          isShow={true}
          onSubmit={onConfirmCreateNewBlogs}
          onClose={() => setIsOpenDialogConfirm(false)}
        />
      )}
      {isDisplayAlert && (
        <Alert
          isShow={true}
          onClose={onCloseAlert}
          message="Bài viết đã được đăng"
          type="success"
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          action={
            <IconButton onClick={onCloseAlert} className={defaultClasses.button}>
              <Close className={defaultClasses.iconClose} />
            </IconButton>
          }
          classes={{
            root: defaultClasses.rootAlert,
            action: defaultClasses.action,
            icon: defaultClasses.icon,
            message: clsx("regular-sm-txt", defaultClasses.message),
          }}
        />
      )}
    </Box>
  );
};

const OPT_ALL_ITEMS_ID = "all";

const DEFAULT_FORM_DATA = {
  title: {
    vi: "",
    en: "",
  },
  content: {
    vi: "",
    en: "",
  },
  published: AppConstant.STATUS_OPTION.publish,
  thumbnail: "",
  blogCategoryIdList: [],
  description: {
    vi: "",
    en: "",
  },
  flagShowToHomePage: AppConstant.STATUS_DISPLAY_HOME_PAGE.hidden,
  hashtags: [],
};

export default memo(PostBlog);

const checkInputDataBeforeChangePage = data => {
  if (
    data.title.vi ||
    data.title.en ||
    data.content.vi ||
    data.content.vi ||
    data.thumbnail ||
    data.description.vi ||
    data.description.en ||
    data.blogCategoryIdList.length > 0
  ) {
    return true;
  }
  return false;
};

const useStyles = makeStyles(theme => ({
  middleLayout: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "flex-end",
  },
  title: {
    height: 24,
    margin: "3px 0",
    lineHeight: 1.5,
  },
  buttonCreateNew: {
    marginRight: 8,
    fontWeight: 600,
    "&:hover": {
      color: theme.palette.white,
      backgroundColor: theme.palette.primary.main,
    },
  },
  rootAlert: {
    width: 400,
    height: 30,
    padding: "5px 8px",
    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.16)",
    backgroundColor: "rgba(101, 179, 157, 0.5)",
    color: "#3e4045",
  },
  button: {
    padding: 0,
  },
  iconClose: {
    width: 20,
    height: 20,
  },
  icon: {
    display: "none",
  },
  action: {
    marginRight: 0,
    paddingLeft: 8,
    display: "unset",
  },
  message: {
    width: "100%",
    lineHeight: 1.67,
    padding: 0,
    textAlign: "center",
  },
}));
