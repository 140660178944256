import React, { useState, useEffect } from "react";
import { makeStyles, Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import StringFormat from "string-format";
import ManageLayout from "layouts/ManageLayout";
import { AppConstant, LangConstant, PathConstant } from "const";
import { PaginationFooter } from "components";
import HeaderBar from "./HeaderBar";
import QuestionItemList, { ACTION_OPTION } from "./QuestionItemList";
import EditQuestion from "./EditQuestion";
import QuestionCategoryAction from "redux/question-category.redux";
import QuestionAction from "redux/question.redux";
import { isArrayNotEqual } from "utils";

const ManageQuestions = () => {
  const defaultClasses = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { data: arrayQuestionData, ...pagination } = useSelector(props => props.helpQuestionRedux.data);
  const isSuccess = useSelector(props => props.helpQuestionRedux.isSuccess);

  const [formData, setFormData] = useState({
    state: ACTION_OPTION.noAction,
    data: {},
  });
  const [formDataFilter, setFormDataFilter] = useState({
    filter: "",
    helpCategoryId: OPT_ALL_ITEMS_ID,
    published: OPT_ALL_ITEMS_ID,
    flagShowToHomePage: OPT_ALL_ITEMS_ID,
  });
  const [isShowFilter, setIsShowFilter] = useState(false);
  const [pageData, setPageData] = useState(DEFAULT_PAGE);
  const [arrayQuestion, setArrayQuestion] = useState([]);
  const [sizeQuestion, setSizeQuestion] = useState(0);

  const onOpenCategory = formData => {
    setFormData(formData);
  };

  const onCreateNewQuestion = () => {
    history.push(PathConstant.CREATE_QUESTION);
  };

  const onChangePage = (_, newPage) => {
    let params = { page: newPage, filter: formDataFilter.filter };
    if (formDataFilter.helpCategoryId !== OPT_ALL_ITEMS_ID) {
      params.helpCategoryId = formDataFilter.helpCategoryId;
    }
    if (formDataFilter.published !== OPT_ALL_ITEMS_ID) {
      params.published = formDataFilter.published;
    }
    if (formDataFilter.flagShowToHomePage !== OPT_ALL_ITEMS_ID) {
      params.flagShowToHomePage = formDataFilter.flagShowToHomePage;
    }
    dispatch(QuestionAction.getHelpQuestion(params));
  };

  const onClose = () => {
    setFormData({
      state: ACTION_OPTION.noAction,
      data: {},
    });
  };

  useEffect(() => {
    dispatch(QuestionCategoryAction.getHelpCategory());
    dispatch(QuestionAction.getHelpQuestion());
  }, []);

  useEffect(() => {
    if ((arrayQuestionData && isArrayNotEqual(arrayQuestionData, arrayQuestion)) || isSuccess) {
      let countQuestionPublish = arrayQuestionData.filter(item => Boolean(item.published)).length;
      setSizeQuestion(countQuestionPublish);
      setArrayQuestion(arrayQuestionData);
      setPageData({
        page: pagination.page,
        rowsPerPage: 10,
        total: pagination.total || 0,
        totalPage: pagination.totalPage,
      });
      if (isSuccess) {
        dispatch(QuestionAction.resetHelpQuestionSuccess());
        if (formData.state !== ACTION_OPTION.edit) {
          onClose();
        }
      }
    }
  }, [arrayQuestionData]);

  return formData.state === ACTION_OPTION.edit ? (
    <EditQuestion data={formData.data} onClose={onClose} />
  ) : (
    <ManageLayout
      title="Quản lý câu hỏi"
      subtitle={StringFormat(LangConstant.FM_SHOWING, sizeQuestion)}
      buttonProps={{ onClick: onCreateNewQuestion }}
    >
      <HeaderBar
        dataFilter={formDataFilter}
        onChangeFilter={filter => setFormDataFilter(filter)}
        isShowFilter={isShowFilter}
        onShowFilter={() => setIsShowFilter(!isShowFilter)}
      />
      <Box className={defaultClasses.root}>
        {arrayQuestion.map((data, index) => (
          <Box key={data.id} marginBottom="16px">
            <QuestionItemList
              data={{
                ...data,
                helpCategoryIdList: data?.helpCategoryList?.map(item => item.id) || [],
                sortOrder: index + 1 + (pagination.page - 1) * 10,
              }}
              onEditItem={onOpenCategory}
              disabledRemove={data.published === AppConstant.STATUS_OPTION.draft}
            />
          </Box>
        ))}
      </Box>
      <Box marginLeft="32px">
        <PaginationFooter data={pageData} onChangePage={onChangePage} />
      </Box>
    </ManageLayout>
  );
};

const OPT_ALL_ITEMS_ID = "all";

const DEFAULT_PAGE = {
  total: 0,
  page: 1,
  rowsPerPage: 10,
  totalPage: 0,
};

export default ManageQuestions;

const useStyles = makeStyles(() => ({
  root: {
    height: "calc(100vh - 350px)",
    overflow: "auto",
    padding: "24px 32px 8px",
    marginBottom: 50,
  },
}));
