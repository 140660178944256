import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, Paper, Box, IconButton, Tooltip, Typography } from "@material-ui/core";
import { EditOutlined, Replay, DeleteOutlined } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import StringFormat from "string-format";
import clsx from "clsx";
import { convertTimestampToDate } from "utils";
import { StatusItem } from "components";
import { AppConstant } from "const";
import ConfirmDialog from "components/dialog/ConfirmDialog";
import QuestionCategoryAction from "redux/question-category.redux";

const CategoryItemList = props => {
  const { data, onEditItem, disabledRemove } = props;
  const defaultClasses = useStyles();
  const dispatch = useDispatch();

  const isSuccess = useSelector(props => props.helpCategoryRedux.isSuccess);

  const [action, setAction] = useState(DEFAULT_ACTION);

  const onClickAction = state => {
    switch (state) {
      case ACTION_OPTION.noAction:
        setAction(DEFAULT_ACTION);
        break;
      case ACTION_OPTION.edit:
        onEditItem({ data: data, state: ACTION_OPTION.edit });
        break;
      case ACTION_OPTION.remove:
        setAction({
          state: ACTION_OPTION.remove,
          title: StringFormat(FM_TITLE.remove, data.title.vi),
          message: StringFormat(FM_MESSAGE.remove, data.title.vi),
        });
        break;
      case ACTION_OPTION.delete:
        setAction({
          state: ACTION_OPTION.delete,
          title: StringFormat(FM_TITLE.delete, data.title.vi),
          message: StringFormat(FM_MESSAGE.delete, data.title.vi),
        });
        break;
      default:
        break;
    }
  };

  const onConfirmAction = () => {
    if (action.state === ACTION_OPTION.remove) {
      let newData = { ...data, published: AppConstant.STATUS_OPTION.draft };
      dispatch(QuestionCategoryAction.updateHelpCategory(newData));
    } else if (action.state === ACTION_OPTION.delete) {
      dispatch(QuestionCategoryAction.deleteHelpCategory({ id: data.id }));
    }
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(QuestionCategoryAction.resetHelpCategorySuccess());
      setAction(DEFAULT_ACTION);
    }
  }, [isSuccess]);

  return (
    <Box display="flex">
      <Box className={defaultClasses.coverNumber}>
        <Typography className={clsx("medium-md-txt", defaultClasses.number)}>{data?.sortOrder + 1}</Typography>
      </Box>
      <Paper className={defaultClasses.paperRoot}>
        <Box className={defaultClasses.mainContent}>
          <Box flexBasis="25%">
            <Typography className={clsx("semiBold-lg-txt ellipsis", defaultClasses.highlightTitle)}>
              {data.title.vi}
            </Typography>
            <Typography className={clsx("medium-md-txt ellipsis", defaultClasses.greyTextTypography)}>
              {data.title.en}
            </Typography>
          </Box>
          <Box flexBasis="25%">
            <Typography
              className={clsx(
                "medium-md-txt ellipsis",
                defaultClasses.contentCategory,
                defaultClasses.hiddenTextTwoLine,
              )}
            >
              {data.description.vi}
            </Typography>
          </Box>
          <Box flexBasis="20%">
            <Typography
              className={clsx(
                "medium-md-txt ellipsis",
                defaultClasses.greyTextTypography,
                defaultClasses.hiddenTextTwoLine,
              )}
            >
              Ngày tạo: {data?.created ? convertTimestampToDate(data.created) : ""}
            </Typography>
          </Box>
          <Box flexBasis="10%">
            <StatusItem status={data.published} />
          </Box>
          <Box flexBasis="10%">
            <Typography
              className={clsx(
                "medium-md-txt ellipsis",
                defaultClasses.greyTextTypography,
                defaultClasses.hiddenTextTwoLine,
              )}
            >
              {StringFormat(FM_TOTAL_QUESTION, data.numberQuestion)}
            </Typography>
          </Box>
          <Box className={defaultClasses.actionRoot}>
            <Tooltip title="Sửa" classes={{ tooltip: clsx("medium-sm-txt", defaultClasses.tooltip) }}>
              <IconButton
                component="div"
                onClick={() => onClickAction(ACTION_OPTION.edit)}
                className={defaultClasses.buttonIconEdit}
              >
                <EditOutlined className={defaultClasses.iconEdit} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Thu hồi" classes={{ tooltip: clsx("medium-sm-txt", defaultClasses.tooltip) }}>
              <IconButton
                component="div"
                onClick={() => onClickAction(ACTION_OPTION.remove)}
                disabled={disabledRemove}
                classes={{ root: defaultClasses.buttonIconDelete, disabled: defaultClasses.disabledButton }}
              >
                <Replay className={defaultClasses.iconDelete} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Xóa" classes={{ tooltip: clsx("medium-sm-txt", defaultClasses.tooltip) }}>
              <IconButton
                component="div"
                onClick={() => onClickAction(ACTION_OPTION.delete)}
                className={defaultClasses.buttonIconDelete}
              >
                <DeleteOutlined className={defaultClasses.iconDelete} />
              </IconButton>
            </Tooltip>
            {[ACTION_OPTION.remove, ACTION_OPTION.delete].includes(action.state) && (
              <ConfirmDialog
                isShow={true}
                onClose={() => onClickAction(ACTION_OPTION.noAction)}
                onSubmit={onConfirmAction}
                title={action.title}
                message={action.message}
              />
            )}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

CategoryItemList.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    sortOrder: PropTypes.number,
    thumbnail: PropTypes.shape({
      vi: PropTypes.string,
      en: PropTypes.string,
    }),
    title: PropTypes.shape({
      vi: PropTypes.string,
      en: PropTypes.string,
    }),
    description: PropTypes.shape({
      vi: PropTypes.string,
      en: PropTypes.string,
    }),
    created: PropTypes.number,
    numberQuestion: PropTypes.number,
    published: PropTypes.number,
  }),
  onEditItem: PropTypes.func,
  disabledRemove: PropTypes.bool,
};

CategoryItemList.defaultProps = {
  onEditItem: () => {},
  disabledRemove: false,
};

export default memo(CategoryItemList);

const FM_TOTAL_QUESTION = "{0} câu hỏi";

export const ACTION_OPTION = {
  noAction: 0,
  add: 1,
  edit: 2,
  remove: 3,
  delete: 4,
};

const DEFAULT_ACTION = {
  state: ACTION_OPTION.noAction,
  title: "",
  message: "",
};

const FM_TITLE = {
  remove: `Thu hồi danh mục "{0}"?`,
  delete: `Xóa danh mục "{0}"?`,
};

const FM_MESSAGE = {
  remove: `Bạn có chắc muốn thu hồi danh mục "{0}" trên website không?`,
  delete: `Bạn có chắc muốn xóa danh mục "{0}" hay không?`,
};

const useStyles = makeStyles(theme => ({
  coverNumber: {
    width: 24,
    height: 24,
    marginRight: 17,
    backgroundColor: "#ffffff",
  },
  number: {
    textAlign: "center",
    color: "#3e4045",
    lineHeight: 1.57,
  },
  paperRoot: {
    width: "100%",
    height: 83,
    maxHeight: 83,
    display: "flex",
    borderRadius: 4,
    boxShadow: "0 1px 6px 0 rgba(0, 0, 0, 0.16)",
  },
  mainContent: {
    display: "flex",
    margin: theme.spacing(2, 1),
    flexGrow: 1,
    "& > div": {
      marginLeft: 8,
      marginRight: 8,
    },
  },
  highlightTitle: {
    color: "#3e4045",
    lineHeight: 1.5,
    wordBreak: "break-word",
  },
  greyTextTypography: {
    lineHeight: 1.57,
    color: theme.palette.grey[500],
    wordBreak: "break-word",
  },
  contentCategory: {
    color: "#3e4045",
    lineHeight: 1.57,
    wordBreak: "break-word",
  },
  hiddenTextTwoLine: {
    WebkitLineClamp: 2,
  },
  tooltip: {
    height: 20,
    marginLeft: 50,
    marginTop: 2,
    padding: "0 5px",
    backgroundColor: "#7f838c",
    lineHeight: 1.67,
    textAlign: "center",
    borderRadius: 0,
  },
  actionRoot: {
    display: "flex",
    justifyContent: "flex-end",
    width: "10%",
  },
  buttonIconEdit: {
    width: 24,
    height: 24,
    padding: 0,
    borderRadius: 0,
    border: "1px solid " + theme.palette.grey[200],
    "&:hover": {
      border: "1px solid #feba40",
    },
  },
  iconEdit: {
    width: 24,
    height: 24,
    padding: 2,
    color: theme.palette.grey[500],
    "&:hover": {
      color: "#feba40",
    },
  },
  buttonIconDelete: {
    width: 24,
    height: 24,
    padding: 0,
    borderRadius: 0,
    border: "1px solid " + theme.palette.grey[200],
    "&:hover": {
      border: "1px solid " + theme.palette.primary.main,
    },
  },
  iconDelete: {
    width: 24,
    height: 24,
    padding: 2,
    color: theme.palette.grey[500],
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  disabledButton: {
    opacity: 0.4,
  },
}));
