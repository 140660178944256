import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { AppConstant, LangConstant } from "const";
import { format as DateFormat } from "date-fns";
import { CheckBoxItem } from "components";
import clsx from "clsx";

const BodyRow = props => {
  const { isChecked, data, onChecked } = props;
  const defaultClasses = useStyles();

  let isRegistration = Boolean(onChecked);

  return (
    <Box className={defaultClasses.root}>
      <Box flexBasis="5%" paddingRight="12px">
        <Typography className={defaultClasses.fontText}>{data.number}</Typography>
      </Box>
      <Box flexBasis="13.2%" paddingRight="12px">
        <Typography className={defaultClasses.fontText}>{data.brandName}</Typography>
      </Box>
      <Box flexBasis="9%" paddingRight="12px">
        <Typography className={defaultClasses.fontText}>{AppConstant.BUSINESS_AREAS[data.businessScope]}</Typography>
      </Box>
      <Box flexBasis="18.2%" paddingRight="12px">
        <Typography className={defaultClasses.fontText}>
          Gói: <b>{LangConstant.PACKAGE_TYPE[data.plan]}</b>
        </Typography>
        <Typography className={defaultClasses.fontText}>{data.contactName}</Typography>
        <Typography className={defaultClasses.fontText}>{data.email}</Typography>
        <Typography className={defaultClasses.fontText}>{data.phoneNumber}</Typography>
      </Box>
      <Box flexBasis="18.2%" paddingRight="12px">
        <Typography className={defaultClasses.fontText}>{data.address}</Typography>
      </Box>
      <Box flexBasis="9%" paddingRight="12px">
        <Typography className={defaultClasses.fontText}>
          {DateFormat(data.created * 1000, `${AppConstant.FM_DD_MM_YYYY} ${AppConstant.FM_HH_MM}`)}
        </Typography>
      </Box>
      <Box flexBasis={isRegistration ? "20%" : "27.4%"} paddingRight="12px">
        {data.details && <Typography className={defaultClasses.fontText}>{data.details}</Typography>}
        <Typography className={defaultClasses.fontText}>
          Website:&emsp;
          {data.websiteUrl && (
            <a className={clsx(defaultClasses.link)} href={data.websiteUrl} target="_blank" rel="noopener noreferrer">
              {isRegistration ? "website" : data.websiteUrl}
            </a>
          )}
        </Typography>
        <Typography className={defaultClasses.fontText}>
          Facebook:&emsp;
          {data.facebookUrl && (
            <a className={clsx(defaultClasses.link)} href={data.facebookUrl} target="_blank" rel="noopener noreferrer">
              {isRegistration ? "facebook" : data.facebookUrl}
            </a>
          )}
        </Typography>
        <Typography className={defaultClasses.fontText}>
          Google map:&emsp;
          {data.googleMapUrl && (
            <a className={clsx(defaultClasses.link)} href={data.googleMapUrl} target="_blank" rel="noopener noreferrer">
              {isRegistration ? "google" : data.googleMapUrl}
            </a>
          )}
        </Typography>
        <Typography className={defaultClasses.fontText}>
          Instagram:&emsp;
          {data.instagramUrl && (
            <a className={clsx(defaultClasses.link)} href={data.instagramUrl} target="_blank" rel="noopener noreferrer">
              {isRegistration ? "instagram" : data.instagramUrl}
            </a>
          )}
        </Typography>
      </Box>
      {isRegistration && (
        <Box flexBasis="7.4%" paddingRight="12px" textAlign="right">
          <CheckBoxItem checked={isChecked} onChecked={onChecked} />
        </Box>
      )}
    </Box>
  );
};

BodyRow.propTypes = {
  isChecked: PropTypes.bool,
  data: PropTypes.object,
  onChecked: PropTypes.func,
};
BodyRow.defaultProps = {
  isChecked: false,
};

export default memo(BodyRow);

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    minHeight: 106,
    marginTop: 8,
    padding: "16px 14px",
    boxShadow: "0 1px 6px 0 rgba(0, 0, 0, 0.16)",
    borderRadius: 4,
  },
  fontText: {
    lineHeight: 1.57,
    color: "#3e4045",
    fontSize: 14,
    fontWeight: 400,
    lineBreak: "anywhere",
  },
  fontTextCenter: {
    textAlign: "center",
  },
  link: {
    maxWidth: 80,
    lineBreak: "anywhere",
    color: theme.palette.text.link,
    textDecoration: "unset",
    [theme.breakpoints.up(1500)]: {
      maxWidth: 100,
    },
  },
}));
