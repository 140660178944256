import { ApiConstant } from "const";
import { call, put, select } from "redux-saga/effects";
import QuestionAction from "redux/question.redux";
import { QuestionService } from "services";
import { toCamel, toSnake } from "utils";

export function* getListQuestion(action) {
  try {
    const {
      helpQuestionRedux: { data: dataQuestion },
    } = yield select();
    const { data } = action;

    let formDataPage = formatPage(dataQuestion);

    let response = yield call(QuestionService.getQuestion, toSnake({ ...formDataPage, ...data }));
    if (response.status === ApiConstant.STT_OK) {
      let responseData = toCamel(response.data);
      yield put(QuestionAction.helpQuestionSuccess({ data: responseData }));
    } else {
      let responseData = response.data?.error;
      yield put(QuestionAction.helpQuestionFailure(responseData));
    }
  } catch (error) {
    yield put(QuestionAction.helpQuestionFailure(error));
  }
}

export function* createQuestion(action) {
  try {
    const {
      helpQuestionRedux: { data: reduxData },
    } = yield select();
    const { data } = action;
    let response = yield call(QuestionService.postQuestion, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      let newData = { ...reduxData, data: [...reduxData.data, toCamel(response.data.data)] };
      yield put(QuestionAction.helpQuestionSuccess({ data: newData, isSuccess: true }));
    } else {
      let responseData = response.data?.error;
      yield put(QuestionAction.helpQuestionFailure(responseData));
    }
  } catch (error) {
    yield put(QuestionAction.helpQuestionFailure(error));
  }
}

export function* updateQuestion(action) {
  try {
    const {
      helpQuestionRedux: { data: reduxData },
    } = yield select();
    const { data } = action;

    let response = yield call(QuestionService.putQuestion, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      let selectedIndex = reduxData.data.findIndex(key => key.id === data.id);

      let updateArray = [...reduxData.data];
      updateArray[selectedIndex] = Object.assign(updateArray[selectedIndex], toCamel(response.data.data));

      yield put(QuestionAction.helpQuestionSuccess({ data: { ...reduxData, data: updateArray }, isSuccess: true }));
    } else {
      let responseData = response.data?.error;
      yield put(QuestionAction.helpQuestionFailure(responseData));
    }
  } catch (error) {
    yield put(QuestionAction.helpQuestionFailure(error));
  }
}

export function* deleteQuestion(action) {
  try {
    const {
      helpQuestionRedux: { data: reduxData },
    } = yield select();
    const { data } = action;

    let response = yield call(QuestionService.deleteQuestion, data);
    if (response.status === ApiConstant.STT_OK) {
      let newData = reduxData.data.filter(item => item.id !== data.id);
      yield put(QuestionAction.helpQuestionSuccess({ data: { ...reduxData, data: newData }, isSuccess: true }));
    } else {
      let responseData = response.data?.error;
      yield put(QuestionAction.helpQuestionFailure(responseData));
    }
  } catch (error) {
    yield put(QuestionAction.helpQuestionFailure(error));
  }
}

const formatPage = data => ({
  page: data?.page || 1,
  size: data?.size || 10,
  asc: 1,
  filter: data?.filter || "",
});
