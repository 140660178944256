import React, { memo, useRef, useState } from "react";

import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { Box, IconButton, Link, makeStyles, Menu, MenuItem, OutlinedInput, Typography } from "@material-ui/core";
import clsx from "clsx";

const ShopInfoLine = ({
  isEditing,
  title,
  placeHolder,
  classes,
  isMenuSelect,
  selectArray,
  selectedMenuItem,
  onChange,
  value,
  isLink,
}) => {
  const defaultClasses = useStyles({ isEditing: isEditing, isLink: isLink });
  const inputRef = useRef();

  const [selectMenuAnchor, setSelectMenuAnchor] = useState(null);

  let isShowDropdown = Boolean(selectMenuAnchor);

  const onClickDropIcon = () => {
    setSelectMenuAnchor(isShowDropdown ? null : inputRef.current);
  };

  const onCloseSelectList = () => {
    setSelectMenuAnchor(null);
  };

  const onChangeValue = value => {
    isEditing && onChange(value);
  };

  return (
    <Box className={clsx(defaultClasses.inputLine, classes?.inputLineRoot)}>
      <Typography className={defaultClasses.inputLabel}>{title}</Typography>
      {isEditing ? (
        isMenuSelect ? (
          <>
            <OutlinedInput
              ref={inputRef}
              value={selectedMenuItem ? selectedMenuItem : ""}
              endAdornment={
                <IconButton onClick={onClickDropIcon} className={defaultClasses.arrowButton}>
                  {isShowDropdown ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              }
              onClick={onClickDropIcon}
              readOnly
              classes={{
                root: clsx(defaultClasses.inputRoot, classes?.inputRoot),
                input: clsx(defaultClasses.input, defaultClasses.selectInput, classes?.input),
                adornedEnd: defaultClasses.adornedEnd,
              }}
              placeholder={placeHolder}
            />
            <SelectMenu
              isShowDropdown={isShowDropdown}
              onCloseSelectList={onCloseSelectList}
              selectMenuAnchor={selectMenuAnchor}
              defaultClasses={defaultClasses}
              selectArray={selectArray}
              onChangeValue={onChangeValue}
              classes={classes}
            />
          </>
        ) : (
          <OutlinedInput
            classes={{
              root: clsx(defaultClasses.inputRoot, classes?.inputRoot),
              input: clsx(defaultClasses.input, classes?.input),
            }}
            placeholder={placeHolder}
            value={value}
            onChange={event => onChangeValue(event.target.value)}
          />
        )
      ) : isLink ? (
        <Link
          target="blank"
          component="a"
          className={clsx(defaultClasses.infoLine, "regular-md-txt", "ellipsis", classes?.textClass)}
          href={value}
        >
          {value}
        </Link>
      ) : (
        <Typography className={clsx(defaultClasses.infoLine, "regular-md-txt", classes?.textClass)}>{value}</Typography>
      )}
    </Box>
  );
};

const STATUS_SHOP = {
  1: "Active",
  2: "Disable",
};

export default memo(ShopInfoLine);

const SelectMenu = ({
  isShowDropdown,
  onCloseSelectList,
  selectMenuAnchor,
  defaultClasses,
  selectArray,
  onChangeValue,
  classes,
}) => {
  const classWithShopStatus = status => {
    return status === Object.values(STATUS_SHOP)[0]
      ? defaultClasses.activeShopStatus
      : status === Object.values(STATUS_SHOP)[1]
      ? defaultClasses.deActiveShopStatus
      : "";
  };

  return (
    <>
      <Menu
        open={isShowDropdown}
        onBackdropClick={onCloseSelectList}
        anchorEl={selectMenuAnchor}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        getContentAnchorEl={null}
        classes={{ paper: defaultClasses.selectMenu }}
      >
        {selectArray &&
          selectArray.map((element, index) => (
            <MenuItem
              key={index}
              value={element}
              onClick={() => onChangeValue(element)}
              classes={{
                root: clsx(defaultClasses.menuItemRoot, classWithShopStatus(element), classes?.menuItem),
              }}
            >
              {element}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  inputRoot: {
    height: 40,
    width: "70%",
    fontSize: 14,
    fontWeight: "normal",
  },

  input: {
    color: prop => (prop.isLink ? theme.palette.text.link : ""),
    "&::placeholder": {
      color: "#979aa1",
      fontSize: 14,
      fontWeight: "normal",
      opacity: 1,
    },
  },

  infoLine: {
    color: prop => (prop.isLink ? theme.palette.text.link : "#3e4045"),
    width: "70%",
  },

  inputLabel: {
    fontSize: 12,
    color: theme.palette.grey[500],
  },

  inputLine: {
    lineBreak: "anywhere",
    marginTop: 8,
    minWidth: 350,
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    marginBottom: prop => (prop.isEditing ? 8 : 20),
  },

  menuItemRoot: {
    fontSize: 14,
    fontWeight: "normal",
    "&:hover": {
      backgroundColor: "#4b4d53",
    },
  },

  arrowButton: {
    padding: 0,
    width: 30,
    height: "100%",
    borderRadius: "unset",
    color: theme.palette.grey[500],
    backgroundColor: theme.palette.white,
  },

  selectMenu: {
    height: "fit-content",
    maxHeight: 150,
    marginTop: 3,
    minWidth: 270,
    width: "30%",
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#d4d5d8",
    },
    "&::-webkit-scrollbar": {
      width: 4,
    },
    "&>ul": {
      padding: 0,
    },
  },

  adornedEnd: {
    padding: 0,
  },
  activeShopStatus: {
    color: theme.palette.secondary.dark,
  },
  deActiveShopStatus: {
    color: "#feba40",
  },
  selectInput: {
    fontSize: 14,
    fontWeight: "normal",
  },
  shopFieldMenuItem: {
    "&:hover": {
      color: "#fff",
    },
  },
  title: {
    marginBottom: 18,
  },
  inputLineRoot: {
    marginTop: 18,
  },
  formRoot: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  formContainer: {
    display: "flex",
  },
  formAction: {
    display: "flex",
    justifyContent: "center",
    marginTop: 65,
  },
  cancelButton: {
    borderRadius: 4,
    marginRight: 8,
  },
  confirmButton: {
    borderRadius: 4,
    marginLeft: 8,
  },
}));
